import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import MicNoneRoundedIcon from '@material-ui/icons/MicNoneRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { Form as FormikForm } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import * as common from '../../common/elements/Form';

export const Form = styled(FormikForm)`
  display: grid;
  grid-template-columns: auto 60% auto auto;
  grid-template-areas: 'camera text mic send';
  align-items: center;
  justify-items: center;
  height: 8rem;
  width: 100%;
  margin: 0;
  box-shadow: 0em -4em 20em rgba(122, 122, 122, 0.25);
  position: fixed;
  bottom: 0;
  background: ${({ theme }) => theme.appBackground};
  ${({ $isStaff }) =>
    $isStaff
      ? css`
          position: relative;
        `
      : css`
          @media only screen and (min-width: 700px) {
            width: calc(100vw - max(10vw, 120px));
          }
        `}

  ${({ $renderLinkInput }) =>
    $renderLinkInput &&
    css`
      grid-template-columns: auto 60% auto auto auto;
      grid-template-areas: 'camera text mic link send';
    `}
`;

export const LockedForm = styled(Form).attrs({ as: 'section' })`
  grid-template-columns: 1fr;
  color: ${({ theme }) => theme.textDark};
`;

export const MessageSubmitButton = styled.button`
  grid-area: send;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.userMessage};
  &:disabled {
    color: ${({ theme }) => theme.textDark};
  }
`;

export const MessageInput = styled(common.FieldInput)`
  grid-area: text;
  width: 95%;
  font-size: 1.6rem;
`;

export const CameraLabel = styled.label`
  grid-area: camera;
  margin-right: 0.8em;
  color: ${({ theme }) => theme.textDark};
  ${({ fileSelected }) =>
    fileSelected &&
    css`
      color: ${({ theme }) => theme.userMessage};
    `}
`;
export const RecordButton = styled.button`
  grid-area: mic;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.textDark};
  ${({ fileSelected }) =>
    fileSelected &&
    css`
      color: ${({ theme }) => theme.userMessage};
    `}
  ${({ isRecording }) =>
    isRecording &&
    css`
      color: ${({ theme }) => theme.buttonWarning};
    `}
`;

export const HiddenImageInput = styled.input`
  display: none;
`;

export const CameraIcon = () => (
  <CameraAltOutlinedIcon style={{ fontSize: '3rem' }} />
);

export const MicIcon = () => (
  <MicNoneRoundedIcon style={{ fontSize: '3rem' }} />
);

export const StopIcon = () => <StopRoundedIcon style={{ fontSize: '3rem' }} />;

export const LinkIcon = () => <LinkRoundedIcon style={{ fontSize: '3rem' }} />;

export const AttachLinkButton = styled.button`
  grid-area: link;
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.textDark};
  &:hover {
    cursor: pointer;
  }
`;

export const SendIcon = () => (
  <SendRoundedIcon style={{ fontSize: '3.2rem' }} />
);

export const MessagePreview = styled.div`
  position: fixed;
  bottom: 80px;
  width: 100vw;
  border-radius: 5px;
  background: ${({ theme }) => theme.appBackground};
  box-shadow: 0em -0.4rem 0.4rem rgba(169, 169, 169, 0.25);
  ${({ inLine }) =>
    inLine &&
    css`
      position: absolute;
      right: 0;
    `};

  @media only screen and (min-width: 700px) {
    width: calc(100vw - max(10vw, 120px));
    margin-right: 1em;
  }

  ${({ isStaff }) =>
    isStaff &&
    css`
      @media only screen and (min-width: 700px) {
        width: 25vw;
        min-width: 325px;
        margin-right: 0;
      }
    `}

  ${({ isLinkInputVisible }) =>
    isLinkInputVisible &&
    css`
      bottom: 140px;
    `}
`;

export const LinkInputContainer = styled(MessagePreview)`
  height: 6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const LinkInput = styled(common.FieldInput)`
  width: 85%;
  font-size: 1.6rem;
`;

export const MessageContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: 0.5em;
  @media only screen and (min-width: 700px) {
    margin-right: 4em;
  }
`;

export const CloseErrorButton = styled.button`
  background: none;
  border: none;
  padding: 0.5em;
`;

export const MessageTextArea = styled(common.TextArea)`
  width: 100%;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Students';
