import { Router } from '@reach/router';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users.js';
import Error from '../common/Error.js';
import EvidenceTarget from '../messaging/EvidenceTarget.js';
import * as CSS from './elements/Module';
import LearningResources from './LearningResources.js';
import Task from './Task';
import Tasks from './Tasks';

const Module = observer(({ moduleId, topicId }) => {
  const topicsStore = useContext(TopicsContext);
  const module = topicsStore.getModuleById(moduleId);
  const usersStore = useContext(UsersContext);

  const store = useLocalStore(() => {
    return {
      evidenceOpen: false,
      messageToEvidence: null,
      openEvidence(isOpen, evidence = null, resource = '') {
        store.evidenceOpen = isOpen;
        store.messageToEvidence = evidence;
        store.evidenceResource = resource;
      },
      evidenceResource: '',
    };
  });

  if (!module) return <Error msg="Module not found" />;

  const loggedInUser = usersStore.getLoggedInUser();
  const unreadCommentCount = module.getTotalUnreadTaskComments(
    loggedInUser.private
  );

  return (
    <div data-testid="Module">
      <CSS.StudentHeaderRow>
        <CSS.StudentImage alt={module.moduleName} src={module.imageURL} />
        <CSS.StudentHeader>{module.moduleName}</CSS.StudentHeader>
      </CSS.StudentHeaderRow>
      <CSS.NavRow>
        <CSS.ActiveLink to="learning">Learn</CSS.ActiveLink>
        <CSS.UnreadCount
          badgeContent={unreadCommentCount}
          data-testid="unread-count-tasksLink"
        >
          <CSS.ActiveLink to="tasks">Tasks</CSS.ActiveLink>
        </CSS.UnreadCount>
      </CSS.NavRow>
      {store.messageToEvidence && (
        <EvidenceTarget
          isOpen={store.evidenceOpen}
          message={store.messageToEvidence}
          openEvidence={store.openEvidence}
          evidenceResource={store.evidenceResource}
        />
      )}
      <Router primary={false}>
        <LearningResources path="/" moduleId={moduleId} topicId={topicId} />
        <LearningResources
          path="/learning"
          moduleId={moduleId}
          topicId={topicId}
        />
        <Tasks path="/tasks" moduleId={moduleId} topicId={topicId} />
        <Task path="/tasks/:taskId" moduleId={moduleId} store={store} />
      </Router>
    </div>
  );
});

export default Module;
