import { Link } from '@reach/router';
import styled, { css } from 'styled-components/macro';

export const HomeContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WelcomeHeader = styled.h1`
  color: ${({ theme }) => theme.headerColour};
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 0.67em 0.5em;
`;

export const ClassroomHeader = styled.h2`
  font-weight: 500;
  font-size: 3rem;
  line-height: 3.9rem;
  margin: auto 0 auto 0.5em;
`;

export const ParticipantsText = styled.p`
  margin: 0;
`;

export const ClassroomPlaceholder = styled.h2`
  font-weight: 600;
  font-size: 2.4rem;
  margin: 0.2em;
`;

export const HomeHeader = styled.h2`
  color: ${({ theme }) => theme.headerColour};
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.1rem;
  margin: 0.8em 1.6em 0.1em 1.6em;
  @media only screen and (min-width: 700px) {
    text-align: center;
    margin: 1.8em;
  }
`;

export const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
  display: flex;
`;

export const LessonLink = styled(UnstyledLink)`
  align-items: center;
`;

export const ModuleLessonLink = styled(LessonLink)`
  align-self: flex-start;
  margin-bottom: 0.5em;
`;

export const LessonThumb = styled.img`
  width: 5em;
  height: 5em;
  border: 4px solid ${({ theme }) => theme.textLight};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.appBackground};
  object-fit: cover;
`;

export const ProfileThumb = styled(LessonThumb)`
  object-fit: cover;
`;

export const Logos = styled.div`
  display: flex;
  justify-content: ${({ single }) => (single ? 'center' : 'space-between')};
  align-items: center;
  margin: 1em 0;
  ${({ empty }) =>
    empty &&
    css`
      height: 4em;
    `}
`;

export const AppLogo = styled.img`
  height: 100px;
  margin: 4em 0 1em 0;
`;

export const HomeCardLi = styled.li`
  list-style: none;
  margin: 1.5em 3em;

  ${({ isTop }) =>
    isTop &&
    css`
      margin-top: 0.2em;
    `}

  @media only screen and (min-width: 700px) {
    margin: 1.5em;
  }
`;

export * from '../../common/elements/Cards';
export * from '../../common/elements/Form';
export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
export * from '../../common/elements/Students';
