import { ErrorMessage, Field, Formik } from 'formik';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { AlertContext } from '../../contexts/Alert';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { TopicsContext } from '../../contexts/Topics';
import { db } from '../../firebase';
import BrokenLinkIcon from '../../svgs/image-placeholder.svg';
import { formatInternalLink } from '../../utils/formatInternalLink';
import Error from '../common/Error';
import * as CSS from './elements/StaffModule';
import ModuleListItem from './ModuleListItem';

const StaffModuleItemsForm = observer(
  ({ moduleId, topicId, collection = 'learningResources' }) => {
    const topicsStore = useContext(TopicsContext);
    const saveChangesStore = useContext(SaveChangesContext);
    const alertStore = useContext(AlertContext);
    const store = useLocalStore(() => ({
      selectedIndex: 0,
      isNewItem: false,
      setIsNewItem: (isNewItem) => {
        store.isNewItem = isNewItem;
      },
    }));

    const validCollections = ['learningResources', 'tasks'];

    useEffect(() => {
      return () => {
        saveChangesStore.setEdited(false);
      };
    }, [saveChangesStore]);

    useEffect(() => {
      store.selectedIndex = 0;
    }, [collection, store]);

    const module = topicsStore.getModuleById(moduleId);

    if (!validCollections.includes(collection))
      return <Error fullscreen={false} />;

    const selectedItem = module.getItemByIndex(store.selectedIndex, collection);

    const copyLinkToClipboard = async () => {
      const relativePath = `/topics/${topicId}/modules/${moduleId}/${
        collection === 'learningResources' ? 'learning' : 'tasks'
      }/${selectedItem.id}`;

      const internalLink = formatInternalLink(
        relativePath,
        window.location.origin
      );
      try {
        await navigator.clipboard.writeText(internalLink);
        alertStore.setText({
          heading: 'Copied to clipboard',
          text: `Copied student link to: "${selectedItem.instructions}"`,
        });
        alertStore.open();
      } catch {
        alertStore.setText({
          heading: 'Something went wrong',
          text: 'This link was NOT copied to the clipboard',
          isWarning: true,
        });
        alertStore.open();
      }
    };

    const handleSubmit = async ({ embedLink, ...values }, { setTouched }) => {
      setTouched({});

      if (collection === 'learningResources') values.embedLink = embedLink;

      const itemsRef = db
        .collection('topics')
        .doc(topicId)
        .collection('modules')
        .doc(moduleId)
        .collection(collection);

      if (selectedItem.id) {
        await itemsRef.doc(selectedItem.id).update(values);
      } else {
        await itemsRef.add(values);
        module.removeItemByIndex(store.selectedIndex, collection);
      }
      saveChangesStore.setEdited(false);
    };

    const handleDeleteItem = () => {
      const resource =
        collection === 'learningResources' ? 'learning resource' : 'task';

      saveChangesStore.setText({
        heading: `Delete ${resource}`,
        message: `Are you sure you want to delete this ${resource}? It will be removed from any students who are currently using it.`,
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(() => {
        if (store.isNewItem) {
          module.removeItemByIndex(store.selectedIndex, collection);
        } else {
          module.deleteItemById(selectedItem.id, collection);
        }
        store.selectedIndex = 0;
      });
    };

    const itemObjectShape = {
      instructions: yup
        .string()
        .required('Please include instructions for the learning resource'),
    };
    if (collection === 'learningResources') {
      itemObjectShape.embedLink = yup.string().url('Invalid URL').required();
    }
    const validationSchema = yup.object().shape(itemObjectShape);

    return selectedItem ? (
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          index: selectedItem.index,
          instructions: selectedItem.instructions,
          embedLink: selectedItem.embedLink || '',
        }}
        validationSchema={validationSchema}
      >
        {({ values, touched, errors, handleChange, setFieldValue }) => {
          return (
            <Observer>
              {() => {
                return (
                  <CSS.StaffModuleForm
                    data-testid={`StaffModuleItemsForm-${collection}`}
                  >
                    <CSS.StaffRow>
                      <CSS.ModuleNav>
                        {module.getOrderedItems(collection).map((item) => {
                          return (
                            <ModuleListItem
                              key={item.id || item.index}
                              item={item}
                              store={store}
                              collection={collection}
                              moduleId={moduleId}
                              topicId={topicId}
                            />
                          );
                        })}
                        <CSS.AddItemButton
                          type="button"
                          aria-label="add item"
                          disabled={
                            (selectedItem && !selectedItem.id) || module.locked
                          }
                          onClick={() => {
                            const index =
                              module.getMaxItemIndex(collection) + 1;

                            const newItem = {
                              instructions: '',
                              index,
                            };
                            if (collection === 'learningResources') {
                              newItem.embedLink = '';
                            }
                            module.addItem(newItem, collection);
                            store.selectedIndex = index;
                            saveChangesStore.setEdited(true);
                            store.setIsNewItem(true);
                          }}
                        >
                          <CSS.AddItemPlusIcon />
                        </CSS.AddItemButton>
                      </CSS.ModuleNav>

                      <CSS.LinkSaveContainer>
                        <CSS.CopyLinkButton
                          type="button"
                          aria-label="copy link"
                          onClick={copyLinkToClipboard}
                        >
                          <CSS.LinkIcon />
                        </CSS.CopyLinkButton>

                        <CSS.DeleteItemButton
                          data-testid={`StaffModuleItemsForm-deleteItem-${store.selectedIndex}-${selectedItem.id}`}
                          onClick={handleDeleteItem}
                          disabled={module.locked}
                        >
                          Delete
                        </CSS.DeleteItemButton>
                        <CSS.SaveChangesButton
                          disabled={!saveChangesStore.isEdited || module.locked}
                          type="submit"
                        >
                          {!saveChangesStore.isEdited ? 'Saved!' : 'Save'}
                        </CSS.SaveChangesButton>
                      </CSS.LinkSaveContainer>
                    </CSS.StaffRow>
                    <CSS.Label htmlFor="instructions">Instructions</CSS.Label>
                    <Field
                      disabled={module.locked}
                      onChange={(e) => {
                        handleChange(e);
                        saveChangesStore.setEdited(true);
                      }}
                      data-testid="StaffModuleItemsForm-instructions"
                      name="instructions"
                      id="instructions"
                      component={
                        collection === 'learningResources'
                          ? CSS.TextInput
                          : CSS.TextArea
                      }
                    ></Field>
                    <ErrorMessage
                      name="instructions"
                      data-testid="instructions-error-msg"
                      component="p"
                    />
                    {collection === 'learningResources' && (
                      <>
                        <CSS.Label htmlFor="embedLink">Embed link</CSS.Label>
                        <Field
                          disabled={module.locked}
                          onChange={(e) => {
                            const { value } = e.target;
                            const matchRes = value.match(
                              /src=["'](?<src>.*?)["']/
                            );
                            if (matchRes) {
                              setFieldValue('embedLink', matchRes.groups.src);
                            } else setFieldValue('embedLink', value);
                            saveChangesStore.setEdited(true);
                          }}
                          data-testid="StaffModuleItemsForm-embedLink"
                          name="embedLink"
                          id="embedLink"
                          component={CSS.TextInput}
                        ></Field>
                        <ErrorMessage
                          data-testid="embed-link-error-message"
                          name="embedLink"
                          component="p"
                        />
                        <CSS.Label htmlFor="embed-preview">Preview</CSS.Label>
                        <CSS.PreviewContainer>
                          <CSS.EmbedPreview
                            data-testid="StaffModule-preview"
                            id="embed-preview"
                            src={
                              touched.embedLink && errors.embedLink
                                ? BrokenLinkIcon
                                : values.embedLink
                            }
                            title={values.instructions}
                            allowFullScreen
                            allowtransparency
                          >
                            <p>Your browser does not support iframes.</p>
                          </CSS.EmbedPreview>
                        </CSS.PreviewContainer>
                      </>
                    )}
                  </CSS.StaffModuleForm>
                );
              }}
            </Observer>
          );
        }}
      </Formik>
    ) : (
      <CSS.AddFirstItemButton
        aria-label="add item"
        disabled={module.locked}
        onClick={() => {
          const newItem = {
            instructions: '',
            index: 0,
          };
          if (collection === 'learningResources') {
            newItem.embedLink = '';
          }
          module.addItem(newItem, collection);
          store.selectedIndex = 0;
          saveChangesStore.setEdited(true);
          store.setIsNewItem(true);
        }}
      >
        Add your first{' '}
        {collection === 'learningResources' ? 'learning resource' : 'task'}
      </CSS.AddFirstItemButton>
    );
  }
);

export default StaffModuleItemsForm;
