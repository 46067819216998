import styled from 'styled-components/macro';
import * as Form from '../../common/elements/Form';
import { RoundProfileImage } from '../../common/elements/ProfileImage';
import * as StaffCommon from '../../common/elements/Staff';

export const UserInfoSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StaffUserHeader = styled(StaffCommon.StaffHeader)`
  padding-right: 0.4em;
`;

export const StaffUserProfileImage = styled(RoundProfileImage)`
  height: 6em;
  width: 6em;
  margin-right: 1em;
  margin-bottom: 1em;
`;

export const StaffRowFill = styled(StaffCommon.StaffRow)`
  flex-grow: 1;
`;

export const ChatButton = styled(Form.CreateButton)`
  margin-top: 0.4em;
  &:hover {
    cursor: pointer;
  }
`;

export * from '../../common/elements/Form.js';
export * from '../../common/elements/Notifications.js';
export * from '../../common/elements/Staff.js';
