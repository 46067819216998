import styled from 'styled-components/macro';
import ProfileImageComponent from '../../common/ProfileImage';
import * as CommonCards from '../../common/elements/Cards';
import { Button } from '../../common/elements/Form';

export const ProfileImage = styled(ProfileImageComponent)`
  height: 8.2em;
  margin: 1.5em;
`;

export const EditUserProfile = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadButton = styled(Button).attrs(() => ({ as: 'label' }))``;

export const ImageInput = styled.input`
  display: none;
`;

export const DisplayName = styled.h1`
  color: ${({ theme }) => theme.headerColour};
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
`;

export const LogoutButton = styled(Button)`
  margin: 1em;
`;

export const ClassroomHeader = styled.h2`
  font-weight: 500;
  font-size: 3rem;
  line-height: 3.9rem;
  margin: 0.2em 0;
`;

export const ProfileCardList = styled(CommonCards.CardList)`
  width: 100%;
`;

export const PreferencesSection = styled.section`
  margin: 2.5em 0;
`;

export * from '../../common/elements/Cards';
export * from '../../common/elements/Form';
export * from '../../common/elements/Students';
