import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import NoStarSVG from '../../svgs/NoStarSVG.js';
import StarSVG from '../../svgs/StarSVG.js';
import Loading from '../common/Loading.js';
import * as CSS from './elements/PersonalTargets';

const PersonalTargets = observer(() => {
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();

  const newTargets = loggedInUser.private.getNewTargets();

  useEffect(() => {
    const updates = newTargets.map((target) =>
      db
        .collection('usersPrivate')
        .doc(loggedInUser.id)
        .update({
          [`personalTargets.${target.targetId}.isNew`]: false,
        })
    );
    Promise.all(updates);
  }, [loggedInUser.id, newTargets, newTargets.length]);

  if (!loggedInUser.private)
    return (
      <CSS.LoadingContainer>
        <Loading />
      </CSS.LoadingContainer>
    );

  const targets = loggedInUser.private.getOrderedPersonalTargets(
    loggedInUser.selectedCategory
  );

  return (
    <div data-testid="PersonalTargets">
      <CSS.StudentHeaderRow>
        <CSS.CenterLink to="/targets">
          <StarSVG />
          <CSS.TargetHeader>My Targets</CSS.TargetHeader>
        </CSS.CenterLink>
      </CSS.StudentHeaderRow>
      <CSS.StudentItemList>
        {targets.length ? (
          targets.map((target) => {
            const complete = target.evidence.length > 0;
            const unreadCount = loggedInUser.private.getUnreadCommentsByItemId(
              target.targetId,
              'personalTargets'
            );
            return (
              <CSS.StudentItemNotification
                key={target.targetId}
                badgeContent={unreadCount}
                data-testid={`unread-count-${target.targetId}`}
              >
                <CSS.TargetLi complete={complete} hasNotifications as="div">
                  <CSS.SpaceBetweenLink to={`/targets/${target.targetId}`}>
                    <CSS.TargetText>{target.targetText}</CSS.TargetText>
                    {complete ? (
                      <StarSVG size="2.5em" targetId={target.targetId} />
                    ) : (
                      <NoStarSVG size="2.5em" targetId={target.targetId} />
                    )}
                  </CSS.SpaceBetweenLink>
                </CSS.TargetLi>
              </CSS.StudentItemNotification>
            );
          })
        ) : (
          <CSS.TargetLi complete={true} notClickable>
            No targets yet
          </CSS.TargetLi>
        )}
      </CSS.StudentItemList>
    </div>
  );
});

export default PersonalTargets;
