import { Modal } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { AlertContext } from '../../contexts/Alert';
import * as CSS from './elements/SaveChangesModal.js';

const AlertModal = observer(() => {
  const alertStore = useContext(AlertContext);
  return (
    <Modal
      open={alertStore.isOpen}
      onClose={() => alertStore.close()}
      aria-labelledby="alert-modal"
      aria-describedby="alert-modal"
    >
      <CSS.Warning data-testid="Alert">
        <CSS.StaffFormHeaderRow>
          <CSS.StaffFormHeader>{alertStore.heading}</CSS.StaffFormHeader>
        </CSS.StaffFormHeaderRow>
        <CSS.SaveChangesMessage>{alertStore.text}</CSS.SaveChangesMessage>
        <CSS.StaffFooterRow>
          <CSS.AlertButton
            data-testid="Alert-ok"
            type="button"
            onClick={() => alertStore.close(false)}
            autoFocus
            isWarning={alertStore.isWarning}
          >
            Ok
          </CSS.AlertButton>
        </CSS.StaffFooterRow>
      </CSS.Warning>
    </Modal>
  );
});

export default AlertModal;
