import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { UsersContext } from '../../contexts/Users';
import AssessmentCard from '../admin/AssessmentCard';
import AddAssessment from './AddAssessment';
import * as CSS from './elements/StaffStudentAssessments';

const StaffStudentAssessments = observer(({ studentId }) => {
  const usersStore = useContext(UsersContext);
  const student = usersStore.getUserById(studentId);
  const store = useLocalStore(() => ({
    isFormOpen: false,
  }));

  const currentAssessment = student?.private.getActiveAssessmentByCategory(
    usersStore.getLoggedInUser().selectedCategory
  );
  const archivedAssessments = student?.private.getArchivedAssessments(
    usersStore.getLoggedInUser().selectedCategory
  );

  const currentAssessmentUnCheckedCount = currentAssessment?.getTotalUnchecked(
    usersStore.loggedInUserId
  );

  return (
    <div data-testid={`StaffStudentAssessments-${student?.displayName}`}>
      <CSS.AssessmentTitle>Active Learning Plan</CSS.AssessmentTitle>
      {store.isFormOpen ? (
        <AddAssessment
          studentId={studentId}
          onClose={() => (store.isFormOpen = false)}
        />
      ) : (
        <CSS.AssessmentList>
          {currentAssessment && (
            <CSS.StaffCardNotification
              badgeContent={currentAssessmentUnCheckedCount}
              data-testid={`unread-count-${currentAssessment.id}`}
            >
              <AssessmentCard
                assessment={currentAssessment}
                studentId={studentId}
                hasNotifications
                as="div"
              />
            </CSS.StaffCardNotification>
          )}
          <CSS.StaffCard>
            <CSS.CreateAssessmentButton
              aria-label="create assessment"
              onClick={() => (store.isFormOpen = true)}
            >
              <CSS.PlusIcon style={{ fontSize: '8rem' }} />
            </CSS.CreateAssessmentButton>
          </CSS.StaffCard>
        </CSS.AssessmentList>
      )}
      <CSS.AssessmentTitle>Archived Learning Plans</CSS.AssessmentTitle>
      <CSS.AssessmentList>
        {archivedAssessments &&
          archivedAssessments.map((assessment) => (
            <AssessmentCard
              assessment={assessment}
              key={assessment.id}
              studentId={studentId}
              archived={true}
            />
          ))}
      </CSS.AssessmentList>
    </div>
  );
});

export default StaffStudentAssessments;
