import React, { createContext } from 'react';
import SaveChangesModel from '../models/SaveChanges.js';

const SaveChangesStore = SaveChangesModel.create({
  isOpen: false,
});

export const SaveChangesContext = createContext(SaveChangesStore);

export const SaveChangesProvider = ({ children }) => {
  return (
    <SaveChangesContext.Provider value={SaveChangesStore}>
      {children}
    </SaveChangesContext.Provider>
  );
};
