import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { LearningAssessmentsContext } from '../../contexts/LearningAssessments';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import AssessmentCard from './AssessmentCard';
import * as CSS from './elements/LearningAssessments.js';

const LearningAssessments = observer(() => {
  const learningAssessmentsStore = useContext(LearningAssessmentsContext);
  const usersStore = useContext(UsersContext);

  const createAssessment = async () => {
    const newAssessmentDoc = await db.collection('learningAssessments').add({
      title: 'New Learning Plan',
      initialQuestions: {},
      finalQuestions: {},
      category: usersStore.getLoggedInUser().selectedCategory,
    });
    navigate(`/staff/admin/learningPlans/${newAssessmentDoc.id}/start`);
  };

  const assessments = learningAssessmentsStore.getAssessments(
    usersStore.getLoggedInUser().selectedCategory
  );

  const publishedAssessments = assessments.filter(
    (assessment) => assessment.published
  );
  const unpublishedAssessments = assessments.filter(
    (assessment) => !assessment.published
  );

  return (
    <div data-testid="LearningAssessments">
      <CSS.StaffRow>
        <CSS.StaffSubHeader>Published</CSS.StaffSubHeader>
      </CSS.StaffRow>
      <CSS.AssessmentList>
        {publishedAssessments.map((assessment) => {
          return <AssessmentCard key={assessment.id} assessment={assessment} />;
        })}
      </CSS.AssessmentList>

      <CSS.StaffRow>
        <CSS.StaffSubHeader>Unpublished</CSS.StaffSubHeader>
      </CSS.StaffRow>
      <CSS.AssessmentList>
        {unpublishedAssessments.map((assessment) => {
          return <AssessmentCard key={assessment.id} assessment={assessment} />;
        })}
        <CSS.StaffCard>
          <CSS.CreateAssessmentButton
            aria-label="create assessment"
            onClick={createAssessment}
          >
            <CSS.PlusIcon style={{ fontSize: '8rem' }} />
          </CSS.CreateAssessmentButton>
        </CSS.StaffCard>
      </CSS.AssessmentList>
    </div>
  );
});

export default LearningAssessments;
