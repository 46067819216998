import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import { navigate } from '@reach/router';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import { AlertContext } from '../../contexts/Alert.js';
import { ChatsContext } from '../../contexts/Chats.js';
import { LearningAssessmentsContext } from '../../contexts/LearningAssessments.js';
import { SaveChangesContext } from '../../contexts/SaveChanges.js';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users.js';
import { db } from '../../firebase/index.js';
import { deleteChatMessages } from '../../utils/db.js';
import Error from '../common/Error.js';
import EditStudentsForm from './EditStudentsForm.js';
import ModuleCard from './ModuleCard';
import SelectModuleForm from './SelectModuleForm.js';
import SetClassroomForm from './SetClassroomForm.js';
import TopicHeader from './TopicHeader.js';
import UserCard from './UserCard.js';
import * as CSS from './elements/EditClassroom.js';

const EditClassroom = observer(({ groupChatId }) => {
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const alertStore = useContext(AlertContext);
  const learningAssessmentsStore = useContext(LearningAssessmentsContext);
  const infoRef = useRef(null);

  const loggedInUser = usersStore.getLoggedInUser();

  const chatToEdit = chatsStore.getChatById(groupChatId, 'groupChats');
  const store = useLocalStore(() => ({
    isModuleModalOpen: false,
    setModuleModal: (open) => {
      store.isModuleModalOpen = open;
    },
    isEditNameModalOpen: false,
    setEditNameModal: (open) => {
      store.isEditNameModalOpen = open;
    },
    isEditStudentsModalOpen: false,
    setEditStudentsModal: (open) => {
      store.isEditStudentsModalOpen = open;
    },
    isEditingModules: false,
    toggleIsEditingModules: () => {
      store.isEditingModules = !store.isEditingModules;
    },
    isInfoOpen: false,
    setInfoOpen: (open) => {
      store.isInfoOpen = open;
    },
  }));

  useEffect(() => {
    chatsStore.setSelectedChatId(groupChatId);
    if (loggedInUser.private.preferences?.autoOpenClassroomChat) {
      chatsStore.setIsSelectedChatVisible(true);
    }
  }, [chatsStore, groupChatId, loggedInUser.private.preferences]);

  if (!chatToEdit) return <Error msg="Classroom not found" />;

  const chatModulesArray = topicsStore.getChatModulesAsArray(
    chatToEdit.modules,
    chatToEdit.topics
  );

  const students = usersStore.getParticipantsByGroupChatId(
    groupChatId,
    'student'
  );

  const usersInChat = chatToEdit.participants.reduce((users, userId) => {
    const user = usersStore.getUserById(userId);
    if (user) {
      users.push(user);
    }
    return users;
  }, []);

  const studentsInChat = usersInChat.filter((user) => user.role === 'student');

  const setCurrentModules = async (e, modules, reason) => {
    const updatedModules = {};
    chatModulesArray.forEach(({ module: originalModule }) => {
      const isStillCurrent = modules.find(
        ({ module }) => module.id === originalModule.id
      );
      updatedModules[`modules.${originalModule.id}.current`] = !!isStillCurrent;
    });
    await db.collection('groupChats').doc(groupChatId).update(updatedModules);
  };

  const handleDeleteClassroom = () => {
    if (chatToEdit.hasStudents(usersStore)) {
      alertStore.setText({
        heading: 'Warning',
        text:
          'This classroom cannot be deleted as it currently has students. \nIf you wish to delete it, first remove all students from the class.',
        isWarning: true,
      });
      alertStore.open();
    } else {
      saveChangesStore.setText({
        heading: 'Delete Classroom',
        leavingText: 'Yes',
        remainingText: 'No',
        message:
          'Are you sure you wish to delete this classroom? This will permanently delete the classroom and all associated messages.',
      });
      saveChangesStore.open(async () => {
        await db.collection('groupChats').doc(groupChatId).delete();
        const filePathsInUse = usersStore.getEvidenceFilePathsInUse();
        deleteChatMessages(groupChatId, 100, filePathsInUse);
        navigate('/staff/classrooms');
        chatsStore.clearSelectedChat();
      });
    }
  };

  const handleILPChange = async (ilpId) => {
    await db
      .collection('groupChats')
      .doc(groupChatId)
      .update({ currentIlpId: ilpId });
  };

  const assignILPToStudents = async () => {
    if (chatToEdit.currentIlpId) {
      const assessmentToAssign = learningAssessmentsStore.getById(
        chatToEdit.currentIlpId
      );
      saveChangesStore.setText({
        heading: 'Assign learning plans?',
        message: `Are you sure you want to assign ${assessmentToAssign.title} to all students in this class?\nAny active learning plans will be archived.`,
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(async () => {
        const updates = studentsInChat.map((student) => {
          const activeAssessment = student.private.getActiveAssessmentByCategory(
            loggedInUser.selectedCategory
          );
          if (activeAssessment?.id === assessmentToAssign.id) {
            return null;
          }
          const assessments = [
            ...student.private.getArchivedAssessments(
              loggedInUser.selectedCategory
            ),
          ];
          const archivedAssessment = assessments.find(
            (assessment) => assessment.id === assessmentToAssign.id
          );
          if (archivedAssessment) {
            return student.activateLearningAssessment(
              archivedAssessment.toJSON(),
              usersStore.getLoggedInUser().selectedCategory
            );
          } else {
            return student.setLearningAssessment(
              assessmentToAssign.toJSON(),
              loggedInUser.selectedCategory
            );
          }
        });
        await Promise.all(updates);
      });
    }
  };

  return (
    <CSS.StaffContainer>
      <CSS.StaffHeaderRow>
        <CSS.Inline>
          <CSS.StaffHeader>{chatToEdit.chatName}</CSS.StaffHeader>
          <CSS.AlignButton>
            <CSS.EditClassroomButton
              data-testid={`EditClassroom-editName`}
              onClick={() => store.setEditNameModal(true)}
              aria-label="edit classroom"
            >
              <CSS.EditIcon />
            </CSS.EditClassroomButton>
          </CSS.AlignButton>
          <CSS.AlignButton>
            <CSS.CreateButton
              type="button"
              onClick={() => store.setModuleModal(true)}
            >
              Add module <CSS.PlusIcon />
            </CSS.CreateButton>
          </CSS.AlignButton>
          <CSS.AlignButton>
            <CSS.DeleteModuleButton
              type="button"
              onClick={() => store.toggleIsEditingModules()}
              isEditing={store.isEditingModules}
            >
              {store.isEditingModules ? 'View modules ' : 'Remove modules '}
              <CSS.EditIcon />
            </CSS.DeleteModuleButton>
          </CSS.AlignButton>
          <CSS.AlignButton ref={infoRef} hoverable>
            <CSS.InfoIcon onClick={() => store.setInfoOpen(true)} />
          </CSS.AlignButton>
          <CSS.AlignButton>
            <CSS.DeleteIconButton aria-label="delete classroom">
              <CSS.Bin
                data-testid="EditClassroom-deleteClassroom"
                onClick={handleDeleteClassroom}
                style={{ fontSize: '2.8rem' }}
              />
            </CSS.DeleteIconButton>
          </CSS.AlignButton>
          <Popover
            open={store.isInfoOpen}
            anchorEl={infoRef.current}
            onClose={() => store.setInfoOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <CSS.InfoContainer>
              <CSS.InfoHeading>Ordering</CSS.InfoHeading>
              <CSS.InfoText>
                Drag and drop topic names and modules to set the order they are
                visible to students.
              </CSS.InfoText>
              <CSS.InfoHeading>Active and Inactive</CSS.InfoHeading>
              <CSS.InfoText>
                Students will only able to see and interact with active modules.
                You can use this to plan out upcoming modules.
              </CSS.InfoText>
              <CSS.InfoHeading>Current Modules</CSS.InfoHeading>
              <CSS.InfoText>
                Current Modules will appear at the top of the students' home
                screen.
              </CSS.InfoText>
            </CSS.InfoContainer>
          </Popover>
        </CSS.Inline>
        <CSS.SetCurrentModules>
          <CSS.StaffAutocomplete
            data-testid="EditClassroom-selectCurrentModules"
            multiple
            disableClearable
            fullWidth
            value={chatModulesArray.filter(({ current }) => current)}
            onChange={setCurrentModules}
            options={chatModulesArray.filter(({ active }) => active)}
            groupBy={({ module }) => module.getParentTopic().topicName}
            getOptionLabel={({ module }) => module.moduleName}
            getOptionSelected={({ module }, value) =>
              module.id === value.module.id
            }
            renderInput={(params) => (
              <CSS.StaffAutocompleteInput
                {...params}
                label="Set current modules"
                variant="outlined"
              />
            )}
          />
        </CSS.SetCurrentModules>
      </CSS.StaffHeaderRow>
      <CSS.ClassroomILP>
        <CSS.StaffSubHeader style={{ paddingLeft: 0 }}>
          Class Learning Plan
        </CSS.StaffSubHeader>
        <CSS.StaffSelect
          select
          id="Choose an ILP"
          label="Set ILP for this class"
          onChange={(e) => handleILPChange(e.target.value)}
          value={chatToEdit.currentIlpId || ''}
        >
          <CSS.StaffMenuItem value="">No ILP assigned</CSS.StaffMenuItem>
          {learningAssessmentsStore
            .getAssessments(usersStore.getLoggedInUser().selectedCategory)
            .map((learningAssessment) => (
              <CSS.StaffMenuItem
                key={learningAssessment.id}
                value={learningAssessment.id}
              >
                {learningAssessment.title}
              </CSS.StaffMenuItem>
            ))}
        </CSS.StaffSelect>
        <CSS.CreateButton onClick={assignILPToStudents}>
          Assign ILP to all students
        </CSS.CreateButton>
      </CSS.ClassroomILP>
      <CSS.StaffRow>
        <CSS.StaffHeaderGroup>
          <CSS.StaffHeaderCount>{students.length}</CSS.StaffHeaderCount>
          <CSS.StaffSubHeader>
            Student{students.length === 1 ? '' : 's'}
          </CSS.StaffSubHeader>
        </CSS.StaffHeaderGroup>
        <CSS.CreateButton onClick={() => store.setEditStudentsModal(true)}>
          Add Students
        </CSS.CreateButton>
      </CSS.StaffRow>
      <CSS.CardList pageBreak={true}>
        {students.map((student) => {
          const staffNotificationCount = student.private.getTotalStaffNotifications(
            usersStore.getLoggedInUser(),
            topicsStore
          );

          return (
            <CSS.StaffCardNotification
              key={student.id}
              data-testid={`student-notifications-${student.id}`}
              badgeContent={staffNotificationCount}
            >
              <UserCard {...student} linkSection="progress" hasNotifications />
            </CSS.StaffCardNotification>
          );
        })}
      </CSS.CardList>
      <CSS.TopicsList>
        {topicsStore
          .getChatModules(chatToEdit.modules, chatToEdit.topics)
          .map(([{ id, topicName }, modules], topicIndex) => (
            <li key={id}>
              <TopicHeader
                id={id}
                topicName={topicName}
                dropIndex={topicIndex}
                groupChatId={groupChatId}
              />
              <CSS.CardList>
                {modules.map((module, i) => (
                  <ModuleCard
                    key={module.id}
                    groupChatId={groupChatId}
                    module={module}
                    topicName={topicName}
                    topicId={id}
                    isEditing={store.isEditingModules}
                    toggleIsEditingModules={store.toggleIsEditingModules}
                    arrayIndex={i}
                  />
                ))}
              </CSS.CardList>
            </li>
          ))}
      </CSS.TopicsList>
      <Modal
        data-testid="add-module-modal"
        open={store.isModuleModalOpen}
        onClose={() => store.setModuleModal(false)}
        aria-labelledby="add-module-modal"
        aria-describedby="add-module-modal"
      >
        <SelectModuleForm
          groupChatId={groupChatId}
          chatToEdit={chatToEdit}
          closeModal={() => store.setModuleModal(false)}
        />
      </Modal>
      <Modal
        data-testid="editName-modal"
        open={store.isEditNameModalOpen}
        onClose={() => store.setEditNameModal(false)}
        aria-labelledby="editName-modal"
        aria-describedby="editName-modal"
      >
        <SetClassroomForm
          groupChatId={groupChatId}
          closeModal={() => store.setEditNameModal(false)}
        />
      </Modal>
      <Modal
        open={store.isEditStudentsModalOpen}
        onClose={() => store.setEditStudentsModal(false)}
        aria-labelledby="edit-students-modal"
        aria-describedby="edit-students-modal"
      >
        <EditStudentsForm
          closeModal={() => store.setEditStudentsModal(false)}
          studentsInChat={studentsInChat}
          usersInChat={usersInChat}
          chatId={groupChatId}
        />
      </Modal>
    </CSS.StaffContainer>
  );
});

export default EditClassroom;
