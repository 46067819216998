import { getParentOfType, types } from 'mobx-state-tree';
import { db } from '../firebase/index.js';
import Module from './Module';
import Topics from './Topics.js';

const Topic = types
  .model('Topic', {
    id: types.string,
    topicName: types.maybeNull(types.string),
    modules: types.optional(types.array(Module), []),
    loading: types.optional(types.boolean, false),
    active: types.optional(types.boolean, true),
    subject: types.optional(types.string, 'General'),
    category: types.optional(types.string, ''),
  })
  .volatile(() => ({
    listeners: [],
  }))
  .actions((self) => {
    return {
      setTopicInfo(newTopic) {
        self.topicName = newTopic.topicName;
        self.active = newTopic.active;
        self.subject = newTopic.subject;
      },
      addModule(newModule) {
        self.modules.push(newModule);
        self.modules.replace(
          self.modules.slice().sort((a, b) => b.index - a.index)
        );
      },
      removeModule(moduleId) {
        const moduleToRemove = self.modules.find(
          (module) => module.id === moduleId
        );
        if (moduleToRemove) self.modules.remove(moduleToRemove);
      },
      listenToModules() {
        self.loading = true;
        return db
          .collection('topics')
          .doc(self.id)
          .collection('modules')
          .onSnapshot(self.handleModulesChange);
      },
      handleModulesChange(querySnapshot) {
        querySnapshot.docChanges().forEach(({ doc, type }) => {
          const module = doc.data();
          if (type === 'added') {
            const newModule = Module.create({
              id: doc.id,
            });
            newModule.updateModuleInfo(module);
            const topicsStore = self.getParentTopics();
            const resourceUnSub = newModule.listenToItem(
              self.id,
              'learningResources'
            );
            const taskUnSub = newModule.listenToItem(self.id, 'tasks');
            self.addModule(newModule);
            topicsStore.addUnSub(resourceUnSub);
            topicsStore.addUnSub(taskUnSub);
          }
          if (type === 'modified') {
            const existingModule = self.modules.find(({ id }) => id === doc.id);
            existingModule.updateModuleInfo(module);
          }
          if (type === 'removed') {
            self.removeModule(doc.id);
          }
        });
        self.loading = false;
      },
      addUnSub(unsubscribe) {
        self.listeners.push(unsubscribe);
      },
      unsubscribeFromAll() {
        self.listeners.forEach((unsubscribe) => {
          if (typeof unsubscribe === 'function') unsubscribe();
        });
      },
    };
  })
  .views((self) => ({
    getParentTopics() {
      return getParentOfType(self, Topics);
    },
    getJSONReport() {
      const { modules, ...restOfTopic } = self.toJSON();
      return { ...restOfTopic, modules };
    },
  }));

export default Topic;
