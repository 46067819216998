import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components/macro';
import * as common from '../../common/elements/Form';
import { StaffNavButton } from '../../staff/elements/User';

export const Form = styled(FormikForm)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 15px;
  ${common.ModalScreenWidthMQs}
`;

export const SubmitChangesButton = styled(StaffNavButton)`
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.buttonDisabled};
      &:hover {
        cursor: default;
      }
    `}
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
