import { Modal } from '@material-ui/core';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { UsersContext } from '../../contexts/Users';
import InviteUser from '../admin/InviteUser';
import UserCard from './UserCard';
import * as CSS from './elements/Users';

const Users = observer(() => {
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const store = useLocalStore(() => ({
    usersSearchValue: '',
    inviteModalOpen: false,
    toggleModal(isOpen) {
      store.inviteModalOpen = isOpen;
    },
  }));

  const handleSearchChange = (e) => {
    const { value } = e.target;
    store.usersSearchValue = value;
  };

  const students = usersStore.getUsersByPartialNameAndRole(
    store.usersSearchValue,
    'student'
  );
  const teachers = usersStore.getUsersByPartialNameAndRole(
    store.usersSearchValue,
    'teacher'
  );
  const admins = usersStore.getUsersByPartialNameAndRole(
    store.usersSearchValue,
    'admin'
  );
  const unlockedStudents = students.filter(
    (student) => !student.private.accountLocked
  );
  const lockedStudents = students.filter(
    (student) => student.private.accountLocked
  );

  const unlockedTeachers = teachers.filter(
    (teacher) => !teacher.private.accountLocked
  );
  const lockedTeachers = teachers.filter(
    (teacher) => teacher.private.accountLocked
  );

  const unlockedAdmins = admins.filter((admin) => !admin.private.accountLocked);
  const lockedAdmins = admins.filter((admin) => admin.private.accountLocked);

  return (
    <CSS.StaffContainer data-testid="Users">
      <CSS.StaffRow>
        <CSS.StaffHeader>Users</CSS.StaffHeader>
        <CSS.ControlsWithLink>
          <CSS.InviteButton onClick={() => store.toggleModal(true)}>
            Invite <CSS.PlusIcon />
          </CSS.InviteButton>
          <CSS.SearchStudentsField
            type="text"
            placeholder="Search students..."
            onChange={handleSearchChange}
            value={store.usersSearchValue}
          />
          {loggedInUser.role === 'admin' && (
            <CSS.StaffFilterLink
              to="/staff/search/users"
              style={{ fontSize: '1rem', gridArea: 'link' }}
              onClick={() => {
                localStorage.removeItem('CrossCategorySearch-searchValue');
                localStorage.removeItem('CrossCategorySearch-users');
              }}
            >
              Search all categories
            </CSS.StaffFilterLink>
          )}
        </CSS.ControlsWithLink>
      </CSS.StaffRow>
      <CSS.StaffRow>
        <CSS.StaffHeaderGroup>
          <CSS.StaffHeaderCount>{unlockedStudents.length}</CSS.StaffHeaderCount>
          <CSS.StaffSubHeader>
            Student{unlockedStudents.length === 1 ? '' : 's'}
          </CSS.StaffSubHeader>
        </CSS.StaffHeaderGroup>
      </CSS.StaffRow>
      <CSS.CardList>
        {unlockedStudents.map((student) => {
          return <UserCard key={student.id} {...student} />;
        })}
      </CSS.CardList>

      <Modal
        open={store.inviteModalOpen}
        onClose={() => store.toggleModal(false)}
        aria-labelledby="invite-user-modal"
        aria-describedby="invite-user-modal"
      >
        <InviteUser closeModal={() => store.toggleModal(false)} />
      </Modal>
      {loggedInUser.role === 'admin' && (
        <>
          <CSS.StaffRow>
            <CSS.StaffHeaderGroup>
              <CSS.StaffHeaderCount>
                {unlockedTeachers.length}
              </CSS.StaffHeaderCount>
              <CSS.StaffSubHeader>
                Teacher{unlockedTeachers.length === 1 ? '' : 's'}
              </CSS.StaffSubHeader>
            </CSS.StaffHeaderGroup>
          </CSS.StaffRow>
          <CSS.CardList>
            {unlockedTeachers.map((teacher) => {
              return <UserCard key={teacher.id} {...teacher} />;
            })}
          </CSS.CardList>
          <CSS.StaffRow>
            <CSS.StaffHeaderGroup>
              <CSS.StaffHeaderCount>
                {unlockedAdmins.length}
              </CSS.StaffHeaderCount>
              <CSS.StaffSubHeader>
                Admin{unlockedAdmins.length === 1 ? '' : 's'}
              </CSS.StaffSubHeader>
            </CSS.StaffHeaderGroup>
          </CSS.StaffRow>
          <CSS.CardList>
            {unlockedAdmins.map((admin) => {
              return <UserCard key={admin.id} {...admin} />;
            })}
          </CSS.CardList>
        </>
      )}
      <br></br>
      <CSS.StaffHeader>Locked Accounts</CSS.StaffHeader>
      <CSS.StaffRow>
        <CSS.StaffHeaderGroup>
          <CSS.StaffHeaderCount data-testid="Users-unlocked-student-count">
            {lockedStudents.length}
          </CSS.StaffHeaderCount>
          <CSS.StaffSubHeader>
            Student{lockedStudents.length === 1 ? '' : 's'}
          </CSS.StaffSubHeader>
        </CSS.StaffHeaderGroup>
      </CSS.StaffRow>
      <CSS.CardList>
        {lockedStudents.map((student) => {
          return <UserCard key={student.id} {...student} />;
        })}
      </CSS.CardList>
      {loggedInUser.role === 'admin' && (
        <>
          <CSS.StaffRow>
            <CSS.StaffHeaderGroup>
              <CSS.StaffHeaderCount>
                {lockedTeachers.length}
              </CSS.StaffHeaderCount>
              <CSS.StaffSubHeader>
                Teacher{lockedTeachers.length === 1 ? '' : 's'}
              </CSS.StaffSubHeader>
            </CSS.StaffHeaderGroup>
          </CSS.StaffRow>
          <CSS.CardList>
            {lockedTeachers.map((teacher) => {
              return <UserCard key={teacher.id} {...teacher} />;
            })}
          </CSS.CardList>
          <CSS.StaffRow>
            <CSS.StaffHeaderGroup>
              <CSS.StaffHeaderCount>{lockedAdmins.length}</CSS.StaffHeaderCount>
              <CSS.StaffSubHeader>
                Admin{lockedAdmins.length === 1 ? '' : 's'}
              </CSS.StaffSubHeader>
            </CSS.StaffHeaderGroup>
          </CSS.StaffRow>
          <CSS.CardList>
            {lockedAdmins.map((admin) => {
              return <UserCard key={admin.id} {...admin} />;
            })}
          </CSS.CardList>
        </>
      )}
    </CSS.StaffContainer>
  );
});

export default Users;
