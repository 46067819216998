import firebase from 'firebase/app';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ChatsContext } from '../../contexts/Chats';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { UsersContext } from '../../contexts/Users';
import { db, storage } from '../../firebase';
import * as CSS from './elements/ModuleCard';

const ModuleCard = observer(
  ({
    module,
    completionPercent,
    studentId,
    topicName,
    topicId,
    groupChatId,
    isEditing,
    toggleIsEditingModules,
    arrayIndex,
    hasNotifications,
  }) => {
    const usersStore = useContext(UsersContext);
    const saveChangesStore = useContext(SaveChangesContext);
    const chatsStore = useContext(ChatsContext);
    const groupChat = chatsStore.getChatById(groupChatId, 'groupChats');

    const active = groupChat?.modules.get(module.id).active;

    const removeModuleAndLinkedItems = async () => {
      await db
        .collection('groupChats')
        .doc(groupChatId)
        .update({
          [`modules.${module.id}`]: firebase.firestore.FieldValue.delete(),
        });
      const taskIds = module.getItemIds('tasks');
      const resourceIds = module.getItemIds('learningResources');
      const tasksToRemove = taskIds.reduce((acc, taskId) => {
        acc[`tasks.${taskId}`] = firebase.firestore.FieldValue.delete();
        return acc;
      }, {});
      const resourcesToRemove = resourceIds.reduce((acc, resourceId) => {
        acc[
          `learningResources.${resourceId}`
        ] = firebase.firestore.FieldValue.delete();
        return acc;
      }, {});
      const removeModulePromises = groupChat.participants.map((userId) => {
        const user = usersStore.getUserById(userId);
        const existingTasks = user.private.tasks;
        let userPrivateUpdate = null;
        const deleteFilePromises = [];

        const otherChatIds = [...user.groupChats.keys()].filter(
          (chatId) => chatId !== groupChatId
        );

        let moduleFoundInChats = false;
        for (let i = 0; i < otherChatIds.length; i++) {
          const chatModuleIds = [
            ...(chatsStore
              .getChatById(otherChatIds[i], 'groupChats')
              ?.modules.keys() || []),
          ];
          if (chatModuleIds.includes(module.id)) {
            moduleFoundInChats = true;
            break;
          }
        }
        if (!moduleFoundInChats) {
          userPrivateUpdate = db
            .collection('usersPrivate')
            .doc(user.id)
            .update({
              ...tasksToRemove,
              ...resourcesToRemove,
            });
          existingTasks.forEach(({ taskId, evidence }) => {
            if (taskIds.includes(taskId)) {
              evidence.forEach(async (message) => {
                if (message.file) {
                  const storageRef = storage.ref();
                  const fileRef = storageRef.child(message.filePath);
                  deleteFilePromises.push(fileRef.delete());
                }
              });
            }
          });
        }
        return [userPrivateUpdate, ...deleteFilePromises];
      });

      await Promise.all(removeModulePromises.flat());
      toggleIsEditingModules();
    };

    const handleRemoveModule = () => {
      saveChangesStore.setText({
        heading: `Remove '${module.moduleName}' module`,
        message: `Are you sure you want to remove the '${module.moduleName}' module? This will delete resources and tasks from all students in the '${groupChat.chatName}' classroom?`,
        leavingText: 'Yes',
        remainingText: 'No',
      });

      saveChangesStore.open(removeModuleAndLinkedItems);
    };

    const toggleClassroomModuleActive = async () => {
      const updatedActiveStatus = !active;
      const chatModuleUpdate = {
        [`modules.${module.id}.active`]: updatedActiveStatus,
      };
      if (updatedActiveStatus === false) {
        chatModuleUpdate[`modules.${module.id}.current`] = false;
      }
      await db
        .collection('groupChats')
        .doc(groupChatId)
        .update(chatModuleUpdate);
    };

    const handleToggleModuleActive = () => {
      const activeText = active ? 'Inactive' : 'Active';
      saveChangesStore.setText({
        heading: `Set module '${module.moduleName}' to '${activeText}'`,
        message: `Students in the '${groupChat.chatName}' classroom will ${
          active ? 'not' : ''
        } be able to view this module, its learning resources and tasks ${
          active ? "until it is set to 'Active'" : ''
        }`,
        leavingText: 'Ok',
        remainingText: 'Go back',
      });

      saveChangesStore.open(toggleClassroomModuleActive);
    };

    const [{ isDragging }, drag] = useDrag({
      item: { type: `${topicId}-module`, id: module.id },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const [{ isOver }, drop] = useDrop({
      accept: `${topicId}-module`,
      drop: (item) => {
        if (groupChatId) {
          const groupChat = chatsStore.getChatById(groupChatId, 'groupChats');
          const draggedModule = groupChat.modules.get(item.id);
          const droppedModule = groupChat.modules.get(module.id);
          groupChat.moveModule(draggedModule, droppedModule);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    if (!isNaN(+completionPercent)) {
      return (
        <CSS.ModuleCard
          data-testid="ModuleCard"
          hasNotifications={hasNotifications}
          as={hasNotifications ? 'div' : 'li'}
        >
          <CSS.ModuleLink
            to={`/staff/users/${studentId}/progress/modules/${module.id}/learning`}
            data-testid="ModuleCard-Link"
          >
            <CSS.ModuleImage src={module.imageURL} alt={module.moduleName} />
            <CSS.AlignProgress>
              <CSS.ProgressBarWithLabel
                data-testid="ModuleCard-ProgressBar"
                completionPercent={completionPercent}
              />
              <CSS.ModuleHeader>{module.moduleName}</CSS.ModuleHeader>
            </CSS.AlignProgress>
          </CSS.ModuleLink>
        </CSS.ModuleCard>
      );
    }
    return (
      <CSS.ModuleCard
        data-testid="ModuleCard"
        ref={groupChatId ? drag : null}
        isDragging={isDragging}
        isOver={isOver}
        isEditing={isEditing}
        groupChatId={groupChatId}
      >
        <CSS.ModuleLink
          to={`/staff/topics/${topicId}/modules/${module.id}`}
          data-testid="ModuleCard-Link"
          ref={groupChatId ? drop : null}
          onClick={(e) => isEditing && e.preventDefault()}
          data-isediting={isEditing}
        >
          <CSS.ModuleImage src={module.imageURL} alt={module.moduleName} />
          <CSS.AlignModuleAndTopic>
            <CSS.ModuleHeader>{module.moduleName}</CSS.ModuleHeader>
            <CSS.AlignSharedIcon>
              <CSS.TopicName>{topicName}</CSS.TopicName>
              {module?.categories.size > 0 && (
                <CSS.ShareIcon
                  data-testid="Shared-Module"
                  style={{ fontSize: '1.2em' }}
                />
              )}
            </CSS.AlignSharedIcon>
          </CSS.AlignModuleAndTopic>
        </CSS.ModuleLink>
        <CSS.Column>
          {groupChatId && (
            <CSS.ActiveModule
              active={active}
              onClick={handleToggleModuleActive}
            >
              {active ? 'Active' : 'Inactive'}
              <CSS.ActiveIcon />
            </CSS.ActiveModule>
          )}
          {isEditing && (
            <CSS.DeleteIconButton
              data-testid={`ModuleCard-RemoveModuleButton-${module.id}`}
              onClick={handleRemoveModule}
            >
              <CSS.Bin style={{ fontSize: '3rem' }} />
            </CSS.DeleteIconButton>
          )}
          {!isEditing && groupChatId && <CSS.Index>{arrayIndex + 1}</CSS.Index>}
        </CSS.Column>
      </CSS.ModuleCard>
    );
  }
);

export default ModuleCard;
