import { Switch, makeStyles } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from '../../../contexts/Theme';

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 80%;
  padding: 1em;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.textDark};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0.7em 0;
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #eaecf3;
  font-family: inherit;
`;

export const FieldInput = ({ field, form, ...props }) => {
  return <Input {...field} {...props} />;
};

export const Button = styled.button`
  background: ${({ theme }) => theme.buttonPrimary};
  box-shadow: 0px 5px 5px #e7e7e7;
  border-radius: 10px;
  border: 0;
  padding: 0.4em 1.8em 0.4em 1.8em;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;
  /* color: ${({ theme }) => theme.textLight}; */
  color: ${({ theme }) =>
    theme.isClassRoomDark ? theme.textLight : theme.headerColour};
  height: fit-content;
  font-family: inherit;

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background: ${({ theme }) => theme.buttonDisabled};
    cursor: default;
  }
`;

export const CreateButton = styled(Button)`
  font-weight: 800;
  font-size: 1.4rem;
  padding: 0.2em 0.9em;
  border-radius: 4px;
  background: ${({ theme }) => theme.buttonCreate};
`;

export const DeleteButton = styled(Button)`
  font-weight: 800;
  font-size: 1.4rem;
  padding: 0.2em 0.9em;
  border-radius: 4px;
  background: ${({ theme }) => theme.buttonWarning};
  color: ${({ theme }) => theme.textLight};
`;

export const NextArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  vertical-align: middle;
  padding-left: 0.5em;
`;

export const FormHeader = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  line-height: 1.3em;
  text-align: center;
  color: ${({ theme }) => theme.headerColour};
`;

export const FormSubHeader = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  margin: 0.5em;
  line-height: 1.3em;
  text-align: center;
  color: ${({ theme }) => theme.headerColour};
`;

export const ScreenWidthMQs = css`
  @media only screen and (min-width: 500px) {
    width: 50%;
    margin: auto;
  }

  @media only screen and (min-width: 900px) {
    width: 30%;
    margin: auto;
  }
`;

export const ModalScreenWidthMQs = css`
  @media only screen and (min-width: 500px) {
    width: 70%;
    margin: auto;
  }

  @media only screen and (min-width: 900px) {
    width: 50%;
    margin: auto;
  }
`;

export const HideScrollBars = css`
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledAutoSize = styled(TextareaAutosize)`
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.textDark};
  &:disabled {
    background-color: ${({ theme }) => theme.appBackground};
  }
`;

export const TextArea = ({ field, form, ...props }) => {
  return <StyledAutoSize {...field} {...props}></StyledAutoSize>;
};

const StyledAssessmentAutoSize = styled(StyledAutoSize)`
  &:disabled {
    background-color: ${({ theme }) => theme.appBackground};
  }
`;

export const AssessmentTextArea = ({ field, form, ...props }) => {
  return (
    <StyledAssessmentAutoSize {...field} {...props}></StyledAssessmentAutoSize>
  );
};

export const SaveChangesButton = styled(Button)`
  align-self: center;
  font-weight: 800;
  font-size: 1.6rem;
  background: ${({ theme }) => theme.buttonUpdate};
  border-radius: 20px;
`;

export const FileError = styled.p`
  color: ${({ theme }) => theme.errorMessage};
  font-size: 2.2rem;
  text-align: center;
  font-weight: 600;
  ${({ isClosable }) =>
    isClosable &&
    css`
      margin-top: 0;
    `}
`;

export const InvisibleButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const LockIcon = (props) => {
  return <LockOutlinedIcon style={{ fontSize: '3em' }} {...props} />;
};

export const UnlockIcon = (props) => {
  return (
    <LockOpenOutlinedIcon
      style={{ fontSize: '3em', color: '#707070' }}
      {...props}
    />
  );
};

export const ResetButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.6rem;
  ${({ narrow }) =>
    narrow &&
    css`
      line-height: 1.5rem;
    `}
  text-align: center;
  color: ${({ theme }) => theme.headerColour};
  width: 100vw;
  margin-top: 0.5em;
  border: none;
  background: none;
  text-decoration-line: underline;
`;

export const LockSwitch = observer(({ ...props }) => {
  const theme = useContext(ThemeContext);
  const classes = makeStyles({
    switchBase: {
      '&$checked': {
        color: theme.lessonHighlight,
      },
      '&$checked + $track': {
        backgroundColor: theme.pendingDark,
      },
    },
    checked: {},
    track: {},
  })();
  return <Switch {...props} classes={classes} />;
});
