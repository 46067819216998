import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

export const useWindowSize = () => {
  const windowStore = useLocalStore(() => ({
    width: 0,
    height: 0,
    isMobile: true,
    getColumns() {
      if (windowStore.width >= 1200) return 4;
      if (windowStore.width >= 1000) return 3;
      if (windowStore.width >= 500) return 2;
      return 1;
    },
  }));

  useEffect(() => {
    function handleResize() {
      windowStore.width = window.innerWidth;
      windowStore.height = window.innerHeight;
      windowStore.isMobile = windowStore.width < 700;
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [windowStore]);

  return windowStore;
};
