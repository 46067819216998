import firebase from 'firebase/app';
import throttle from 'lodash.throttle';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import { db } from '../../firebase/index.js';
import useLoadingTimer from '../../hooks/useLoadingTimer';
import * as CSS from './elements/Chat.js';
import EvidenceTarget from './EvidenceTarget.js';
import Message from './Message';
import MessageForm from './MessageForm';
import SayHello from './SayHello';

const Chat = observer(({ chatId, chatCollection, whiteboardStore }) => {
  const messageListRef = useRef(null);
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const loggedInUserIsStaff = ['admin', 'teacher'].includes(loggedInUser.role);

  const thisChat = chatsStore[chatCollection].find(
    (chat) => chat.id === chatId
  );
  const loadingTimer = useLoadingTimer();

  const messagesLength = thisChat?.messages?.length;

  const store = useLocalStore(() => {
    return {
      evidenceOpen: false,
      messageToEvidence: null,
      scrollId: 1,
      atBottom: true,
      setAtBottom(atBottom) {
        store.atBottom = atBottom;
      },
      openEvidence(isOpen, message = null) {
        store.evidenceOpen = isOpen;
        store.messageToEvidence = message;
      },
    };
  });

  useEffect(() => {
    const throttledScroll = throttle((event) => {
      const distanceFromTop = window.scrollY;
      const heightOfScreen = window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const distanceFromBottom = distanceFromTop + heightOfScreen;

      const isAtBottom = documentHeight - distanceFromBottom < 150;
      store.setAtBottom(isAtBottom);
    }, 500);
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [store]);

  useEffect(() => {
    if (store.atBottom && messageListRef.current) {
      messageListRef.current.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: loadingTimer ? 'auto' : 'smooth',
      });
    }
  }, [store, messagesLength, store.scrollId, loadingTimer]);

  useEffect(() => {
    if (thisChat?.id) {
      db.collection(chatCollection)
        .doc(thisChat.id)
        .update({
          [`lastChecked.${usersStore.loggedInUserId}`]: firebase.firestore.Timestamp.fromDate(
            new Date(Date.now())
          ),
        });
    }
  }, [chatCollection, messagesLength, thisChat, usersStore.loggedInUserId]);

  return (
    <CSS.ChatContainer>
      <CSS.ChatMessagesList
        whiteboardHeight={whiteboardStore.height}
        ref={messageListRef}
      >
        {messagesLength ? (
          thisChat.messages.map((message) => (
            <Message
              key={message.id}
              message={message}
              onLoad={() => (store.scrollId = Math.random())}
              onEvidence={() => store.openEvidence(true, message)}
              chatCollection={chatCollection}
              chatId={chatId}
            />
          ))
        ) : (
          <SayHello />
        )}
      </CSS.ChatMessagesList>
      <MessageForm
        chatId={chatId}
        chatCollection={chatCollection}
        renderLinkInput={loggedInUserIsStaff}
      />
      {store.messageToEvidence && (
        <EvidenceTarget
          isOpen={store.evidenceOpen}
          message={store.messageToEvidence}
          openEvidence={store.openEvidence}
        />
      )}
    </CSS.ChatContainer>
  );
});

export default Chat;
