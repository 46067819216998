/* eslint-disable no-unused-expressions */
import { destroy, types } from 'mobx-state-tree';
import { db } from '../firebase';
import { addFieldsToReport } from '../utils/reports.js';
import User from './User';

const Users = types
  .model('Users', {
    loggedInUserId: types.maybeNull(types.string),
    users: types.map(User),
    loading: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    fetchUsers(isStaff, selectedCategoryId, listenersStore) {
      self.loading = true;
      return db
        .collection('users')
        .where(
          `categories.${selectedCategoryId}`,
          '==',
          db.collection('categories').doc(selectedCategoryId)
        )
        .onSnapshot((querySnapshot) =>
          self.handleQuerySnapshot(querySnapshot, isStaff, listenersStore)
        );
    },
    handleQuerySnapshot(querySnapshot, isStaff, listenersStore) {
      querySnapshot.docChanges().forEach((change) => {
        const { doc, type } = change;
        const userData = doc.data();
        const isLoggedInUser = doc.id === self.loggedInUserId;
        if (type === 'added') {
          if (!isLoggedInUser) {
            const newUser = User.create({ id: doc.id });
            self.users.set(doc.id, newUser);
            newUser.setUserInfo(userData);
            if (isStaff) {
              const privateUnSub = newUser.listenToPrivate();
              listenersStore.addCategoryUnSub(privateUnSub);
            }
          } else {
            const loggedInUser = self.users.get(doc.id);
            loggedInUser.updateUserInfo(userData);
          }
        } else if (type === 'modified') {
          const existingUser = self.users.get(doc.id);
          existingUser.updateUserInfo(userData);
        } else if (type === 'removed' && doc.id !== self.loggedInUserId) {
          self.deleteUserById(doc.id);
        }
      });
      self.loading = false;
    },
    logout() {
      self.users = {};
      self.loading = false;
    },
    clearOtherUsers() {
      const loggedInUser = self.getLoggedInUser();
      self.users = {
        [loggedInUser.id]: loggedInUser,
      };
    },
    setLoggedInUserId(userId) {
      self.loggedInUserId = userId;
    },
    storeLoggedInUser() {
      return db
        .collection('users')
        .doc(self.loggedInUserId)
        .get()
        .then(self.handleStoreLoggedInUser);
    },
    handleStoreLoggedInUser(userDoc) {
      const userData = userDoc.data();
      const newUser = User.create({ id: self.loggedInUserId });
      self.users.set(self.loggedInUserId, newUser);
      newUser.setUserInfo(userData);
      return self.getLoggedInUser();
    },
    deleteUserById(userId) {
      const user = self.users.get(userId);
      user.unsubscribeFromAll();
      destroy(user);
    },
    getAllUsers(searchTerm = '', searchBy = 'name') {
      if (searchBy === 'phoneNumber') {
        return db
          .collection('usersPrivate')
          .where('phoneNumber', '==', searchTerm)
          .get()
          .then((res) => {
            if (res.docs.length) {
              return Promise.all(
                res.docs.map((doc) => db.collection('users').doc(doc.id).get())
              );
            }
            return [];
          })
          .then((docs) => {
            self.setUsers(docs, '');
          });
      }
      return db
        .collection('users')
        .get()
        .then((res) => {
          return self.setUsers(res.docs, searchTerm);
        });
    },
    setUsers(docs, searchValue = '') {
      let nameRegex;
      // let phoneNumberRegex;
      try {
        nameRegex = new RegExp(searchValue.replace(' ', ''), 'i');
        // phoneNumberRegex = new RegExp(searchValue, 'i');
      } catch (error) {
        searchValue = '';
      }
      let docsToUse = docs;
      if (searchValue !== '') {
        docsToUse = docs.filter((doc) => {
          const userData = doc.data();
          return nameRegex.test(userData.displayName.replace(' ', ''));
        });
      }
      const updates = docsToUse.map((doc) => {
        const userData = doc.data();
        const newUser = User.create({ id: doc.id });
        self.users.set(doc.id, newUser);
        return newUser.setUserInfo(userData);
        // newUser.getPrivate();
      });
      return Promise.all(updates);
    },
  }))
  .views((self) => ({
    getLoggedInUser() {
      return self.users.get(self.loggedInUserId);
    },
    getUserById(id) {
      return self.users.get(id);
    },
    getUsers() {
      return [...self.users.values()].sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      );
    },
    getUsersByRole(role) {
      return self.getUsers().filter((user) => user.role === role);
    },
    getParticipantsByIndividualChatId(chatId) {
      return [...self.users.values()].filter(
        (user) =>
          user.individualChats.has(chatId) && user.id !== self.loggedInUserId
      );
    },
    getParticipantsByGroupChatId(chatId, role) {
      return [...self.users.values()].filter((user) => {
        const isDifferentUserInChat =
          user.groupChats.has(chatId) && user.id !== self.loggedInUserId;
        const isCorrectRole = role ? user.role === role : true;
        return isDifferentUserInChat && isCorrectRole;
      });
    },
    getUsersSortedByRoleAndDisplayName() {
      return [...self.users.values()].sort((a, b) => {
        if (a.role === b.role) {
          return a.displayName.localeCompare(b.displayName);
        }
        return a.role.localeCompare(b.role);
      });
    },
    getUnsortedUsers() {
      return [...self.users.values()];
    },
    getUsersByPartialNameAndRole(searchValue, role) {
      const users = self.getUsersByRole(role);
      let nameRegex;
      let phoneNumberRegex;
      try {
        nameRegex = new RegExp(searchValue, 'i');
        phoneNumberRegex = new RegExp(searchValue, 'i');
      } catch (error) {
        searchValue = '';
      }
      if (searchValue === '') return users;
      return users.filter(
        (user) =>
          nameRegex.test(user.displayName) ||
          phoneNumberRegex.test(user.private?.phoneNumber)
      );
    },
    getIndividualChatIds() {
      return [...self.users.values()].reduce((ids, user) => {
        ids.push(...user.individualChats.values());
        return [...new Set(ids)];
      }, []);
    },
    getJSONReport(
      userId,
      chatsStore,
      individualChatRef,
      selectedCategoryId,
      groupChatId
    ) {
      const report = {
        fields: [
          'id',
          'displayName',
          'role',
          'imageURL',
          'groupChats',
          'individualChats',
          'phoneNumber',
          'firstName',
          'surname',
          'learningAssessment',
          'learningAssessment-initialQuestions',
          'learningAssessment-finalQuestions',
        ],
        data: [],
      };
      [...self.users.values()].forEach((user) => {
        const allUsers = !userId && !groupChatId;
        const singleUserMatches = userId === user.id;
        const groupChatMatches = user.groupChats.has(groupChatId);
        if (allUsers || singleUserMatches || groupChatMatches) {
          const groupChatNames = user
            .getUsersGroupChats(chatsStore, selectedCategoryId)
            .map((chat) => chat.chatName);

          const individualChatNames = [...user.individualChats.values()].map(
            (chatId) => individualChatRef[chatId].chatName
          );
          const userData = [
            user.id,
            user.displayName,
            user.role,
            user.imageURL,
            groupChatNames.join(','),
            individualChatNames.join(','),
            user.private.phoneNumber,
            user.private.firstName,
            user.private.surname,
          ];
          // learning assessment
          const selectedAssessment = user.private.learningAssessments.get(
            selectedCategoryId
          );
          if (selectedAssessment) {
            const titleIndex = report.fields.indexOf('learningAssessment');
            const initialQIndex = report.fields.indexOf(
              'learningAssessment-initialQuestions'
            );
            const finalQIndex = report.fields.indexOf(
              'learningAssessment-finalQuestions'
            );

            const initialQuestions = [
              ...selectedAssessment.initialQuestions.values(),
            ]
              .map(({ text, answer }) => `${text}:${answer}`)
              .join(',');
            const finalQuestions = [
              ...selectedAssessment.finalQuestions.values(),
            ]
              .map(({ text, answer }) => `${text}:${answer}`)
              .join(',');

            userData[titleIndex] = selectedAssessment.title;
            userData[initialQIndex] = initialQuestions;
            userData[finalQIndex] = finalQuestions;
          }

          // personal Targets
          [...user.private.personalTargets.values()].forEach((target, i) => {
            addFieldsToReport({
              report,
              objName: 'target',
              reportedFields: ['targetId', 'targetText'],
              obj: target,
              objIndex: target.index,
              data: userData,
            });
            target.evidence.forEach((message) => {
              addFieldsToReport({
                report,
                objName: `target.${target.index}.evidence`,
                reportedFields: ['text', 'file', 'fileType'],
                obj: message,
                objIndex: target.index,
                data: userData,
              });
            });
          });
          // modules tasks
          [...user.private.tasks.values()].forEach((task, i) => {
            addFieldsToReport({
              report,
              objName: 'target',
              reportedFields: ['taskId'],
              obj: task,
              objIndex: i,
              data: userData,
            });
            task.evidence.forEach((message) => {
              addFieldsToReport({
                report,
                objName: `task.${i}.evidence`,
                reportedFields: ['text', 'file', 'fileType'],
                obj: message,
                objIndex: i,
                data: userData,
              });
            });
          });
          report.data.push(userData);
        }
      });

      return report;
    },
    getEvidenceFilePathsInUse() {
      const filePathsInUse = new Map();
      self.users.forEach((user) => {
        user.private?.tasks?.forEach((task) => {
          task.evidence.forEach((evidence) => {
            if (evidence.filePath) {
              filePathsInUse.set(evidence.filePath, true);
            }
          });
        });
        user.private?.personalTargets?.forEach((personalTarget) => {
          personalTarget.evidence.forEach((evidence) => {
            if (evidence.filePath) {
              filePathsInUse.set(evidence.filePath, true);
            }
          });
        });
      });
      return filePathsInUse;
    },
    fetchUsersByCategory(categoryId) {
      return db
        .collection('users')
        .where(
          `categories.${categoryId}`,
          '==',
          db.collection('categories').doc(categoryId)
        )
        .get();
    },
  }));

export default Users;
