import AccordionSummary from '@material-ui/core/AccordionSummary';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { UsersContext } from '../../contexts/Users';
import { useWindowSize } from '../../hooks/useWindowSize.js';
import CheckSVGStaff from '../../svgs/CheckSVGStaff';
import CommentForm from '../common/CommentForm';
import EvidenceList from '../common/EvidenceList.js';
import MessageForm from '../messaging/MessageForm';
import * as CSS from './elements/StaffStudentTargets';

const StaffStudentTargets = observer(({ studentId }) => {
  const usersStore = useContext(UsersContext);
  const windowStore = useWindowSize();

  const commentFormRef = useRef(null);

  const store = useLocalStore(() => ({
    evidenceIdToComment: null,
    targetIdBeingEvidenced: null,
    isAddingEvidence: false,
    targetIdToEvidence: null,
    selectEvidence(evidenceId, targetId, isAddingEvidence = false) {
      store.evidenceIdToComment = evidenceId;
      store.targetIdBeingEvidenced = targetId;
      store.isAddingEvidence = isAddingEvidence;
      store.targetIdToEvidence = null;
    },
    setTargetIdToEvidence(targetId) {
      store.targetIdToEvidence = targetId;
    },
  }));

  useEffect(() => {
    if (store.evidenceIdToComment && commentFormRef.current) {
      commentFormRef.current.scrollIntoView({
        block: 'end',
        inline: 'center',
        behavior: 'smooth',
      });
    }
  }, [store.evidenceIdToComment]);

  const student = usersStore.getUserById(studentId);

  const targets = student.private.getOrderedPersonalTargets(
    usersStore.getLoggedInUser().selectedCategory
  );
  const numberOfColumns = windowStore.getColumns();

  return (
    <div data-testid="StaffStudentTargets">
      <CSS.ItemListContainer>
        <CSS.ItemList>
          {targets.map((target, index) => {
            const complete = target.evidence.length > 0;
            const evidence = student.private.getEvidenceByTargetId(
              target.targetId,
              numberOfColumns
            );
            const uncommentedCount = student.private.getUncommentedEvidenceCountByItemId(
              target.targetId,
              'personalTargets'
            );
            return (
              <CSS.ItemContainer key={target.targetId} isTop={index === 0}>
                <CSS.FullWidthUnreadCount
                  badgeContent={uncommentedCount}
                  data-testid={`uncommented-count-${target.targetId}`}
                >
                  <CSS.ItemAccordion>
                    <AccordionSummary>
                      <CSS.ItemSummary>
                        <CSS.Circle>
                          <CSS.ItemIndex data-testid={`target-${index + 1}`}>
                            {index + 1}
                          </CSS.ItemIndex>
                        </CSS.Circle>
                        <CSS.ModuleItemInstructions>
                          {target.targetText}
                        </CSS.ModuleItemInstructions>
                        <CSS.CheckSVGContainer>
                          <CheckSVGStaff complete={complete} />
                        </CSS.CheckSVGContainer>
                      </CSS.ItemSummary>
                    </AccordionSummary>

                    <CSS.ItemDetails>
                      <CSS.ListContainer>
                        <EvidenceList
                          evidence={evidence}
                          resource="target"
                          onEvidenceClick={(evidenceId) =>
                            store.selectEvidence(evidenceId, target.targetId)
                          }
                          targetId={target.targetId}
                          studentId={studentId}
                          isStaff
                        />
                        {store.targetIdBeingEvidenced === target.targetId ? (
                          <CommentForm
                            refProp={commentFormRef}
                            studentId={student.id}
                            targetId={store.targetIdBeingEvidenced}
                            evidenceId={store.evidenceIdToComment}
                            afterSubmit={() => store.selectEvidence(null, null)}
                          />
                        ) : store.targetIdToEvidence === target.targetId ? (
                          <>
                            <CSS.ModuleItemInstructions
                              as="h3"
                              style={{ margin: '1em 0' }}
                            >
                              Adding Evidence for {student.displayName}
                            </CSS.ModuleItemInstructions>
                            <CSS.DeleteButton
                              onClick={() => store.setTargetIdToEvidence(null)}
                            >
                              Cancel adding evidence
                            </CSS.DeleteButton>
                            <MessageForm
                              isStaff={true}
                              targetId={target.targetId}
                              userToAddTo={student}
                            ></MessageForm>
                          </>
                        ) : (
                          <CSS.CreateButton
                            style={{ margin: '1em 0' }}
                            onClick={() =>
                              store.setTargetIdToEvidence(target.targetId)
                            }
                          >
                            Add evidence
                          </CSS.CreateButton>
                        )}
                      </CSS.ListContainer>
                    </CSS.ItemDetails>
                  </CSS.ItemAccordion>
                </CSS.FullWidthUnreadCount>
              </CSS.ItemContainer>
            );
          })}
        </CSS.ItemList>
      </CSS.ItemListContainer>
    </div>
  );
});

export default StaffStudentTargets;
