import { observer } from 'mobx-react-lite';
import React from 'react';
import * as CSS from './elements/UserCard';

const UserCard = observer(
  ({
    id,
    imageURL,
    displayName,
    linkSection = `personal`,
    hasNotifications,
    categoryNames,
    showCategories = false,
    onClick,
  }) => {
    return (
      <CSS.UserCard
        data-testid="UserCard"
        hasNotifications={hasNotifications}
        as={hasNotifications ? 'div' : 'li'}
        showCategories={showCategories}
      >
        <CSS.UserLink
          data-testid="UserCard-Link"
          to={`/staff/users/${id}/${linkSection}`}
          onClick={onClick}
          state={showCategories ? { changingCategory: true } : {}}
        >
          <CSS.UserProfileImage src={imageURL} alt={`${displayName} profile`} />
          <CSS.PWithSubscript>
            <CSS.UserDisplayName>{displayName}</CSS.UserDisplayName>
            {showCategories &&
              categoryNames.map((categoryName) => (
                <CSS.UserCategory key={categoryName}>
                  {categoryName}
                </CSS.UserCategory>
              ))}
          </CSS.PWithSubscript>
        </CSS.UserLink>
      </CSS.UserCard>
    );
  }
);

export default UserCard;
