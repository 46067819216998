import ChatIcon from '@material-ui/icons/Chat';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useContext } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from '../../../contexts/Theme';

export const EvidenceList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
  padding: 0;
  width: fit-content;
`;

export const Column = styled.li`
  display: grid;
  grid-gap: 1em;
  height: fit-content;
`;

export const EvidenceCommentContainer = styled.div`
  border-radius: 0.5em;
  padding: 0.2em;
  background: ${({ theme }) => theme.incomingMessage};
`;

export const EvidenceItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25em 0;
  @media only screen and (min-width: 500px) {
    padding: 0.5em;
  }

  ${({ isCommented }) =>
    isCommented &&
    css`
      margin-bottom: 0.5em;
    `}

  ${({ isAudio }) =>
    isAudio
      ? css`
          min-width: 18em;
          ${EvidenceContent} {
            border-radius: 2.5em 2.5em 2.5em 2.5em;
          }
        `
      : css`
          min-width: 10em;
        `}
`;

export const EvidenceContent = styled.div`
  margin: 0;
  background: ${({ theme }) => theme.userMessage};
  color: ${({ theme }) => theme.textLight};
  border-radius: 1em 1em 1em 1em;
  line-height: 0;
  padding: 0.2em;
  display: flex;
  flex-direction: column;
  max-width: 40em;

  ${({ resource, theme }) =>
    resource === 'task' &&
    css`
      background: ${theme.completedFill};
      color: ${theme.isTaskDark ? theme.textLight : theme.headerColour};
    `}

  ${({ resource, theme }) =>
    resource === 'target' &&
    css`
      background: ${theme.targetHighlightPale};
      color: ${theme.isTargetDark ? theme.textLight : theme.headerColour};

      ${!theme.isTargetDark &&
      css`
        border: 2px solid ${theme.classroomHighlight};
      `};
    `}
`;

export const EvidenceText = styled.p`
  white-space: pre-line;
  margin: 0;
  padding: 0.2em 0.75em 0 0.75em;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.7em;
`;

export const EvidenceImage = styled.img`
  max-width: 100%;
  border-radius: 0.8em;
  align-self: center;
`;

export const EvidenceVideo = styled.video`
  max-width: 100%;
  border-radius: 0.8em;
`;

export const EvidenceAudio = styled(AudioPlayer)`
  min-width: 18em;
  padding: 0.3em 0.5em;
  border-radius: 2.8em 2.8em 2.8em 2.8em;
`;

export const EvidenceCreatedAt = styled.p`
  margin: 0 0 0.3em 0.3em;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.subHeaderColour};
  align-self: flex-end;
`;

export const CommentItem = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid grey;
  padding-top: 0.5em;
  ${({ isStaff }) =>
    isStaff &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  @media only screen and (min-width: 500px) {
    padding: 0.5em;
  }
`;

export const SpaceBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SpaceBetweenFlexReverse = styled(SpaceBetweenFlex)`
  flex-direction: row-reverse;
`;

export const CommentDetails = styled.p`
  margin: 0.2em 0.5em 0 0.5em;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.subHeaderColour};
`;

export const DeleteCommentButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const EditCommentButton = styled.button`
  grid-area: comment-edit;
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.3em;
  align-self: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

export const AddCommentIcon = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <ChatIcon
      style={{
        fontSize: '1.8rem',
        margin: '0.3em 0.3em 0 0',
        color: theme.subHeaderColour,
      }}
      {...props}
    />
  );
};

export const EditCommentIcon = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <EditOutlinedIcon
      style={{
        fontSize: '1.8rem',
        margin: '0.1em 0.3em 0 0',
        color: theme.subHeaderColour,
      }}
      {...props}
    />
  );
};

export const CommentContent = styled(EvidenceContent)`
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.feedbackLight};
  color: ${({ theme }) => theme.textDark};
  margin: 0.5em 0;
  ${({ withFile }) =>
    withFile &&
    css`
      display: grid;
      grid-template-columns: 90% 10%;
      grid-template-areas:
        'comment-text comment-edit'
        'comment-file comment-file';
    `}
`;

export const CommentText = styled(EvidenceText)`
  grid-area: comment-text;
  white-space: pre-line;
  padding: 0.2em 0.75em 0 0.75em;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.7em;
  color: ${({ theme }) => theme.headerColour};
`;

export const CommentImage = styled.img`
  grid-area: comment-file;
  max-width: 100%;
  border-radius: 0.8em;
`;

export const CommentVideo = styled.video`
  grid-area: comment-file;
  max-width: 100%;
  border-radius: 0.8em;
`;

export const CommentAudio = styled(AudioPlayer)`
  grid-area: comment-file;
  min-width: 18em;
  padding: 0.3em 0.5em;
  border-radius: 2.8em 2.8em 2.8em 2.8em;
`;

export * from './Staff';
export * from './Students';
