import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from '@reach/router';
import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

export const StudentHeaderRow = styled.section`
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.staffBackground};
  padding: 0em 2em 4em 2em;

  @media only screen and (min-width: 700px) {
    padding-top: 4em;
  }
`;

export const StudentHeader = styled.h1`
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: ${({ theme }) => theme.headerColour};
  margin-left: 0.5em;
`;

export const StudentImage = styled.img`
  align-self: center;
  justify-self: center;
  border-radius: 10px;
  height: 8em;
  width: 8em;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(169, 169, 169, 0.25);
`;

export const NavRow = styled.nav`
  display: flex;
  justify-content: space-evenly;
  margin-top: 2em;
`;

export const EvidenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2em 8rem 2em;
`;

export const StudentItemList = styled.ol`
  list-style-type: none;
  padding: 0 2em;
  margin: 0;
`;

export const ItemLi = styled.li``;

export const UnStyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const SpaceBetweenLink = styled(UnStyledLink)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CenterLink = styled(UnStyledLink)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLink = styled(UnStyledLink)`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.textDark};
  width: auto;
  padding: 0 0.7em;
  &[data-ispartiallycurrent='true'] {
    text-decoration: underline;
    color: ${({ theme }) => theme.lessonHighlight};
  }
`;

export const ActiveLink = (props) => {
  const getProps = ({ isPartiallyCurrent, isCurrent }) => {
    return (isPartiallyCurrent && props.to !== '/') || isCurrent
      ? { 'data-ispartiallycurrent': true }
      : {};
  };
  return <NavLink getProps={getProps} {...props} />;
};

export const ItemInstructions = styled.p`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: ${({ theme, complete }) =>
    complete ? theme.textLight : theme.textDark};
  background: ${({ theme, complete }) =>
    complete ? theme.lessonHighlight : theme.staffBackground};
  border-radius: 12px;
  padding: 1em;
  width: 100%;
  margin: 1em;
  white-space: pre-line;
  box-sizing: border-box;

  ${({ hasNotifications }) =>
    hasNotifications &&
    css`
      margin: 0;
    `}
`;

export const NoItemsLi = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UploadEvidence = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0;
`;

export const UploadText = styled.p`
  font-size: 3rem;
  color: ${({ theme }) => theme.pendingDark};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 1s linear;
  min-height: calc(100vh - max(17vh, 134px));

  @media only screen and (min-width: 700px) {
    min-height: 100vh;
  }
`;

export const XIcon = (props) => {
  return <CloseIcon {...props} />;
};

export const AssessmentIcon = (props) => {
  return <AssignmentRoundedIcon style={{ fontSize: '5.0rem' }} {...props} />;
};

export const EvidenceButton = styled.button`
  grid-area: evidence;
  background: none;
  border: none;
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
`;
