import styled from 'styled-components/macro';

export const AssessmentList = styled.ul`
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Staff';
