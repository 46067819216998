import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import { Form } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { QuestionBullet } from '../../common/elements/Assessments';
import { SaveChangesButton } from '../../common/elements/Form';
import { StaffFilterLink, StaffHeader } from '../../staff/elements/User';

export const AssessmentForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 0;
`;

export const AssessmentLink = styled(StaffFilterLink)`
  margin: 0 1em;
  &[data-ispartiallycurrent='true'] {
    color: ${({ theme }) =>
      theme.isTargetDark ? theme.targetHighlight : theme.headerColour};
  }
`;

export const AssessmentHeader = styled(StaffHeader)`
  margin-bottom: 1em;
`;

export const AnswerList = styled.ol`
  padding: 0;
  list-style-type: none;
  width: 90%;
  margin: 3em 0;
`;

export const AnswerLi = styled.li`
  flex-direction: column;
  display: flex;
  margin-top: 1em;
  padding: 1em;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.pendingDark};
  ${({ isComplete }) =>
    isComplete &&
    css`
      background-color: ${({ theme }) => theme.targetHighlight};
    `};
`;

export const StudentBullet = styled(QuestionBullet)`
  grid-area: bullet;
  background: ${({ theme }) => theme.buttonWarningGradient};
`;

export const StudentQuestion = styled(StaffHeader).attrs({ as: 'label' })`
  grid-area: question;
  font-size: 1.8rem;
  color: ${({ theme, isComplete }) =>
    isComplete
      ? theme.isTargetDark
        ? theme.textLight
        : theme.textDark
      : theme.textLight};
  padding-bottom: 0.3em;
  display: flex;
  justify-content: space-between;
`;

export const SaveAssessmentButton = styled(SaveChangesButton)`
  background: ${({ theme }) => theme.buttonWarningGradient};
`;

export const InfoHeader = styled.h2`
  font-weight: 600;
  font-size: 2rem;
  margin: 0.2em;
`;

export const NoAssessmentIcon = (props) => {
  return <AssignmentRoundedIcon style={{ fontSize: '5.0rem' }} {...props} />;
};

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Cards';
export * from '../../common/elements/Form';
export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
