import styled from 'styled-components/macro';

export const Inline = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const SearchForm = styled.form``;

export const SearchField = styled.input`
  background: ${({ theme }) => theme.appBackground};
  box-shadow: 0px 4px 4px rgba(169, 169, 169, 0.25);
  border-radius: 5px;
  padding: 0.4em 1.5em;
  border: none;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.textDark};
  ::placeholder {
    color: ${({ theme }) => theme.pendingDark};
  }
`;

export const CardList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
