import firebase from 'firebase/app';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import { useWindowSize } from '../../hooks/useWindowSize.js';
import UploadPendingBiggerSVG from '../../svgs/UploadPendingBiggerSVG.js';
import Error from '../common/Error';
import EvidenceList from '../common/EvidenceList.js';
import MessageForm from '../messaging/MessageForm';
import * as CSS from './elements/Task.js';

const Task = observer(({ moduleId, taskId, store }) => {
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);
  const windowStore = useWindowSize();

  const loggedInUser = usersStore.getLoggedInUser();

  const unreadCommentCount = loggedInUser.private.getUnreadCommentsByItemId(
    taskId,
    'tasks'
  );

  useEffect(() => {
    db.collection('usersPrivate')
      .doc(loggedInUser.id)
      .update({
        [`tasks.${taskId}.lastChecked.${loggedInUser.id}`]: firebase.firestore.Timestamp.fromDate(
          new Date()
        ),
      });
  }, [loggedInUser.id, taskId, unreadCommentCount]);

  const module = topicsStore.getModuleById(moduleId);
  if (!module) return <Error msg="Module not found" />;

  const task = module.getItemById(taskId, 'tasks');
  if (!task) return <Error msg="Task not found" />;

  const numberOfColumns = windowStore.getColumns();

  const evidence = loggedInUser.private.getEvidenceByTaskId(
    taskId,
    numberOfColumns
  );
  const isEvidenced = evidence[0]?.length > 0;

  return (
    <CSS.EvidenceContainer>
      <CSS.ItemInstructions complete={isEvidenced}>
        {task.instructions}
      </CSS.ItemInstructions>
      {!isEvidenced ? (
        <CSS.UploadEvidence data-testid="Task-upload">
          <UploadPendingBiggerSVG taskId={taskId} />
          <CSS.UploadText>Add evidence</CSS.UploadText>
        </CSS.UploadEvidence>
      ) : (
        <EvidenceList
          evidence={evidence}
          resource="task"
          taskId={taskId}
          studentId={loggedInUser.id}
          store={store}
        />
      )}
      <MessageForm taskId={taskId} moduleId={moduleId} />
    </CSS.EvidenceContainer>
  );
});

export default Task;
