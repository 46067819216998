import { AccordionSummary } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import CheckSVGStaff from '../../svgs/CheckSVGStaff';
import BrokenLinkIcon from '../../svgs/image-placeholder.svg';
import Error from '../common/Error';
import * as CSS from './elements/StudentModuleLearning';

const StudentModuleLearning = observer(({ studentId, moduleId }) => {
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);

  const student = usersStore.getUserById(studentId);
  const module = topicsStore.getModuleById(moduleId);
  if (!module) return <Error msg="Module not found" />;

  const learningResources = module.getOrderedItems('learningResources');

  const completionPercent = student.private.getModuleItemsCompletionPercent(
    learningResources,
    'learningResources'
  );

  const uncommentedCount = module.getUncommentedEvidenceCountByStudent(
    student.private
  );

  return (
    <CSS.StaffContainerSpaceBetween data-testid="StudentModuleLearning">
      <CSS.StaffRow>
        <nav>
          <CSS.UnreadCount badgeContent={0}>
            <CSS.StaffFilterLink
              to={`/staff/users/${studentId}/progress/modules/${moduleId}/learning`}
              isfirst
            >
              Learning
            </CSS.StaffFilterLink>
          </CSS.UnreadCount>
          <CSS.UnreadCount
            badgeContent={uncommentedCount}
            data-testid={`uncommented-count-tasksLink`}
          >
            <CSS.StaffFilterLink
              to={`/staff/users/${studentId}/progress/modules/${moduleId}/tasks`}
            >
              Tasks
            </CSS.StaffFilterLink>
          </CSS.UnreadCount>
        </nav>
        <CSS.ProgressContainer>
          <CSS.ProgressBarWithLabel
            data-testid="StudentModuleLearning-ProgressBar"
            completionPercent={completionPercent}
          />
        </CSS.ProgressContainer>
      </CSS.StaffRow>
      <CSS.ItemListContainer>
        <CSS.ItemList>
          {learningResources.length ? (
            learningResources.map((resource) => {
              const complete = student.private.isResourceComplete(resource.id);
              return (
                <CSS.ItemContainer
                  key={resource.id}
                  isTop={resource.index === 0}
                >
                  <CSS.ItemAccordion>
                    <AccordionSummary>
                      <CSS.ItemSummary>
                        <CSS.Circle>
                          <CSS.ItemIndex
                            data-testid={`learning-${resource.index + 1}`}
                          >
                            {resource.index + 1}
                          </CSS.ItemIndex>
                        </CSS.Circle>
                        <CSS.ModuleItemInstructions>
                          {resource.instructions}
                        </CSS.ModuleItemInstructions>
                        <CSS.CheckSVGContainer>
                          <CheckSVGStaff complete={complete} />
                        </CSS.CheckSVGContainer>
                      </CSS.ItemSummary>
                    </AccordionSummary>
                    <CSS.ItemDetails>
                      <CSS.ProgressEmbedPreview
                        data-testid="StudentModuleLearning-preview"
                        id="embed-preview"
                        src={
                          resource.embedLink
                            ? resource.embedLink
                            : BrokenLinkIcon
                        }
                        title={resource.instructions}
                        allowFullScreen
                        allowtransparency
                      >
                        <p>Your browser does not support iframes.</p>
                      </CSS.ProgressEmbedPreview>
                    </CSS.ItemDetails>
                  </CSS.ItemAccordion>
                </CSS.ItemContainer>
              );
            })
          ) : (
            <CSS.ItemContainer isTop={true}>
              <CSS.ItemAccordion>
                <CSS.ItemSummary>
                  <CSS.ModuleItemInstructions>
                    No resources yet
                  </CSS.ModuleItemInstructions>
                </CSS.ItemSummary>
              </CSS.ItemAccordion>
            </CSS.ItemContainer>
          )}
        </CSS.ItemList>
      </CSS.ItemListContainer>
    </CSS.StaffContainerSpaceBetween>
  );
});

export default StudentModuleLearning;
