import { getParentOfType, types } from 'mobx-state-tree';
import { db } from '../firebase';
import LearningResource from './LearningResource';
import Task from './Task';
import Topic from './Topic';

const Module = types
  .model('Module', {
    id: types.string,
    moduleName: types.maybeNull(types.string),
    imageURL: types.maybeNull(types.string),
    learningResources: types.optional(types.array(LearningResource), []),
    tasks: types.optional(types.array(Task), []),
    categories: types.optional(types.map(types.string), {}),
    locked: types.optional(types.boolean, false),
    createdBy: types.maybeNull(types.string),
  })
  .actions((self) => ({
    updateModuleInfo(updatedModule) {
      for (let field in updatedModule) {
        if (field === 'categories') {
          self.categories.forEach((value, id) => {
            if (!updatedModule.categories.hasOwnProperty(id)) {
              self.categories.delete(id);
            }
          });
          for (let id in updatedModule.categories) {
            if (!self.categories.has(id)) {
              self.categories.set(id, id);
            }
          }
        } else if (self[field] !== updatedModule[field]) {
          self[field] = updatedModule[field];
        }
      }
    },
    listenToItem(topicId, collection) {
      return db
        .collection('topics')
        .doc(topicId)
        .collection('modules')
        .doc(self.id)
        .collection(collection)
        .onSnapshot((querySnapshot) =>
          self.handleModuleItemSnapshot(querySnapshot, collection)
        );
    },
    handleModuleItemSnapshot(querySnapshot, collection) {
      querySnapshot.docChanges().forEach(({ doc, type }) => {
        const item = doc.data();
        if (type === 'added') {
          self.addItem(
            {
              ...item,
              id: doc.id,
            },
            collection
          );
        } else if (type === 'modified') {
          const itemToUpdate = self[collection].find(({ id }) => id === doc.id);
          itemToUpdate.setInfo(item);
        } else if (type === 'removed') {
          self.removeItemById(doc.id, collection);
        }
      });
    },
    addItem(item, collection) {
      self[collection].push(item);
    },
    removeItemById(itemId, collection) {
      const itemToRemove = self[collection].find(({ id }) => id === itemId);
      if (itemToRemove) self[collection].remove(itemToRemove);
    },
    removeItemByIndex(i, collection) {
      const indexToRemove = self[collection].findIndex(
        ({ index }) => index === i
      );
      self[collection].splice(indexToRemove, 1);
    },
    moveItem(collection, startIndex, endIndex, topicId, moduleId) {
      self[collection].forEach((item) => {
        if (endIndex > startIndex) {
          if (item.index === startIndex) {
            item.index = endIndex;
          } else if (item.index > startIndex && item.index <= endIndex) {
            item.index--;
          }
        } else {
          if (item.index === startIndex) {
            item.index = endIndex;
          } else if (item.index < startIndex && item.index >= endIndex) {
            item.index++;
          }
        }
      });
      const itemUpdates = self[collection].map((item) => {
        return db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .doc(moduleId)
          .collection(collection)
          .doc(item.id)
          .update({
            index: item.index,
          });
      });
      return Promise.all(itemUpdates);
    },
  }))
  .views((self) => ({
    getItemByIndex(indexToFind, collection) {
      return self[collection].find(({ index }) => index === indexToFind);
    },
    getItemById(idToFind, collection) {
      return self[collection].find(({ id }) => id === idToFind);
    },
    getOrderedItems(collection) {
      return [...self[collection]].sort((a, b) => a.index - b.index);
    },
    getMaxItemIndex(collection) {
      return Math.max(...self[collection].map(({ index }) => index));
    },
    getOffsetResource(index, offset) {
      const sortedResources = self.getOrderedItems('learningResources');
      for (let i = 0; i < sortedResources.length; i++) {
        const resource = sortedResources[i];
        if (resource.index === index) return sortedResources[i + offset];
      }
    },
    getParentTopic() {
      return getParentOfType(self, Topic);
    },
    getItemIds(collection) {
      return self[collection].map(({ id }) => id);
    },
    deleteItemById(id, collection) {
      const topicId = self.getParentTopic().id;
      const itemToDelete = self.getItemById(id, collection);
      const itemPromises = self[collection].map((item) => {
        if (item.id === id) {
          return db
            .collection('topics')
            .doc(topicId)
            .collection('modules')
            .doc(self.id)
            .collection(collection)
            .doc(item.id)
            .delete();
        } else if (item.index > itemToDelete.index) {
          return db
            .collection('topics')
            .doc(topicId)
            .collection('modules')
            .doc(self.id)
            .collection(collection)
            .doc(item.id)
            .update({
              index: item.index - 1,
            });
        }
        return null;
      });
      return Promise.all(itemPromises);
    },
    getUncommentedEvidenceCountByStudent(studentPrivate) {
      return self.tasks.reduce((total, task) => {
        return (
          total +
          studentPrivate.getUncommentedEvidenceCountByItemId(task.id, 'tasks')
        );
      }, 0);
    },
    getTotalUnreadTaskComments(studentPrivate) {
      return self.tasks.reduce((total, task) => {
        const studentTask = studentPrivate.tasks.get(task.id);
        if (!studentTask) return total;
        const lastRead = studentTask.lastChecked.get(
          studentPrivate.getParentUser().id
        );
        studentTask.evidence.forEach((evidence) => {
          const notRead = evidence.comment?.createdAt > lastRead;
          if (evidence.comment && (notRead || !lastRead)) total++;
        });
        return total;
      }, 0);
    },
  }));

export default Module;
