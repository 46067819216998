import styled from 'styled-components/macro';

export const Whiteboard = styled.section`
  background: #ffffff;
  box-shadow: 0em 0.4rem 0.4rem rgba(169, 169, 169, 0.25);
  border-radius: 0 0 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: fixed;
  top: max(8.5vh, 67px);
  z-index: 10;
  padding-bottom: 1em;
  @media only screen and (min-width: 700px) {
    top: 0;
    width: calc(100vw - max(10vw, 120px));
  }
`;

export const ClassroomTitle = styled.h1`
  margin: 0;
  /* font-weight: bold; */
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textDark};

  @media only screen and (min-width: 700px) {
    margin-top: 0.5em;
  }
`;

export const CurrentModule = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.headerColour};
  padding-top: 0.4em;

  @media only screen and (min-width: 700px) {
    margin-top: 0.5em;
  }
`;

export const WhiteboardContent = styled.p`
  text-align: center;
  padding-top: 0.4em;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  color: #b40029;
`;
