import { Modal } from '@material-ui/core';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { CategoriesContext } from '../../contexts/Categories.js';
import { ChatsContext } from '../../contexts/Chats.js';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users';
import BOLTON_LOGO from '../../svgs/BOLTON_APP_LOGO_TEXT.png';
import MAE_LOGO from '../../svgs/MAE_LOGO.js';
import MCC_LOGO from '../../svgs/MCC_LOGO.js';
import NOTTINGHAM_APP_LOGO from '../../svgs/NOTTINGHAM_APP_LOGO.png';
import RCC_LOGO from '../../svgs/RCC_LOGO.js';
import SALFORD_APP_LOGO from '../../svgs/SALFORD_APP_LOGO.png';
import SpeechBubblesSVG from '../../svgs/SpeechBubblesSVG.js';
import StarSVG from '../../svgs/StarSVG.js';
import THURROCK_APP_LOGO from '../../svgs/THURROCK_APP_LOGO.png';
import SetCategoryModal from '../common/SetCategoryModal.js';
import * as CSS from './elements/Home';
const { REACT_APP_THEME = 'talkEnglish' } = process.env;

const Home = observer(() => {
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);
  const categoriesStore = useContext(CategoriesContext);

  const store = useLocalStore(() => ({
    isCategoryModalOpen: false,
    setCategoryModal: (open, id) => {
      store.isCategoryModalOpen = open;
      if (id) store.selectedCategoryId = id;
      if (!open) store.selectedCategoryId = null;
    },
    selectedCategoryId: null,
  }));

  const loggedInUser = usersStore.getLoggedInUser();
  const selectedCategory = categoriesStore.getCategoryById(
    loggedInUser.selectedCategory
  );

  const usersGroupChats = loggedInUser.getUsersGroupChats(
    chatsStore,
    loggedInUser.selectedCategory
  );

  const currentModules = loggedInUser.getUniqueActiveModules(
    usersGroupChats,
    topicsStore,
    true
  );

  const otherModules = loggedInUser.getUniqueActiveModules(
    usersGroupChats,
    topicsStore,
    false
  );

  const totalUnreadTargetComments = loggedInUser.private.getTotalUnreadTargetComments(
    loggedInUser.selectedCategory
  );
  const newTargetCount = loggedInUser.private.getNewTargetCount(
    loggedInUser.selectedCategory
  );
  const totalTargetNotifications = totalUnreadTargetComments + newTargetCount;
  const totalAssessmentNotifications = loggedInUser.private.getTotalAssessmentNotifications(
    loggedInUser
  );

  return (
    <CSS.HomeContainer data-testid="Home">
      <div>
        <CSS.WelcomeHeader>
          Welcome {loggedInUser.displayName}!
        </CSS.WelcomeHeader>

        {usersStore.getLoggedInUser().categories.size > 1 && (
          <CSS.CategoryButtonContainer>
            <CSS.InvisibleButton
              onClick={() => store.setCategoryModal(true)}
              data-testid={`Nav-selectedCategoryId-${selectedCategory?.id}`}
            >
              <CSS.CardWithImage isCategory as="div">
                <CSS.CategoryArrow></CSS.CategoryArrow>
                <CSS.HomeCardHeader as="h2">
                  {selectedCategory?.categoryName}
                </CSS.HomeCardHeader>
              </CSS.CardWithImage>
            </CSS.InvisibleButton>
          </CSS.CategoryButtonContainer>
        )}

        <CSS.HomeHeader>Classrooms</CSS.HomeHeader>
        <CSS.CardList>
          {usersGroupChats.length ? (
            usersGroupChats.map((groupChat) => {
              const unreadCount = groupChat.getUnreadCount(
                usersStore.loggedInUserId
              );
              const { id, chatName, participants } = groupChat;
              return (
                <CSS.HomeCardLi key={id}>
                  <CSS.FullWidthUnreadCount
                    badgeContent={unreadCount}
                    data-testid={`unread-count-${id}`}
                  >
                    <CSS.CardWithImage hasNotifications as="div">
                      <CSS.UnstyledLink to={`/classes/${id}`}>
                        <CSS.LessonThumb as="div">
                          <SpeechBubblesSVG />
                        </CSS.LessonThumb>
                        <div>
                          <CSS.ClassroomHeader>{chatName}</CSS.ClassroomHeader>
                          {participants.length === 1 ? (
                            <CSS.ParticipantsText>
                              {participants.length} member
                            </CSS.ParticipantsText>
                          ) : (
                            <CSS.ParticipantsText>
                              {participants.length} members
                            </CSS.ParticipantsText>
                          )}
                        </div>
                      </CSS.UnstyledLink>
                    </CSS.CardWithImage>
                  </CSS.FullWidthUnreadCount>
                </CSS.HomeCardLi>
              );
            })
          ) : (
            <CSS.CardWithImage>
              <CSS.LessonThumb as="div">
                <SpeechBubblesSVG />
              </CSS.LessonThumb>
              <CSS.ClassroomPlaceholder>
                Classroom chats will appear here
              </CSS.ClassroomPlaceholder>
            </CSS.CardWithImage>
          )}
        </CSS.CardList>

        {currentModules.length !== 0 && (
          <>
            <CSS.HomeHeader>Current Lessons</CSS.HomeHeader>
            <CSS.CardList>
              {currentModules.map((module, i) => {
                const completionPercent = loggedInUser.private.getModuleAverageCompletionPercent(
                  module.learningResources,
                  module.tasks
                );

                const totalUnreadTaskComments = module.getTotalUnreadTaskComments(
                  loggedInUser.private
                );
                return (
                  <CSS.HomeCardLi key={module.id}>
                    <CSS.FullWidthUnreadCount
                      badgeContent={totalUnreadTaskComments}
                      data-testid={`unread-count-home-${module.id}`}
                    >
                      <CSS.ModuleCardWithImage
                        lesson
                        isTop={i === 0}
                        hasNotifications
                        as="div"
                      >
                        <CSS.ModuleLessonLink
                          to={`/topics/${module.getParentTopic().id}/modules/${
                            module.id
                          }/learning`}
                        >
                          <CSS.LessonThumb
                            src={module.imageURL}
                            alt=""
                          ></CSS.LessonThumb>
                          <CSS.HomeCardHeader>
                            {module.moduleName}
                          </CSS.HomeCardHeader>
                        </CSS.ModuleLessonLink>
                        <CSS.ProgressContainer>
                          <CSS.ProgressBarWithLabel
                            data-testid={`Home-module-progress-${module.id}`}
                            completionPercent={completionPercent}
                            lessonColours
                            aria-label="module progress"
                          />
                        </CSS.ProgressContainer>
                      </CSS.ModuleCardWithImage>
                    </CSS.FullWidthUnreadCount>
                  </CSS.HomeCardLi>
                );
              })}
            </CSS.CardList>
          </>
        )}

        <CSS.HomeHeader>Me</CSS.HomeHeader>
        <CSS.CardList>
          <CSS.HomeCardLi>
            <CSS.FullWidthUnreadCount
              badgeContent={totalTargetNotifications}
              data-testid="unread-count-homeTargets"
            >
              <CSS.CardWithImage isTarget hasNotifications as="div">
                <CSS.LessonLink to="/targets">
                  <StarSVG size="5em" />
                  <CSS.HomeCardHeader>My Targets</CSS.HomeCardHeader>
                </CSS.LessonLink>
              </CSS.CardWithImage>
            </CSS.FullWidthUnreadCount>
          </CSS.HomeCardLi>
          <CSS.HomeCardLi>
            <CSS.FullWidthUnreadCount
              badgeContent={totalAssessmentNotifications}
              data-testid="unread-count-homeAssessment"
            >
              <CSS.CardWithImage isAssessment hasNotifications as="div">
                <CSS.LessonLink to="/learningPlan/start">
                  <CSS.AssessmentIcon />
                  <CSS.HomeCardHeader>My Learning Plan</CSS.HomeCardHeader>
                </CSS.LessonLink>
              </CSS.CardWithImage>
            </CSS.FullWidthUnreadCount>
          </CSS.HomeCardLi>
        </CSS.CardList>

        {otherModules.length !== 0 && (
          <CSS.HomeHeader>My Lessons</CSS.HomeHeader>
        )}

        <CSS.CardList>
          {!!otherModules.length &&
            otherModules.map((module, i) => {
              const completionPercent = loggedInUser.private.getModuleAverageCompletionPercent(
                module.learningResources,
                module.tasks
              );
              const totalUnreadTaskComments = module.getTotalUnreadTaskComments(
                loggedInUser.private
              );
              return (
                <CSS.HomeCardLi key={module.id}>
                  <CSS.FullWidthUnreadCount
                    badgeContent={totalUnreadTaskComments}
                    data-testid={`unread-count-home-${module.id}`}
                  >
                    <CSS.ModuleCardWithImage
                      key={module.id}
                      lesson
                      isTop={i === 0}
                      data-testid={`Home-activeModule-${module.id}`}
                      hasNotifications
                      as="div"
                    >
                      <CSS.ModuleLessonLink
                        to={`/topics/${module.getParentTopic().id}/modules/${
                          module.id
                        }/learning`}
                      >
                        <CSS.LessonThumb
                          src={module.imageURL}
                          alt=""
                        ></CSS.LessonThumb>
                        <CSS.HomeCardHeader>
                          {module.moduleName}
                        </CSS.HomeCardHeader>
                      </CSS.ModuleLessonLink>
                      <CSS.ProgressContainer>
                        <CSS.ProgressBarWithLabel
                          data-testid={`Home-module-progress-${module.id}`}
                          completionPercent={completionPercent}
                          lessonColours
                          aria-label="module progress"
                        />
                      </CSS.ProgressContainer>
                    </CSS.ModuleCardWithImage>
                  </CSS.FullWidthUnreadCount>
                </CSS.HomeCardLi>
              );
            })}

          {!otherModules.length && !currentModules.length && (
            <CSS.CardWithImage lesson isTop={true}>
              <CSS.LessonLink to="/profile">
                <CSS.ProfileThumb
                  src={loggedInUser.imageURL}
                  alt=""
                ></CSS.ProfileThumb>
                <CSS.HomeCardHeader>Update Profile</CSS.HomeCardHeader>
              </CSS.LessonLink>
            </CSS.CardWithImage>
          )}
        </CSS.CardList>
      </div>

      {REACT_APP_THEME === 'rochdale' ? (
        <CSS.Logos single>
          <RCC_LOGO />
        </CSS.Logos>
      ) : REACT_APP_THEME === 'birminghamESOL' ? (
        <CSS.Logos empty></CSS.Logos>
      ) : REACT_APP_THEME === 'birminghamAES' ? (
        <CSS.Logos empty></CSS.Logos>
      ) : REACT_APP_THEME === 'bolton' ? (
        <CSS.Logos single>
          <CSS.AppLogo src={BOLTON_LOGO} alt="Bolton College"></CSS.AppLogo>
        </CSS.Logos>
      ) : REACT_APP_THEME === 'salford' ? (
        <CSS.Logos single>
          <CSS.AppLogo
            src={SALFORD_APP_LOGO}
            alt="Salford Learning"
          ></CSS.AppLogo>
        </CSS.Logos>
      ) : REACT_APP_THEME === 'nottingham' ? (
        <CSS.Logos single>
          <CSS.AppLogo
            src={NOTTINGHAM_APP_LOGO}
            alt="Nottingham Jobs"
          ></CSS.AppLogo>
        </CSS.Logos>
      ) : REACT_APP_THEME === 'thurrock' ? (
        <CSS.Logos single>
          <CSS.AppLogo
            src={THURROCK_APP_LOGO}
            alt="Thurrock Adult Community College"
          ></CSS.AppLogo>
        </CSS.Logos>
      ) : (
        <CSS.Logos>
          <MCC_LOGO />
          <MAE_LOGO />
        </CSS.Logos>
      )}
      <Modal
        open={store.isCategoryModalOpen}
        onClose={() => store.setCategoryModal(false)}
      >
        <SetCategoryModal closeModal={() => store.setCategoryModal(false)} />
      </Modal>
    </CSS.HomeContainer>
  );
});

export default Home;
