import styled, { css } from 'styled-components/macro';
import ProfileImageComponent from '../../common/ProfileImage';
import { Whiteboard } from './Whiteboard.js';

export const DirectMessageWhiteboard = styled(Whiteboard)`
  flex-direction: row;
  padding-bottom: 0.1em;
`;

export const ParticipantName = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  color: ${({ theme }) => theme.headerColour};
`;

export const ParticipantRole = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.classroomHighlight};
`;

export const ParticipantProfileImage = styled(ProfileImageComponent)`
  grid-area: image;
  align-self: center;
  justify-self: center;
  height: 3.5em;
  width: 3.5em;
  margin: 0 0.5em 1em 0.5em;

  ${({ isStaff }) =>
    isStaff &&
    css`
      border: 3px solid ${({ theme }) => theme.classroomHighlight};
      border-radius: 50%;
    `}
`;
