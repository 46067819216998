import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

export const UnreadCount = (props) => {
  const theme = useContext(ThemeContext);
  const classes = makeStyles({
    badge: {
      fontSize: '1.25rem',
      fontWeight: 550,
    },
    colorError: {
      backgroundColor: theme.notification,
    },
  })();
  return <Badge color="error" classes={classes} {...props}></Badge>;
};

export const FullWidthUnreadCount = (props) => {
  const theme = useContext(ThemeContext);
  const classes = makeStyles({
    badge: {
      fontSize: '1.25rem',
      fontWeight: 550,
    },
    colorError: {
      backgroundColor: theme.notification,
    },
    root: {
      width: '100%',
    },
  })();
  return <Badge color="error" classes={classes} {...props}></Badge>;
};

export const StaffCardMargin = styled.li`
  margin: 1em 1em 2em 1em;
  list-style: none;
`;

export const StudentTargetLi = styled.li`
  font-size: 1.8rem;
  // change margin -> padding to prevent collapsing margins
  padding: 1em 0;
`;

export const StudentTaskLi = styled.div`
  font-size: 1.8rem;
  margin: 1em;
  width: 100%;
`;

export const StaffCardNotification = (props) => {
  return (
    <StaffCardMargin>
      <UnreadCount {...props}></UnreadCount>
    </StaffCardMargin>
  );
};

export const StudentItemNotification = (props) => {
  return (
    <StudentTargetLi>
      <FullWidthUnreadCount {...props}></FullWidthUnreadCount>
    </StudentTargetLi>
  );
};

export const StudentTaskNotification = (props) => {
  return (
    <StudentTaskLi>
      <FullWidthUnreadCount {...props}></FullWidthUnreadCount>
    </StudentTaskLi>
  );
};
