import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/SetTopicForm';

const setTopicSchema = yup.object().shape({
  topicName: yup.string().required('Please enter a topic name'),
  subject: yup.string().required('Please enter a subject name'),
});

const SetTopicForm = React.forwardRef(({ closeModal, topicId }, ref) => {
  const topicsStore = useContext(TopicsContext);
  const usersStore = useContext(UsersContext);
  const topic = topicsStore.getTopicById(topicId);

  const handleSubmit = async ({ topicName, subject }) => {
    const selectedCategory = usersStore.getLoggedInUser().selectedCategory;
    if (!topicId) {
      await db.collection('topics').add({
        topicName,
        active: true,
        category: selectedCategory,
        subject: subject.trim(),
      });
    } else {
      await db
        .collection('topics')
        .doc(topicId)
        .update({ topicName, subject: subject.trim() });
    }
    closeModal();
  };

  return useObserver(() => (
    <Formik
      enableReinitialize
      initialValues={{
        topicName: topic?.topicName || '',
        subject: topic?.subject || '',
      }}
      validationSchema={setTopicSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="SetTopicForm">
              <CSS.StaffFormHeaderRow>
                <CSS.StaffFormHeader>
                  {topicId ? 'Edit topic' : 'Create a Topic'}
                </CSS.StaffFormHeader>
                <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                  <CSS.StaffCloseIcon />
                </CSS.StaffFormCloseButton>
              </CSS.StaffFormHeaderRow>
              <CSS.FieldGroup>
                <Field
                  type="text"
                  name="topicName"
                  id="topicName"
                  label="Enter a topic name"
                  component={CSS.StaffTextFieldInput}
                />
                <ErrorMessage name="topicName" component="p" />
              </CSS.FieldGroup>
              <CSS.FieldGroup>
                <FieldArray name="subject">
                  {({ form }) => (
                    <Observer>
                      {() => (
                        <CSS.StaffAutocomplete
                          freeSolo
                          fullWidth
                          value={values.subject}
                          onChange={(event, subject, reason) => {
                            form.setFieldValue('subject', subject);
                          }}
                          options={topicsStore.getOrderedSubjects(
                            usersStore.getLoggedInUser().selectedCategory
                          )}
                          getOptionLabel={(subject) => subject}
                          renderInput={(params) => (
                            <CSS.StaffAutocompleteInput
                              {...params}
                              label="Enter a subject"
                              variant="outlined"
                              onChange={(e) => {
                                form.setFieldValue('subject', e.target.value);
                              }}
                            />
                          )}
                        />
                      )}
                    </Observer>
                  )}
                </FieldArray>
                <ErrorMessage name="subject" component="p" />
              </CSS.FieldGroup>
              <CSS.StaffFooterRow>
                <CSS.StaffNavButton type="submit">Finish</CSS.StaffNavButton>
              </CSS.StaffFooterRow>
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  ));
});

export default SetTopicForm;
