import { Redirect, Router } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users.js';
import * as CSS from './elements/Progress.js';
import ModuleProgress from './ModuleProgress';
import StaffStudentModules from './StaffStudentModules';
import StaffStudentTargets from './StaffStudentTargets';

const Progress = observer(({ studentId, store }) => {
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);
  const student = usersStore.getUserById(studentId);
  const loggedInUser = usersStore.getLoggedInUser();
  const totalUncommentedTargetEvidence = student.private.getTotalUncommentedItemEvidence(
    'personalTargets',
    loggedInUser.selectedCategory,
    topicsStore
  );
  const totalUncommentedModuleEvidence = student.private.getTotalUncommentedItemEvidence(
    'tasks',
    loggedInUser.selectedCategory,
    topicsStore
  );

  return (
    <div>
      <CSS.StaffRow>
        <nav>
          <CSS.UnreadCount
            badgeContent={totalUncommentedModuleEvidence}
            data-testid={`uncommented-count-modulesLink`}
          >
            <CSS.StaffFilterLink to="modules" isfirst>
              Modules
            </CSS.StaffFilterLink>
          </CSS.UnreadCount>
          <CSS.UnreadCount
            badgeContent={totalUncommentedTargetEvidence}
            data-testid={`uncommented-count-targets`}
          >
            <CSS.StaffFilterLink to="targets">
              Personal Targets
            </CSS.StaffFilterLink>
          </CSS.UnreadCount>
        </nav>
      </CSS.StaffRow>
      <Router primary={false}>
        <Redirect from="/" to="modules" noThrow />
        <StaffStudentModules path="/modules" studentId={studentId} />
        <StaffStudentTargets path="/targets" studentId={studentId} />
        <ModuleProgress
          path="/modules/:moduleId/*"
          studentId={studentId}
          store={store}
        />
      </Router>
    </div>
  );
});

export default Progress;
