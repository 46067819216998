import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ItemInstructions } from '../../student/elements/Home';

export const EvidenceTargetContainer = styled.section`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: ${({ theme }) => theme.appBackground};
  display: flex;
  flex-direction: column;

  ${({ isStaff }) =>
    !isStaff &&
    css`
      @media only screen and (min-width: 700px) {
        width: calc(100vw - max(10vw, 120px));
      }
    `};

  ${({ isStaff }) =>
    isStaff &&
    css`
      @media only screen and (min-width: 700px) {
        width: auto;
      }
    `};
`;

export const CloseButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  align-self: flex-start;
`;

export const ShowButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  align-self: flex-start;
`;

export const ShowIcon = () => {
  return <ExpandMoreRoundedIcon style={{ fontSize: '3.5rem' }} />;
};

export const HideIcon = () => {
  return <ExpandLessRoundedIcon style={{ fontSize: '3.5rem' }} />;
};

export const ChatBackground = styled.div`
  background: ${({ theme }) => theme.chatBackground};
  display: flex;
  justify-content: space-between;
  box-shadow: 0em 0.4rem 0.4rem rgba(169, 169, 169, 0.25);
`;

export const TargetEvidenceHeaderRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em;
`;

export const ScrollSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
`;

export const TaskInstructions = styled(ItemInstructions).attrs({ as: 'li' })`
  margin: 1em 0;
`;

export const SelectContainer = styled.div`
  padding: 0 2em;
`;

export * from '../../common/elements/Staff';
export * from '../../student/elements/PersonalTarget.js';
