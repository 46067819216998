import { Form } from 'formik';
import styled from 'styled-components/macro';
import { FieldGroup } from '../../common/elements/Form';
import { DeleteIconButton } from '../../common/elements/Staff';

export const AddAssessmentContainer = styled.section`
  text-align: center;
`;

export const AssessmentForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoText = styled.p`
  font-size: 1.6rem;
  margin: 1em 0 0 0;
`;

export const SelectContainer = styled(FieldGroup)`
  background: ${({ theme }) => theme.appBackground};
  padding: 0;
  margin: 2em;
`;

export const CloseIconButton = styled(DeleteIconButton)`
  margin: 0 2em 0 auto;
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
export * from '../../common/elements/Students';
