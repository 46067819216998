import { Link } from '@reach/router';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { db } from '../../firebase';
import useLoadingTimer from '../../hooks/useLoadingTimer';
import Error from '../common/Error';
import Loading from '../common/Loading';
import * as CSS from './elements/PrivacyPolicy';

const PrivacyPolicy = observer(() => {
  const store = useLocalStore(() => ({
    policy: null,
    setPolicy: (policy) => {
      store.policy = policy;
    },
  }));

  useEffect(() => {
    db.collection('privacyPolicies')
      .orderBy('createdAt', 'desc')
      .get()
      .then((policyDocs) => {
        const { policy } = policyDocs.docs
          .filter((policy) => policy.data().active)[0]
          .data();

        store.setPolicy(policy);
      });
  }, [store]);
  const loadingTimer = useLoadingTimer();

  if (!store.policy && loadingTimer) return <Loading />;
  if (store.policy === null) return <Error msg="Policy not found" />;
  return (
    <CSS.PrivacyContainer>
      <h1>Privacy Notice</h1>
      <CSS.PolicyText>{store.policy}</CSS.PolicyText>
      Return to <Link to="/">Home</Link>
    </CSS.PrivacyContainer>
  );
});

export default PrivacyPolicy;

/*
Manadulted.app is a web-based application developed to support and enhance learning both in the classroom and remotely.
            The application allows student users to:
            - access online learning content and activities
            - record and evidence their learning
            - communicate with their teacher and other learners in their class

            This Privacy Notice governs the use of data collected by manadulted.app.  This includes personally identifiable information that we collect when you:
            - create an account,
            - add content to My Targets, My Learning Plan and Tasks
            - send messages via manadulted.app either privately or in Classroom Chats

            Any data collected by manadulted.app that can be linked back to an individual student is considered “Student Data”.

            By using manadulted.app, you agree to this Privacy Policy. If you don’t agree, please don’t use manadulted.app.  You can contact us anytime with questions about this policy at nuala.trace@manchester.gov.uk.

            The personal information manadulted.app collects
            - Your name
            - Phone number
            - Profile picture (optional)
            Content added to My Learning Plan, My Targets and My Tasks in the form of:
            - text
            - voice recordings
            - photos
            - video
            - hyperlinks
            Messages sent and received in manadulted.app which could be in the form of text, voice recordings, photos, videos and hyperlinks that are sent and received in manadulted.app
            Where this personal information comes from
            The Information is provided directly to us by you when you enrol on a course and when you use manadulted.app.

            How we use your personal information
            We only use this information to provide our services to you, for example:
            - To create an account to access and use our service, including:
              - Accessing online learning content
              - Recording and evidencing learning
              - Communicating with teachers and other learners
            - Research, understand, and analyse trends of users to improve and develop new features for our product.

            Creating an account
            In order to use the application, student users provide their name and mobile phone number.  This information may be entered by an administrator, teacher or the student user.
            Users will use their phone number and PIN to use the application.
            The PIN should be reset by the user to something memorable and personal using the ‘reset pin’.  This will trigger a text message to the registered mobile number with a time limited link which will enable the user to reset their PIN
            Users can choose to upload an image to their profile and change or remove this at any time.

            Accessing online learning content
            To access online learning content and tasks, student users will be assigned to a classroom by a teacher or administrator.  The administrator will provide access to relevant learning content and tasks.
            Student users can use text, voice recordings, photos and video to complete tasks, which can only be seen by the student user and a teacher or administrator.
            Student users can delete posts they have added.
            Teachers and administrators can use text, voice recordings, photos and video to feedback to student users on their posts.
            Teachers and administrators can also delete a student users’ posts.

            Recording and evidencing learning
            The application allows student users to record and evidence their learning.  A teacher or administrator will assign a relevant individual learning plan (ILP) to student users on which they will record some limited information relating to the learner’s skills, progress and achievement at the start and end of their course with Manchester City Council.  The student user can add information to their ILP about their situation and future plans.
            A teacher or administrator will also set learning targets specific to the student user’s course programme and personal needs.  Student users can use text, voice recordings, photos and video to evidence their learning which can only be seen by the student user and a teacher or administrator.
            Student users can delete posts they have added.
            Teachers and administrators can use text, voice recordings, photos and video to feedback to student users on their posts.
            Teachers and administrators can also delete a student users’ posts.

            Communicating with teachers and other learners

            Private messaging
            Student users can communicate with teachers and administrators privately through their Inbox messages using text, voice recordings, photos and video.  These messages cannot be seen by other student users or teachers.
            Student users cannot communicate with other student users outside of the classroom chat.

            Classroom Chat
            Once assigned to a classroom, student users can use text, voice recordings, photos and video to communicate with their teacher and other students assigned to their classroom.  Student users can only see the names and profile pictures of other student users posting in the chat.
            Users can delete any posts they have made to remove them from the chat.
            Teachers and administrators can also delete a student users’ posts.

            Log Data
            When you use manadulted.app, we receive log data such as your IP address, browser type, operating system, device information, and your mobile carrier.
            How to view, edit, update or delete your personal information
            You have the right to access, correct or delete any of your personal information collected by manadaulted.app.
            You can update the following information associated with your account directly by logging into your account:
            - Profile picture
            - Learning plan
            You can delete any content you have posted to your targets, against tasks or via private or classroom messaging
            You can request to delete your account or selected content attached to it at any time. If you want to correct, edit, delete or update any other information, including the phone number associated with your account,  please contact nuala.trace@manchester.gov.uk

            How we keep your data safe
            We use a secure UK-based data centre to host and store your data and SSL encrypted security technology to establish secure connections between a web server and browser.
            All teachers and administrators on manadulted.app are employees of Manchester City Council and undergo regular training regarding their responsibilities for data protection.

            How we use cookies
            Cookies are small text files that we transfer to your web browser that allow us to identify your web browser and store information about your account.  We use these cookies to keep you logged in to manadulted.app and customise your experience.
            You can choose to remove or disable cookies via your browser settings. Please be aware that manadulted.app will not work properly if you disable or decline cookies.

            How we use analytics services
            manadulted.app is constantly developing, and we use aggregate data about how it is used - for example what buttons you click on or what pages you visit - to inform those decisions.
            To help us analyse this data, we use Google Analytics.  In no circumstances are any data you have shared with manadulted.app (such as your account information or learning content) shared with these services.  In addition, Google Analytics  is contractually obligated only to use data about your usage of manadulted.app to provide analytics services to us and are prohibited from sharing it or using it for other purposes. If you do not wish to participate in Google Analytics, you may download the Google Analytics opt-out browser add-on.

            Who we share your information with
            We do not share any data you provide with third parties except in the limited circumstances detailed below:
            - With user consent. We will share personal information when we have user consent
            - For external processing. We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.
            - For legal reasons. We will share personal information with companies, organisations or individuals outside of Manchester City Council if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:
              - meet any applicable law, regulation, legal process or enforceable governmental request.
              - enforce applicable Terms of Service, including investigation of potential violations.
              - detect, prevent, or otherwise address fraud, security or technical issues.
              - protect against harm to the rights, property or safety of Manchester City Council, our users or the public as required or permitted by law.
            We may share non-personal information publicly and with our funders. For example, we may share information publicly to show trends about the general use of our services.
            How long we keep your information for
            We will keep your account and associated information for 6 months after you finish your course with Manchester City Council.  Prior to deleting an account, we will download an archive copy of your completed  learning plan and targets, which we will keep securely in compliance with the general privacy notices of Manchester City Council and Manchester Adult Education.

            How to view, edit, update or delete your personal information
            You have the right to access, correct or delete any of your personal information collected by manadaulted.app.
            You can update the following information associated with your account directly by logging into your account:
            - Profile picture
            - Learning Plan
            You can delete any content you have posted to your targets, against tasks or via private or classroom messaging
            If you want to correct, edit, delete or update any other information, including the phone number associated with your account,  please contact nuala.trace@manchester.gov.uk

            Changes to this Privacy Notice
            We may from time to time make changes to this Privacy Policy to account for changes to our practices or applicable law. If we make changes to this Privacy Policy that we believe will materially affect your rights, we will notify you by text about these changes and post a notice on manadulted.app.
            If you continue to use our service after you receive notice of changes to this Privacy Policy, you will accept these changes.

            Further information
            If you would like further information about this privacy notice, please contact
            nuala.trace@manchester.gov.uk
*/
