import Slide from '@material-ui/core/Slide';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { UsersContext } from '../../contexts/Users';
import Error from '../common/Error';
import EvidenceTarget from '../messaging/EvidenceTarget';
import Classrooms from '../staff/Classrooms';
import EditClassroom from '../staff/EditClassroom';
import StaffClassroom from '../staff/StaffClassroom';
import StaffModule from '../staff/StaffModule';
import Topics from '../staff/Topics';
import User from '../staff/User';
import Users from '../staff/Users';
import AdminAuth from './AdminAuth';
import * as CSS from './elements/StaffAuth';
import CrossCategorySearch from '../staff/CrossCategorySearch';

const StaffAuth = observer(() => {
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);

  const store = useLocalStore(() => {
    return {
      evidenceOpen: false,
      messageToEvidence: null,
      openEvidence(isOpen, evidence = null, resource = '') {
        store.evidenceOpen = isOpen;
        store.messageToEvidence = evidence;
        store.evidenceResource = resource;
      },
      evidenceResource: '',
    };
  });

  const loggedInUser = usersStore.getLoggedInUser();

  const allowedRoles = ['teacher', 'admin'];
  if (!allowedRoles.includes(loggedInUser.role)) return <Error />;

  return (
    <>
      <CSS.StaffRouter
        primary={false}
        data-testid="StaffAuth"
        chatid={chatsStore.selectedChatId}
        isselectedchatvisible={
          chatsStore.isSelectedChatVisible ? 'true' : undefined
        }
      >
        <Classrooms path="/" />
        <Classrooms path="/classrooms" />
        <EditClassroom path="/classrooms/:groupChatId" />
        <Users path="/users" />
        <User path="/users/:userId/*" store={store} />
        <Topics path="/topics" />
        <StaffModule path="/topics/:topicId/modules/:moduleId/*" />
        {loggedInUser.role === 'admin' && (
          <CrossCategorySearch path="/search/users" />
        )}
        {loggedInUser.role === 'admin' && <AdminAuth path="/admin/*" />}
        <Error default />
      </CSS.StaffRouter>
      {store.messageToEvidence && (
        <EvidenceTarget
          isOpen={store.evidenceOpen}
          message={store.messageToEvidence}
          openEvidence={store.openEvidence}
          evidenceResource={store.evidenceResource}
        />
      )}
      <Slide
        direction="left"
        in={
          Boolean(chatsStore.selectedChatId) && chatsStore.isSelectedChatVisible
        }
        mountOnEnter
        unmountOnExit
      >
        <StaffClassroom chatId={chatsStore.selectedChatId} />
      </Slide>
    </>
  );
});

export default StaffAuth;
