import { types } from 'mobx-state-tree';
import Question from './Question';

const LearningAssessment = types
  .model('LearningAssessment', {
    id: types.maybeNull(types.string),
    title: types.string,
    initialQuestions: types.map(Question),
    finalQuestions: types.map(Question),
    published: types.optional(types.boolean, false),
    category: types.string,
    initialLastModified: types.maybeNull(types.Date),
    finalLastModified: types.maybeNull(types.Date),
    initialLastChecked: types.optional(types.map(types.Date), {}),
    finalLastChecked: types.optional(types.map(types.Date), {}),
  })
  .actions((self) => ({
    updateInfo(updatedLearningAssessment) {
      self.id = updatedLearningAssessment.id || null;
      self.title = updatedLearningAssessment.title;
      self.published = updatedLearningAssessment.published;
      self.category = updatedLearningAssessment.category;
      self.initialLastModified = updatedLearningAssessment.initialLastModified
        ? updatedLearningAssessment.initialLastModified.toDate()
        : null;
      self.finalLastModified = updatedLearningAssessment.finalLastModified
        ? updatedLearningAssessment.finalLastModified.toDate()
        : null;
      for (let questionId in updatedLearningAssessment.initialQuestions) {
        self.initialQuestions.set(
          questionId,
          updatedLearningAssessment.initialQuestions[questionId]
        );
      }
      self.initialQuestions.forEach((question, questionId) => {
        if (!updatedLearningAssessment.initialQuestions[questionId]) {
          self.initialQuestions.delete(questionId);
        }
      });
      for (let questionId in updatedLearningAssessment.finalQuestions) {
        self.finalQuestions.set(
          questionId,
          updatedLearningAssessment.finalQuestions[questionId]
        );
      }
      self.finalQuestions.forEach((question, questionId) => {
        if (!updatedLearningAssessment.finalQuestions[questionId]) {
          self.finalQuestions.delete(questionId);
        }
      });
      for (let userId in updatedLearningAssessment.initialLastChecked) {
        self.initialLastChecked.set(
          userId,
          updatedLearningAssessment.initialLastChecked[userId].toDate()
        );
      }
      self.initialLastChecked.forEach((lastCheckedDate, userId) => {
        if (!updatedLearningAssessment.initialLastChecked[userId]) {
          self.initialLastChecked.delete(userId);
        }
      });
      for (let userId in updatedLearningAssessment.finalLastChecked) {
        self.finalLastChecked.set(
          userId,
          updatedLearningAssessment.finalLastChecked[userId].toDate()
        );
      }
      self.finalLastChecked.forEach((lastCheckedDate, userId) => {
        if (!updatedLearningAssessment.finalLastChecked[userId]) {
          self.finalLastChecked.delete(userId);
        }
      });
    },
  }))
  .views((self) => ({
    getOrderedQuestions(questionSet) {
      return [...self[questionSet].values()].sort((a, b) => {
        return a.index - b.index;
      });
    },
    getQuestionCount(questionSet) {
      return self[questionSet].size;
    },
    getUncheckedCount(questionSet, loggedInUserId) {
      const lastModified = self[`${questionSet}LastModified`];
      const lastChecked = self[`${questionSet}LastChecked`].get(loggedInUserId);
      return (!lastChecked && lastModified) || lastModified > lastChecked
        ? 1
        : 0;
    },
    getTotalUnchecked(loggedInUserId) {
      const initialCount = self.getUncheckedCount('initial', loggedInUserId);
      const finalCount = self.getUncheckedCount('final', loggedInUserId);
      return initialCount + finalCount;
    },
  }));

export default LearningAssessment;
