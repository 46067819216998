import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import useLoadingTimer from '../../hooks/useLoadingTimer';
import Error from '../common/Error';
import Loading from '../common/Loading';
import * as CSS from './elements/ClassroomCard';

const ClassroomCard = observer(({ groupChatId }) => {
  const chatsStore = useContext(ChatsContext);
  const topicsStore = useContext(TopicsContext);
  const usersStore = useContext(UsersContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const groupChat = chatsStore.getChatById(groupChatId, 'groupChats');
  const isLoadingTimer = useLoadingTimer();

  if (!groupChat && isLoadingTimer) return <Loading />;
  if (!groupChat) return <Error msg="Chat not found" />;

  const toggleClassroomActive = async () => {
    await db
      .collection('groupChats')
      .doc(groupChatId)
      .update({ active: !groupChat.active, locked: groupChat.active });
  };

  const currentModule = groupChat?.getCurrentModule(topicsStore);
  const currentTopic = groupChat?.getCurrentTopic(topicsStore);
  const teacher = groupChat?.getTeacher(usersStore);

  const handleToggleClassroomActive = (e) => {
    e.stopPropagation();
    const activeText = groupChat.active ? 'Inactive' : 'Active';
    saveChangesStore.setText({
      heading: `Set classroom '${groupChat.chatName}' to '${activeText}'`,
      message: `Students in the '${groupChat.chatName}' classroom will ${
        groupChat.active ? 'not' : ''
      } be able to post messages in this chat`,
      leavingText: 'Ok',
      remainingText: 'Go back',
    });

    saveChangesStore.open(toggleClassroomActive);
  };

  const unreadCount = groupChat.getUnreadCount(usersStore.loggedInUserId);

  return (
    <CSS.ClassroomCardLi>
      <CSS.UnreadCount
        badgeContent={unreadCount}
        data-testid={`unread-count-${groupChatId}`}
      >
        <CSS.ClassroomCard
          data-testid={`ClassroomCard-${groupChat.chatName}`}
          onClick={() => {
            // trl workaround -> can't navigate in tests?
            if (
              usersStore.getLoggedInUser().private.preferences
                ?.autoOpenClassroomChat
            ) {
              chatsStore.setSelectedChatId(groupChatId);
              chatsStore.setIsSelectedChatVisible(true);
            }
            navigate(`/staff/classrooms/${groupChatId}`);
          }}
        >
          <CSS.ClassroomHeader>{groupChat.chatName}</CSS.ClassroomHeader>
          <CSS.ActiveClassroom
            active={groupChat.active}
            data-testid={`Active-Button-${groupChatId}-${groupChat.active}`}
            onClick={handleToggleClassroomActive}
          >
            {groupChat.active ? 'Active' : 'Inactive'}
            <CSS.ActiveIcon />
          </CSS.ActiveClassroom>
          <CSS.Teacher>Teacher: {teacher?.displayName}</CSS.Teacher>
          <CSS.Topic>Topic: {currentTopic?.topicName}</CSS.Topic>
          <CSS.Module>Current Module: {currentModule?.moduleName}</CSS.Module>
        </CSS.ClassroomCard>
      </CSS.UnreadCount>
    </CSS.ClassroomCardLi>
  );
});

export default ClassroomCard;
