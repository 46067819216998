import styled from 'styled-components/macro';

export const ChatContainer = styled.section`
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-areas: 'staffWhiteboard' 'chat';
  background: ${({ theme }) => theme.chatBackground};
  position: fixed;
  right: 0;
  top: 8.5vh;
  width: 100%;
  min-width: 325px;
  height: calc(100% - 8.5vh);
  z-index: 20;

  @media only screen and (min-width: 700px) {
    top: 0;
    width: 25vw;
    height: 100%;
  }
`;

export * from '../../common/elements/Staff';
