import React from 'react';
import styled from 'styled-components/macro';
import { SaveChangesButton } from '../../common/elements/Form';
import { StaffSubHeader } from '../../common/elements/Staff';

export const AssessmentContainer = styled.section`
  margin: 1em 0;
`;

const Title = styled(StaffSubHeader).attrs({ as: 'input' })`
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  width: 200%;
  &:disabled {
    background-color: ${({ theme }) => theme.appBackground};
  }
`;

export const AssessmentTitle = ({ form, field, ...props }) => {
  return <Title {...field} {...props} />;
};

export const InfoText = styled.p`
  grid-area: text;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.textDark};
`;

export const PublishButton = styled(SaveChangesButton)`
  margin-top: 1em;
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
