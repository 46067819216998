import { Modal } from '@material-ui/core';
import { navigate, Redirect } from '@reach/router';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AlertContext } from '../../contexts/Alert';
import { ChatsContext } from '../../contexts/Chats';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import useLoadingTimer from '../../hooks/useLoadingTimer.js';
import Error from '../common/Error';
import Loading from '../common/Loading';
import * as CSS from './elements/StaffModule';
import SetModuleForm from './SetModuleForm';
import ShareModuleForm from './ShareModuleForm';
import StaffModuleItemsForm from './StaffModuleItemsForm.js';

const StaffModule = observer(({ moduleId, topicId }) => {
  const topicsStore = useContext(TopicsContext);
  const usersStore = useContext(UsersContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const chatsStore = useContext(ChatsContext);
  const alertStore = useContext(AlertContext);

  const store = useLocalStore(() => ({
    isEditModuleOpen: false,
    setEditModuleOpen(isOpen) {
      store.isEditModuleOpen = isOpen;
    },
    isShareModuleOpen: false,
    setShareModuleOpen(isOpen) {
      store.isShareModuleOpen = isOpen;
    },
  }));

  const module = topicsStore.getModuleById(moduleId);
  const isLoadingTimer = useLoadingTimer();

  if (!module && isLoadingTimer)
    return (
      <CSS.StaffLoadingContainer>
        <Loading />
      </CSS.StaffLoadingContainer>
    );
  if (!module) return <Error msg="Module not found" />;

  const handleLock = async (e) => {
    await db
      .collection('topics')
      .doc(topicId)
      .collection('modules')
      .doc(moduleId)
      .update({ locked: e.target.checked });
  };

  const handleDeleteModule = () => {
    const chatsWithModule = chatsStore.getChatsWithModule(moduleId);

    if (chatsWithModule.length > 0) {
      const chatList = chatsWithModule
        .map((chat) => `- ${chat.chatName}`)
        .join('\n');
      alertStore.setText({
        heading: 'Delete module',
        text: `Cannot delete modules that are currently being used in classrooms. \n\n Please remove this module from each of the following classrooms first: \n\n ${chatList}`,
        isWarning: true,
      });
      alertStore.open();
    } else {
      saveChangesStore.setText({
        heading: 'Delete module',
        message: 'Are you sure you want to delete this module?',
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(async () => {
        const learningResourcesDeletions = module.learningResources.map(
          (learningResource) =>
            db
              .collection('topics')
              .doc(topicId)
              .collection('modules')
              .doc(moduleId)
              .collection('learningResources')
              .doc(learningResource.id)
              .delete()
        );
        const taskDeletions = module.tasks.map((task) =>
          db
            .collection('topics')
            .doc(topicId)
            .collection('modules')
            .doc(moduleId)
            .collection('tasks')
            .doc(task.id)
            .delete()
        );
        await Promise.all([
          db
            .collection('topics')
            .doc(topicId)
            .collection('modules')
            .doc(moduleId)
            .delete(),
          ...learningResourcesDeletions,
          ...taskDeletions,
        ]);
        navigate('/staff/topics');
      });
    }
  };

  const loggedInUser = usersStore.getLoggedInUser();
  const createdByMatch = module.createdBy === loggedInUser.id;
  const isAdmin = loggedInUser.role === 'admin';
  const canLockModule = createdByMatch || isAdmin;

  return (
    <CSS.StaffContainerSpaceBetween data-testid="StaffModule">
      <CSS.ModuleInfoContainer>
        <CSS.StaffRow>
          <CSS.ModuleInfo>
            <CSS.ModuleImage src={module.imageURL} alt={module.moduleName} />
            <div>
              <CSS.ModuleHeader characterLength={module.moduleName.length}>
                {module.moduleName}
              </CSS.ModuleHeader>
              <CSS.ModuleCreatedBy>
                {module.createdBy &&
                  `Created by: ${
                    usersStore.getUserById(module.createdBy).displayName
                  }`}
              </CSS.ModuleCreatedBy>
            </div>
          </CSS.ModuleInfo>
          <CSS.AlignModuleButtons>
            {module.locked ? <CSS.LockIcon /> : <CSS.UnlockIcon />}
            <CSS.LockSwitch
              disabled={!canLockModule}
              checked={module.locked}
              onChange={handleLock}
              inputProps={{ 'aria-label': 'locked module' }}
            />
            {module?.categories.size > 0 && (
              <CSS.ShareIcon data-testid="Shared-Module" />
            )}
            {usersStore.getLoggedInUser().categories.size > 1 && (
              <CSS.EditModuleButton
                onClick={() => store.setShareModuleOpen(true)}
              >
                Share
              </CSS.EditModuleButton>
            )}
            <CSS.EditModuleButton
              disabled={module.locked}
              onClick={() => store.setEditModuleOpen(true)}
            >
              Edit
            </CSS.EditModuleButton>
            <CSS.DeleteIconButton
              onClick={handleDeleteModule}
              aria-label="Delete module"
            >
              <CSS.Bin style={{ fontSize: '2.5em' }}></CSS.Bin>
            </CSS.DeleteIconButton>
          </CSS.AlignModuleButtons>
        </CSS.StaffRow>
        <CSS.StaffRow>
          <div>
            <CSS.StaffFilterLink
              to={`/staff/topics/${topicId}/modules/${moduleId}/learningResources`}
              isfirst
            >
              Learning
            </CSS.StaffFilterLink>
            <CSS.StaffFilterLink
              to={`/staff/topics/${topicId}/modules/${moduleId}/tasks`}
            >
              Tasks
            </CSS.StaffFilterLink>
          </div>
        </CSS.StaffRow>
      </CSS.ModuleInfoContainer>
      <CSS.StaffRouter primary={false}>
        <Redirect
          from="/"
          to="learningResources"
          moduleId={moduleId}
          topicId={topicId}
          noThrow
        />
        <StaffModuleItemsForm
          path="/:collection"
          moduleId={moduleId}
          topicId={topicId}
        />
      </CSS.StaffRouter>
      <Modal
        data-testid="edit-module-modal"
        open={store.isEditModuleOpen}
        onClose={() => store.setEditModuleOpen(false)}
        aria-labelledby="edit-module-modal"
        aria-describedby="edit-module-modal"
      >
        <SetModuleForm
          topicId={topicId}
          moduleId={moduleId}
          closeModal={() => store.setEditModuleOpen(false)}
        />
      </Modal>
      <Modal
        data-testid="share-module-modal"
        open={store.isShareModuleOpen}
        onClose={() => store.setShareModuleOpen(false)}
        aria-labelledby="share-module-modal"
        aria-describedby="share-module-modal"
      >
        <ShareModuleForm
          topicId={topicId}
          moduleId={moduleId}
          closeModal={() => store.setShareModuleOpen(false)}
        />
      </Modal>
    </CSS.StaffContainerSpaceBetween>
  );
});

export default StaffModule;
