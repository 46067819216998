import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/Theme';

const UploadPendingBiggerSVG = observer(({ taskId }) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      data-testid="bigger-pending"
      width="118"
      height="119"
      viewBox="0 0 118 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="59" cy="59.5137" r="59" fill="url(#upload-pending-linear)" />
      <g clipPath={`url(#upload-pending-clip0-${taskId})`}>
        <path
          d="M60.0619 23.9528C59.4762 23.3673 58.5267 23.3673 57.9408 23.9528L29.4414 52.4524C26.3705 55.5234 26.3705 60.5024 29.4414 63.5733C32.5123 66.6442 37.4913 66.6442 40.5622 63.5733L51.5 52.6355V88.014C51.5 92.156 54.8578 95.5138 58.9999 95.5138C63.1419 95.5138 66.4997 92.156 66.4997 88.014V52.6354L77.4375 63.5732C80.5084 66.6441 85.4874 66.6441 88.5584 63.5732C91.6293 60.5023 91.6293 55.5232 88.5584 52.4523L60.0619 23.9528Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="upload-pending-linear"
          x1="95.2621"
          y1="107.633"
          x2="21.9778"
          y2="-6.01965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.pendingDark} stopOpacity="0.47" />
          <stop offset="1" stopColor={theme.pendingFill} stopOpacity="0.51" />
        </linearGradient>
        <clipPath id={`upload-pending-clip0-${taskId}`}>
          <rect
            width="72"
            height="72"
            fill="white"
            transform="translate(23 23.5137)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default UploadPendingBiggerSVG;
