import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import { StaffSubHeader } from '../../common/elements/Staff';

export const PolicyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const PolicyInfo = styled(StaffSubHeader)`
  padding: 0;
`;

export const PolicyText = styled.p`
  font-size: 1.6rem;
`;

export const PolicyCard = styled.li`
  display: flex;
  flex-direction: column;
  margin: 1em 1em 2em 1em;
  padding: 0.9em;
  border-radius: 11px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.chatBackground};
  width: 100%;
`;

export const PolicyTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
