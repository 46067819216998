import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { CategoriesContext } from '../../contexts/Categories';
import { db } from '../../firebase';
import * as CSS from '../staff/elements/SetTopicForm';

const setCategorySchema = yup.object().shape({
  categoryName: yup.string().required('Please enter a category name'),
});

const SetCategoryForm = React.forwardRef(({ closeModal, categoryId }, ref) => {
  const categories = useContext(CategoriesContext);
  const category = categories.getCategoryById(categoryId);

  const handleSubmit = async ({ categoryName }) => {
    if (!categoryId) {
      await db.collection('categories').add({ categoryName });
    } else {
      await db
        .collection('categories')
        .doc(categoryId)
        .update({ categoryName });
    }
    closeModal();
  };

  return useObserver(() => (
    <Formik
      initialValues={{
        categoryName: category?.categoryName || '',
      }}
      validationSchema={setCategorySchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="set-category-form">
              <CSS.StaffFormHeaderRow>
                <CSS.StaffFormHeader>
                  {categoryId ? 'Edit category' : 'Create a Category'}
                </CSS.StaffFormHeader>
                <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                  <CSS.StaffCloseIcon />
                </CSS.StaffFormCloseButton>
              </CSS.StaffFormHeaderRow>
              <CSS.FieldGroup>
                <Field
                  type="text"
                  name="categoryName"
                  id="categoryName"
                  label="Enter a category name"
                  component={CSS.StaffTextFieldInput}
                />
                <ErrorMessage name="categoryName" component="p" />
              </CSS.FieldGroup>
              <CSS.StaffFooterRow>
                <CSS.StaffNavButton type="submit">Finish</CSS.StaffNavButton>
              </CSS.StaffFooterRow>
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  ));
});

export default SetCategoryForm;
