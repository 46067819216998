import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const SpeechBubblesSVG = observer(() => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ height: '5rem', margin: '0 1em 0 0' }}
    >
      <g>
        <g>
          <g>
            <path
              fill={theme.classroomHighlight}
              d="M266.667,320c52.928,0,96-43.072,96-96v-64c0-52.928-43.072-96-96-96H96c-52.928,0-96,43.072-96,96v64
				c0,52.928,43.072,96,96,96h10.667v53.333c0,4.309,2.603,8.213,6.592,9.856c1.301,0.555,2.709,0.811,4.075,0.811
				c2.773,0,5.504-1.088,7.531-3.115L185.749,320H266.667z M173.781,301.803L128,347.584v-38.251
				c0-5.888-4.779-10.667-10.667-10.667H96c-41.173,0-74.667-33.493-74.667-74.667v-64c0-41.173,33.493-74.667,74.667-74.667
				h170.667c41.173,0,74.667,33.493,74.667,74.667v64c0,41.173-33.493,74.667-74.667,74.667h-85.333
				C178.496,298.667,175.787,299.797,173.781,301.803z"
            />
            <path
              fill={theme.classroomHighlight}
              d="M416,128h-20.288c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667H416
				c41.173,0,74.667,33.493,74.667,74.667v64c0,41.173-33.493,74.667-74.667,74.667h-21.333c-5.888,0-10.667,4.779-10.667,10.667
				v38.251l-45.781-45.803c-2.005-1.984-4.715-3.115-7.552-3.115h-85.333c-15.317,0-30.101-4.757-42.795-13.76
				c-4.757-3.392-11.435-2.283-14.869,2.517c-3.392,4.8-2.283,11.456,2.539,14.869C206.528,377.877,225.579,384,245.333,384h80.917
				l60.864,60.885c2.048,2.027,4.779,3.115,7.552,3.115c1.365,0,2.773-0.256,4.075-0.811c3.989-1.643,6.592-5.547,6.592-9.856V384
				H416c52.928,0,96-43.072,96-96v-64C512,171.072,468.928,128,416,128z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});

export default SpeechBubblesSVG;
