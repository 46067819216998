import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components/macro';
import * as StaffCommon from '../../common/elements/Staff';
import { EmbedPreview } from './StaffModule';

export const ItemListContainer = styled(StaffCommon.StaffRow)`
  flex-grow: 0.9;
`;

export const ItemList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 1em;
  width: 100%;
`;

export const ItemContainer = styled.li`
  ${({ theme, isTop }) =>
    !isTop &&
    css`
      border-top: 1px solid ${theme.buttonPrimary};
    `}
`;

export const ItemAccordion = withStyles({
  root: {
    width: '100%',
  },
})(Accordion);

export const ItemDetails = withStyles({
  root: {},
})(AccordionDetails);

export const ItemSummary = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr 4rem;
  grid-template-areas: 'index instructions check-svg';
  width: 100%;
`;

export const Circle = styled.div`
  grid-area: index;
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  margin: 0;
  background: ${({ theme }) => theme.buttonUpdate};
  color: ${({ theme }) => theme.textLight};
  align-self: center;
  justify-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemIndex = styled.p`
  margin: 0;
`;

export const ModuleItemInstructions = styled.p`
  grid-area: instructions;
  margin: 1em;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.textDark};
`;
export const CheckSVGContainer = styled.div`
  grid-area: check-svg;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProgressEmbedPreview = styled(EmbedPreview)`
  height: 40em;
`;

export * from './StaffStudentModules';
