import React, { createContext } from 'react';
import LearningAssessmentsModel from '../models/LearningAssessments.js';

const LearningAssessmentsStore = LearningAssessmentsModel.create({
  learningAssessments: {},
});

export const LearningAssessmentsContext = createContext(
  LearningAssessmentsStore
);

export const LearningAssessmentsProvider = ({ children }) => {
  return (
    <LearningAssessmentsContext.Provider value={LearningAssessmentsStore}>
      {children}
    </LearningAssessmentsContext.Provider>
  );
};
