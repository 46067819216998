import uniqueBy from 'lodash.uniqby';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import Error from '../common/Error';
import * as CSS from './elements/StaffStudentModules';
import ModuleCard from './ModuleCard';

const StaffStudentModules = observer(({ studentId }) => {
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);
  const topicsStore = useContext(TopicsContext);

  const store = useLocalStore(() => ({
    filterTopicName: '',
  }));

  const student = usersStore.getUserById(studentId);
  if (!student) return <Error msg="Student not found" />;

  const handleTopicChange = (e) => {
    const { value } = e.target;
    store.filterTopicName = value;
  };

  const usersGroupChats = student.getUsersGroupChats(
    chatsStore,
    usersStore.getLoggedInUser().selectedCategory
  );
  const chatModules = usersGroupChats.reduce((chatModules, groupChat) => {
    const topics = topicsStore.getChatModules(
      groupChat.modules,
      groupChat.topics
    );
    topics.forEach(([{ topicName }, topicModules]) => {
      const existingTopic = chatModules.find(
        (chatModule) => chatModule[0] === topicName
      );
      if (existingTopic) {
        existingTopic[1].push(...topicModules);
        existingTopic[1] = uniqueBy(existingTopic[1], 'id');
      } else {
        chatModules.push([topicName, topicModules]);
      }
    });
    return chatModules;
  }, []);

  const filteredModules = chatModules.filter(
    ([topicName]) =>
      store.filterTopicName === '' || topicName === store.filterTopicName
  );

  return (
    <div>
      <CSS.StaffRow>
        <CSS.StaffRowFill>
          <CSS.FilterTopicsForm>
            <CSS.StaffSelect
              select
              id="Choose a topic"
              label="Choose a topic"
              onChange={handleTopicChange}
              value={store.filterTopicName}
            >
              <CSS.StaffMenuItem value="">All Topics</CSS.StaffMenuItem>
              {chatModules.map(([topicName]) => (
                <CSS.StaffMenuItem key={topicName} value={topicName}>
                  {topicName}
                </CSS.StaffMenuItem>
              ))}
            </CSS.StaffSelect>
          </CSS.FilterTopicsForm>
        </CSS.StaffRowFill>
      </CSS.StaffRow>
      <CSS.StaffRow>
        {filteredModules.map(([topicName, modules]) => (
          <CSS.Module key={topicName}>
            <CSS.StaffRow>
              <CSS.StaffHeaderGroup>
                <CSS.StaffSubHeader data-testid="TopicHeader">
                  {topicName}
                </CSS.StaffSubHeader>
              </CSS.StaffHeaderGroup>
            </CSS.StaffRow>
            <CSS.ModuleList>
              {modules.map((module) => {
                const completionPercent = student.private.getModuleAverageCompletionPercent(
                  module.learningResources,
                  module.tasks
                );

                const uncommentedCount = module.getUncommentedEvidenceCountByStudent(
                  student.private
                );

                return (
                  <CSS.StaffCardNotification
                    key={module.id}
                    badgeContent={uncommentedCount}
                    data-testid={`uncommented-count-${module.id}`}
                  >
                    <ModuleCard
                      completionPercent={completionPercent}
                      studentId={studentId}
                      module={module}
                      topicName={topicName}
                      topicId={module.topic}
                      hasNotifications={true}
                    />
                  </CSS.StaffCardNotification>
                );
              })}
            </CSS.ModuleList>
          </CSS.Module>
        ))}
      </CSS.StaffRow>
    </div>
  );
});

export default StaffStudentModules;
