import { getParentOfType, types } from 'mobx-state-tree';
import { db } from '../firebase';
import Chats from './Chats';
import Message from './Message';

const Chat = types
  .model('Chat', {
    id: types.string,
    messages: types.optional(types.array(Message), []),
    chatName: types.maybeNull(types.string),
    active: types.optional(types.boolean, true),
    currentModule: types.maybeNull(types.string),
    whiteboard: types.optional(types.string, ''),
    currentTopic: types.maybeNull(types.string),
    teacherId: types.maybeNull(types.string),
    participants: types.optional(types.array(types.string), []),
    locked: types.optional(types.boolean, false),
    modules: types.optional(
      types.map(
        types.model({
          module: types.string,
          topic: types.string,
          index: types.maybeNull(types.number),
          active: types.optional(types.boolean, true),
          current: types.optional(types.boolean, false),
        })
      ),
      {}
    ),
    topics: types.optional(
      types.map(
        types.model({
          topic: types.string,
          index: types.maybeNull(types.number),
        })
      ),
      {}
    ),
    category: types.maybeNull(types.string),
    lastChecked: types.optional(types.map(types.Date), {}),
    currentIlpId: types.optional(types.string, ''),
  })
  .volatile(() => ({
    listeners: [],
  }))
  .actions((self) => {
    return {
      setChatInfo(newChat) {
        if (!newChat) return;
        self.chatName = newChat.chatName;
        self.active = newChat.active;
        self.currentModule = newChat.currentModule;
        self.whiteboard = newChat.whiteboard;
        self.currentTopic = newChat.currentTopic;
        self.teacherId = newChat.teacher;
        self.locked = newChat.locked;
        self.category = newChat.category;
        self.modules.forEach((module, id) => {
          if (!newChat.modules[id]) self.modules.delete(id);
        });
        for (let moduleId in newChat.modules) {
          const { module, topic, ...restOfModule } = newChat.modules[moduleId];
          self.modules.set(module.id, {
            module: module.id,
            topic: topic.id,
            ...restOfModule,
          });
        }
        self.topics.forEach((topic, id) => {
          if (newChat.topics && !newChat.topics[id]) self.topics.delete(id);
        });
        for (let topicId in newChat.topics) {
          const { topic, ...restOfTopic } = newChat.topics[topicId];
          self.topics.set(topic.id, {
            topic: topic.id,
            ...restOfTopic,
          });
        }
        self.lastChecked.forEach((lastChecked, id) => {
          if (
            !newChat.lastChecked ||
            (newChat.lastChecked && !newChat.lastChecked[id])
          )
            self.lastChecked.delete(id);
        });
        for (let userId in newChat.lastChecked) {
          self.lastChecked.set(userId, newChat.lastChecked[userId].toDate());
        }
        self.currentIlpId = newChat.currentIlpId;
      },
      addMessage(newMessage) {
        self.messages.push(newMessage);
      },
      removeMessage(messageId) {
        const messageToRemove = self.messages.find(
          (message) => message.id === messageId
        );
        if (messageToRemove) self.messages.remove(messageToRemove);
      },
      clearMessages() {
        self.messages = [];
      },
      listenToUpdates(collection) {
        const chatUnSub = db
          .collection(collection)
          .doc(self.id)
          .onSnapshot((docSnap) => {
            const chatData = docSnap.data();
            if (chatData) {
              self.setChatInfo(chatData);
            } else {
              self.unsubscribeFromAll();
              const chatsStore = getParentOfType(self, Chats);
              chatsStore.removeChat(self, collection);
            }
          });

        const chatMsgsUnSub = db
          .collection(collection)
          .doc(self.id)
          .collection('messages')
          .orderBy('createdAt', 'asc')
          .onSnapshot(self.handleMessagesChange);

        self.addUnSubs([chatUnSub, chatMsgsUnSub]);
        return [chatUnSub, chatMsgsUnSub];
      },
      addUnSubs(unsubscribes) {
        unsubscribes.forEach((unsubscribe) => {
          self.listeners.push(unsubscribe);
        });
      },
      unsubscribeFromAll() {
        self.listeners.forEach((unsubscribe) => {
          if (typeof unsubscribe === 'function') unsubscribe();
        });
      },
      storeParticipant(id) {
        if (!self.participants.includes(id)) {
          self.participants.unshift(id);
        }
      },
      removeParticipant(id) {
        self.participants.remove(id);
      },
      storeLoggedInUser(id) {
        if (!self.participants.includes(id)) {
          self.participants.push(id);
        }
      },
      handleMessagesChange(querySnapshot) {
        querySnapshot.docChanges().forEach(({ doc, type }) => {
          if (type === 'added') {
            const message = doc.data();
            self.addMessage({
              ...message,
              createdBy: message.createdBy.id,
              createdAt: message.createdAt.toDate(),
              id: doc.id,
            });
          }
          if (type === 'removed') {
            self.removeMessage(doc.id);
          }
        });
      },
      moveModule(moduleToMove, targetModule) {
        const currentTopic = moduleToMove.topic;
        const startIndex = moduleToMove.index;
        const endIndex = targetModule.index;
        self.modules.forEach((module) => {
          if (module.topic === currentTopic) {
            if (endIndex > startIndex) {
              if (module.index === startIndex) {
                module.index = endIndex;
              } else if (
                module.index > startIndex &&
                module.index <= endIndex
              ) {
                module.index--;
              }
            } else {
              if (module.index === startIndex) {
                module.index = endIndex;
              } else if (
                module.index < startIndex &&
                module.index >= endIndex
              ) {
                module.index++;
              }
            }
          }
        });
        const updatedModules = {};
        self.modules.forEach(({ module, topic, ...restOfModule }) => {
          updatedModules[module] = {
            topic: db.collection('topics').doc(topic),
            module: db
              .collection('topics')
              .doc(topic)
              .collection('modules')
              .doc(module),
            ...restOfModule,
          };
        });
        return db.collection('groupChats').doc(self.id).update({
          modules: updatedModules,
        });
      },
    };
  })
  .views((self) => {
    return {
      getMaxModuleIndex(topicId) {
        const indexes = [...self.modules.values()]
          .filter((module) => module.topic === topicId)
          .map((module) => module.index);
        return Math.max(-1, ...indexes);
      },
      getCurrentModule(topicsStore) {
        return topicsStore.getModuleById(self.currentModule);
      },
      getCurrentTopic(topicsStore) {
        return topicsStore.getTopicById(self.currentTopic);
      },
      getTeacher(usersStore) {
        return usersStore.getUserById(self.teacherId);
      },
      hasStudents(usersStore) {
        return (
          self.participants.filter((id) => {
            const user = usersStore.getUserById(id);
            return user.role === 'student';
          }).length > 0
        );
      },
      getUnreadCount(loggedInUserId) {
        return self.messages.reduce((total, message) => {
          const lastRead = self.lastChecked.get(loggedInUserId);
          const messageSinceLastRead = message.createdAt > lastRead;
          if (messageSinceLastRead || !lastRead) total++;
          return total;
        }, 0);
      },
    };
  });

export default Chat;
