import { getParentOfType, types } from 'mobx-state-tree';
import Module from './Module';
import Topic from './Topic';

const Task = types
  .model('Task', {
    id: types.maybe(types.string),
    instructions: types.string,
    index: types.number,
  })
  .actions((self) => ({
    setInfo(task) {
      for (let field in task) {
        self[field] = task[field];
      }
    },
  }))
  .views((self) => ({
    getParentModule() {
      return getParentOfType(self, Module);
    },
    getParentTopic() {
      return getParentOfType(self, Topic);
    },
  }));

export default Task;
