import { types } from 'mobx-state-tree';

const Message = types.model('Message', {
  id: types.maybeNull(types.string),
  index: types.maybeNull(types.number),
  text: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  fileType: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  createdAt: types.Date,
  createdBy: types.maybeNull(types.string),
  comment: types.maybeNull(
    types.model('Comment', {
      id: types.maybeNull(types.string),
      text: types.maybeNull(types.string),
      createdAt: types.Date,
      createdBy: types.maybeNull(types.string),
      file: types.maybeNull(types.string),
      fileType: types.maybeNull(types.string),
      filePath: types.maybeNull(types.string),
    })
  ),
  link: types.maybeNull(types.string),
});

export default Message;
