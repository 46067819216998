import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React from 'react';
import styled from 'styled-components/macro';
import { DeleteButton } from '../../auth/elements/Auth.js';
import { StaffHeaderGroup } from '../../common/elements/Staff.js';

export const UserPersonalContainer = styled.div``;

export const StaffPersonalHeaderGroup = styled(StaffHeaderGroup)`
  margin-top: 3em;
`;

export const EditButton = styled.button`
  background: none;
  border: none;
`;

export const EditIcon = (props) => {
  return <EditOutlinedIcon {...props} style={{ fontSize: '2rem' }} />;
};

export const DetailsRow = styled.div`
  margin-left: 1em;
`;

export const StudentDetail = styled.p`
  font-style: normal;
  color: ${({ theme }) => theme.headerColour};
  margin: 0.5em 0 0.5em 0;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const TargetList = styled.ol`
  padding: 0;
  margin: 0.5em 0 0.5em 1.5em;
  color: ${({ theme }) => theme.headerColour};
  font-size: 1.5rem;
  font-weight: 600;
`;

export const TargetLi = styled.li`
  margin-bottom: 0.5em;
`;

export const UserManagementControls = styled.div`
  display: flex;
  margin: 2em 0;
`;

export const UserManagementButton = styled(DeleteButton)`
  margin-right: 0.5em;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
