import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { AlertProvider } from './contexts/Alert';
import { CategoriesProvider } from './contexts/Categories.js';
import { ChatsProvider } from './contexts/Chats.js';
import { DragAndDropProvider } from './contexts/DragAndDrop';
import { LearningAssessmentsProvider } from './contexts/LearningAssessments.js';
import { ListenersProvider } from './contexts/Listeners';
import { PrivacyPoliciesProvider } from './contexts/PrivacyPolicies.js';
import { SaveChangesProvider } from './contexts/SaveChanges.js';
import { ThemeProvider } from './contexts/Theme.js';
import { TopicsProvider } from './contexts/Topics.js';
import { UsersProvider } from './contexts/Users.js';
import './index.css';

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';

window.MediaRecorder = AudioRecorder;

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // % of requests to sample for trace profiling
    tracesSampleRate: 0.01,
  });
}

ReactDOM.render(
  <ThemeProvider>
    <PrivacyPoliciesProvider>
      <UsersProvider>
        <CategoriesProvider>
          <ChatsProvider>
            <TopicsProvider>
              <AlertProvider>
                <SaveChangesProvider>
                  <ListenersProvider>
                    <DragAndDropProvider>
                      <LearningAssessmentsProvider>
                        <App />
                      </LearningAssessmentsProvider>
                    </DragAndDropProvider>
                  </ListenersProvider>
                </SaveChangesProvider>
              </AlertProvider>
            </TopicsProvider>
          </ChatsProvider>
        </CategoriesProvider>
      </UsersProvider>
    </PrivacyPoliciesProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
