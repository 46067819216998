import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SpeakerNotesOffRoundedIcon from '@material-ui/icons/SpeakerNotesOffRounded';
import { Link } from '@reach/router';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { ChatsContext } from '../../../contexts/Chats';
import { SaveChangesContext } from '../../../contexts/SaveChanges';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ProfileImage from '../ProfileImage';

export const NavContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1.5em 0 1.5em;

  overflow: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media only screen and (min-width: 700px) {
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 0;
  }
`;

export const NavBar = styled.nav`
  height: 8.5vh;
  min-height: 67px;
  width: 100%;
  position: fixed;
  top: 0;
  background: ${({ theme }) => theme.appBackground};
  z-index: 10;

  @media only screen and (min-width: 700px) {
    height: 100%;
    width: 10vw;
    min-width: 120px;
    left: 0;
  }
`;

export const NavDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 2em;

  overflow: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const UserAvatarImage = styled(ProfileImage)`
  width: 3.4em;
  height: 3.4em;
`;

export const TeacherAvatarImage = styled(ProfileImage)`
  width: 4em;
  margin: 0;
`;

export const ProfileLink = styled(Link)``;

export const NavLink = styled(Link)`
  color: ${({ theme }) => theme.headerColour};
`;

export const ActiveLink = styled(Link)`
  color: black;
  margin: 0;
  font-size: 1.4rem;
  text-decoration: none;
  padding: 0.2em 0.7em;
  &[data-ispartiallycurrent='true'] {
    text-decoration: underline;
  }
`;

export const TeacherNavLink = ({ afterClick, ...props }) => {
  const saveChangesStore = useContext(SaveChangesContext);
  const windowStore = useWindowSize();
  const chatsStore = useContext(ChatsContext);
  const getProps = ({ isPartiallyCurrent, isCurrent }) => {
    return (isPartiallyCurrent && props.to !== '/') || isCurrent
      ? { 'data-ispartiallycurrent': true }
      : {};
  };
  return (
    <ActiveLink
      onClick={(e) => {
        saveChangesStore.handleNavigate(e, windowStore.isMobile, chatsStore);
        if (afterClick) afterClick();
      }}
      getProps={getProps}
      {...props}
    />
  );
};

export const BigMailOutlineIcon = () => {
  const theme = useContext(ThemeContext);
  return <MailOutlineIcon style={{ fontSize: 26, color: theme.textDark }} />;
};

export const BigHomeIcon = () => {
  const theme = useContext(ThemeContext);
  return <HomeRoundedIcon style={{ fontSize: 26, color: theme.textDark }} />;
};

export const BigMenuIcon = () => {
  const theme = useContext(ThemeContext);
  return <MenuRoundedIcon style={{ fontSize: 26, color: theme.textDark }} />;
};

export const OpenChatIcon = () => {
  const theme = useContext(ThemeContext);
  return <ChatRoundedIcon style={{ fontSize: 26, color: theme.textDark }} />;
};

export const CloseChatIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <SpeakerNotesOffRoundedIcon
      style={{ fontSize: 26, color: theme.textDark }}
    />
  );
};

export * from './Form';
export * from './Notifications';
