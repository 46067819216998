import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { CategoriesContext } from '../../contexts/Categories';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/EditUserPersonalInfoForm';

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Please enter a first name'),
  surname: yup.string().required('Please enter a surname'),
  phoneNumber: yup.string().required('Please enter a phone number'),
  categories: yup
    .array()
    .of(yup.object())
    .required('Please select at least one category for the user'),
});

const EditUserPersonalInfoForm = React.forwardRef(
  ({ closeModal, userId, isAdmin }, ref) => {
    const usersStore = useContext(UsersContext);
    const categoriesStore = useContext(CategoriesContext);
    const user = usersStore.getUserById(userId);

    const initialValues = {
      firstName: user?.private.firstName || '',
      surname: user?.private.surname || '',
      phoneNumber: user?.private.phoneNumber || '',
      role: user?.role || '',
      categories: categoriesStore.getUsersCategories(user.categories),
    };

    const handleSubmit = async ({
      firstName,
      surname,
      phoneNumber,
      role,
      categories,
    }) => {
      const updatedCategories = categories.reduce((acc, cat) => {
        acc[cat.id] = db.collection('categories').doc(cat.id);
        return acc;
      }, {});

      const userUpdate = {
        displayName: `${firstName} ${surname}`,
        role,
        categories: updatedCategories,
      };

      if (!updatedCategories.hasOwnProperty(user.selectedCategory)) {
        userUpdate.selectedCategory = Object.keys(updatedCategories)[0];
      }

      const infoPromises = [
        db
          .collection('usersPrivate')
          .doc(userId)
          .update({ firstName, surname, phoneNumber }),
        db.collection('users').doc(userId).update(userUpdate),
      ];

      await Promise.all(infoPromises);
      closeModal();
    };

    return useObserver(() => (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, setFieldValue, values }) => (
          <Observer>
            {() => (
              <CSS.Form data-testid="EditUserPersonalInfoForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>
                    Edit User Information
                  </CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <CSS.FieldGroup>
                  <Field
                    type="text"
                    name="firstName"
                    id="firstName"
                    label="First Name"
                    component={CSS.StaffTextFieldInput}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    data-testid="firstName-error"
                  />
                </CSS.FieldGroup>
                <CSS.FieldGroup>
                  <Field
                    type="text"
                    name="surname"
                    id="surname"
                    label="Surname"
                    component={CSS.StaffTextFieldInput}
                  />
                  <ErrorMessage
                    name="surname"
                    component="p"
                    data-testid="surname-error"
                  />
                </CSS.FieldGroup>
                <CSS.FieldGroup>
                  <Field
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone Number"
                    component={CSS.StaffTextFieldInput}
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="p"
                    data-testid="phoneNumber-error"
                  />
                </CSS.FieldGroup>
                {isAdmin && (
                  <CSS.FieldGroup>
                    <FieldArray name="categories">
                      {({ form }) => (
                        <CSS.StaffAutocomplete
                          multiple
                          fullWidth
                          disableClearable
                          value={values.categories}
                          onChange={(event, categories, reason) => {
                            form.setFieldValue('categories', categories);
                          }}
                          options={categoriesStore.getInAlphabeticalOrder()}
                          getOptionLabel={({ categoryName }) => categoryName}
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <CSS.StaffAutocompleteInput
                              {...params}
                              label="Type a category name"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </FieldArray>
                    <ErrorMessage name="categories" component="p" />
                  </CSS.FieldGroup>
                )}
                {isAdmin && (
                  <CSS.FieldGroup>
                    <Field
                      select
                      name="role"
                      id="role"
                      label="Role"
                      component={CSS.StaffTextFieldInput}
                    >
                      {['Student', 'Teacher', 'Admin'].map((role) => {
                        return (
                          <CSS.StaffMenuItem
                            key={role}
                            value={role.toLowerCase()}
                          >
                            {role}
                          </CSS.StaffMenuItem>
                        );
                      })}
                    </Field>
                    <ErrorMessage name="role" component="p" />
                  </CSS.FieldGroup>
                )}
                <CSS.StaffFooterRow>
                  <CSS.SubmitChangesButton
                    data-testid="EditUserPersonalInfoForm-submit"
                    type="submit"
                    disabled={!dirty}
                  >
                    Save changes
                  </CSS.SubmitChangesButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default EditUserPersonalInfoForm;
