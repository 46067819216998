import styled from 'styled-components/macro';

export const ChatContainer = styled.section`
  min-height: 100%;
  width: 100%;
`;

export const ChatMessagesList = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.chatBackground};
  list-style: none;
  padding: 0.5em;
  padding-bottom: 8rem;
  padding-top: ${({ whiteboardHeight }) => whiteboardHeight}px;
  margin: 0;
  overflow-y: scroll;
  /* fullscreen - MessageForm - WhiteBoard - Nav */
  min-height: calc(
    100vh - 8rem - ${({ whiteboardHeight }) => whiteboardHeight}px - 8.5vh
  );
  @media only screen and (min-width: 700px) {
    /* fullscreen - MessageForm - WhiteBoard */
    min-height: calc(
      100vh - 8rem - ${({ whiteboardHeight }) => whiteboardHeight}px
    );
  }
`;
