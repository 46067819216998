import { navigate } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React from 'react';
import * as CSS from './elements/Error';

const Error = observer(({ msg, fullscreen = true }) => {
  return (
    <CSS.ErrorContainer data-testid="Error" fullscreen={fullscreen}>
      <CSS.ErrorMessage>{msg ? msg : 'Sorry, page not found'}</CSS.ErrorMessage>
      {!msg && (
        <CSS.BackHomeButton onClick={() => navigate('/')}>
          <CSS.BigHomeIcon />
          Home
        </CSS.BackHomeButton>
      )}
    </CSS.ErrorContainer>
  );
});
export default Error;
