import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

export const ErrorContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - max(8.5vh, 67px));
  width: 100%;
  background: ${({ theme }) => theme.staffBackground};
  font-size: 3rem;
  min-height: 100%;
  ${({ fullscreen }) =>
    fullscreen &&
    css`
      min-height: 100vh;
    `};
`;

export const ErrorMessage = styled.h2`
  color: ${({ theme }) => theme.headerColour};
`;

export const BackHomeButton = styled.button`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  color: ${({ theme }) => theme.textDark};
  &:hover {
    cursor: pointer;
  }
`;

export const BigHomeIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <HomeRoundedIcon style={{ fontSize: '10rem', color: theme.textDark }} />
  );
};
