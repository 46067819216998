import React, { createContext } from 'react';
import TopicsModel from '../models/Topics.js';

const TopicsStore = TopicsModel.create({
  topics: [],
});

export const TopicsContext = createContext(TopicsStore);

export const TopicsProvider = ({ children }) => {
  return (
    <TopicsContext.Provider value={TopicsStore}>
      {children}
    </TopicsContext.Provider>
  );
};
