import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { SaveChangesContext } from '../../contexts/SaveChanges.js';
import { UsersContext } from '../../contexts/Users.js';
import { db, storage } from '../../firebase';
import NoStarSVG from '../../svgs/NoStarSVG.js';
import StarSVG from '../../svgs/StarSVG.js';
import { formatDate } from '../../utils/format-date.js';
import { formatInternalLink } from '../../utils/formatInternalLink.js';
import * as CSS from './elements/Message.js';

const Message = React.forwardRef(
  (
    {
      message,
      onDelete = () => {},
      isPreview,
      isEvidencePreview,
      onLoad = () => {},
      onEvidence = () => {},
      chatCollection,
      chatId,
      as,
    },
    ref
  ) => {
    if (message === undefined) return null;
    const { createdAt, createdBy, text, file, fileType, link } = message;
    const usersStore = useContext(UsersContext);
    const saveChangesStore = useContext(SaveChangesContext);

    let internalLink;
    if (link) internalLink = formatInternalLink(link);

    const loggedInUser = usersStore.getLoggedInUser();
    const createdByUser = usersStore.getUserById(createdBy) || {};

    const formattedDate = formatDate(createdAt);
    const isOwn = loggedInUser.id === createdByUser.id;
    const createdByStudent = createdByUser.role === 'student';
    const isLoggedInAsStudent = loggedInUser.role === 'student';
    const isLoggedInAsTeacher = loggedInUser.role === 'teacher';
    const isLoggedInAsAdmin = loggedInUser.role === 'admin';

    const isEvidencable =
      !isPreview &&
      !isEvidencePreview &&
      ((isOwn && isLoggedInAsStudent) ||
        ((isLoggedInAsTeacher || isLoggedInAsAdmin) && createdByStudent));

    const isMessageDeletable =
      !isPreview &&
      !isEvidencePreview &&
      (isOwn || isLoggedInAsAdmin || (isLoggedInAsTeacher && createdByStudent));

    const handleDeleteMessage = async () => {
      saveChangesStore.setText({
        heading: 'Delete Message',
        message: 'Are you sure you want to delete this message?',
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(async () => {
        const dbPromises = [
          db
            .collection(chatCollection)
            .doc(chatId)
            .collection('messages')
            .doc(message.id)
            .delete(),
        ];
        const filePathsInUse = usersStore.getEvidenceFilePathsInUse();
        if (message.filePath && !filePathsInUse.has(message.filePath)) {
          const storageRef = storage.ref();
          const fileToRemoveRef = storageRef.child(message.filePath);
          dbPromises.push(fileToRemoveRef.delete());
        }
        await Promise.all(dbPromises);
      });
    };

    const handleExternalNavigate = () => {
      saveChangesStore.setText({
        heading: 'External Link',
        message: 'This link will open outside of the app. Are you sure?',
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(() => {
        window.open(link);
      });
    };

    return (
      <CSS.MessageItem
        data-testid="chat-message"
        isOwn={isOwn}
        isMessageDeletable={isMessageDeletable}
        isEvidencable={isEvidencable}
        isAudio={fileType === 'audio'}
        hasText={!!text}
        authorIsStaff={['teacher', 'admin'].includes(createdByUser.role)}
        isPreview={isPreview}
        isEvidencePreview={isEvidencePreview}
        ref={ref}
        as={as}
      >
        <CSS.MessageContent>
          {file && fileType === 'image' && (
            <CSS.MessageImage
              src={file}
              alt={`${isPreview ? 'preview' : 'user'} upload`}
              onLoad={onLoad}
            />
          )}
          {file && fileType === 'video' && (
            <CSS.MessageVideo
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onLoad={onLoad}
            >
              <source
                src={file}
                data-testid={`${isPreview ? 'preview' : 'user'}-video-source`}
              ></source>
            </CSS.MessageVideo>
          )}
          {file && fileType === 'audio' && (
            <CSS.MessageAudio
              src={file}
              data-testid={`${isPreview ? 'preview' : 'user'}-audio-source`}
              type="audio/mp3"
              showJumpControls={false}
              layout="horizontal-reverse"
              customProgressBarSection={[
                RHAP_UI.PROGRESS_BAR,
                RHAP_UI.CURRENT_LEFT_TIME,
              ]}
              customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
              onLoad={onLoad}
            />
          )}
          {text && (
            <CSS.MessageText fileType={fileType}>
              {internalLink ? (
                <CSS.InvisibleInternalLink to={internalLink}>
                  {text}
                </CSS.InvisibleInternalLink>
              ) : link ? (
                <CSS.InvisibleExternalLink onClick={handleExternalNavigate}>
                  {text}
                </CSS.InvisibleExternalLink>
              ) : (
                text
              )}
            </CSS.MessageText>
          )}
        </CSS.MessageContent>
        {isPreview && (
          <CSS.DeleteButton
            type="button"
            aria-label={isPreview ? 'delete preview' : 'delete message'}
            onClick={onDelete}
            isPreview={isPreview}
            userRole={loggedInUser.role}
          >
            <CSS.XIcon />
          </CSS.DeleteButton>
        )}
        {isEvidencable && (
          <CSS.EvidenceButton
            aria-label="Evidence"
            onClick={onEvidence}
            disabled={createdByUser.private.hasMessageBeenEvidenced(message.id)}
          >
            {createdByUser.private.hasMessageBeenEvidenced(message.id) ? (
              <StarSVG size={'2em'} targetId={message.id} />
            ) : (
              <NoStarSVG size={'2em'} targetId={message.id} />
            )}
          </CSS.EvidenceButton>
        )}
        <CSS.MessageCreatedBy>
          {isOwn ? 'Me' : createdByUser.displayName}
        </CSS.MessageCreatedBy>
        {!isOwn && !isEvidencePreview && !isPreview && (
          <CSS.MessageCreatedByImage
            src={createdByUser.imageURL}
            alt={`${createdByUser.displayName || 'Author'} avatar`}
          />
        )}
        {isMessageDeletable && (
          <CSS.DeleteMessageButton
            data-testid={`Message-delete-${message.id}`}
            aria-label="Delete button"
            onClick={handleDeleteMessage}
          >
            <CSS.XIcon style={{ fontSize: '1.8rem' }} />
          </CSS.DeleteMessageButton>
        )}
        <CSS.MessageCreatedAt>{formattedDate}</CSS.MessageCreatedAt>
      </CSS.MessageItem>
    );
  }
);

export default observer(Message);
