import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/Theme';

const CheckSVGStaff = observer(({ complete = false }) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      data-testid={`CheckSVGStaff-complete-${complete}`}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.1216"
        cy="15.0679"
        r="14.5337"
        fill={complete ? theme.lessonHighlight : theme.staffBackground}
      />
      <path
        d="M13.9697 21.3668C13.4317 21.3668 12.9127 21.1662 12.5204 20.8039L7.00066 15.7094C6.15514 14.929 6.11857 13.6281 6.91893 12.8037C7.71929 11.9793 9.05341 11.9435 9.89913 12.724L13.8482 16.3687L20.2266 9.45082C21.005 8.60657 22.3379 8.53759 23.2038 9.29631C24.0695 10.0552 24.1405 11.3548 23.3621 12.1991L15.5374 20.6855C15.1573 21.0976 14.6227 21.3427 14.0546 21.3651C14.0262 21.3663 13.9978 21.3668 13.9697 21.3668Z"
        fill="white"
      />
    </svg>
  );
});

export default CheckSVGStaff;
