import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { ChatsContext } from '../../contexts/Chats';
import { db } from '../../firebase';
import * as CSS from './elements/SetClassroomForm';

const setClassroomSchema = yup.object().shape({
  chatName: yup.string().required('Please enter a classroom name'),
});

const SetClassroomForm = React.forwardRef(
  ({ closeModal, groupChatId }, ref) => {
    const chatsStore = useContext(ChatsContext);
    const chat = chatsStore.getChatById(groupChatId, 'groupChats');

    const handleSubmit = async ({ chatName }) => {
      await db.collection('groupChats').doc(groupChatId).update({ chatName });
      closeModal();
    };

    return useObserver(() => (
      <Formik
        initialValues={{
          chatName: chat?.chatName || '',
        }}
        validationSchema={setClassroomSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Observer>
            {() => (
              <CSS.Form data-testid="SetClassroomForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>Edit classroom</CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <CSS.FieldGroup>
                  <Field
                    type="text"
                    name="chatName"
                    id="chatName"
                    label="Enter a classroom name"
                    component={CSS.StaffTextFieldInput}
                  />
                  <ErrorMessage name="chatName" component="p" />
                </CSS.FieldGroup>
                <CSS.StaffFooterRow>
                  <CSS.StaffNavButton type="submit">Finish</CSS.StaffNavButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default SetClassroomForm;
