import { Form as FormikForm } from 'formik';
import styled from 'styled-components/macro';
import { StaffSubHeader } from '../../staff/elements/User';

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 15px;
  padding: 2em;
  margin-top: 3em;
`;

export const FormHeader = styled(StaffSubHeader)`
  margin-bottom: 1em;
  padding: 0;
  font-size: 2.1rem;
`;

export const FormSpacer = styled.div`
  margin-bottom: 4em;
  width: 30%;
  display: flex;
  justify-content: center;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
