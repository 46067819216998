import { Form as FormikForm } from 'formik';
import styled from 'styled-components/macro';
import * as common from '../../common/elements/Form';

export const Form = styled(FormikForm)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 15px;
  ${common.ModalScreenWidthMQs}
`;

export const UploadButton = styled(common.Button).attrs(() => ({
  as: 'label',
}))``;

export const ImageInput = styled.input`
  display: none;
`;
export const ImagePreview = styled.img`
  margin: 1em;
  border-radius: 10px;
  height: 9em;
  width: 9em;
  object-fit: cover;
`;

export const ImportButton = styled.button`
  font-weight: 800;
  margin: 1em 0;
  font-size: 1.6rem;
  text-decoration: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.textDark};
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.buttonPrimary};
    cursor: pointer;
  }
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
