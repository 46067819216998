import Modal from '@material-ui/core/Modal';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { CategoriesContext } from '../../contexts/Categories';
import CategoryCard from './CategoryCard';
import * as CSS from './elements/Categories';
import SetCategoryForm from './SetCategoryForm';

const Categories = observer(() => {
  const categoriesStore = useContext(CategoriesContext);
  const store = useLocalStore(() => ({
    isCategoryModalOpen: false,
    setCategoryModal: (open, id) => {
      store.isCategoryModalOpen = open;
      if (id) store.selectedCategoryId = id;
      if (!open) store.selectedCategoryId = null;
    },
    selectedCategoryId: null,
  }));

  return (
    <div>
      <CSS.CategoryList>
        {categoriesStore.categories.map(({ id }) => (
          <CategoryCard
            key={id}
            categoryId={id}
            setCategoryModal={store.setCategoryModal}
          />
        ))}
        <CSS.StaffCard>
          <CSS.CreateAssessmentButton
            aria-label="Add Category"
            onClick={() => store.setCategoryModal(true)}
          >
            <CSS.PlusIcon style={{ fontSize: '8rem' }} />
          </CSS.CreateAssessmentButton>
        </CSS.StaffCard>
      </CSS.CategoryList>
      <Modal
        open={store.isCategoryModalOpen}
        onClose={() => store.setCategoryModal(false)}
        data-testid="set-category-modal"
      >
        <SetCategoryForm
          closeModal={() => store.setCategoryModal(false)}
          categoryId={store.selectedCategoryId}
        />
      </Modal>
    </div>
  );
});

export default Categories;
