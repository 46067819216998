import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { AlertContext } from '../../contexts/Alert';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { TopicsContext } from '../../contexts/Topics';
import * as CSS from './elements/StaffModule';

const ModuleListItem = observer(
  ({ item, store, collection, topicId, moduleId }) => {
    const saveChangesStore = useContext(SaveChangesContext);
    const alertStore = useContext(AlertContext);
    const topicsStore = useContext(TopicsContext);

    const module = topicsStore.getModuleById(moduleId);

    const selectedItem = module.getItemByIndex(store.selectedIndex, collection);

    const [{ isDragging }, dragRef] = useDrag({
      item: {
        type: `${moduleId}-${collection}`,
        startIndex: item.index,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const [{ isOver }, drop] = useDrop({
      accept: `${moduleId}-${collection}`,
      drop: ({ startIndex }) => {
        if (saveChangesStore.isEdited) {
          alertStore.setText({
            heading: 'Unsaved Changes',
            text:
              'You have unsaved changes \n\n Please save your changes before re-ordering',
            isWarning: true,
          });
          alertStore.open();
        } else {
          moveItem(startIndex);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    const moveItem = (startIndex) => {
      if (store.selectedIndex === startIndex) {
        store.selectedIndex = item.index;
      } else {
        if (startIndex > item.index) {
          store.selectedIndex++;
        } else if (startIndex < item.index) {
          store.selectedIndex--;
        }
      }
      module.moveItem(collection, startIndex, item.index, topicId, moduleId);
    };

    return (
      <CSS.ModuleListItem
        ref={module.locked ? null : drop}
        data-testid={`${moduleId}-${collection}-${item.index}-dropTarget`}
      >
        <CSS.ItemLink
          ref={module.locked ? null : dragRef}
          data-testid={`${moduleId}-${collection}-${item.index}-dragTarget`}
          isSelected={store.selectedIndex === item.index}
          // disabled={store.selectedIndex === item.index}
          type="button"
          isDragging={isDragging}
          isOver={isOver}
          onClick={() => {
            if (saveChangesStore.isEdited) {
              saveChangesStore.open(() => {
                if (!selectedItem.id) {
                  module.removeItemByIndex(store.selectedIndex, collection);
                }
                store.selectedIndex = item.index;
                saveChangesStore.setEdited(false);
              });
            } else store.selectedIndex = item.index;
          }}
        >
          {item.index + 1}
        </CSS.ItemLink>
      </CSS.ModuleListItem>
    );
  }
);

export default ModuleListItem;
