import ListSubheader from '@material-ui/core/ListSubheader';
import Modal from '@material-ui/core/Modal';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AlertContext } from '../../contexts/Alert';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { TopicsContext } from '../../contexts/Topics';
import { db } from '../../firebase';
import * as CSS from './elements/Topics';
import ModuleCard from './ModuleCard';
import SetModuleForm from './SetModuleForm';
import SetTopicForm from './SetTopicForm';

const Topics = observer(() => {
  const topicsStore = useContext(TopicsContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const alertStore = useContext(AlertContext);
  const store = useLocalStore(() => ({
    isTopicModalOpen: false,
    setTopicModal: (open, id) => {
      store.isTopicModalOpen = open;
      if (id) store.selectedTopicId = id;
      if (!open) store.selectedTopicId = null;
    },
    isModuleModalOpen: false,
    setModuleModal: (open, id) => {
      store.isModuleModalOpen = open;
      store.selectedTopicId = id;
    },
    isDeleteTopicModalOpen: false,
    setDeleteTopicModal: (open, id) => {
      store.isDeleteTopicModalOpen = open;
      if (id) store.selectedTopicId = id;
      if (!open) store.selectedTopicId = null;
    },
    selectedTopicId: null,
    filterTopicId: '',
  }));

  const handleTopicChange = (e) => {
    const { value } = e.target;
    store.filterTopicId = value;
  };

  const toggleTopicActive = async ({ id, active }) => {
    await db.collection('topics').doc(id).update({ active: !active });
  };

  const handleToggleTopicActive = ({ topicName, active, id }) => {
    const activeText = active ? 'Inactive' : 'Active';
    saveChangesStore.setText({
      heading: `Set Topic '${topicName}' to '${activeText}'`,
      message: `Are you sure you want to make the '${topicName}' topic '${activeText}'?`,
      leavingText: 'Yes',
      remainingText: 'No',
    });

    saveChangesStore.open(() => toggleTopicActive({ id, active }));
  };
  const deleteTopic = async ({ id }) => {
    await db.collection('topics').doc(id).delete();
  };

  const handleDeleteTopic = ({ topicName, id, modules }) => {
    if (modules.length === 0) {
      saveChangesStore.setText({
        heading: `Delete Topic '${topicName}'`,
        message: `Are you sure you want to delete the Topic: '${topicName}'?`,
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(() => deleteTopic({ id }));
    } else {
      alertStore.setText({
        heading: `Unable to delete topic: ${topicName}`,
        text:
          'Remove associated modules before you can delete this topic.\nPlease go to each module to delete them individually.',
        isWarning: true,
      });
      alertStore.open();
    }
  };

  return (
    <CSS.StaffContainer data-testid="Topics">
      <CSS.StaffRow>
        <CSS.Inline>
          <CSS.StaffHeader>Topics</CSS.StaffHeader>
          <CSS.AlignButton>
            <CSS.CreateButton onClick={() => store.setTopicModal(true)}>
              Create <CSS.PlusIcon />
            </CSS.CreateButton>
          </CSS.AlignButton>
        </CSS.Inline>
        <CSS.FilterTopicsForm>
          <CSS.StaffSelect
            select
            id="Choose a topic"
            label="Choose a topic"
            onChange={handleTopicChange}
            value={store.filterTopicId}
          >
            <CSS.StaffMenuItem value="">All Topics</CSS.StaffMenuItem>
            {topicsStore.getOrderedTopics().map((topic, i, topics) => {
              const isNewSubject = topic.subject !== topics[i - 1]?.subject;
              return [
                isNewSubject && (
                  <ListSubheader value="">{topic.subject}</ListSubheader>
                ),
                <CSS.StaffMenuItem key={topic.id} value={topic.id}>
                  {topic.topicName}
                </CSS.StaffMenuItem>,
              ];
            })}
          </CSS.StaffSelect>
        </CSS.FilterTopicsForm>
      </CSS.StaffRow>
      {topicsStore
        .filterTopicsById(store.filterTopicId)
        .map((topic, i, topics) => {
          const { topicName, active, modules, id, loading, subject } = topic;
          const isNewSubject = subject !== topics[i - 1]?.subject;
          const isLastInSubject = subject !== topics[i + 1]?.subject;
          return (
            <CSS.SubjectContainer key={id} isLastInSubject={isLastInSubject}>
              {isNewSubject && <CSS.SubjectHeader>{subject}</CSS.SubjectHeader>}
              <CSS.StaffRow>
                <CSS.StaffHeaderGroup>
                  <CSS.Column>
                    <CSS.Row>
                      <CSS.StaffTopicSubHeader data-testid="TopicHeader">
                        {topicName}
                      </CSS.StaffTopicSubHeader>
                      <CSS.EditTopicButton
                        data-testid={`Topic-EditButton-${id}`}
                        onClick={() => store.setTopicModal(true, id)}
                        aria-label="edit topic"
                      >
                        <CSS.EditIcon />
                      </CSS.EditTopicButton>
                    </CSS.Row>
                    <CSS.ActiveTopic
                      active={active}
                      data-testid={`Topic-ActiveButton-${id}-${active}`}
                      onClick={() => handleToggleTopicActive(topic)}
                      aria-label="toggle active topic"
                    >
                      {active ? 'Active' : 'Inactive'}
                      <CSS.ActiveIcon />
                    </CSS.ActiveTopic>
                  </CSS.Column>
                  {!loading && (
                    <CSS.DeleteIconButton
                      data-testid={`delete-topic-button-${id}`}
                      onClick={() => handleDeleteTopic(topic)}
                      aria-label="delete topic"
                    >
                      <CSS.Bin style={{ fontSize: '3rem' }} />
                    </CSS.DeleteIconButton>
                  )}
                </CSS.StaffHeaderGroup>
                <CSS.CreateButton
                  data-testid={`create-module-button-${id}`}
                  onClick={() => store.setModuleModal(true, id)}
                >
                  Add module <CSS.PlusIcon />
                </CSS.CreateButton>
              </CSS.StaffRow>
              <CSS.CardList>
                {modules.map((module) => (
                  <ModuleCard
                    key={module.id}
                    module={module}
                    topicName={topicName}
                    topicId={id}
                  />
                ))}
              </CSS.CardList>
            </CSS.SubjectContainer>
          );
        })}
      <Modal
        data-testid="create-topic-modal"
        open={store.isTopicModalOpen}
        onClose={() => store.setTopicModal(false)}
        aria-labelledby="create-topic-modal"
        aria-describedby="create-topic-modal"
      >
        <SetTopicForm
          topicId={store.selectedTopicId}
          closeModal={() => store.setTopicModal(false)}
        />
      </Modal>
      <Modal
        data-testid="create-module-modal"
        open={store.isModuleModalOpen}
        onClose={() => store.setModuleModal(false)}
        aria-labelledby="create-module-modal"
        aria-describedby="create-module-modal"
      >
        <SetModuleForm
          topicId={store.selectedTopicId}
          closeModal={() => store.setModuleModal(false)}
          newModule={true}
        />
      </Modal>
    </CSS.StaffContainer>
  );
});

export default Topics;
