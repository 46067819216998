export const addFieldsToReport = ({
  reportedFields,
  report,
  obj,
  objIndex,
  objName,
  data,
}) => {
  reportedFields.forEach((field) => {
    const colName = `${objName}.${objIndex}.${field}`;
    let colIndex = report.fields.indexOf(colName);
    if (colIndex === -1) {
      report.fields.push(colName);
      colIndex = report.fields.length - 1;
    }
    data[colIndex] = obj[field];
  });
};
