import styled from 'styled-components/macro';
import RoundProfileImage from '../../common/ProfileImage';

export const ListCard = styled.li`
  display: grid;
  grid-template-columns: 20% 65% 15%;
  grid-template-areas: 'avatar name delete';
  list-style-type: none;
  margin: 1em;
  padding: 1em 0;
  border-radius: 7px;
  color: ${({ theme }) => theme.textDark};
  box-shadow: 0px 1px 2px rgba(165, 165, 165, 0.25);
  background: white;
  background-clip: padding-box;
  border: solid 1px
    ${({ theme, isStaff }) =>
      isStaff ? theme.classroomHighlight : 'transparent'};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: ${({ theme }) => theme.buttonUpdate};
  }
`;

export const Avatar = styled(RoundProfileImage)`
  grid-area: avatar;
  justify-self: center;
  align-self: center;
  width: 4em;
  height: 4em;
`;

export const StudentName = styled.h3`
  grid-area: name;
`;

export const DeleteButton = styled.button`
  color: ${({ theme }) => theme.textDark};
  grid-area: delete;
  background: none;
  border: none;
`;

export * from '../../common/elements/Staff';
