const { REACT_APP_THEME = 'talkEnglish' } = process.env;

export const updateIcons = () => {
  const manifestLink = document.getElementById('manifest');
  if (manifestLink) {
    manifestLink.setAttribute('href', `/${REACT_APP_THEME}/manifest.json`);
  }

  const links = document.getElementsByClassName('icon-link');
  [...links].forEach((link) => {
    const newHref = link.href.replace(
      /\/talkEnglish\//,
      `/${REACT_APP_THEME}/`
    );
    link.href = newHref;
  });
};
