import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ChatsContext } from '../../contexts/Chats.js';
import { TopicsContext } from '../../contexts/Topics.js';
import { db } from '../../firebase/index.js';
import * as CSS from './elements/TopicHeader.js';

const TopicHeader = observer(({ id, topicName, dropIndex, groupChatId }) => {
  const chatsStore = useContext(ChatsContext);
  const topicsStore = useContext(TopicsContext);

  const chatToEdit = chatsStore.getChatById(groupChatId, 'groupChats');

  const [{ isDragging }, drag] = useDrag({
    item: { type: `topic`, id, startingIndex: dropIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: `topic`,
    drop: async (item) => {
      if (groupChatId && dropIndex !== item.startingIndex) {
        const existingTopics = topicsStore.getChatModules(
          chatToEdit.modules,
          chatToEdit.topics
        );
        const newTopicOrder = existingTopics.reduce(
          (acc, [topic], oldIndex) => {
            const topicRef = db.collection('topics').doc(topic.id);
            if (topic.id === item.id) {
              acc[topic.id] = {
                index: dropIndex,
                topic: topicRef,
                topicName: topic.topicName,
              };
            } else if (
              item.startingIndex < dropIndex &&
              oldIndex <= dropIndex
            ) {
              acc[topic.id] = {
                index: oldIndex - 1,
                topic: topicRef,
                topicName: topic.topicName,
              };
            } else if (
              item.startingIndex > dropIndex &&
              oldIndex >= dropIndex
            ) {
              acc[topic.id] = {
                index: oldIndex + 1,
                topic: topicRef,
                topicName: topic.topicName,
              };
            } else {
              acc[topic.id] = {
                index: oldIndex,
                topic: topicRef,
                topicName: topic.topicName,
              };
            }
            return acc;
          },
          {}
        );
        await db.collection('groupChats').doc(groupChatId).update({
          topics: newTopicOrder,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <CSS.StaffRow
      data-testid={`EditClassroom-${id}-dropTarget`}
      ref={drop}
      isOver={isOver}
    >
      <CSS.StaffHeaderGroup
        data-testid={`EditClassroom-${id}-${dropIndex}`}
        ref={drag}
        isDragging={isDragging}
      >
        <CSS.StaffHeaderCount>{dropIndex + 1}</CSS.StaffHeaderCount>
        <CSS.StaffSubHeader>{topicName}</CSS.StaffSubHeader>
      </CSS.StaffHeaderGroup>
    </CSS.StaffRow>
  );
});

export default TopicHeader;
