import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Link } from '@reach/router';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { ThemeContext } from '../../../contexts/Theme';

export const ResourceContainer = styled.section`
  display: grid;
  grid-template-rows: auto 67px;
  grid-template-areas: 'resource' 'nav';
  min-height: calc(100vh - max(8.5vh, 67px));

  @media only screen and (min-width: 700px) {
    min-height: 100vh;
  }
`;

export const ResourceColumn = styled.div`
  grid-area: resource;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const ModuleHeader = styled.h1`
  font-weight: bold;
  font-size: 2.8rem;
  margin: 0;
  color: ${({ theme }) => theme.headerColour};
`;

export const Instructions = styled.p`
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.textDark};
`;

export const EmbedFrame = styled.iframe`
  width: 95%;
  min-height: 65%;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  @media only screen and (min-width: 700px) {
    min-height: 75%;
  }
`;

export const NavRow = styled.nav`
  grid-area: nav;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.headerColour};
  background: ${({ theme }) => theme.lessonHighlight};
  border-radius: 25px;
  padding: 0.25em 0.8em;
  text-align: center;
  text-decoration: none;
  ${({ $lightText, theme }) =>
    $lightText &&
    css`
      color: ${({ theme }) => theme.textLight};
    `}
`;

export const BorderlessButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const BackArrowIcon = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <ArrowBackRoundedIcon
      style={{ fontSize: '4rem', color: theme.appBackground }}
      {...props}
    />
  );
};

export const NextArrowIcon = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <ArrowForwardRoundedIcon
      style={{ fontSize: '4rem', color: theme.appBackground }}
      {...props}
    />
  );
};
