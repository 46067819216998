import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, styled as materialStyled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ShareIconMUI from '@material-ui/icons/Share';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from '@reach/router';
import { observer, useLocalStore, useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { SaveChangesContext } from '../../../contexts/SaveChanges.js';
import { ThemeContext } from '../../../contexts/Theme.js';
import { Button } from './Form';
import { FormHeader } from './Form.js';

export const StaffContainer = styled.section`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - max(8.5vh, 67px) - 3em);
  background-color: ${({ theme }) => theme.staffBackground};
  padding: 1.5em 0.5em;

  @media only screen and (min-width: 700px) {
    min-height: calc(100vh - 3em);
  }

  @media only screen and (min-width: 900px) {
    padding: 1.5em 3em;
  }
`;

export const StaffContainerSpaceBetween = styled(StaffContainer)`
  justify-content: space-between;
`;

export const StaffRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5em 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  ${({ isOver }) =>
    isOver &&
    css`
      background-color: ${({ theme }) => theme.hoverFill};
    `}
`;

export const StaffHeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5em 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: baseline;
  }
`;

export const AlignButton = styled.div`
  margin: 0.5em;
  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
    `};
  ${({ hoverable }) =>
    hoverable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const StaffHeader = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  color: ${({ theme }) => theme.headerColour};
  margin: 0;
`;

export const StaffSubHeader = styled.h2`
  color: #838383;
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.subHeaderColour};
  margin: 0;
  padding-left: 1em;
`;

export const StaffHeaderGroup = styled.div`
  border-left: 3px solid ${({ theme }) => theme.classroomHighlight};
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  ${({ isDragging }) =>
    isDragging &&
    css`
      opacity: 0.3;
    `}
`;

export const StaffHeaderCount = styled(StaffHeader).attrs(() => ({ as: 'p' }))`
  font-size: 3.6rem;
`;

export const StaffActiveLink = styled(Link)`
  font-weight: 800;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1.4em;
  margin-right: 0.6em;
  text-decoration: none;
  &[data-ispartiallycurrent='true'] {
    color: ${({ theme }) => theme.lessonHighlight};
    text-decoration: underline;
  }
  ${({ isfirst }) =>
    isfirst &&
    css`
      margin-left: 0;
    `}
`;

export const StaffFilterLink = ({ isfirst, ...props }) => {
  const saveChangesStore = useContext(SaveChangesContext);
  const getProps = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { 'data-ispartiallycurrent': true } : {};
  };
  return (
    <StaffActiveLink
      onClick={saveChangesStore.handleNavigate}
      getProps={getProps}
      isfirst={isfirst ? 'true' : ''}
      {...props}
    />
  );
};

export const StaffCard = styled.li`
  background: ${({ theme }) => theme.appBackground};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  margin: 1em 1em 2em 1em;
  padding: 0.9em;
  min-width: calc(100% - 4em);

  @media only screen and (min-width: 700px) {
    min-width: 24rem;
    max-width: 52rem;
  }

  ${({ hasNotifications }) =>
    hasNotifications &&
    css`
      margin: 0;
    `}
`;

export const StaffFormHeaderRow = styled.header`
  background: ${({ theme }) => theme.lessonHighlight};
  border-radius: 15px 15px 0px 0px;
  width: 100%;
`;

export const StaffFormHeader = styled(FormHeader)`
  color: ${({ theme }) => theme.textLight};
  margin-bottom: 0.4em;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

export const StaffFooterRow = styled.footer`
  display: flex;
  border-radius: 0px 0px 15px 15px;
  border-top: 1px solid ${({ theme }) => theme.staffBackground};
  width: 100%;
  justify-content: space-around;
  padding: 1.5em 0;
`;

export const StaffFormCloseButton = styled.button`
  position: absolute;
  top: 0.75em;
  right: 0.75em;
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.textLight};
  &:hover {
    cursor: pointer;
  }
`;

export const StaffCloseIcon = () => <CloseIcon style={{ fontSize: '2rem' }} />;

export const StaffTextFieldInput = observer(
  ({ field, form, stylesOverride, inputMode, ...props }) => {
    const theme = useContext(ThemeContext);
    const store = useLocalStore(() => ({
      styles: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#C1C1C1',
          },
          '&:hover fieldset': {
            borderColor: theme.buttonPrimary,
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.buttonPrimary,
          },
        },
        '& label': {
          '&.Mui-focused': {
            color: theme.buttonPrimary,
          },
        },
      },
      StyledTextInput: TextField,
    }));
    if (stylesOverride) store.styles = stylesOverride;
    useEffect(() => {
      store.StyledTextInput = materialStyled(TextField)(store.styles);
    }, [theme, store]);

    return (
      <store.StyledTextInput
        {...field}
        variant="outlined"
        InputLabelProps={{
          style: {
            fontSize: '1.6rem',
          },
        }}
        InputProps={{
          style: {
            fontSize: '1.6rem',
            color: theme.headerColour,
          },
        }}
        inputProps={
          inputMode === 'numeric'
            ? {
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }
            : undefined
        }
        {...props}
      />
    );
  }
);

export const StaffMenuItem = React.forwardRef(({ ...props }, ref) => {
  const theme = useContext(ThemeContext);
  const store = useLocalStore(() => ({
    styles: {
      fontSize: '1.6rem',
      color: theme.headerColour,
    },
    StyledMenuItem: MenuItem,
  }));
  useEffect(() => {
    store.StyledMenuItem = materialStyled(MenuItem)(store.styles);
  }, [theme, store]);

  return useObserver(() => <store.StyledMenuItem {...props} />);
});

export const StaffNavButton = styled(Button)`
  border-radius: 54px;
  background: ${({ theme }) => theme.buttonUpdate};
  &:disabled {
    background: ${({ theme }) => theme.buttonDisabled};
    color: ${({ theme }) => theme.textLight};
    cursor: default;
  }
`;

export const AutocompleteMui = styled(Autocomplete)`
  margin: 2em;
  width: 75%;
  font-size: 1.6rem;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      margin: 0;
    `}
`;

export const StaffAutocomplete = (props) => {
  return (
    <AutocompleteMui
      {...props}
      ListboxProps={{
        style: { fontSize: '1.6rem' },
      }}
    />
  );
};

export const StaffAutocompleteInput = React.forwardRef(({ ...props }, ref) => {
  const theme = useContext(ThemeContext);
  const store = useLocalStore(() => ({
    styles: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#C1C1C1',
        },
        '&:hover fieldset': {
          borderColor: theme.buttonPrimary,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.buttonPrimary,
        },
      },
      '& label': {
        '&.Mui-focused': {
          color: theme.buttonPrimary,
        },
        color: theme.textDark,
      },
      '& .MuiChip-label': {
        fontSize: '1.6rem',
      },
    },
    StyledTextField: TextField,
  }));
  useEffect(() => {
    store.StyledTextField = materialStyled(TextField)(store.styles);
  }, [theme, store]);

  return useObserver(() => (
    <store.StyledTextField
      {...props}
      InputProps={{
        style: {
          fontSize: '1.6rem',
        },
        ...props.InputProps,
      }}
      InputLabelProps={{
        style: {
          fontSize: '1.6rem',
        },
        ...props.InputLabelProps,
      }}
    />
  ));
});

export const PlusIcon = (props) => (
  <AddCircleOutlineIcon
    style={{ fontSize: '1.6rem', paddingLeft: '0.3em', verticalAlign: 'sub' }}
    {...props}
  />
);

export const StaffDropDown = React.forwardRef(({ ...props }, ref) => {
  const theme = useContext(ThemeContext);
  const store = useLocalStore(() => ({
    styles: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#C1C1C1',
        },
        '&:hover fieldset': {
          borderColor: theme.buttonPrimary,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.buttonPrimary,
        },
      },
      '& label': {
        '&.Mui-focused': {
          color: theme.buttonPrimary,
        },
      },
    },
    StyledTextField: TextField,
  }));
  useEffect(() => {
    store.StyledTextField = materialStyled(TextField)(store.styles);
  }, [theme, store]);

  return useObserver(() => <store.StyledTextField {...props} />);
});

export const ProgressBarWithLabel = ({
  completionPercent,
  lessonColours,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const classes = makeStyles({
    colorPrimary: {
      background: theme.buttonDisabled,
    },
    barColorPrimary: {
      background: lessonColours ? theme.textLight : theme.buttonUpdate,
    },
  })();
  return (
    <Box display="flex" alignItems="center">
      <Box
        minWidth={30}
        color={lessonColours ? theme.textLight : theme.headerColour}
        fontSize="1rem"
        fontWeight="bold"
      >
        {completionPercent}%
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={completionPercent}
          classes={{
            barColorPrimary: classes.barColorPrimary,
            colorPrimary: classes.colorPrimary,
          }}
          {...props}
        />
      </Box>
    </Box>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const StaffLoadingContainer = styled(StaffContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 1s linear;
`;

export const ItemLink = styled.button`
  padding: 0;
  border: none;
  font-size: 2.4rem;
  font-weight: bold;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  margin: 0 0.2em;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.buttonUpdate : theme.appBackground};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.textLight : theme.headerColour};
  &:hover {
    cursor: pointer;
  }
  ${({ isDragging }) =>
    isDragging &&
    css`
      opacity: 0.3;
    `}
  ${({ isOver }) =>
    isOver &&
    css`
      background: ${({ theme }) => theme.buttonUpdate};
      color: ${({ theme }) => theme.textLight};
    `}
`;

export const AddItemButton = styled(ItemLink)`
  background: ${({ theme }) => theme.buttonCreate};
  color: ${({ theme }) => theme.textLight};
  &:disabled {
    background: ${({ theme }) => theme.buttonDisabled};
    cursor: default;
  }
`;

export const Bin = (props) => (
  <DeleteForeverOutlinedIcon
    style={{
      fontSize: '2rem',
    }}
    {...props}
  />
);

export const AddItemPlusIcon = (props) => (
  <AddCircleOutlineIcon
    style={{
      fontSize: '2.4rem',
      position: 'relative',
      top: '0.14em',
    }}
    {...props}
  />
);

export const DeleteIconButton = styled.button`
  grid-area: delete;
  height: min-content;
  display: flex;
  background: none;
  border: none;
  color: ${({ theme }) => theme.textDark};
  transition: 150ms;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.buttonDelete};
    transition: 150ms;
  }
`;

export const ActiveButton = styled.button`
  padding: 0;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.classroomHighlight};
    `}
`;

export const ActiveIcon = () => (
  <FiberManualRecordIcon
    style={{
      fontSize: '2rem',
      alignSelf: 'end',
    }}
  />
);

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StaffSelect = observer(({ field, form, ...props }) => {
  const theme = useContext(ThemeContext);
  const store = useLocalStore(() => ({
    styles: {
      'min-width': '20em',
      '& .MuiFilledInput-root': {
        borderColor: theme.lessonHighlight,
        '& fieldset': {
          borderColor: '#C1C1C1',
        },
        '&:hover:before': {
          borderColor: theme.lessonHighlight,
        },
        '&:after': {
          borderColor: theme.lessonHighlight,
        },
      },
      '& .MuiSelect-select:focus': {
        background: 'none',
      },
      '& label': {
        '&.Mui-focused': {
          color: theme.lessonHighlight,
        },
      },
    },
    StyledTextInput: TextField,
  }));
  useEffect(() => {
    store.StyledTextInput = materialStyled(TextField)(store.styles);
  }, [theme, store]);

  return (
    <store.StyledTextInput
      {...field}
      {...props}
      variant="filled"
      InputLabelProps={{
        style: {
          fontSize: '1.2rem',
        },
      }}
      InputProps={{
        style: {
          fontSize: '1.2rem',
          color: theme.headerColour,
          background: theme.appBackground,
        },
      }}
    />
  );
});

export const InfoIcon = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <InfoRoundedIcon
      style={{ fontSize: '2.5rem', color: theme.textDark }}
      {...props}
    />
  );
};

export const InfoContainer = styled.section`
  padding: 1em 2em;
`;

export const InfoHeading = styled.h2`
  color: ${({ theme }) => theme.headerColour};
  font-size: 2rem;
`;

export const InfoText = styled.p`
  color: ${({ theme }) => theme.textDark};
  font-size: 1.8rem;
`;

export const Inline = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const InlineCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const EditIcon = (props) => {
  return <EditOutlinedIcon {...props} style={{ fontSize: '1.6rem' }} />;
};

export const ShareIcon = (props) => (
  <ShareIconMUI style={{ fontSize: '3rem' }} {...props} />
);
