import Drawer from '@material-ui/core/Drawer';
import Modal from '@material-ui/core/Modal';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { CategoriesContext } from '../../contexts/Categories.js';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import { useWindowSize } from '../../hooks/useWindowSize.js';
import * as CSS from './elements/Nav.js';
import SetCategoryModal from './SetCategoryModal.js';

const Nav = observer(({ pathname = '/' }) => {
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);
  const categoriesStore = useContext(CategoriesContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const windowStore = useWindowSize();
  const selectedCategory = categoriesStore.getCategoryById(
    loggedInUser.selectedCategory
  );

  const inboxUnread = chatsStore.getChatsUnreadCount(
    usersStore.loggedInUserId,
    loggedInUser.selectedCategory,
    'individualChats'
  );

  const classroomUnread = chatsStore.getChatsUnreadCount(
    usersStore.loggedInUserId,
    loggedInUser.selectedCategory,
    'groupChats'
  );

  const store = useLocalStore(() => ({
    isOpen: false,
    setIsOpen(isOpen) {
      store.isOpen = isOpen;
    },
    isCategoryModalOpen: false,
    setCategoryModal: (open, id) => {
      store.isCategoryModalOpen = open;
      if (id) store.selectedCategoryId = id;
      if (!open) store.selectedCategoryId = null;
    },
    selectedCategoryId: null,
  }));

  const isStaffRoute = pathname.startsWith('/staff');

  const modal = (
    <Modal
      open={store.isCategoryModalOpen}
      onClose={() => store.setCategoryModal(false)}
    >
      <SetCategoryModal closeModal={() => store.setCategoryModal(false)} />
    </Modal>
  );

  if (['teacher', 'admin'].includes(loggedInUser.role)) {
    const teacherLinks = [
      { displayText: 'Home', to: '/' },
      {
        displayText: 'Classrooms',
        to: '/staff/classrooms',
        unreadCount: classroomUnread,
      },
      {
        displayText: loggedInUser.role === 'admin' ? 'Users' : 'Students',
        to: '/staff/users',
      },
      { displayText: 'Topics', to: '/staff/topics' },
      { displayText: 'Inbox', to: '/inbox', unreadCount: inboxUnread },
    ];
    if (loggedInUser.role === 'admin')
      teacherLinks.push({
        displayText: 'Admin',
        to: '/staff/admin/learningPlans',
      });
    if (windowStore.isMobile) {
      return (
        <CSS.NavBar aria-label="site nav">
          <CSS.NavContent>
            <CSS.InvisibleButton
              data-testid="Nav-mobile-open"
              onClick={() => store.setIsOpen(true)}
            >
              <CSS.BigMenuIcon />
            </CSS.InvisibleButton>
            <CSS.ProfileLink to="/profile">
              <CSS.UserAvatarImage
                src={loggedInUser.imageURL}
                alt="User Avatar"
              />
            </CSS.ProfileLink>
            <CSS.UnreadCount
              data-testid="nav-Inbox-unread"
              badgeContent={inboxUnread}
            >
              <CSS.NavLink to="/inbox">
                <CSS.BigMailOutlineIcon />
              </CSS.NavLink>
            </CSS.UnreadCount>
          </CSS.NavContent>
          <Drawer
            anchor="left"
            open={store.isOpen}
            onClose={() => store.setIsOpen(false)}
          >
            <CSS.NavDrawer>
              <CSS.InvisibleButton
                onClick={() => store.setCategoryModal(true)}
                data-testid={`Nav-selectedCategoryId-${selectedCategory?.id}`}
              >
                {selectedCategory?.categoryName}
              </CSS.InvisibleButton>
              <CSS.ProfileLink
                to="/profile"
                onClick={() => store.setIsOpen(false)}
              >
                <CSS.TeacherAvatarImage
                  src={loggedInUser.imageURL}
                  alt="User Avatar"
                />
              </CSS.ProfileLink>
              {isStaffRoute && chatsStore.selectedChatId && (
                <CSS.InvisibleButton
                  data-testid="Nav-toggle-chat"
                  onClick={() => {
                    store.setIsOpen(false);
                    chatsStore.toggleChatVisibility();
                  }}
                  title={
                    chatsStore.isSelectedChatVisible
                      ? 'Close selected chat'
                      : 'Open selected chat'
                  }
                >
                  {chatsStore.isSelectedChatVisible ? (
                    <CSS.CloseChatIcon />
                  ) : (
                    <CSS.OpenChatIcon />
                  )}
                </CSS.InvisibleButton>
              )}
              {teacherLinks.map((link) => (
                <CSS.UnreadCount
                  key={link.to}
                  data-testid={`nav-${link.displayText}-unread`}
                  badgeContent={link.unreadCount}
                >
                  <CSS.TeacherNavLink
                    to={link.to}
                    afterClick={() => store.setIsOpen(false)}
                  >
                    {link.displayText}
                  </CSS.TeacherNavLink>
                </CSS.UnreadCount>
              ))}
            </CSS.NavDrawer>
          </Drawer>
          {modal}
        </CSS.NavBar>
      );
    }
    return (
      <CSS.NavBar aria-label="site nav">
        <CSS.NavContent>
          <CSS.InvisibleButton
            onClick={() => store.setCategoryModal(true)}
            data-testid={`Nav-selectedCategoryId-${selectedCategory?.id}`}
          >
            {selectedCategory?.categoryName}
          </CSS.InvisibleButton>
          <CSS.ProfileLink to="/profile">
            <CSS.TeacherAvatarImage
              src={loggedInUser.imageURL}
              alt="User Avatar"
            />
          </CSS.ProfileLink>
          {isStaffRoute && chatsStore.selectedChatId && (
            <CSS.InvisibleButton
              data-testid="Nav-toggle-chat"
              onClick={() => {
                chatsStore.toggleChatVisibility();
              }}
              title={
                chatsStore.isSelectedChatVisible
                  ? 'Close selected chat'
                  : 'Open selected chat'
              }
            >
              {chatsStore.isSelectedChatVisible ? (
                <CSS.CloseChatIcon />
              ) : (
                <CSS.OpenChatIcon />
              )}
            </CSS.InvisibleButton>
          )}
          {teacherLinks.map((link) => (
            <CSS.UnreadCount
              key={link.to}
              data-testid={`nav-${link.displayText}-unread`}
              badgeContent={link.unreadCount}
            >
              <CSS.TeacherNavLink to={link.to}>
                {link.displayText}
              </CSS.TeacherNavLink>
            </CSS.UnreadCount>
          ))}
        </CSS.NavContent>
        {modal}
      </CSS.NavBar>
    );
  }
  return (
    <CSS.NavBar aria-label="site nav">
      <CSS.NavContent>
        <CSS.UnreadCount
          data-testid={'nav-Home-unread'}
          badgeContent={classroomUnread}
        >
          <CSS.NavLink to="/" aria-label="home">
            <CSS.BigHomeIcon />
          </CSS.NavLink>
        </CSS.UnreadCount>
        <CSS.ProfileLink to="/profile" aria-label="my profile">
          <CSS.UserAvatarImage src={loggedInUser.imageURL} alt="User Avatar" />
        </CSS.ProfileLink>
        <CSS.UnreadCount
          data-testid="nav-Inbox-unread"
          badgeContent={inboxUnread}
        >
          <CSS.NavLink to="/inbox" aria-label="inbox">
            <CSS.BigMailOutlineIcon />
          </CSS.NavLink>
        </CSS.UnreadCount>
      </CSS.NavContent>
      {modal}
    </CSS.NavBar>
  );
});

export default Nav;
