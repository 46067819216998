import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { saveAs } from 'file-saver';
import { Field, Formik } from 'formik';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import Papa from 'papaparse';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/Reports.js';

const Reports = observer(() => {
  const topicsStore = useContext(TopicsContext);
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);
  const store = useLocalStore(() => ({
    csv: null,
    reportName: '',
    generateCSV(jsonReport, report) {
      const csv = Papa.unparse(jsonReport);
      const reportName = `${report.reportName}-report.csv`;
      store.csv = new File([csv], reportName, {
        type: 'text/csv',
      });
      store.reportName = reportName;
    },
  }));

  const options = [
    { text: 'Topics and Modules', reportName: 'topics-modules' },
    { text: 'Classrooms', reportName: 'classrooms' },
    { text: 'Classroom Chats', reportName: 'classroom-chats' },
    { text: 'Individual Chats', reportName: 'individual-chats' },
    { text: 'Single User', reportName: 'user' },
    { text: 'Users by Classroom', reportName: 'users-by-classroom' },
    { text: 'Users by Current Category', reportName: 'users' },
  ];

  const generateReport = (report, user, classroom) => {
    let jsonReport;
    switch (report.text) {
      case 'Topics and Modules':
        jsonReport = topicsStore.getJSONReport();
        store.generateCSV(jsonReport, report);

        break;
      case 'Classrooms':
        jsonReport = chatsStore.getJSONReport(
          'groupChats',
          usersStore,
          topicsStore,
          usersStore.getLoggedInUser().selectedCategory
        );
        store.generateCSV(jsonReport, report);

        break;
      case 'Classroom Chats':
        jsonReport = chatsStore.getMessagesJSONReport(
          'groupChats',
          usersStore,
          usersStore.getLoggedInUser().selectedCategory
        );
        store.generateCSV(jsonReport, report);

        break;
      case 'Individual Chats':
        db.collection('individualChats')
          .get()
          .then((chatSnap) => {
            const messagePromises = chatSnap.docs.map((doc) =>
              db
                .collection('individualChats')
                .doc(doc.id)
                .collection('messages')
                .get()
            );
            return Promise.all([chatSnap, ...messagePromises]);
          })
          .then(([chatSnap, ...nestedMessages]) => {
            jsonReport = chatsStore.generateJSONReportFromDocs(
              chatSnap,
              nestedMessages,
              usersStore
            );
            store.generateCSV(jsonReport, report);
          });

        break;
      case 'Single User':
        db.collection('individualChats')
          .get()
          .then((chatSnap) => {
            const individualChatRef = chatSnap.docs.reduce(
              (ref, chatDoc, i) => {
                ref[chatDoc.id] = chatDoc.data();
                return ref;
              },
              {}
            );
            jsonReport = usersStore.getJSONReport(
              user.id,
              chatsStore,
              individualChatRef,
              usersStore.getLoggedInUser().selectedCategory
            );
            store.generateCSV(jsonReport, report);
          });
        break;
      case 'Users by Classroom':
        db.collection('individualChats')
          .get()
          .then((chatSnap) => {
            const individualChatRef = chatSnap.docs.reduce(
              (ref, chatDoc, i) => {
                ref[chatDoc.id] = chatDoc.data();
                return ref;
              },
              {}
            );
            jsonReport = usersStore.getJSONReport(
              null,
              chatsStore,
              individualChatRef,
              usersStore.getLoggedInUser().selectedCategory,
              classroom.id
            );
            store.generateCSV(jsonReport, report);
          });
        break;
      case 'Users by Current Category':
        db.collection('individualChats')
          .get()
          .then((chatSnap) => {
            const individualChatRef = chatSnap.docs.reduce(
              (ref, chatDoc, i) => {
                ref[chatDoc.id] = chatDoc.data();
                return ref;
              },
              {}
            );
            jsonReport = usersStore.getJSONReport(
              null,
              chatsStore,
              individualChatRef,
              usersStore.getLoggedInUser().selectedCategory
            );
            store.generateCSV(jsonReport, report);
          });
        break;
      default:
        return;
    }
  };

  const downloadReport = () => {
    if (store.csv) saveAs(store.csv, store.reportName);
  };

  return (
    <div data-testid="Reports">
      <Formik
        key="no-assessment"
        enableReinitialize
        initialValues={{
          report: options[0],
          user: null,
          classroom: null,
        }}
        onSubmit={downloadReport}
      >
        {({ values, setFieldValue }) => (
          <Observer>
            {() => (
              <CSS.Form>
                <CSS.FormHeader>Generate CSVs</CSS.FormHeader>
                <CSS.FormSpacer>
                  <Field
                    select
                    name="report"
                    id="report"
                    label="Choose a report"
                    component={CSS.StaffTextFieldInput}
                    style={{ width: '100%' }}
                  >
                    {options.map((option) => {
                      return (
                        <CSS.StaffMenuItem key={option.text} value={option}>
                          {option.text}
                        </CSS.StaffMenuItem>
                      );
                    })}
                  </Field>
                </CSS.FormSpacer>
                {values.report.text === 'Single User' && (
                  <CSS.FormSpacer>
                    <Field name="user" id="user" label="Select a User">
                      {(props) => (
                        <CSS.StaffAutocomplete
                          {...props}
                          onChange={(event, user, reason) => {
                            if (reason === 'select-option') {
                              setFieldValue('user', user);
                            } else {
                              setFieldValue('user', null);
                            }
                          }}
                          options={usersStore.getUsers()}
                          getOptionLabel={(user) => user.displayName}
                          filterOptions={createFilterOptions({
                            limit: 20,
                          })}
                          renderInput={(params) => (
                            <CSS.StaffAutocompleteInput
                              value={values.user}
                              {...params}
                              label="Select a User"
                              variant="outlined"
                            />
                          )}
                          style={{ width: '100%', margin: 0 }}
                        />
                      )}
                    </Field>
                  </CSS.FormSpacer>
                )}
                {values.report.text === 'Users by Classroom' && (
                  <CSS.FormSpacer>
                    <Field
                      name="classroom"
                      id="classroom"
                      label="Select a Classroom"
                    >
                      {(props) => (
                        <CSS.StaffAutocomplete
                          {...props}
                          onChange={(event, classroom, reason) => {
                            if (reason === 'select-option') {
                              setFieldValue('classroom', classroom);
                            } else {
                              setFieldValue('classroom', null);
                            }
                          }}
                          //
                          options={chatsStore.groupChats.filter(
                            (chat) =>
                              chat.category ===
                              usersStore.getLoggedInUser().selectedCategory
                          )}
                          getOptionLabel={(chat) => chat.chatName}
                          filterOptions={createFilterOptions({
                            limit: 20,
                          })}
                          renderInput={(params) => (
                            <CSS.StaffAutocompleteInput
                              value={values.user}
                              {...params}
                              label="Select a Classroom"
                              variant="outlined"
                            />
                          )}
                          style={{ width: '100%', margin: 0 }}
                        />
                      )}
                    </Field>
                  </CSS.FormSpacer>
                )}
                <CSS.FormSpacer>
                  <CSS.StaffNavButton
                    type="button"
                    onClick={() =>
                      generateReport(
                        values.report,
                        values.user,
                        values.classroom
                      )
                    }
                    disabled={
                      (values.report.text === 'Single User' && !values.user) ||
                      (values.report.text === 'Users by Classroom' &&
                        !values.classroom)
                    }
                  >
                    Generate Report
                  </CSS.StaffNavButton>
                </CSS.FormSpacer>

                {store.csv && (
                  <CSS.StaffNavButton type="submit">
                    Download
                  </CSS.StaffNavButton>
                )}
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    </div>
  );
});

export default Reports;
