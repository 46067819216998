import styled, { css } from 'styled-components/macro';
import { DeleteButton as FormDeleteButton } from '../../common/elements/Form';

export const SetCurrentModules = styled.div`
  align-self: center;
  width: 30em;
  margin-top: 2em;

  @media only screen and (min-width: 700px) {
    margin-top: 0;
  }
`;

export const DeleteModuleButton = styled(FormDeleteButton)`
  ${({ isEditing, theme }) =>
    isEditing &&
    css`
      background: ${theme.buttonDisabled};
    `}
`;

export const CardList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
  ${({ pageBreak }) =>
    pageBreak &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.pendingFill};
      padding-bottom: 1.2em;
      margin-bottom: 1.2em;
    `}
`;

export const TopicsList = styled(CardList)`
  flex-direction: column;
`;

export const EditClassroomButton = styled.button`
  background: none;
  border: none;
  margin-left: 0.5em;
`;

export const ClassroomILP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
