import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Form } from 'formik';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from '../../../contexts/Theme';
import { CreateButton } from '../../common/elements/Form';
import { StaffTextFieldInput } from '../../common/elements/Staff';

export const SearchForm = styled(Form)``;

export const SearchStudentsField = (props) => {
  const theme = useContext(ThemeContext);
  const stylesOverride = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  };
  return (
    <StaffTextFieldInput
      stylesOverride={stylesOverride}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: '3rem' }} />
          </InputAdornment>
        ),
        style: {
          fontSize: '1.6rem',
          color: theme.textDark,
          background: theme.appBackground,
          height: '2.4em',
        },
        ...props.InputProps,
      }}
      {...props}
    />
  );
};

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlsWithLink = styled.div`
  display: grid;
  grid-template: auto auto / auto auto;
  grid-template-areas:
    'invite search'
    '_ link';
  align-items: center;
`;

export const InviteButton = styled(CreateButton)`
  margin-right: 1em;
`;

export * from './Topics';
