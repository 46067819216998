import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import { formatDate } from '../../utils/format-date.js';
import * as CSS from './elements/MessagePreview.js';

const MessagePreview = observer(
  ({ text, createdBy, createdAt, chatId, index, fileType }) => {
    const usersStore = useContext(UsersContext);
    const loggedInUser = usersStore.getLoggedInUser();
    const chatsStore = useContext(ChatsContext);
    const thisChat = chatsStore.individualChats.find(({ id }) => id === chatId);

    const participantsIds = thisChat.participants;
    const otherParticipant = usersStore.getUserById(
      participantsIds.filter((id) => id !== loggedInUser.id)[0]
    ) || { displayName: 'User not found' };

    const formattedDate = formatDate(createdAt);
    const isOwn = loggedInUser.id === createdBy;

    const textPreview = `${isOwn ? 'Me: ' : ''}${text ? text : 'uploaded '}${
      !text && fileType === 'image'
        ? 'an image'
        : !text && fileType === 'video'
        ? 'a video'
        : !text && fileType === 'audio'
        ? 'a voice note'
        : ''
    }`;
    const unreadCount = thisChat.getUnreadCount(usersStore.loggedInUserId);
    return (
      <CSS.MessagePreview
        isStaff={['teacher', 'admin'].includes(otherParticipant.role)}
      >
        <CSS.FullWidthUnreadCount
          badgeContent={unreadCount}
          data-testid={`unread-count-${chatId}`}
        >
          <CSS.MessagePreviewLink
            to={`/messages/${chatId}`}
            data-testid={`message-preview-${index}`}
          >
            <CSS.ParticipantProfileImage
              src={otherParticipant.imageURL}
              alt={otherParticipant.displayName || 'Chat Participant'}
            />
            <CSS.MessagePreviewCreatedAt>
              {createdAt && formattedDate}
            </CSS.MessagePreviewCreatedAt>
            <CSS.ParticipantDisplayName>
              {otherParticipant.displayName || 'Chat Participant'}
            </CSS.ParticipantDisplayName>

            <CSS.TextPreview>{textPreview}</CSS.TextPreview>
          </CSS.MessagePreviewLink>
        </CSS.FullWidthUnreadCount>
      </CSS.MessagePreview>
    );
  }
);

export default MessagePreview;
