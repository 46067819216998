import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import { StaffSubHeader } from '../../common/elements/Staff';

export const AssessmentLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const AssessmentTitle = styled(StaffSubHeader)`
  padding: 0;
`;

export const AssessmentInfo = styled.p`
  font-size: 1.6rem;
`;

export * from '../../common/elements/Staff';
