import firebase from 'firebase/app';
import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, observer, useLocalStore } from 'mobx-react';
import React, { useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { AlertContext } from '../../contexts/Alert.js';
import { ChatsContext } from '../../contexts/Chats.js';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users.js';
import { db, storage } from '../../firebase';
import Loading from '../common/Loading.js';
import Message from './Message';
import * as CSS from './elements/MessageForm';

const messageSchema = yup.object().shape({
  input: yup.string().test(function (value) {
    const { file } = this.parent;
    if (!file) return value !== '';
    return true;
  }),
  file: yup
    .mixed()
    .test('file', 'Max file size: 200mb', (value) => {
      if (!value) return true;
      return value?.size <= 200 * 1024 * 1024;
    })
    .nullable(),
});

const MessageForm = observer(
  ({
    chatId,
    chatCollection,
    isStaff,
    taskId,
    targetId,
    moduleId,
    renderLinkInput,
    userToAddTo,
  }) => {
    const usersStore = useContext(UsersContext);
    const chatsStore = useContext(ChatsContext);
    const topicsStore = useContext(TopicsContext);

    const loggedInUser = usersStore.getLoggedInUser();
    const alertStore = useContext(AlertContext);
    const linkStore = useLocalStore(() => ({
      isLinkInputVisible: false,
      setIsLinkInputVisible(isVisible) {
        linkStore.isLinkInputVisible = isVisible;
      },
    }));
    const audioStore = useLocalStore(() => ({
      recorder: null,
      isRecording: false,
      startRecording(setFieldValue, setTouched) {
        return navigator.mediaDevices
          .getUserMedia({
            audio: {
              echoCancellation: false,
              autoGainControl: false,
              noiseSuppression: false,
            },
          })
          .then((stream) => {
            audioStore.isRecording = true;
            const recorder = new MediaRecorder(stream);
            recorder.addEventListener('dataavailable', (e) => {
              const blob = e.data;
              const audioFile = new File([blob], `audio-${Date.now()}`, {
                type: blob.type,
              });
              setFieldValue('file', audioFile);
              setTouched({ file: true });
              handleFilePreview(setFieldValue, audioFile);
            });
            recorder.start();
            audioStore.recorder = recorder;
          })
          .catch((err) => {
            if (
              err.message === 'Permission denied' ||
              err.name === 'NotAllowedError'
            ) {
              alertStore.setText({
                heading: 'Warning',
                text: 'Please enable microphone access',
                isWarning: true,
              });
              alertStore.open();
            } else {
              throw err;
            }
          });
      },
      stopRecording() {
        audioStore.recorder.stop();
        audioStore.recorder.stream.getTracks().forEach((i) => i.stop());
        audioStore.isRecording = false;
      },
    }));

    const selectedChat =
      chatId && chatCollection
        ? chatsStore.getChatById(chatId, chatCollection)
        : null;

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isSafari =
      /Safari/i.test(navigator.userAgent) &&
      !/AlohaBrowser|Android|Brave|Chrome|CrIOS|diigobrowser|DuckDuckGo|EdgiOS|Firefox|FxiOS|Google|KodeiOS|TansoDL/i.test(
        navigator.userAgent
      );

    const imageVideoRef = useRef(null);
    const formRef = useRef(null);

    const handleImageVideoChange = (setFieldValue, file) => {
      setFieldValue('file', file);
    };

    const handleFilePreview = (setFieldValue, file) => {
      if (!file) return;
      let reader = new FileReader();
      const match = file.type.match(/^(?<fileType>\w+)\/.*/);
      const { fileType } = match?.groups;
      reader.onloadend = (e) => {
        setFieldValue('filePreview', reader.result);
        setFieldValue('fileType', fileType);
      };
      reader.readAsDataURL(file);
    };

    const handleSubmit = async ({ input, file, link }, actions) => {
      const newMessage = {
        text: input,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      };
      if (file) {
        const match = file.type.match(/^(?<fileType>\w+)\/.*/);
        const { fileType } = match.groups;
        const storageRef = storage.ref();
        const filePath = `users/${loggedInUser.id}/${file.name}${
          fileType === 'audio' ? '.mp3' : '-' + Date.now()
        }`;
        const fileRef = storageRef.child(filePath);
        await fileRef.put(file);

        const fileURL = await fileRef.getDownloadURL();
        newMessage.file = fileURL;
        newMessage.filePath = filePath;
        newMessage.fileType = fileType;
      }

      if (link) {
        newMessage.link = link;
        if (!input) newMessage.text = link;
      }

      actions.setFieldValue('file', null);
      actions.setFieldValue('input', '');
      actions.setFieldValue('link', '');
      linkStore.setIsLinkInputVisible(false);
      actions.resetForm();
      if (imageVideoRef.current) {
        imageVideoRef.current.value = '';
      }

      if (chatId) {
        newMessage.createdBy = db.collection('users').doc(loggedInUser.id);
        await db
          .collection(chatCollection)
          .doc(chatId)
          .collection('messages')
          .add(newMessage);
      } else if (taskId) {
        const evidenceId = uuidv4();
        const newEvidence = { ...newMessage, evidenceId };
        const userToEvidence = userToAddTo || loggedInUser;
        await userToEvidence.private.addTaskEvidence(
          taskId,
          newEvidence,
          topicsStore,
          moduleId
        );
      } else if (targetId) {
        const evidenceId = uuidv4();
        const userToEvidence = userToAddTo || loggedInUser;
        const index =
          userToEvidence.private.getMaxEvidenceIndexByTargetId(targetId) + 1;
        const newEvidence = { ...newMessage, evidenceId, index };
        await db
          .collection('usersPrivate')
          .doc(userToEvidence.id)
          .update({
            [`personalTargets.${targetId}.evidence.${evidenceId}`]: newEvidence,
          });
      }
    };
    return selectedChat?.locked ? (
      <CSS.LockedForm $isStaff={isStaff}>
        <CSS.LockIcon data-testid="MessageForm-lockIcon" />
      </CSS.LockedForm>
    ) : (
      <Formik
        initialValues={{ input: '', file: null, filePreview: null, link: '' }}
        validationSchema={messageSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting, setTouched, errors }) => {
          const match = values.file?.type.match(/^(?<fileType>\w+)\/.*/);
          const fileType = match?.groups.fileType;
          return (
            <Observer>
              {() => (
                <CSS.Form
                  data-testid="MessageForm"
                  $isStaff={isStaff}
                  ref={formRef}
                  $renderLinkInput={renderLinkInput}
                >
                  <CSS.CameraLabel
                    htmlFor="MessageForm-upload-file"
                    fileSelected={
                      (!!values.file && fileType === 'image') ||
                      fileType === 'video'
                    }
                  >
                    <CSS.CameraIcon />
                  </CSS.CameraLabel>
                  <CSS.HiddenImageInput
                    ref={imageVideoRef}
                    aria-label="upload-file"
                    id="MessageForm-upload-file"
                    name="upload-file"
                    type="file"
                    accept="image/*, video/*"
                    data-testid={
                      values.file
                        ? 'MessageForm-ImageVideoInput-selected'
                        : 'MessageForm-ImageVideoInput'
                    }
                    onClick={() => {
                      if (navigator.mediaDevices) {
                        navigator.mediaDevices
                          .getUserMedia({ video: true })
                          .then((stream) => {
                            stream
                              .getTracks()
                              .forEach((stream) => stream.stop());
                          })
                          .catch(() => {
                            alertStore.setText({
                              heading: 'Warning',
                              text: 'Please enable camera access',
                              isWarning: true,
                            });
                            alertStore.open();
                          });
                      }
                    }}
                    onChange={(e) => {
                      setTouched({ file: true }, true);
                      handleImageVideoChange(setFieldValue, e.target.files[0]);
                      handleFilePreview(setFieldValue, e.target.files[0]);
                    }}
                  />
                  <Field
                    name="input"
                    id="input"
                    type="text"
                    aria-label="Message"
                    rowsMax={3}
                    component={CSS.MessageTextArea}
                    placeholder="Write something..."
                    autoComplete="off"
                  />
                  {renderLinkInput && (
                    <CSS.AttachLinkButton
                      type="button"
                      aria-label="Attach link"
                      onClick={() => linkStore.setIsLinkInputVisible(true)}
                    >
                      <CSS.LinkIcon />
                    </CSS.AttachLinkButton>
                  )}
                  {audioStore.isRecording ? (
                    <CSS.RecordButton
                      type="button"
                      data-testid="MessageForm-AudioInput-stop"
                      aria-label="Stop"
                      onClick={audioStore.stopRecording}
                      isRecording={audioStore.isRecording}
                    >
                      <CSS.StopIcon />
                    </CSS.RecordButton>
                  ) : (
                    <CSS.RecordButton
                      type="button"
                      data-testid="MessageForm-AudioInput-start"
                      aria-label="Record"
                      onClick={
                        isIOS && !isSafari
                          ? () => {
                              alertStore.setText({
                                heading: 'Warning',
                                text:
                                  'Voice recording on iOS is only available in Safari',
                                isWarning: true,
                              });
                              alertStore.open();
                            }
                          : () =>
                              audioStore.startRecording(
                                setFieldValue,
                                setTouched
                              )
                      }
                      fileSelected={!!values.file && fileType === 'audio'}
                    >
                      <CSS.MicIcon />
                    </CSS.RecordButton>
                  )}
                  <CSS.MessageSubmitButton
                    type="submit"
                    data-testid="message-submit"
                    disabled={
                      isSubmitting ||
                      Object.values(errors).length ||
                      (!values.input && !values.file)
                    }
                    aria-label="Send"
                  >
                    {isSubmitting ? <Loading /> : <CSS.SendIcon />}
                  </CSS.MessageSubmitButton>
                  {values.filePreview && (
                    <CSS.MessagePreview
                      data-testid={`MessageForm-preview-${values.fileType}`}
                      isStaff={isStaff}
                      isLinkInputVisible={linkStore.isLinkInputVisible}
                      inLine={!!userToAddTo}
                    >
                      <>
                        {errors.file && (
                          <CSS.CloseErrorButton
                            onClick={() => {
                              setFieldValue('filePreview', null);
                              setFieldValue('file', null);
                              if (imageVideoRef.current) {
                                imageVideoRef.current.value = '';
                              }
                            }}
                          >
                            <CSS.XIcon />
                          </CSS.CloseErrorButton>
                        )}
                        <ErrorMessage
                          name="file"
                          component={CSS.FileError}
                          isClosable
                        />
                      </>
                      {!errors.file && (
                        <CSS.MessageContainer>
                          <Message
                            message={{
                              text: values.input,
                              createdBy: loggedInUser.id,
                              file: values.filePreview,
                              fileType: values.fileType,
                            }}
                            onDelete={() => {
                              setFieldValue('filePreview', null);
                              setFieldValue('file', null);
                              if (imageVideoRef.current) {
                                imageVideoRef.current.value = '';
                              }
                            }}
                            isPreview
                          />
                        </CSS.MessageContainer>
                      )}
                    </CSS.MessagePreview>
                  )}
                  {linkStore.isLinkInputVisible && (
                    <CSS.LinkInputContainer isStaff={isStaff}>
                      <CSS.CloseErrorButton
                        type="button"
                        onClick={() => {
                          linkStore.setIsLinkInputVisible(false);
                          setFieldValue('link', '');
                        }}
                      >
                        <CSS.XIcon />
                      </CSS.CloseErrorButton>
                      <Field
                        name="link"
                        id="link"
                        type="text"
                        aria-label="Link"
                        component={CSS.LinkInput}
                        placeholder="Paste link here..."
                        autoComplete="off"
                      />
                    </CSS.LinkInputContainer>
                  )}
                </CSS.Form>
              )}
            </Observer>
          );
        }}
      </Formik>
    );
  }
);

export default MessageForm;
