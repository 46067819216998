import { types } from 'mobx-state-tree';
import { db } from '../firebase';
import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicies = types
  .model('PrivacyPolicies', {
    privacyPolicies: types.map(PrivacyPolicy),
  })
  .actions((self) => ({
    listenToAllPrivacyPolicies() {
      return db.collection('privacyPolicies').onSnapshot(self.handleSnapshot);
    },
    handleSnapshot(querySnapshot) {
      querySnapshot.docChanges().forEach(({ doc, type }) => {
        const { createdAt, ...restOfPolicy } = doc.data();
        const formattedDate = createdAt.toDate();
        const policy = { ...restOfPolicy, createdAt: formattedDate };
        if (type === 'added') {
          const newPrivacyPolicy = PrivacyPolicy.create({
            id: doc.id,
            ...policy,
          });
          self.privacyPolicies.set(doc.id, newPrivacyPolicy);
        } else if (type === 'modified') {
          const existingPrivacyPolicy = self.privacyPolicies.get(doc.id);
          existingPrivacyPolicy.updateInfo(policy);
        } else if (type === 'removed') {
          self.privacyPolicies.delete(doc.id);
        }
      });
    },
  }))
  .views((self) => ({
    getPolicies() {
      return [...self.privacyPolicies.values()].sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      );
    },
    getPolicyById(id) {
      return self.privacyPolicies.get(id);
    },
  }));

export default PrivacyPolicies;
