import { Router } from '@reach/router';
import { observer } from 'mobx-react';
import React from 'react';
import Categories from '../admin/Categories';
import LearningAssessment from '../admin/LearningAssessment';
import LearningAssessments from '../admin/LearningAssessments';
import PrivacyPolicies from '../admin/PrivacyPolicies';
import Reports from '../admin/Reports';
import * as CSS from './elements/AdminAuth';

const AdminAuth = observer(() => {
  return (
    <CSS.StaffContainer data-testid="AdminAuth">
      <CSS.StaffRow>
        <CSS.StaffHeader>Admin</CSS.StaffHeader>
      </CSS.StaffRow>
      <CSS.AdminNav>
        <CSS.StaffFilterLink to="/staff/admin/learningPlans" isfirst>
          Learning Plans
        </CSS.StaffFilterLink>
        <CSS.StaffFilterLink to="/staff/admin/reports">
          Reports
        </CSS.StaffFilterLink>
        <CSS.StaffFilterLink to="/staff/admin/categories">
          Categories
        </CSS.StaffFilterLink>
        <CSS.StaffFilterLink to="/staff/admin/privacy">
          Privacy Policy
        </CSS.StaffFilterLink>
      </CSS.AdminNav>
      <Router>
        <LearningAssessments path="/learningPlans" />
        <LearningAssessment path="/learningPlans/:id/:questionSet" />
        <Reports path="/reports" />
        <PrivacyPolicies path="/privacy" />
        <Categories path="/categories" />
      </Router>
    </CSS.StaffContainer>
  );
});

export default AdminAuth;
