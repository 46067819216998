import styled from 'styled-components/macro';
import * as StaffCommon from '../../common/elements/Staff';

export const DoubleHeaderGroup = styled(StaffCommon.StaffHeaderGroup)`
  flex-direction: column;
  align-items: flex-start;
`;

export const TopicSubHeader = styled.h3`
  margin: 0;
  padding-left: 1rem;
  color: ${({ theme }) => theme.subHeaderColour};
`;

export const ModuleSubHeader = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.subHeaderColour};
  margin: 0;
  padding-left: 1rem;
`;

export * from '../../common/elements/Staff.js';
