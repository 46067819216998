import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users';
import CheckSVG from '../../svgs/CheckSVG.js';
import BrokenLinkIcon from '../../svgs/image-placeholder.svg';
import Error from '../common/Error.js';
import * as CSS from './elements/LearningResource';

const urlValidationSchema = yup.string().url('Invalid URL').required();

const LearningResource = observer(({ moduleId, topicId, resourceId }) => {
  const topicsStore = useContext(TopicsContext);
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();

  const module = topicsStore.getModuleById(moduleId);

  if (!module) return <Error msg="Module not found" />;

  const resource = module.getItemById(resourceId, 'learningResources');
  if (!resource) return <Error msg="Resource not found" />;

  const urlIsValid = urlValidationSchema.isValidSync(resource.embedLink);

  const nextResource = module.getOffsetResource(resource.index, 1);
  const previousResource = module.getOffsetResource(resource.index, -1);

  const complete = loggedInUser.private?.isResourceComplete(resource.id);

  const toggleComplete = () => {
    const newStatus = loggedInUser.private.isResourceComplete(resource.id)
      ? null
      : 'completed';
    loggedInUser.private.setResourceStatus(resource, newStatus);
  };

  return (
    <CSS.ResourceContainer data-testid="LearningResource">
      <CSS.ResourceColumn>
        <CSS.ModuleHeader>{module.moduleName}</CSS.ModuleHeader>
        <CSS.Instructions>{resource.instructions}</CSS.Instructions>
        <CSS.EmbedFrame
          data-testid="LearningResource-embedded"
          src={urlIsValid ? resource.embedLink : BrokenLinkIcon}
          title={resource.instructions}
          allowFullScreen
          allowtransparency
          loading="lazy"
        >
          <p>Your browser does not support iframes.</p>
        </CSS.EmbedFrame>
      </CSS.ResourceColumn>
      <CSS.NavRow>
        <CSS.NavLink
          aria-label="back"
          to={`/topics/${topicId}/modules/${moduleId}/${
            previousResource ? `learning/${previousResource.id}` : 'learning'
          }`}
        >
          <CSS.BackArrowIcon />
        </CSS.NavLink>
        <CSS.BorderlessButton
          data-testid="LearningResource-complete"
          onClick={toggleComplete}
          aria-label="toggle resource complete"
        >
          <CheckSVG complete={complete} id={resource.id} />
        </CSS.BorderlessButton>
        <CSS.NavLink
          $lightText
          aria-label={nextResource && 'next'}
          to={`/topics/${topicId}/modules/${moduleId}/${
            nextResource ? `learning/${nextResource.id}` : 'tasks'
          }`}
        >
          {nextResource ? <CSS.NextArrowIcon /> : 'Tasks'}
        </CSS.NavLink>
      </CSS.NavRow>
    </CSS.ResourceContainer>
  );
});

export default LearningResource;
