import { types } from 'mobx-state-tree';
import { db } from '../firebase/index.js';
import { addFieldsToReport } from '../utils/reports.js';
import Topic from './Topic.js';

const Topics = types
  .model('Topics', {
    topics: types.array(Topic),
  })
  .volatile(() => ({
    listeners: [],
  }))
  .actions((self) => {
    return {
      listenToTopics(categoryId) {
        return db
          .collection('topics')
          .where('category', '==', categoryId)
          .onSnapshot(self.handleTopicChange);
      },
      handleTopicChange(querySnapshot) {
        querySnapshot.docChanges().forEach(({ doc, type }) => {
          if (type === 'added') {
            const newTopic = Topic.create({ id: doc.id, ...doc.data() });
            const moduleUnSub = newTopic.listenToModules();
            self.topics.push(newTopic);
            self.addUnSub(moduleUnSub);
          } else if (type === 'modified') {
            const existingTopic = self.topics.find(({ id }) => id === doc.id);
            existingTopic.setTopicInfo(doc.data());
          } else if (type === 'removed') {
            const existingTopic = self.topics.find(({ id }) => id === doc.id);
            self.topics.remove(existingTopic);
          }
        });
      },
      addUnSub(unsubscribe) {
        self.listeners.push(unsubscribe);
      },
      unsubscribeFromAll() {
        self.listeners.forEach((unsubscribe) => {
          if (typeof unsubscribe === 'function') unsubscribe();
        });
      },
      logout() {
        self.topics = [];
      },
      clearTopics() {
        self.unsubscribeFromAll();
        self.logout();
      },
      fetchSharedModules(selectedCategoryId) {
        return db
          .collection('topics')
          .get()
          .then((topicsSnap) => {
            const modulePromises = topicsSnap.docs.map((topicDoc) => {
              return db
                .collection('topics')
                .doc(topicDoc.id)
                .collection('modules')
                .where(
                  `categories.${selectedCategoryId}`,
                  '==',
                  db.collection('categories').doc(selectedCategoryId)
                )
                .get();
            });
            return Promise.all([topicsSnap, ...modulePromises]);
          })
          .then(([topicsSnap, ...nestedModulesSnap]) => {
            return nestedModulesSnap
              .map((nestedModuleSnap, i) =>
                nestedModuleSnap.docs.map((moduleDoc) => {
                  const topic = {
                    id: topicsSnap.docs[i].id,
                    ...topicsSnap.docs[i].data(),
                  };
                  return { id: moduleDoc.id, ...moduleDoc.data(), topic };
                })
              )
              .flat();
          });
      },
    };
  })
  .views((self) => ({
    getModuleById(moduleId) {
      let module;
      self.topics.forEach((topic) => {
        const potentialModule = topic.modules.find(
          (module) => module.id === moduleId
        );
        if (potentialModule) module = potentialModule;
      });
      return module;
    },
    getTopicById(topicId) {
      return self.topics.find(({ id }) => topicId === id);
    },
    getOrderedTopics() {
      return self.topics.slice().sort((a, b) => {
        if (a.subject === b.subject) {
          return a.topicName.localeCompare(b.topicName);
        }
        return a.subject.localeCompare(b.subject);
      });
    },
    filterTopicsById(filterId) {
      return filterId
        ? self.getOrderedTopics().filter(({ id }) => filterId === id)
        : self.getOrderedTopics();
    },
    getChatModules(groupChatModules, groupChatTopics) {
      const chatModules = self.topics.reduce((acc, topic) => {
        topic.modules.forEach((module) => {
          if (groupChatModules.has(module.id)) {
            if (acc[topic.id]) {
              acc[topic.id].push(module);
            } else acc[topic.id] = [module];
          }
        });
        if (acc[topic.id]) {
          acc[topic.id].sort((a, b) => {
            const aIndex = groupChatModules.get(a.id)?.index;
            const bIndex = groupChatModules.get(b.id)?.index;
            return aIndex - bIndex;
          });
        }
        return acc;
      }, {});
      return Object.entries(chatModules)
        .map(([topicId, topicsModules]) => [
          self.getTopicById(topicId),
          topicsModules,
        ])
        .sort(([topicA], [topicB]) => {
          const groupChatTopicA = groupChatTopics.get(topicA.id);
          const groupChatTopicB = groupChatTopics.get(topicB.id);
          if (
            groupChatTopicA?.hasOwnProperty('index') &&
            groupChatTopicB?.hasOwnProperty('index')
          ) {
            return groupChatTopicA.index - groupChatTopicB.index;
          }
          return topicA.topicName.localeCompare(topicB.topicName);
        });
    },
    getChatModulesAsArray(groupChatModules) {
      return [...groupChatModules.values()]
        .reduce((acc, { module: moduleId, current, active }) => {
          const module = self.getModuleById(moduleId);
          if (module) {
            acc.push({ module, current, active });
          }
          return acc;
        }, [])
        .sort((a, b) => {
          const aTopicName = a.module.getParentTopic().topicName;
          const bTopicName = b.module.getParentTopic().topicName;
          if (aTopicName === bTopicName) {
            return a.module.moduleName.localeCompare(b.module.moduleName);
          }
          return aTopicName.localeCompare(bTopicName);
        });
    },
    getAvailableChatModules(groupChatModules) {
      const chatModules = self.topics.reduce((acc, topic) => {
        topic.modules.forEach((module) => {
          if (!groupChatModules.has(module.id)) {
            acc.push({
              ...module,
              topicName: topic.topicName,
              topicId: topic.id,
            });
          }
        });
        return acc;
      }, []);
      return chatModules;
    },
    getJSONReport() {
      const report = {
        fields: ['id', 'topicName'],
        data: [],
      };
      self.topics.forEach(({ id, topicName, modules }) => {
        const topicData = [id, topicName];
        modules.forEach((module, i) => {
          addFieldsToReport({
            report,
            objName: 'module',
            reportedFields: ['id', 'moduleName'],
            obj: module,
            objIndex: i,
            data: topicData,
          });
          module.tasks.forEach((task, taskIndex) => {
            addFieldsToReport({
              report,
              objName: `module.${i}.task`,
              reportedFields: ['instructions'],
              obj: task,
              objIndex: taskIndex,
              data: topicData,
            });
          });
          module.learningResources.forEach((resource) => {
            addFieldsToReport({
              report,
              objName: `module.${i}.learningResource`,
              reportedFields: ['instructions', 'embedLink'],
              obj: resource,
              objIndex: resource.index,
              data: topicData,
            });
          });
        });
        report.data.push(topicData);
      });
      return report;
    },
    getSharedModules(selectedCategoryId, categoriesStore) {
      return self.topics
        .reduce((sharedModules, topic) => {
          topic.modules.forEach((module) => {
            if (module.categories.has(selectedCategoryId)) {
              sharedModules.push(module);
            }
          });
          return sharedModules;
        }, [])
        .sort((a, b) => {
          const aCategory = categoriesStore.getCategoryById(
            a.getParentTopic().category
          );
          const bCategory = categoriesStore.getCategoryById(
            b.getParentTopic().category
          );
          if (aCategory.categoryName === bCategory.categoryName) {
            return a.moduleName.localeCompare(b.moduleName);
          }
          return aCategory.categoryName.localeCompare(bCategory.categoryName);
        });
    },
    getOrderedSubjects(selectedCategoryId) {
      return self.topics
        .reduce((subjects, topic) => {
          if (
            !subjects.includes(topic.subject) &&
            topic.category === selectedCategoryId
          ) {
            subjects.push(topic.subject);
          }
          return subjects;
        }, [])
        .sort((a, b) => a.localeCompare(b));
    },
  }));

export default Topics;
