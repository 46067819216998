import { Redirect, Router, useLocation } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import Error from '../common/Error';
import Loading from '../common/Loading';
import Progress from './Progress';
import StaffStudentAssessment from './StaffStudentAssessment';
import StaffStudentAssessments from './StaffStudentAssessments';
import UserPersonal from './UserPersonal';
import * as CSS from './elements/User.js';

const User = observer(({ userId, store }) => {
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const chatsStore = useContext(ChatsContext);
  const topicsStore = useContext(TopicsContext);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setTimedOut(true);
    }, 5000);
    return () => clearTimeout(timerId);
  }, []);

  const location = useLocation();

  const user = usersStore.getUserById(userId);
  if (location.state?.changingCategory && !user && !timedOut)
    return (
      <CSS.StaffContainer>
        <Loading />
      </CSS.StaffContainer>
    );
  if (!user) return <Error msg="User not found" />;

  const handleOpenChat = async () => {
    const individualChatId = user.getIndividualChatByParticipantId(
      loggedInUser,
      chatsStore,
      loggedInUser.selectedCategory
    );

    if (individualChatId) {
      chatsStore.setSelectedChatId(individualChatId);
      chatsStore.setIsSelectedChatVisible(true);
    } else {
      const users = [user];
      const [newChatRef] = await chatsStore.createNewIndividualChat(
        loggedInUser,
        users
      );
      chatsStore.setSelectedChatId(newChatRef.id);
      chatsStore.setIsSelectedChatVisible(true);
    }
  };

  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const isStudent = user.role === 'student';

  const totalProgressNotifications = user.private.getTotalProgressNotifications(
    loggedInUser.selectedCategory,
    topicsStore
  );

  const currentAssessmentUnCheckedCount = user?.private.getTotalAssessmentNotifications(
    loggedInUser
  );

  return (
    <CSS.StaffContainer>
      <CSS.StaffRow>
        <CSS.UserInfoSection>
          <CSS.StaffUserHeader>{user.displayName}</CSS.StaffUserHeader>
          {user.private.accountLocked && <CSS.LockIcon></CSS.LockIcon>}
          <CSS.StaffUserProfileImage
            src={user.imageURL}
            alt={user.displayName}
            isChrome={isChrome}
          />
        </CSS.UserInfoSection>
        <CSS.StaffRowFill>
          <CSS.ChatButton onClick={handleOpenChat}>Chat</CSS.ChatButton>
        </CSS.StaffRowFill>
      </CSS.StaffRow>

      {user.private.accountLocked && loggedInUser.role !== 'admin' && (
        <CSS.StaffSubHeader style={{ padding: 0 }}>
          Account is locked. Please contact an admin to restore access
        </CSS.StaffSubHeader>
      )}

      <CSS.StaffRow>
        <nav>
          <CSS.UnreadCount badgeContent={0}>
            <CSS.StaffFilterLink to="personal" isfirst>
              Personal
            </CSS.StaffFilterLink>
          </CSS.UnreadCount>
          {isStudent && (
            <CSS.UnreadCount
              badgeContent={totalProgressNotifications}
              data-testid={`uncommented-count-progress`}
            >
              <CSS.StaffFilterLink to="progress">Progress</CSS.StaffFilterLink>
            </CSS.UnreadCount>
          )}
          {isStudent && (
            <CSS.UnreadCount
              badgeContent={currentAssessmentUnCheckedCount}
              data-testid="unread-count-assessmentsLink"
            >
              <CSS.StaffFilterLink to="learningPlans">
                Learning Plans
              </CSS.StaffFilterLink>
            </CSS.UnreadCount>
          )}
        </nav>
      </CSS.StaffRow>
      <Router primary={false}>
        <UserPersonal path="/personal" userId={userId} />
        {isStudent && (
          <>
            <Redirect from="/learningPlan" to="start" noThrow />
            <StaffStudentAssessments path="/learningPlans" studentId={userId} />
            <StaffStudentAssessment
              path="/learningPlans/:id/:questionSet"
              studentId={userId}
            />
          </>
        )}
        {isStudent && (
          <Progress path="/progress/*" studentId={userId} store={store} />
        )}
      </Router>
    </CSS.StaffContainer>
  );
});

export default User;
