import elementResizeEvent, { unbind } from 'element-resize-event';
import { observer } from 'mobx-react-lite';
import React, { useContext, useLayoutEffect, useRef } from 'react';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import Loading from '../common/Loading.js';
import * as CSS from './elements/DirectMessageBoard.js';

const DirectMessageBoard = observer(
  ({ whiteboardStore, chatId, chatCollection }) => {
    const whiteboardRef = useRef(null);
    const chatsStore = useContext(ChatsContext);
    const usersStore = useContext(UsersContext);
    const loggedInUser = usersStore.getLoggedInUser();

    useLayoutEffect(() => {
      if (whiteboardRef.current) {
        const whiteboardElement = whiteboardRef.current;
        whiteboardStore.height = whiteboardElement.clientHeight;
        elementResizeEvent(whiteboardElement, function () {
          whiteboardStore.height = whiteboardElement.clientHeight;
        });
        return () => unbind(whiteboardElement);
      }
    }, [whiteboardStore.height]);

    const thisChat = chatsStore[chatCollection].find(
      (chat) => chat.id === chatId
    );

    if (!thisChat?.participants.length)
      return (
        <CSS.DirectMessageWhiteboard ref={whiteboardRef}>
          <Loading />
        </CSS.DirectMessageWhiteboard>
      );

    const [participantId] = thisChat.participants.filter(
      (participant) => participant !== loggedInUser.id
    );

    const participant = usersStore.getUserById(participantId) || {
      displayName: 'User not found',
    };

    return (
      <CSS.DirectMessageWhiteboard ref={whiteboardRef}>
        <CSS.ParticipantProfileImage
          src={participant.imageURL}
          alt={participant.displayName}
          isStaff={['teacher', 'admin'].includes(participant.role)}
        />
        <CSS.ParticipantName>{participant.displayName}</CSS.ParticipantName>
      </CSS.DirectMessageWhiteboard>
    );
  }
);

export default DirectMessageBoard;
