import { Link } from '@reach/router';
import { ErrorMessage, Field, Formik } from 'formik';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import * as api from '../../api.js';
import { UsersContext } from '../../contexts/Users.js';
import { db } from '../../firebase/index.js';
import { ReactComponent as Padlock } from '../../svgs/padlock.svg';
import Loading from '../common/Loading.js';
import * as CSS from './elements/CreateUserForm';

const resetSchema = yup.object().shape({
  phoneNumber: yup.string().required('Please enter your phone number'),
  pin: yup
    .string()
    .min(6, 'PINs must be numeric and have a minimum length of 6 digits')
    .matches(/^[\d]*$/g, 'PINs can only contain numbers')
    .required('Please choose a new PIN'),
  confirmNewPin: yup
    .string()
    .min(6, 'PINs must be numeric and have a minimum length of 6 digits')
    .matches(/^[\d]*$/g, 'PINs can only contain numbers')
    .oneOf([yup.ref('pin'), null], 'PINs must match')
    .required('Please confirm your PIN'),
});

const ResetPin = observer(({ resetCode, resetRequired }) => {
  const usersStore = useContext(UsersContext);

  const store = useLocalStore(() => ({
    hasReset: false,
    token: null,
  }));

  const handleSubmit = async (
    { phoneNumber, pin },
    { setFieldError, resetForm }
  ) => {
    if (resetRequired) {
      const userPrivateDoc = await db
        .collection('usersPrivate')
        .doc(usersStore.loggedInUserId)
        .get();
      if (userPrivateDoc.data().resetRequired) {
        resetCode = userPrivateDoc.data().resetCode;
      }
    }

    return api
      .resetPin({ phoneNumber, pin, resetCode })
      .then(() => {
        resetForm();
        store.hasReset = true;
        if (store.token) {
          localStorage.setItem('token', store.token);
        }
      })
      .catch((err) => {
        setFieldError('serverError', err.response?.data.msg);
      });
  };

  const handleInvalid = (e, submitForm) => {
    e.preventDefault();
    submitForm();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      store.token = token;
      localStorage.removeItem('token');
    }
  }, [store]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phoneNumber: resetRequired
          ? usersStore.getLoggedInUser()?.private?.phoneNumber
          : '',
        pin: '',
        confirmNewPin: '',
        serverError: null,
      }}
      validationSchema={resetSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <Observer>
          {() => (
            <CSS.ResetForm data-testid="ResetPin">
              <Padlock />
              {!resetRequired && (
                <CSS.BackLink to="/" aria-label="back">
                  <CSS.BigArrowBackIcon />
                </CSS.BackLink>
              )}
              {store.hasReset ? (
                <CSS.Success>
                  <CSS.FormHeader>PIN Reset OK</CSS.FormHeader>
                  <Link to="/">
                    <CSS.SignUpButton>Login</CSS.SignUpButton>
                  </Link>
                </CSS.Success>
              ) : (
                <>
                  <CSS.FieldGroup>
                    <CSS.FormHeader>Choose a new PIN</CSS.FormHeader>
                    <Field
                      type="text"
                      placeholder="07123456789"
                      name="phoneNumber"
                      autoComplete="tel"
                      label="Phone Number"
                      component={CSS.StaffTextFieldInput}
                      id="phoneNumber"
                      inputMode="numeric"
                      onInvalid={(e) => handleInvalid(e, submitForm)}
                    />
                    <ErrorMessage name="phoneNumber" component="p" />
                  </CSS.FieldGroup>
                  <CSS.FieldGroup>
                    <Field
                      type="password"
                      placeholder="000000"
                      name="pin"
                      autoComplete="new-password"
                      label="PIN"
                      component={CSS.StaffTextFieldInput}
                      id="pin"
                      inputMode="numeric"
                      onInvalid={(e) => handleInvalid(e, submitForm)}
                    />
                    <ErrorMessage name="pin" component="p" />
                  </CSS.FieldGroup>
                  <CSS.FieldGroup>
                    <Field
                      type="password"
                      placeholder="000000"
                      name="confirmNewPin"
                      autoComplete="new-password"
                      label="Confirm PIN"
                      component={CSS.StaffTextFieldInput}
                      id="confirmNewPin"
                      inputMode="numeric"
                      onInvalid={(e) => handleInvalid(e, submitForm)}
                    />
                    <ErrorMessage name="confirmNewPin" component="p" />
                  </CSS.FieldGroup>
                  <ErrorMessage name="serverError" component="p" />
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <CSS.SignUpButton
                      data-testid="RequestReset-submit"
                      type="submit"
                    >
                      OK
                    </CSS.SignUpButton>
                  )}
                </>
              )}
            </CSS.ResetForm>
          )}
        </Observer>
      )}
    </Formik>
  );
});

export default ResetPin;
