import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from '../../../contexts/Theme';
import { ItemLink, StaffRow } from '../../common/elements/Staff';
import { Button } from './Form';

export const QuestionList = styled.ol`
  padding: 0;
  list-style-type: none;
`;

export const QuestionLi = styled.li`
  display: grid;
  grid-template-columns: min-content auto min-content min-content;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    'bullet text check delete'
    '. error . .';
  grid-gap: 0.5em;
  margin: 1em 0;
`;

export const AnswerLi = styled(QuestionLi)`
  grid-template-columns: min-content auto 3.6em;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    'bullet question .'
    '. answer check'
    '. error .';
  grid-gap: 0.5em;
  margin: 1em 0;
`;

export const QuestionBullet = styled(ItemLink).attrs({
  type: 'button',
  isSelected: true,
})`
  grid-area: bullet;
  &:hover {
    cursor: default;
  }
`;

export const ErrorMessage = styled.p`
  grid-area: error;
  margin: 0;
`;

export const TitleError = styled.p`
  margin: 0;
  padding: 0.5em;
  font-size: 1.6em;
`;

export const TitleGroup = styled.div`
  margin-bottom: 1em;
`;

export const AddQuestionButton = styled(Button)`
  background: ${({ theme }) => theme.buttonCreate};
  font-size: 1.6rem;
  font-weight: 800;
  border-radius: 20px;
`;

export const DeleteAssessmentButton = styled(Button)`
  background: ${({ theme }) => theme.buttonDisabled};
  color: ${({ theme }) => theme.textLight};
  font-size: 1.6rem;
  font-weight: 800;
  border-radius: 20px;

  &:hover {
    background: ${({ theme }) => theme.buttonWarningGradient};
  }
  &:disabled {
    background: ${({ theme }) => theme.buttonDisabled};
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
`;

export const AssessmentNavRow = styled(StaffRow)`
  margin-bottom: 3em;
`;

export const TeacherLabel = styled.p`
  grid-area: check;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

export const Check = ({ form, field, text = 'Teacher Only', ...props }) => {
  const theme = useContext(ThemeContext);
  return (
    <Checkbox
      {...field}
      {...props}
      checked={field.value}
      style={{
        color: theme.lessonHighlight,
        height: 'min-content',
        padding: '0.3em 0',
        gridArea: 'check',
      }}
      icon={
        <div>
          <CheckBoxOutlineBlankRoundedIcon style={{ fontSize: '2.5rem' }} />
          <TeacherLabel>{text}</TeacherLabel>
        </div>
      }
      checkedIcon={
        <div>
          <CheckBoxRoundedIcon style={{ fontSize: '2.5rem' }} />
          <TeacherLabel>{text}</TeacherLabel>
        </div>
      }
    />
  );
};

export const CreateAssessmentButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.pendingFill};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms;

  &:hover {
    transition: 150ms;
    cursor: pointer;
    color: ${({ theme }) => theme.textDark};
  }
`;
