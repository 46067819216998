import { FieldArray, Formik } from 'formik';
import { Observer, useLocalStore, useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { CategoriesContext } from '../../contexts/Categories';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/SetModuleForm';

const importSchema = yup.object().shape({});

const ImportModuleForm = React.forwardRef(
  (
    { closeModal, topicId, moduleId, newModule, isImporting, setImporting },
    ref
  ) => {
    const topicsStore = useContext(TopicsContext);
    const usersStore = useContext(UsersContext);
    const categoriesStore = useContext(CategoriesContext);

    const sharedModulesStore = useLocalStore(() => ({
      modules: [],
      setModules(newModules) {
        sharedModulesStore.modules = newModules;
      },
    }));

    useEffect(() => {
      topicsStore
        .fetchSharedModules(usersStore.getLoggedInUser().selectedCategory)
        .then((modules) => {
          sharedModulesStore.setModules(modules);
        });
    }, [sharedModulesStore, topicsStore, usersStore]);

    const initialValues = {
      module: '',
    };

    const handleSubmit = async ({
      module: { id, topic, categories, ...moduleUpdate },
    }) => {
      const moduleUpdateWithCreatedBy = {
        ...moduleUpdate,
        createdBy: usersStore.loggedInUserId,
      };
      const newModuleDoc = await db
        .collection('topics')
        .doc(topicId)
        .collection('modules')
        .add(moduleUpdateWithCreatedBy);
      // learning Resources + Tasks
      const resourcesPromise = db
        .collection('topics')
        .doc(topic.id)
        .collection('modules')
        .doc(id)
        .collection('learningResources')
        .get();
      const tasksPromise = db
        .collection('topics')
        .doc(topic.id)
        .collection('modules')
        .doc(id)
        .collection('tasks')
        .get();

      const [resourceDocs, taskDocs] = await Promise.all([
        resourcesPromise,
        tasksPromise,
      ]);

      const resourceAdditions = resourceDocs.docs?.map((resourceDoc) =>
        db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .doc(newModuleDoc.id)
          .collection('learningResources')
          .add(resourceDoc.data())
      );
      const taskAdditions = taskDocs.docs?.map((taskDoc) =>
        db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .doc(newModuleDoc.id)
          .collection('tasks')
          .add(taskDoc.data())
      );

      await Promise.all([resourceAdditions, taskAdditions]);

      closeModal();
    };

    return useObserver(() => (
      <div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={importSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, dirty, setFieldValue, setTouched }) => (
            <Observer>
              {() => (
                <CSS.Form data-testid="SetModuleForm">
                  <CSS.StaffFormHeaderRow>
                    <CSS.StaffFormHeader>
                      Import Shared module
                    </CSS.StaffFormHeader>
                    <CSS.StaffFormCloseButton
                      onClick={closeModal}
                      type="button"
                    >
                      <CSS.StaffCloseIcon />
                    </CSS.StaffFormCloseButton>
                  </CSS.StaffFormHeaderRow>
                  <FieldArray name="module">
                    {({ form }) => (
                      <Observer>
                        {() => (
                          <CSS.StaffAutocomplete
                            onChange={(event, module, reason) => {
                              form.setFieldValue('module', module);
                            }}
                            options={sharedModulesStore.modules}
                            groupBy={(option) =>
                              categoriesStore.getCategoryById(
                                option.topic.category
                              ).categoryName
                            }
                            getOptionLabel={(module) =>
                              `${module.moduleName} - ${module.topic.topicName}`
                            }
                            renderInput={(params) => (
                              <CSS.StaffAutocompleteInput
                                {...params}
                                label="Type a module name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </Observer>
                    )}
                  </FieldArray>
                  <CSS.ImportButton
                    type="button"
                    onClick={() => setImporting(false)}
                  >
                    Create a new Module
                  </CSS.ImportButton>
                  <CSS.StaffFooterRow>
                    <CSS.StaffNavButton
                      type="submit"
                      disabled={!dirty || Object.values(errors).length}
                    >
                      Finish
                    </CSS.StaffNavButton>
                  </CSS.StaffFooterRow>
                </CSS.Form>
              )}
            </Observer>
          )}
        </Formik>
      </div>
    ));
  }
);

export default ImportModuleForm;
