import { Router as ReachRouter } from '@reach/router';
import styled from 'styled-components/macro';

export const AppContainer = styled.main`
  padding-top: max(8.5vh, 67px);
  font-family: ${({ theme }) => theme.fontFamily};

  @media only screen and (min-width: 700px) {
    padding-top: 0;
    padding-left: max(10vw, 120px);
  }
`;

export const Router = styled(ReachRouter)`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-areas: 'content';
`;
