import { globalHistory, Location } from '@reach/router';
import React, { useContext, useEffect } from 'react';
import { SaveChangesContext } from '../contexts/SaveChanges';
import { updateIcons } from '../utils/publicAssets';
import Auth from './auth/Auth';
import PrivacyPolicy from './auth/PrivacyPolicy';
import StaffAuth from './auth/StaffAuth';
import AlertModal from './common/AlertModal';
import Error from './common/Error';
import Nav from './common/Nav';
import SaveChangesModal from './common/SaveChangesModal';
import * as CSS from './elements/App.js';
import Classroom from './messaging/Classroom';
import DirectMessage from './messaging/DirectMessage';
import Inbox from './messaging/Inbox';
import EditUserProfile from './student/EditUserProfile';
import Home from './student/Home';
import LearningResource from './student/LearningResource';
import Module from './student/Module';
import PersonalTarget from './student/PersonalTarget';
import PersonalTargets from './student/PersonalTargets';
import StudentAssessment from './student/StudentAssessment';

function App() {
  const saveChangesStore = useContext(SaveChangesContext);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      saveChangesStore.setEdited(false);
    });
  }, [saveChangesStore]);

  useEffect(() => {
    updateIcons();
  }, []);

  return (
    <Auth>
      <CSS.AppContainer>
        <Location>
          {({ location }) => <Nav pathname={location.pathname} />}
        </Location>
        <CSS.Router primary={false}>
          <Home path="/" />
          <Classroom path="/classes/:id" />
          <LearningResource path="/topics/:topicId/modules/:moduleId/learning/:resourceId" />
          <Module path="/topics/:topicId/modules/:moduleId/*" />
          <Inbox path="/inbox" />
          <DirectMessage path="/messages/:chatId" />
          <EditUserProfile path="/profile" />
          <PersonalTargets path="/targets" />
          <PersonalTarget path="/targets/:targetId" />
          <StudentAssessment path="/learningPlan/:questionSet" />
          <StaffAuth path="/staff/*" />
          <PrivacyPolicy path="/privacy" />
          <Error default />
        </CSS.Router>
      </CSS.AppContainer>
      <SaveChangesModal />
      <AlertModal />
    </Auth>
  );
}

export default App;
