import axios from 'axios';

const {
  REACT_APP_AUTH_SERVER_URL = 'https://europe-west1-adult-education-service.cloudfunctions.net/authServer/api',
} = process.env;

const instance = axios.create({
  baseURL: REACT_APP_AUTH_SERVER_URL,
});

export const signUpUser = async (user) => {
  const { data } = await instance.post('/users', user);
  return data.user;
};

export const login = async (loginDetails) => {
  const { data } = await instance.post('/users/login', loginDetails);
  return data.token;
};

export const requestPinReset = async (phoneNumber) => {
  const { data } = await instance.post('/users/reset', { phoneNumber });
  return data;
};

export const resetPin = async ({ phoneNumber, pin, resetCode }) => {
  const { data } = await instance.patch('/users/reset', {
    phoneNumber,
    pin,
    resetCode,
  });
  return data;
};
