import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/Theme';

const CheckSVG = observer(({ complete = false, id }) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      data-testid={`CheckSVG-complete-${complete}`}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0-${id})`}>
        <path
          d="M33.6558 0.131836H6.61475C3.03625 0.131836 0.135254 2.96031 0.135254 6.44934V32.8143C0.135254 36.3034 3.03625 39.1318 6.61475 39.1318H33.6558C37.2343 39.1318 40.1353 36.3034 40.1353 32.8143V6.44934C40.1353 2.96031 37.2343 0.131836 33.6558 0.131836Z"
          fill={complete ? theme.lessonHighlight : theme.staffBackground}
        />
        <path
          d="M18.3756 29.2511C17.5541 29.2511 16.7615 28.9446 16.1625 28.3915L7.73321 20.6116C6.44201 19.4199 6.38617 17.4332 7.6084 16.1743C8.83062 14.9153 10.868 14.8606 12.1595 16.0526L18.1901 21.6185L27.9307 11.0541C29.1193 9.76481 31.1548 9.65947 32.4772 10.8181C33.7992 11.9771 33.9075 13.9617 32.7189 15.251L20.7697 28.2106C20.1893 28.8399 19.3729 29.2142 18.5053 29.2484C18.462 29.2502 18.4186 29.2511 18.3756 29.2511Z"
          fill={complete ? theme.appBackground : theme.textDark}
        />
      </g>
      <defs>
        <clipPath id={`clip0-${id}`}>
          <rect
            width="40"
            height="39"
            fill="white"
            transform="translate(0.135254 0.131836)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default CheckSVG;
