import Modal from '@material-ui/core/Modal';
import { navigate } from '@reach/router';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as api from '../../api';
import { AlertContext } from '../../contexts/Alert';
import { CategoriesContext } from '../../contexts/Categories';
import { ChatsContext } from '../../contexts/Chats';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import Error from '../common/Error';
import AddStudentPersonalTargetForm from './AddStudentPersonalTargetForm';
import EditStudentPersonalTargetsForm from './EditStudentPersonalTargetsForm';
import EditUserPersonalInfoForm from './EditUserPersonalInfoForm';
import * as CSS from './elements/UserPersonal';

const UserPersonal = observer(({ userId }) => {
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);
  const categoriesStore = useContext(CategoriesContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const alertStore = useContext(AlertContext);
  const store = useLocalStore(() => ({
    isEditUserInfoModalOpen: false,
    setIsEditUserInfoModalOpen: (isOpen) => {
      store.isEditUserInfoModalOpen = isOpen;
    },
    isEditStudentTargetsModalOpen: false,
    setIsEditStudentTargetsModalOpen: (isOpen) => {
      store.isEditStudentTargetsModalOpen = isOpen;
    },
    isAddStudentTargetModalOpen: false,
    setIsAddStudentTargetModalOpen: (isOpen) => {
      store.isAddStudentTargetModalOpen = isOpen;
    },
  }));
  const loggedInUser = usersStore.getLoggedInUser();
  const user = usersStore.getUserById(userId);

  if (!user) return <Error msg="User not found" />;
  const isStudent = user.role === 'student';

  const groupChats = user.getUsersGroupChats(
    chatsStore,
    usersStore.getLoggedInUser().selectedCategory
  );

  let targets = [];
  if (isStudent)
    targets = user.private.getOrderedPersonalTargets(
      loggedInUser.selectedCategory
    );

  const handleDeleteUser = async () => {
    saveChangesStore.setText({
      heading: `Delete User ${user.displayName}`,
      message: `Are you sure that you want to delete ${user.displayName}? \nThis cannot be undone.`,
      leavingText: 'Yes',
      remainingText: 'No',
    });
    saveChangesStore.open(async () => {
      await Promise.all([
        db.collection('usersPrivate').doc(userId).delete(),
        db.collection('users').doc(userId).delete(),
      ]);
      return navigate('/staff/users');
    });
  };

  const handleResetPin = () => {
    saveChangesStore.setText({
      heading: `Reset PIN`,
      message: `Are you sure you want to reset ${user.displayName}'s PIN?`,
      leavingText: 'Yes',
      remainingText: 'No',
    });
    saveChangesStore.open(async () => {
      await api.requestPinReset(user.private.phoneNumber).catch((err) => {
        alertStore.setText({
          heading: 'Reset PIN failed',
          text:
            "Failed to send a reset message.\nPlease check the user's phone number is correct.",
          isWarning: true,
        });
        alertStore.open();
      });
    });
  };

  const toggleAccountLock = (accountLocked) => {
    db.collection('usersPrivate').doc(user.id).update({
      accountLocked,
      failedLoginAttempts: 0,
    });
  };

  return (
    <CSS.UserPersonalContainer data-testid="UserPersonal">
      <CSS.StaffRow>
        <CSS.StaffPersonalHeaderGroup>
          <CSS.StaffSubHeader>Personal Information</CSS.StaffSubHeader>
          <CSS.EditButton
            data-testid="UserPersonal-edit-info"
            onClick={() => store.setIsEditUserInfoModalOpen(true)}
          >
            <CSS.EditIcon />
          </CSS.EditButton>
        </CSS.StaffPersonalHeaderGroup>
      </CSS.StaffRow>
      <CSS.DetailsRow>
        <CSS.StudentDetail>{user.displayName}</CSS.StudentDetail>
        <CSS.StudentDetail>{user.private.phoneNumber}</CSS.StudentDetail>
        <CSS.StudentDetail>
          {user.getCategoriesString(categoriesStore)}
        </CSS.StudentDetail>
        {loggedInUser.role === 'admin' && (
          <CSS.StudentDetail>{`${user.role[0].toUpperCase()}${user.role.slice(
            1
          )}`}</CSS.StudentDetail>
        )}
      </CSS.DetailsRow>
      <CSS.StaffRow>
        <CSS.StaffPersonalHeaderGroup>
          <CSS.StaffSubHeader>Classes</CSS.StaffSubHeader>
        </CSS.StaffPersonalHeaderGroup>
      </CSS.StaffRow>
      <CSS.DetailsRow>
        {groupChats.map(({ chatName, id }) => {
          return <CSS.StudentDetail key={id}>{chatName}</CSS.StudentDetail>;
        })}
      </CSS.DetailsRow>
      {isStudent && (
        <>
          <CSS.StaffRow>
            <CSS.StaffPersonalHeaderGroup>
              <CSS.StaffSubHeader>Personal Learning Targets</CSS.StaffSubHeader>
              <CSS.EditButton
                data-testid="UserPersonal-edit-targets"
                onClick={() => store.setIsEditStudentTargetsModalOpen(true)}
              >
                <CSS.EditIcon />
              </CSS.EditButton>
            </CSS.StaffPersonalHeaderGroup>
          </CSS.StaffRow>
          <CSS.StaffRow>
            <CSS.TargetList>
              {user.private
                .getOrderedPersonalTargets(loggedInUser.selectedCategory)
                .map((target) => {
                  return (
                    <CSS.TargetLi key={target.targetId}>
                      {target.targetText}
                    </CSS.TargetLi>
                  );
                })}
            </CSS.TargetList>
          </CSS.StaffRow>
          <CSS.StaffRow>
            <CSS.CreateButton
              data-testid="UserPersonal-add-target"
              onClick={() => store.setIsAddStudentTargetModalOpen(true)}
            >
              Add {targets.length ? 'new' : 'first'} personal target{' '}
              <CSS.PlusIcon />
            </CSS.CreateButton>
          </CSS.StaffRow>
        </>
      )}
      {(loggedInUser.role === 'teacher' || loggedInUser.role === 'admin') && (
        <>
          <CSS.StaffRow>
            <CSS.StaffPersonalHeaderGroup>
              <CSS.StaffSubHeader>User Management</CSS.StaffSubHeader>
            </CSS.StaffPersonalHeaderGroup>
          </CSS.StaffRow>
          <CSS.DetailsRow>
            <CSS.StudentDetail as={'div'}>
              {user.private.accountLocked ? (
                <CSS.InlineCenter>
                  Account Locked{' '}
                  <CSS.LockIcon style={{ fontSize: '16px' }}></CSS.LockIcon>
                </CSS.InlineCenter>
              ) : (
                ''
              )}
            </CSS.StudentDetail>

            {user.private.accountLocked ? (
              <CSS.CreateButton onClick={() => toggleAccountLock(false)}>
                Unlock account
              </CSS.CreateButton>
            ) : (
              <CSS.UserManagementButton onClick={() => toggleAccountLock(true)}>
                Lock Account
              </CSS.UserManagementButton>
            )}
          </CSS.DetailsRow>
        </>
      )}
      {loggedInUser.role === 'admin' && (
        <>
          <CSS.DetailsRow>
            <CSS.UserManagementControls>
              <CSS.UserManagementButton onClick={handleResetPin}>
                Reset user's PIN
              </CSS.UserManagementButton>
              <CSS.UserManagementButton onClick={handleDeleteUser}>
                Delete user
              </CSS.UserManagementButton>
            </CSS.UserManagementControls>
          </CSS.DetailsRow>
        </>
      )}
      <Modal
        data-testid="edit-student-info-modal"
        open={store.isEditUserInfoModalOpen}
        onClose={() => store.setIsEditUserInfoModalOpen(false)}
        aria-labelledby="edit-student-info-modal"
        aria-describedby="edit-student-info-modal"
      >
        <EditUserPersonalInfoForm
          userId={userId}
          isAdmin={loggedInUser.role === 'admin'}
          closeModal={() => store.setIsEditUserInfoModalOpen(false)}
        />
      </Modal>
      <Modal
        data-testid="edit-student-targets-modal"
        open={store.isEditStudentTargetsModalOpen}
        onClose={() => store.setIsEditStudentTargetsModalOpen(false)}
        aria-labelledby="edit-student-targets-modal"
        aria-describedby="edit-student-targets-modal"
      >
        <EditStudentPersonalTargetsForm
          studentId={userId}
          closeModal={() => store.setIsEditStudentTargetsModalOpen(false)}
        />
      </Modal>
      <Modal
        data-testid="add-student-target-modal"
        open={store.isAddStudentTargetModalOpen}
        onClose={() => store.setIsAddStudentTargetModalOpen(false)}
        aria-labelledby="add-student-target-modal"
        aria-describedby="add-student-target-modal"
      >
        <AddStudentPersonalTargetForm
          studentId={userId}
          closeModal={() => store.setIsAddStudentTargetModalOpen(false)}
        />
      </Modal>
    </CSS.UserPersonalContainer>
  );
});

export default UserPersonal;
