import Modal from '@material-ui/core/Modal';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase/index.js';
import Error from '../common/Error';
import Loading from '../common/Loading';
import EditStudentsForm from './EditStudentsForm';
import * as CSS from './elements/StaffWhiteboard';

const StaffWhiteboard = observer(() => {
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);
  const whiteboardInput = useRef(null);

  const store = useLocalStore(() => ({
    isModalOpen: false,
    whiteboardInputDisabled: true,
    whiteboardInput: '',
    updateWhiteboardInput(e) {
      const { value } = e.target;
      store.whiteboardInput = value;
    },
  }));

  useEffect(() => {
    if (!store.whiteboardInputDisabled && whiteboardInput.current) {
      whiteboardInput.current.focus();
      whiteboardInput.current.selectionStart = store.whiteboardInput.length;
      whiteboardInput.current.selectionEnd = store.whiteboardInput.length;
    }
  }, [store, store.whiteboardInputDisabled]);

  const [selectedChat, selectedCollection] = chatsStore.getSelectedChat();

  if (!selectedChat) return <Error msg="chat not found" />;

  const saveUpdatedWhiteboard = async (e) => {
    e.preventDefault();
    store.whiteboardInputDisabled = true;
    await db
      .collection('groupChats')
      .doc(selectedChat.id)
      .update({ whiteboard: store.whiteboardInput });
  };

  const toggleChatLock = async () => {
    await db
      .collection('groupChats')
      .doc(selectedChat.id)
      .update({ locked: !selectedChat.locked });
  };

  const usersInChat = selectedChat.participants.reduce((users, userId) => {
    const user = usersStore.getUserById(userId);
    if (user) {
      users.push(user);
    }
    return users;
  }, []);

  const studentsInChat = usersInChat.filter((user) => user.role === 'student');

  const teacher = selectedChat.getTeacher(usersStore);

  if (selectedCollection === 'individualChats') {
    const [user] = usersInChat;
    if (!user) return <Loading />;
    return (
      <CSS.StaffWhiteboard isIndividual>
        <CSS.CloseButton
          data-testid="StaffWhiteboard-close"
          onClick={() => chatsStore.setIsSelectedChatVisible(false)}
          aria-label="Close Whiteboard"
        >
          <CSS.StaffCloseIcon />
        </CSS.CloseButton>
        <CSS.ClassroomTitle>{user.displayName}</CSS.ClassroomTitle>
        <CSS.UserProfileImage src={user.imageURL} alt={user.displayName} />
      </CSS.StaffWhiteboard>
    );
  }

  return (
    <CSS.StaffWhiteboard>
      <CSS.CloseButton
        data-testid="StaffWhiteboard-close"
        onClick={() => chatsStore.setIsSelectedChatVisible(false)}
        aria-label="Close Whiteboard"
      >
        <CSS.StaffCloseIcon />
      </CSS.CloseButton>
      <CSS.ClassroomTitle>{selectedChat.chatName}</CSS.ClassroomTitle>
      {teacher && (
        <CSS.ClassroomSubHeading>
          Teacher: {teacher?.displayName}
        </CSS.ClassroomSubHeading>
      )}
      <CSS.WhiteboardContentForm
        id="StaffWhiteboard-form"
        data-testid="StaffWhiteboard-form"
        onSubmit={saveUpdatedWhiteboard}
      >
        {store.whiteboardInputDisabled ? (
          <CSS.IconButton
            type="button"
            data-testid="StaffWhiteboard-editWhiteboard"
            onClick={() => {
              store.whiteboardInputDisabled = false;
              store.whiteboardInput = selectedChat.whiteboard;
            }}
          >
            Edit Whiteboard
            <CSS.EditIcon />
          </CSS.IconButton>
        ) : (
          <CSS.Inline>
            <CSS.IconButton
              type="submit"
              data-testid="StaffWhiteboard-SaveEditWhiteboard"
            >
              Save Whiteboard
              <CSS.SaveIcon />
            </CSS.IconButton>
            <CSS.IconButton
              type="button"
              data-testid="StaffWhiteboard-cancelEditWhiteboard"
              onClick={() => {
                store.whiteboardInputDisabled = true;
              }}
            >
              <CSS.ClearIcon />
            </CSS.IconButton>
          </CSS.Inline>
        )}

        <CSS.WhiteboardInput
          value={
            store.whiteboardInputDisabled
              ? selectedChat.whiteboard
              : store.whiteboardInput
          }
          onChange={store.updateWhiteboardInput}
          disabled={store.whiteboardInputDisabled}
          aria-label="Whiteboard"
          ref={whiteboardInput}
        />
      </CSS.WhiteboardContentForm>
      <CSS.Controls>
        <CSS.IconButton
          type="button"
          data-testid="StaffWhiteboard-editStudents"
          onClick={() => (store.isModalOpen = true)}
        >
          Students: {studentsInChat.length}
          <CSS.EditIcon />
        </CSS.IconButton>

        <CSS.CreateButton type="button" onClick={toggleChatLock}>
          {selectedChat.locked ? 'Unlock' : 'Lock'} chat
        </CSS.CreateButton>
      </CSS.Controls>
      <Modal
        open={store.isModalOpen}
        onClose={() => (store.isModalOpen = false)}
        aria-labelledby="edit-students-modal"
        aria-describedby="edit-students-modal"
      >
        <EditStudentsForm
          closeModal={() => (store.isModalOpen = false)}
          studentsInChat={studentsInChat}
          usersInChat={usersInChat}
          chatId={chatsStore.selectedChatId}
        />
      </Modal>
    </CSS.StaffWhiteboard>
  );
});

export default StaffWhiteboard;
