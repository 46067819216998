import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import * as StaffCommon from '../../common/elements/Staff';

export const StaffTopicSubHeader = styled(StaffCommon.StaffSubHeader).attrs({
  as: 'h3',
})`
  padding-left: 1rem;
`;

export const Inline = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const CardList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;

export const FilterTopicsForm = styled.form`
  align-self: center;
`;

export const ActiveTopic = styled(StaffCommon.ActiveButton)`
  padding-left: 1rem;
  &:hover {
    cursor: pointer;
  }
`;

export const EditTopicButton = styled.button`
  background: none;
  border: none;
  margin-left: 0.5em;
`;

export const EditIcon = (props) => {
  return <EditOutlinedIcon {...props} style={{ fontSize: '2rem' }} />;
};

export const SubjectHeader = styled.h2`
  color: ${({ theme }) => theme.headerColour};
  font-weight: 600;
  font-size: 2.5rem;
  margin: 1.4em 0 1em 0;
`;

export const SubjectContainer = styled.div`
  ${({ isLastInSubject }) =>
    isLastInSubject &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.pendingFill};
      padding-bottom: 1.2em;
    `}
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
