import { Link } from '@reach/router';
import styled, { css } from 'styled-components/macro';
import * as StaffCommon from '../../common/elements/Staff';

export const ModuleCard = styled(StaffCommon.StaffCard)`
 ${({ isEditing, groupChatId }) =>
   (isEditing || groupChatId) &&
   css`
     display: flex;
     justify-content: space-between;
   `}
  ${({ isDragging }) =>
    isDragging &&
    css`
      opacity: 0.3;
    `}
  ${({ isOver, theme }) =>
    isOver &&
    css`
      background-color: ${theme.hoverFill};
    `}
`;

export const ModuleLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: inherit;
  &[data-isediting='true'] {
    &:hover {
      cursor: default;
    }
  }
`;

export const ModuleImage = styled.img`
  align-self: center;
  justify-self: center;
  border-radius: 10px;
  height: 6em;
  width: 6em;
  object-fit: cover;
`;

export const AlignModuleAndTopic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1em;
`;

export const ModuleHeader = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.staffCardHeaderColour};
  margin: 0;
`;

export const TopicName = styled.p`
  margin: 0;
  align-self: end;
  font-weight: 700;
  color: ${({ theme }) => theme.lessonHighlight};
`;

export const AlignProgress = styled(AlignModuleAndTopic)`
  justify-content: start;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 1em;
`;

export const ActiveModule = styled(StaffCommon.ActiveButton)`
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Index = styled.p`
  color: ${({ theme }) => theme.lessonHighlight};
  font-size: 1.6rem;
  margin: 0;
  font-weight: 700;
`;

export const AlignSharedIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;

export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
