import styled from 'styled-components/macro';

export const SayHelloMsg = styled.li`
  margin: 0;
  margin-top: 1.5em;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 3em 3em 3em 3em;
  justify-self: start;
  align-self: center;
  font-weight: 600;
  font-size: 1.3em;
  line-height: 1.7em;
  padding: 1em 1.5em;
`;
