import React, { createContext } from 'react';
import ChatsModel from '../models/Chats.js';

const ChatsStore = ChatsModel.create({
  groupChats: [],
  individualChats: [],
});

export const ChatsContext = createContext(ChatsStore);

export const ChatsProvider = ({ children }) => {
  return (
    <ChatsContext.Provider value={ChatsStore}>{children}</ChatsContext.Provider>
  );
};
