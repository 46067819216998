import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { Link } from '@reach/router';
import React from 'react';
import styled, { css } from 'styled-components/macro';

export const CardList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;

  @media only screen and (min-width: 700px) {
    width: 80%;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const Card = styled.li`
  border-radius: 15px;
  box-shadow: 0px 5px 5px #e7e7e7;
  margin: 1.5em 3em;
  text-align: center;
  list-style: none;
  padding: 1.7em;
  background-color: ${({ theme }) => theme.classroomHighlight};
  color: ${({ theme }) =>
    theme.isClassRoomDark ? theme.textLight : theme.headerColour};

  @media only screen and (min-width: 700px) {
    padding: 3em;
    min-width: 20em;
  }

  ${({ lesson }) =>
    lesson &&
    css`
      background-color: ${({ theme }) => theme.lessonHighlight};
      color: ${({ theme }) =>
        theme.isLessonDark ? theme.textLight : theme.headerColour};
    `}

  ${({ isTarget }) =>
    isTarget &&
    css`
      background-color: ${({ theme }) => theme.targetHighlight};
      color: ${({ theme }) =>
        theme.isTargetDark ? theme.textLight : theme.headerColour};
    `}

  ${({ isAssessment }) =>
    isAssessment &&
    css`
      background-color: ${({ theme }) => theme.targetHighlight};
      color: ${({ theme }) =>
        theme.isTargetDark ? theme.textLight : theme.headerColour};
    `}

  ${({ isCategory }) =>
    isCategory &&
    css`
      background-color: ${({ theme }) => theme.pendingFill};
      color: ${({ theme }) => theme.headerColour};
      font-size: 1rem;
      margin: 0;
      @media only screen and (min-width: 700px) {
        min-width: 23em;
        padding: 1em 1.7em;
      }
    `}
`;

export const CardWithImage = styled(Card)`
  display: flex;
  align-items: center;
  ${({ isTop }) =>
    isTop &&
    css`
      margin-top: 0.2em;
    `}

  @media only screen and (min-width: 700px) {
    margin: 1.5em;
  }

  ${({ hasNotifications }) =>
    hasNotifications &&
    css`
      width: 100%;
      margin: 0;
      @media only screen and (min-width: 700px) {
        margin: 0;
      }
    `}
`;

export const ModuleCardWithImage = styled(CardWithImage)`
  flex-direction: column;
  justify-content: space-between;
  padding: 1.7em 1.7em 1em 1.7em;
  @media only screen and (min-width: 700px) {
    padding: 2em 3em 1.5em 3em;
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
`;

export const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;

export const CategoryButtonContainer = styled.div`
  margin: 1.5em 3em;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    width: 80%;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const HomeCardHeader = styled.h3`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.1rem;
  text-align: left;
  vertical-align: center;
  margin: auto 0 auto 0.75em;
`;

export const CategoryArrow = (props) => {
  return (
    <SwapHorizIcon
      {...props}
      style={{ fontSize: '1rem', width: ' 5em', height: '5em' }}
    ></SwapHorizIcon>
  );
};
