import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import CheckSVG from '../../svgs/CheckSVG.js';
import * as CSS from './elements/Module';

const LearningResources = observer(({ moduleId, topicId }) => {
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);
  const module = topicsStore.getModuleById(moduleId);
  const learningResources = module.getOrderedItems('learningResources');
  const loggedInUser = usersStore.getLoggedInUser();

  return (
    <CSS.StudentItemList>
      {learningResources.length ? (
        learningResources.map((resource) => {
          const complete = loggedInUser.private.isResourceComplete(resource.id);
          return (
            <CSS.ItemLi key={resource.id}>
              <CSS.SpaceBetweenLink
                to={`/topics/${topicId}/modules/${moduleId}/learning/${resource.id}`}
              >
                <CSS.ItemInstructions complete={complete}>
                  {resource.instructions}
                </CSS.ItemInstructions>
                <CheckSVG complete={complete} id={resource.id} />
              </CSS.SpaceBetweenLink>
            </CSS.ItemLi>
          );
        })
      ) : (
        <CSS.NoItemsLi>
          <CSS.ItemInstructions complete={true}>
            No resources yet
          </CSS.ItemInstructions>
        </CSS.NoItemsLi>
      )}
    </CSS.StudentItemList>
  );
});

export default LearningResources;
