import { Redirect, Router } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { TopicsContext } from '../../contexts/Topics';
import Error from '../common/Error';
import * as CSS from './elements/ModuleProgress';
import StudentModuleLearning from './StudentModuleLearning';
import StudentModuleTasks from './StudentModuleTasks';

const ModuleProgress = observer(({ studentId, moduleId, store }) => {
  const topicsStore = useContext(TopicsContext);
  const module = topicsStore.getModuleById(moduleId);
  if (!module) return <Error msg="Module not found" />;

  const topic = module.getParentTopic();

  return (
    <div>
      <CSS.StaffRow>
        <CSS.DoubleHeaderGroup>
          <CSS.TopicSubHeader>{topic.topicName}</CSS.TopicSubHeader>
          <CSS.ModuleSubHeader>{module.moduleName}</CSS.ModuleSubHeader>
        </CSS.DoubleHeaderGroup>
      </CSS.StaffRow>
      <Router primary={false}>
        <Redirect from="/" to="learning" noThrow />
        <StudentModuleLearning path="/learning" studentId={studentId} />
        <StudentModuleTasks path="/tasks" studentId={studentId} store={store} />
      </Router>
    </div>
  );
});

export default ModuleProgress;
