import { types } from 'mobx-state-tree';

const Question = types.model('Question', {
  id: types.string,
  index: types.number,
  text: types.string,
  answer: types.maybeNull(types.string),
  teacherOnly: types.optional(types.boolean, false),
});

export default Question;
