import { types } from 'mobx-state-tree';
import { db } from '../firebase';
import Category from './Category';

const Categories = types
  .model('Categories', {
    categories: types.optional(types.array(Category), []),
  })
  .actions((self) => ({
    listenToCategories() {
      db.collection('categories').onSnapshot(self.handleSnapshot);
    },
    handleSnapshot(querySnapshot) {
      querySnapshot.docChanges().forEach(({ doc, type }) => {
        if (type === 'added') {
          const newCategory = Category.create({
            id: doc.id,
            ...doc.data(),
          });
          self.categories.push(newCategory);
        } else if (type === 'modified') {
          const existingCategory = self.categories.find(
            ({ id }) => doc.id === id
          );
          existingCategory.updateInfo({
            id: doc.id,
            ...doc.data(),
          });
        } else if (type === 'removed') {
          const categoryToRemove = self.categories.find(
            ({ id }) => doc.id === id
          );
          self.categories.remove(categoryToRemove);
        }
      });
    },
  }))
  .views((self) => ({
    getCategoryById(categoryId) {
      return self.categories.find(({ id }) => categoryId === id);
    },
    getUsersCategories(usersCatIds) {
      return self.categories.filter((cat) => usersCatIds.has(cat.id));
    },
    getInAlphabeticalOrder() {
      return [...self.categories].sort((a, b) =>
        a.categoryName.localeCompare(b.categoryName)
      );
    },
  }));

export default Categories;
