import Modal from '@material-ui/core/Modal';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import * as CSS from './elements/Inbox';
import MessagePreview from './MessagePreview';
import NewMessageForm from './NewMessageForm.js';

const Inbox = observer(() => {
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const store = useLocalStore(() => ({
    isModalOpen: false,
    setModal: (open) => {
      store.isModalOpen = open;
    },
  }));

  const chats = chatsStore.getIndividualChatsByNewestMessages(
    loggedInUser.selectedCategory
  );

  return (
    <>
      <CSS.InboxHeader>
        <CSS.InboxTitle>Inbox</CSS.InboxTitle>
        {loggedInUser.role !== 'student' && (
          <CSS.CreateMessageButton onClick={() => store.setModal(true)}>
            New message <CSS.CreateMessagePlusIcon />
          </CSS.CreateMessageButton>
        )}
      </CSS.InboxHeader>
      <Modal
        data-testid="new-message-modal"
        open={store.isModalOpen}
        onClose={() => store.setModal(false)}
        aria-labelledby="new-message-modal"
        aria-describedby="new-message-modal"
      >
        <NewMessageForm closeModal={() => store.setModal(false)} />
      </Modal>
      <CSS.PreviewsList>
        {chats.length > 0 ? (
          chats.map(({ id: chatId, messages }, i) => {
            const message = messages[messages.length - 1] || {
              id: chatId,
              text: 'No messages yet',
            };
            return (
              <MessagePreview
                key={chatId}
                {...message}
                chatId={chatId}
                index={i}
              />
            );
          })
        ) : (
          <CSS.InboxPlaceholder>
            Private messages will appear here
          </CSS.InboxPlaceholder>
        )}
      </CSS.PreviewsList>
    </>
  );
});

export default Inbox;
