import { useObserver } from 'mobx-react-lite';
import React from 'react';
import * as CSS from './elements/StaffClassroom';
import StaffChat from './StaffChat';
import StaffWhiteboard from './StaffWhiteboard';

const StaffClassroom = React.forwardRef(({ chatId }, ref) => {
  return useObserver(() => (
    <CSS.ChatContainer data-testid="StaffClassroom" ref={ref}>
      {chatId && (
        <>
          <StaffWhiteboard
            data-testid="StaffClassroom-whiteboard"
            chatId={chatId}
          />
          <StaffChat key={chatId} />
        </>
      )}
    </CSS.ChatContainer>
  ));
});

export default StaffClassroom;
