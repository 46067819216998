import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db, storage } from '../../firebase';
import ImagePlaceholder from '../../svgs/image-placeholder.svg';
import * as CSS from './elements/SetModuleForm';

const createModuleSchema = yup.object().shape({
  moduleName: yup.string().required('Please enter a module name'),
  imageFile: yup
    .mixed()
    .required('Please add an image')
    .test(
      'file',
      'Max file size: 200mb',
      (value) => value?.size <= 1 * 1024 * 1024
    ),
});

const editModuleSchema = yup.object().shape({
  moduleName: yup.string(),
  imageFile: yup
    .mixed()
    .test(
      'file',
      'Max file size: 200mb',
      (value) => value?.size <= 1 * 1024 * 1024
    ),
});

const CreateEditModuleForm = React.forwardRef(
  (
    { closeModal, topicId, moduleId, newModule, isImporting, setImporting },
    ref
  ) => {
    const topicsStore = useContext(TopicsContext);
    const usersStore = useContext(UsersContext);
    const module = topicsStore.getModuleById(moduleId);

    const initialValues = {
      moduleName: module?.moduleName || '',
      imageFile: '',
      imagePreview: module?.imageURL || '',
    };

    const handleSubmit = async (
      { moduleName, imageFile },
      { setFieldError }
    ) => {
      if (!moduleId) {
        const moduleSnap = await db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .where('moduleName', '==', moduleName)
          .get();
        if (!moduleSnap.empty) {
          return setFieldError(
            'moduleName',
            'The module name already exists for this topic'
          );
        }
        const { id: newModuleId } = await db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .add({ moduleName, createdBy: usersStore.loggedInUserId });

        const storageRef = storage.ref();
        const fileRef = storageRef.child(
          `/topics/${topicId}/modules/${newModuleId}`
        );
        await fileRef.put(imageFile);
        const imageURL = await fileRef.getDownloadURL();

        await db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .doc(newModuleId)
          .update('imageURL', imageURL);
      } else {
        const updatedModule = {
          moduleName,
        };
        if (imageFile) {
          const storageRef = storage.ref();
          const fileRef = storageRef.child(
            `/topics/${topicId}/modules/${moduleId}`
          );
          await fileRef.put(imageFile);
          const imageURL = await fileRef.getDownloadURL();
          updatedModule.imageURL = imageURL;
        }
        await db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .doc(moduleId)
          .update(updatedModule);
      }
      closeModal();
    };

    const handleImageChange = (event, setFieldValue, setTouched) => {
      const file = event.target.files[0];
      if (!file) return;

      setTouched({ imageFile: true });

      let reader = new FileReader();
      reader.onloadend = (e) => {
        setFieldValue('imagePreview', reader.result);
      };
      reader.readAsDataURL(file);

      setFieldValue('imageFile', file);
    };

    return useObserver(() => (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={moduleId ? editModuleSchema : createModuleSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, dirty, setFieldValue, setTouched }) => (
          <Observer>
            {() => (
              <CSS.Form data-testid="SetModuleForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>
                    {moduleId ? 'Edit ' : 'Add a '}Module
                  </CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <CSS.FieldGroup>
                  <Field
                    type="text"
                    name="moduleName"
                    id="moduleName"
                    label="Enter a module name"
                    component={CSS.StaffTextFieldInput}
                  />
                  <ErrorMessage
                    name="moduleName"
                    component="p"
                    data-testid="moduleName-error"
                  />
                </CSS.FieldGroup>
                <CSS.UploadButton htmlFor="SetModuleForm-upload-file">
                  Set module image
                </CSS.UploadButton>
                <CSS.ImageInput
                  id="SetModuleForm-upload-file"
                  type="file"
                  accept="image/*"
                  data-testid="SetModuleForm-change-pic"
                  onChange={(e) =>
                    handleImageChange(e, setFieldValue, setTouched)
                  }
                />
                <ErrorMessage name="imageFile" component="p" />
                <CSS.ImagePreview
                  src={
                    !errors.imageFile && values.imagePreview
                      ? values.imagePreview
                      : ImagePlaceholder
                  }
                  alt={`${values.moduleName}-preview` || 'placeholder'}
                />
                {newModule && (
                  <CSS.InlineCenter>
                    <CSS.ShareIcon style={{ fontSize: '1.4rem' }} />
                    <CSS.ImportButton
                      type="button"
                      onClick={() => setImporting(true)}
                    >
                      Import shared module
                    </CSS.ImportButton>
                  </CSS.InlineCenter>
                )}
                <CSS.StaffFooterRow>
                  <CSS.StaffNavButton
                    type="submit"
                    disabled={!dirty || Object.values(errors).length}
                  >
                    Finish
                  </CSS.StaffNavButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default CreateEditModuleForm;
