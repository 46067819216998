import { Router } from '@reach/router';
import styled, { css } from 'styled-components/macro';

export const StaffRouter = styled(Router)`
  padding-right: 0;
  grid-area: content;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    ${({ chatid, isselectedchatvisible }) =>
      chatid &&
      isselectedchatvisible &&
      css`
        padding-right: max(25vw, 325px);
      `}
  }
`;
