import styled from 'styled-components/macro';

export const ChatContainer = styled.section`
  grid-area: chat;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto min-content;
  grid-template-areas: 'messages' 'messageForm';
`;

export const ChatMessagesList = styled.ul`
  grid-area: messages;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.chatBackground};
  list-style: none;
  padding: 0.5em;
  margin: 0;
  overflow-y: scroll;
`;
