import { Form as FormikForm } from 'formik';
import styled from 'styled-components/macro';
import * as common from '../../common/elements/Form';

export const Form = styled(FormikForm)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 15px;
  ${common.ModalScreenWidthMQs}
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
