import React, { createContext } from 'react';
import CategoriesModel from '../models/Categories.js';

const CategoriesStore = CategoriesModel.create({
  categories: [],
});

export const CategoriesContext = createContext(CategoriesStore);

export const CategoriesProvider = ({ children }) => {
  return (
    <CategoriesContext.Provider value={CategoriesStore}>
      {children}
    </CategoriesContext.Provider>
  );
};
