import { types } from 'mobx-state-tree';

const Listeners = types
  .model('Listeners', {})
  .volatile(() => ({
    listeners: [],
    categoryListeners: [],
  }))
  .actions((self) => ({
    addUnSub(unsubscribe) {
      self.listeners.push(unsubscribe);
    },
    addUnSubs(unsubscribes) {
      unsubscribes.forEach((unsubscribe) => {
        self.listeners.push(unsubscribe);
      });
    },
    addCategoryUnSub(unsubscribe) {
      self.categoryListeners.push(unsubscribe);
    },
    addCategoryUnSubs(unsubscribes) {
      unsubscribes.forEach((unsubscribe) => {
        self.categoryListeners.push(unsubscribe);
      });
    },
    unsubscribeFromAll() {
      self.listeners.forEach((unsubscribe) => {
        if (typeof unsubscribe === 'function') unsubscribe();
      });
      self.categoryListeners.forEach((unsubscribe) => {
        if (typeof unsubscribe === 'function') unsubscribe();
      });
    },
    unsubscribeFromCategoryListeners() {
      self.categoryListeners.forEach((unsubscribe) => {
        if (typeof unsubscribe === 'function') unsubscribe();
      });
    },
  }));

export default Listeners;
