import { observer } from 'mobx-react-lite';
import React from 'react';

const MCC_LOGO = observer(() => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 198.4 56.7"
      style={{ width: '60%', height: '6em' }}
    >
      <pattern
        x="14"
        width="69"
        height="69"
        patternUnits="userSpaceOnUse"
        id="Unnamed_Pattern"
        viewBox="2.1 -70.9 69 69"
      >
        <g>
          <rect x="2.1" y="-70.9" className="st0" width="69" height="69" />
          <rect x="2.1" y="-70.9" className="st1" width="69" height="69" />
          <g>
            <path
              className="st2"
              d="M61.8-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-71.7,61.8-71.7,61.8-71.7
            C61.8-71.6,61.8-71.6,61.8-71.7"
            />
            <path
              className="st2"
              d="M54.1-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-71.7,54.1-71.7,54.1-71.7
            C54.1-71.6,54.1-71.6,54.1-71.7"
            />
            <path
              className="st2"
              d="M46.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.4-71.7,46.4-71.7,46.4-71.7
            C46.4-71.6,46.4-71.6,46.4-71.7"
            />
            <path
              className="st2"
              d="M38.8-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-71.7,38.8-71.7,38.8-71.7
            C38.8-71.6,38.8-71.6,38.8-71.7"
            />
            <path
              className="st2"
              d="M31.1-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-71.7,31.1-71.7,31.1-71.7
            C31.1-71.6,31.1-71.6,31.1-71.7"
            />
            <path
              className="st2"
              d="M23.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.4-71.7,23.4-71.7,23.4-71.7
            C23.4-71.6,23.4-71.6,23.4-71.7"
            />
            <path
              className="st2"
              d="M15.8-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-71.7,15.8-71.7,15.8-71.7
            C15.8-71.6,15.8-71.6,15.8-71.7"
            />
            <path
              className="st2"
              d="M8.1-71.7c0,0.1,0,0.1,0,0.2C8-71.4,8-71.4,7.9-71.3c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.1-71.7,8.1-71.7,8.1-71.7
            C8.1-71.6,8.1-71.6,8.1-71.7"
            />
            <path
              className="st2"
              d="M0.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.8-69.4,1-69,1.2-68.9c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.4-71.7,0.4-71.7,0.4-71.7
            C0.4-71.6,0.4-71.6,0.4-71.7"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M69.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-71.7,69.4-71.7,69.4-71.7
            C69.4-71.6,69.4-71.6,69.4-71.7"
            />
          </g>
          <path
            className="st2"
            d="M0.5-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
          c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-69.4,1-69,1.3-68.9c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
          c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
          c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8c-0.2,0-0.3,0.1-0.4,0.2
          c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-71.7,0.5-71.7,0.5-71.7C0.5-71.6,0.5-71.6,0.5-71.7"
          />
          <g>
            <g>
              <path
                className="st2"
                d="M69.4-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-64,69.4-64.1,69.4-64C69.4-64,69.4-64,69.4-64"
              />
              <path
                className="st2"
                d="M61.8-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-64,61.8-64.1,61.8-64C61.8-64,61.8-64,61.8-64"
              />
              <path
                className="st2"
                d="M54.1-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-64,54.1-64.1,54.1-64C54.1-64,54.1-64,54.1-64"
              />
              <path
                className="st2"
                d="M46.5-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-64,46.5-64.1,46.5-64C46.5-64,46.5-64,46.5-64"
              />
              <path
                className="st2"
                d="M38.8-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-64,38.8-64.1,38.8-64C38.8-64,38.8-64,38.8-64"
              />
              <path
                className="st2"
                d="M31.1-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-64,31.1-64.1,31.1-64C31.1-64,31.1-64,31.1-64"
              />
              <path
                className="st2"
                d="M23.5-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-64,23.5-64.1,23.5-64C23.5-64,23.5-64,23.5-64"
              />
              <path
                className="st2"
                d="M15.8-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-64,15.8-64.1,15.8-64C15.8-64,15.8-64,15.8-64"
              />
              <path
                className="st2"
                d="M8.2-64c0,0.1,0,0.1,0,0.2C8.1-63.7,8-63.7,8-63.7c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-62.2,8.2-62,8.3-61.9c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-64,8.2-64.1,8.2-64C8.1-64,8.1-64,8.2-64"
              />
              <path
                className="st2"
                d="M0.5-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5C2.8-62,3-61.9,3.1-62c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-64,0.5-64.1,0.5-64C0.5-64,0.5-64,0.5-64"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-56.4,69.4-56.4,69.4-56.3
              C69.4-56.3,69.4-56.3,69.4-56.3"
              />
              <path
                className="st2"
                d="M61.8-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-56.4,61.8-56.4,61.8-56.3
              C61.8-56.3,61.8-56.3,61.8-56.3"
              />
              <path
                className="st2"
                d="M54.1-56.3c0,0.1,0,0.1,0,0.2C54-56.1,54-56.1,53.9-56c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-56.4,54.1-56.4,54.1-56.3
              C54.1-56.3,54.1-56.3,54.1-56.3"
              />
              <path
                className="st2"
                d="M46.5-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-56.4,46.5-56.4,46.5-56.3
              C46.5-56.3,46.5-56.3,46.5-56.3"
              />
              <path
                className="st2"
                d="M38.8-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-56.4,38.8-56.4,38.8-56.3
              C38.8-56.3,38.8-56.3,38.8-56.3"
              />
              <path
                className="st2"
                d="M31.1-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-56.4,31.1-56.4,31.1-56.3
              C31.1-56.3,31.1-56.3,31.1-56.3"
              />
              <path
                className="st2"
                d="M23.5-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-56.4,23.5-56.4,23.5-56.3
              C23.5-56.3,23.5-56.3,23.5-56.3"
              />
              <path
                className="st2"
                d="M15.8-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-56.4,15.8-56.4,15.8-56.3
              C15.8-56.3,15.8-56.3,15.8-56.3"
              />
              <path
                className="st2"
                d="M8.2-56.3c0,0.1,0,0.1,0,0.2C8.1-56.1,8-56.1,8-56c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-56.4,8.2-56.4,8.2-56.3
              C8.1-56.3,8.1-56.3,8.2-56.3"
              />
              <path
                className="st2"
                d="M0.5-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-56.4,0.5-56.4,0.5-56.3
              C0.5-56.3,0.5-56.3,0.5-56.3"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-48.7,69.4-48.8,69.4-48.7
              C69.4-48.7,69.4-48.7,69.4-48.7"
              />
              <path
                className="st2"
                d="M61.8-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-48.7,61.8-48.8,61.8-48.7
              C61.8-48.7,61.8-48.7,61.8-48.7"
              />
              <path
                className="st2"
                d="M54.1-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-48.7,54.1-48.8,54.1-48.7
              C54.1-48.7,54.1-48.7,54.1-48.7"
              />
              <path
                className="st2"
                d="M46.5-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-48.7,46.5-48.8,46.5-48.7
              C46.5-48.7,46.5-48.7,46.5-48.7"
              />
              <path
                className="st2"
                d="M38.8-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-48.7,38.8-48.8,38.8-48.7
              C38.8-48.7,38.8-48.7,38.8-48.7"
              />
              <path
                className="st2"
                d="M31.1-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-48.7,31.1-48.8,31.1-48.7
              C31.1-48.7,31.1-48.7,31.1-48.7"
              />
              <path
                className="st2"
                d="M23.5-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-48.7,23.5-48.8,23.5-48.7
              C23.5-48.7,23.5-48.7,23.5-48.7"
              />
              <path
                className="st2"
                d="M15.8-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-48.7,15.8-48.8,15.8-48.7
              C15.8-48.7,15.8-48.7,15.8-48.7"
              />
              <path
                className="st2"
                d="M8.2-48.7c0,0.1,0,0.1,0,0.2C8.1-48.4,8-48.4,8-48.4c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C8.5-46.4,8.7-46,8.9-46c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-48.7,8.2-48.8,8.2-48.7
              C8.1-48.7,8.1-48.7,8.2-48.7"
              />
              <path
                className="st2"
                d="M0.5-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-46.4,1-46,1.3-46c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
              c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
              c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8c-0.2,0-0.3,0.1-0.4,0.2
              c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-48.7,0.5-48.8,0.5-48.7C0.5-48.7,0.5-48.7,0.5-48.7"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-41.1,69.4-41.1,69.4-41C69.4-41,69.4-41,69.4-41
              "
              />
              <path
                className="st2"
                d="M61.8-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-41.1,61.8-41.1,61.8-41C61.8-41,61.8-41,61.8-41
              "
              />
              <path
                className="st2"
                d="M54.1-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-41.1,54.1-41.1,54.1-41C54.1-41,54.1-41,54.1-41
              "
              />
              <path
                className="st2"
                d="M46.5-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-41.1,46.5-41.1,46.5-41C46.5-41,46.5-41,46.5-41
              "
              />
              <path
                className="st2"
                d="M38.8-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-41.1,38.8-41.1,38.8-41C38.8-41,38.8-41,38.8-41
              "
              />
              <path
                className="st2"
                d="M31.1-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-41.1,31.1-41.1,31.1-41C31.1-41,31.1-41,31.1-41
              "
              />
              <path
                className="st2"
                d="M23.5-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-41.1,23.5-41.1,23.5-41C23.5-41,23.5-41,23.5-41
              "
              />
              <path
                className="st2"
                d="M15.8-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-41.1,15.8-41.1,15.8-41C15.8-41,15.8-41,15.8-41
              "
              />
              <path
                className="st2"
                d="M8.2-41c0,0.1,0,0.1,0,0.2C8.1-40.8,8-40.8,8-40.7c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-41.1,8.2-41.1,8.2-41C8.1-41,8.1-41,8.2-41"
              />
              <path
                className="st2"
                d="M0.5-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5C2.8-39,3-39,3.1-39c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-41.1,0.5-41.1,0.5-41C0.5-41,0.5-41,0.5-41"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-33.4,69.4-33.5,69.4-33.4
              C69.4-33.4,69.4-33.4,69.4-33.4"
              />
              <path
                className="st2"
                d="M61.8-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-33.4,61.8-33.5,61.8-33.4
              C61.8-33.4,61.8-33.4,61.8-33.4"
              />
              <path
                className="st2"
                d="M54.1-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-33.4,54.1-33.5,54.1-33.4
              C54.1-33.4,54.1-33.4,54.1-33.4"
              />
              <path
                className="st2"
                d="M46.5-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-33.4,46.5-33.5,46.5-33.4
              C46.5-33.4,46.5-33.4,46.5-33.4"
              />
              <path
                className="st2"
                d="M38.8-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-33.4,38.8-33.5,38.8-33.4
              C38.8-33.4,38.8-33.4,38.8-33.4"
              />
              <path
                className="st2"
                d="M31.1-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-33.4,31.1-33.5,31.1-33.4
              C31.1-33.4,31.1-33.4,31.1-33.4"
              />
              <path
                className="st2"
                d="M23.5-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-33.4,23.5-33.5,23.5-33.4
              C23.5-33.4,23.5-33.4,23.5-33.4"
              />
              <path
                className="st2"
                d="M15.8-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-33.4,15.8-33.5,15.8-33.4
              C15.8-33.4,15.8-33.4,15.8-33.4"
              />
              <path
                className="st2"
                d="M8.2-33.4c0,0.1,0,0.1,0,0.2C8.1-33.1,8-33.1,8-33.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-33.4,8.2-33.5,8.2-33.4
              C8.1-33.4,8.1-33.4,8.2-33.4"
              />
              <path
                className="st2"
                d="M0.5-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-33.4,0.5-33.5,0.5-33.4
              C0.5-33.4,0.5-33.4,0.5-33.4"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-25.8,69.4-25.8,69.4-25.7
              C69.4-25.7,69.4-25.7,69.4-25.7"
              />
              <path
                className="st2"
                d="M61.8-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-25.8,61.8-25.8,61.8-25.7
              C61.8-25.7,61.8-25.7,61.8-25.7"
              />
              <path
                className="st2"
                d="M54.1-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-25.8,54.1-25.8,54.1-25.7
              C54.1-25.7,54.1-25.7,54.1-25.7"
              />
              <path
                className="st2"
                d="M46.5-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-25.8,46.5-25.8,46.5-25.7
              C46.5-25.7,46.5-25.7,46.5-25.7"
              />
              <path
                className="st2"
                d="M38.8-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-25.8,38.8-25.8,38.8-25.7
              C38.8-25.7,38.8-25.7,38.8-25.7"
              />
              <path
                className="st2"
                d="M31.1-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-25.8,31.1-25.8,31.1-25.7
              C31.1-25.7,31.1-25.7,31.1-25.7"
              />
              <path
                className="st2"
                d="M23.5-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-25.8,23.5-25.8,23.5-25.7
              C23.5-25.7,23.5-25.7,23.5-25.7"
              />
              <path
                className="st2"
                d="M15.8-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-25.8,15.8-25.8,15.8-25.7
              C15.8-25.7,15.8-25.7,15.8-25.7"
              />
              <path
                className="st2"
                d="M8.2-25.7c0,0.1,0,0.1,0,0.2C8.1-25.4,8-25.5,8-25.4c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-24,8.2-23.8,8.3-23.6c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-25.8,8.2-25.8,8.2-25.7
              C8.1-25.7,8.1-25.7,8.2-25.7"
              />
              <path
                className="st2"
                d="M0.5-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-23.5,1-23.1,1.3-23c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-25.8,0.5-25.8,0.5-25.7
              C0.5-25.7,0.5-25.7,0.5-25.7"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-18.1,69.4-18.2,69.4-18.1
              C69.4-18,69.4-18.1,69.4-18.1"
              />
              <path
                className="st2"
                d="M61.8-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-18.1,61.8-18.2,61.8-18.1
              C61.8-18,61.8-18.1,61.8-18.1"
              />
              <path
                className="st2"
                d="M54.1-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-18.1,54.1-18.2,54.1-18.1
              C54.1-18,54.1-18.1,54.1-18.1"
              />
              <path
                className="st2"
                d="M46.5-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-18.1,46.5-18.2,46.5-18.1
              C46.5-18,46.5-18.1,46.5-18.1"
              />
              <path
                className="st2"
                d="M38.8-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-18.1,38.8-18.2,38.8-18.1
              C38.8-18,38.8-18.1,38.8-18.1"
              />
              <path
                className="st2"
                d="M31.1-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-18.1,31.1-18.2,31.1-18.1
              C31.1-18,31.1-18.1,31.1-18.1"
              />
              <path
                className="st2"
                d="M23.5-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-18.1,23.5-18.2,23.5-18.1
              C23.5-18,23.5-18.1,23.5-18.1"
              />
              <path
                className="st2"
                d="M15.8-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-18.1,15.8-18.2,15.8-18.1
              C15.8-18,15.8-18.1,15.8-18.1"
              />
              <path
                className="st2"
                d="M8.2-18.1c0,0.1,0,0.1,0,0.2C8.1-17.8,8-17.8,8-17.8c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-16.3,8.2-16.1,8.3-16c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-18.1,8.2-18.2,8.2-18.1C8.1-18,8.1-18.1,8.2-18.1
              "
              />
              <path
                className="st2"
                d="M0.5-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5C2.8-16.1,3-16,3.1-16c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-18.1,0.5-18.2,0.5-18.1C0.5-18,0.5-18.1,0.5-18.1
              "
              />
            </g>
            <g>
              <path
                className="st2"
                d="M69.4-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-10.5,69.4-10.5,69.4-10.4
              C69.4-10.4,69.4-10.4,69.4-10.4"
              />
              <path
                className="st2"
                d="M61.8-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1C63.9-8,63.8-8.2,64-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-10.5,61.8-10.5,61.8-10.4
              C61.8-10.4,61.8-10.4,61.8-10.4"
              />
              <path
                className="st2"
                d="M54.1-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-10.5,54.1-10.5,54.1-10.4
              C54.1-10.4,54.1-10.4,54.1-10.4"
              />
              <path
                className="st2"
                d="M46.5-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-10.5,46.5-10.5,46.5-10.4
              C46.5-10.4,46.5-10.4,46.5-10.4"
              />
              <path
                className="st2"
                d="M38.8-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1C40.9-8,40.8-8.2,41-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-10.5,38.8-10.5,38.8-10.4
              C38.8-10.4,38.8-10.4,38.8-10.4"
              />
              <path
                className="st2"
                d="M31.1-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-10.5,31.1-10.5,31.1-10.4
              C31.1-10.4,31.1-10.4,31.1-10.4"
              />
              <path
                className="st2"
                d="M23.5-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-10.5,23.5-10.5,23.5-10.4
              C23.5-10.4,23.5-10.4,23.5-10.4"
              />
              <path
                className="st2"
                d="M15.8-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1C17.9-8,17.8-8.2,18-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-10.5,15.8-10.5,15.8-10.4
              C15.8-10.4,15.8-10.4,15.8-10.4"
              />
              <path
                className="st2"
                d="M8.2-10.4c0,0.1,0,0.1,0,0.2C8.1-10.1,8-10.2,8-10.1C7.9-10,7.9-9.8,7.9-9.8c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-8.7,8.2-8.5,8.3-8.3c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-10.5,8.2-10.5,8.2-10.4
              C8.1-10.4,8.1-10.4,8.2-10.4"
              />
              <path
                className="st2"
                d="M0.5-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1C0.3-10,0.2-9.8,0.2-9.8c-0.2,0.1,0,0.2,0,0.3
              c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-8.2,1-7.8,1.3-7.7c0.2,0,0.4-0.1,0.5-0.1
              c0.2,0,0.4,0,0.6-0.1C2.6-8,2.5-8.2,2.7-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
              c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
              c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-10.5,0.5-10.5,0.5-10.4
              C0.5-10.4,0.5-10.4,0.5-10.4"
              />
            </g>
          </g>
          <g>
            <path
              className="st2"
              d="M69.4-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C69.8-0.5,70-0.1,70.2,0c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-2.8,69.4-2.9,69.4-2.8
            C69.4-2.7,69.4-2.8,69.4-2.8"
            />
            <path
              className="st2"
              d="M61.8-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C61.6-1,61.8-0.8,62-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-2.8,61.8-2.9,61.8-2.8
            C61.8-2.7,61.8-2.8,61.8-2.8"
            />
            <path
              className="st2"
              d="M54.1-2.8c0,0.1,0,0.1,0,0.2C54-2.5,54-2.5,53.9-2.5c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C54-1,54.1-0.8,54.3-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1C56.9-0.8,57-1,57.1-1.2c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-2.8,54.1-2.9,54.1-2.8
            C54.1-2.7,54.1-2.8,54.1-2.8"
            />
            <path
              className="st2"
              d="M46.5-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C46.8-0.5,47-0.1,47.2,0c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-2.8,46.5-2.9,46.5-2.8
            C46.5-2.7,46.5-2.8,46.5-2.8"
            />
            <path
              className="st2"
              d="M38.8-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C38.6-1,38.8-0.8,39-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-2.8,38.8-2.9,38.8-2.8
            C38.8-2.7,38.8-2.8,38.8-2.8"
            />
            <path
              className="st2"
              d="M31.1-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C31-1,31.1-0.8,31.3-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-2.8,31.1-2.9,31.1-2.8
            C31.1-2.7,31.1-2.8,31.1-2.8"
            />
            <path
              className="st2"
              d="M23.5-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C23.8-0.5,24-0.1,24.3,0c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-2.8,23.5-2.9,23.5-2.8
            C23.5-2.7,23.5-2.8,23.5-2.8"
            />
            <path
              className="st2"
              d="M15.8-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C15.7-1,15.8-0.8,16-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
            c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
            c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-2.8,15.8-2.9,15.8-2.8
            C15.8-2.7,15.8-2.8,15.8-2.8"
            />
            <path
              className="st2"
              d="M8.2-2.8c0,0.1,0,0.1,0,0.2C8.1-2.5,8-2.5,8-2.5C7.9-2.4,7.9-2.2,7.9-2.1c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-1,8.2-0.8,8.3-0.7C8.5-0.5,8.7-0.1,8.9,0c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
            c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
            c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8C9-3.5,8.8-3.4,8.7-3.4
            C8.5-3.3,8.6-3.2,8.4-3.1C8.3-3.1,8.2-3,8.2-2.9C8.2-2.8,8.2-2.9,8.2-2.8C8.1-2.7,8.1-2.8,8.2-2.8"
            />
            <path
              className="st2"
              d="M0.5-2.8c0,0.1,0,0.1,0,0.2C0.4-2.5,0.4-2.5,0.3-2.5C0.3-2.4,0.2-2.2,0.2-2.1c-0.2,0.1,0,0.2,0,0.3
            c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C0.3-1,0.5-0.8,0.7-0.7C0.9-0.5,1-0.1,1.3,0c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
            c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1C3.3-0.8,3.4-1,3.5-1.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
            c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8C1.3-3.5,1.2-3.4,1-3.4
            C0.9-3.3,0.9-3.2,0.7-3.1C0.6-3.1,0.5-3,0.5-2.9C0.5-2.8,0.5-2.9,0.5-2.8C0.5-2.7,0.5-2.8,0.5-2.8"
            />
          </g>
        </g>
      </pattern>
      <g>
        <path
          d="M32.6,27.1C32.6,27.1,32.7,27.1,32.6,27.1c0.1-0.1,0.3-0.5,1.4-0.2c0,0,0,0,0,0c-0.2-0.2-0.2-0.5-0.2-0.8
        c0.1,0,0.1-0.1,0-0.1c-0.3,0-0.7,0.1-0.9,0.2c-0.1,0-0.1,0.1,0,0.1C32.6,26.6,32.6,27,32.6,27.1z"
        />
        <path
          d="M34.4,28.4c0.2-0.4,1.1-0.3,1.7-0.1c0,0,0.1,0,0,0c-0.5-0.9-0.3-1.3-0.2-1.4c0.2,0,0.1-0.1,0-0.1c0,0-0.8,0.1-1.4,0.2
        c-0.1,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0C34.2,27.3,34,28,34.4,28.4C34.3,28.4,34.4,28.4,34.4,28.4z"
        />
        <path
          d="M34.5,26.9C34.5,26.9,34.5,27,34.5,26.9c0.1-0.2,0.6-0.4,1.4-0.2c0,0,0,0,0,0c-0.2-0.2-0.3-0.8-0.1-1c0.1,0,0.1,0,0.1,0
        c0,0,0,0-0.1,0c-0.4,0-0.8,0.1-1.1,0.2c-0.1,0-0.1,0.1,0,0C34.4,26.2,34.5,26.8,34.5,26.9z"
        />
        <path
          d="M36.5,26.9c-0.5,0.6-0.2,1.2-0.2,1.2s0.3-0.4,0.9-0.2c-0.3,0.3-0.6,0.6-0.6,0.6c-0.3,0.4,0,1.1,0,1.1s1.6-0.2,1.7-0.2
        c0.2,0,0.1-0.1-0.1-0.1c-0.2-0.2-0.5-1.2-0.4-1.9c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.1-0.5,0-0.8,0-0.9c0.1,0,0.1-0.1,0-0.1C37,26.8,36.6,26.9,36.5,26.9C36.4,26.9,36.4,27,36.5,26.9z"
        />
        <path
          d="M30.7,29.1c0.2-0.1,0.6,0,1,0.2c0,0,0,0,0,0c0.1-0.5,0.2-0.9,0.6-1.3c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0,0,0
        c0-0.1,0.3-0.6,1.6-0.1c-0.7,0.1-1,0.1-1.6,0.2c-0.1,0-0.1,0.1,0,0.1c-0.6,0.4-0.3,0.9,0,1.1c0.2-0.2,0.7-0.4,1.8,0.2c0,0,0,0,0,0
        c-0.2-0.5-0.4-1.4-0.1-1.5c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0-0.1,0c-0.3-0.6-0.3-0.8-0.1-1.2c0.1,0,0.1,0,0.1,0c0,0,0,0-0.1,0
        c-0.4,0-1,0.1-1.4,0.2c-0.1,0-0.2,0.1,0,0.1c-0.2,0.2-0.3,0.4-0.3,0.6C31.5,28.3,30.8,28.9,30.7,29.1C30.6,29,30.6,29.1,30.7,29.1z
        "
        />
        <path
          d="M36.1,26.3c0.2-0.1,0.5,0.2,0.6,0c0,0,0,0.3,0,0.4c0,0.1,0,0,0-0.1c0-0.1,0-0.6,0-0.9c0-0.1-0.1-0.1-0.1,0
        c-0.2,0.2-0.7-0.1-0.7,0.1C36.2,26.1,36.2,26.2,36.1,26.3z"
        />
        <path d="M30.8,28.3L30.8,28.3C30.8,28.3,30.8,28.3,30.8,28.3z" />
        <path
          d="M33.9,15.7c-0.3,0.8-0.1,1.5,0.7,1.5c0.8,0,1-0.7,0.7-1.5c0.7,1,1.6,0.3,1.3-0.4c0.6-0.2,0.7-1,0.1-1.2
        c-0.7-0.2-1.3,0.2-1.6,0.5c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.3,0-0.4c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.1-0.5,0-0.4
        c0,0-0.1,0.4-0.1,0.4c-0.1,0.1-0.1,0.3-0.2,0.3c0,0-0.1,0-0.2,0c0-0.1-0.1-0.2-0.2-0.2l0,0c0,0,0,0,0,0l0,0c0,0-0.1,0.1-0.2,0.2
        c-0.1,0-0.1,0-0.2,0c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0-0.4-0.1-0.4c-0.1-0.1-0.1,0.3,0,0.4c0,0.2,0.1,0.3,0.2,0.4
        c-0.1,0.1-0.1,0.3,0,0.4c0,0,0,0.1,0,0.1c-0.3-0.2-0.9-0.7-1.6-0.5c-0.6,0.2-0.5,1,0.1,1.2C32.2,15.9,33.1,16.7,33.9,15.7z
         M34.6,16.9c0,0-0.8,0-0.6-0.7c0.2,0.1,0.5,0.1,0.6,0.1c0,0,0.1,0,0.1,0c0.1,0,0.4,0,0.6-0.1C35.3,16.9,34.6,16.9,34.6,16.9z
         M32.4,14.6c0.1-0.6,1.3-0.1,1.7,0.2c-0.3,0.6,0.4,0.7,0.5,0.7l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.1,0,0.7,0,0.5-0.7
        c0.4-0.3,1.6-0.9,1.7-0.2c0.1,0.5-0.8,0.9-1.6,0.7c0.5,0.3,0.9,0.2,1.2,0.1c0.3,0.4-0.4,1-1.3,0c0,0.1,0.1,0.3,0.1,0.4
        c-0.1,0.1-0.4,0.1-0.6,0.1l0,0v0c-0.2,0-0.5-0.1-0.6-0.1c0-0.1,0.1-0.3,0.1-0.4c-0.9,1-1.5,0.4-1.3,0c0.3,0.1,0.7,0.1,1.2-0.1
        C33.2,15.5,32.3,15.1,32.4,14.6z"
        />
        <path
          d="M30.7,43.1C30.7,43.1,30.7,43.1,30.7,43.1c0.1-0.2,0-0.4,0-0.7c-0.1-0.4-0.2-0.6-0.4-0.6c0.1-0.2,0.1-0.4,0.1-0.4
        s-0.2,0-0.3,0.2c-0.1-0.1-0.4-0.2-0.7-0.2c-0.4,0-0.6,0.1-0.7,0.2c-0.1-0.1-0.3-0.2-0.3-0.2s0,0.2,0.1,0.4
        c-0.2,0.1-0.3,0.3-0.4,0.6c-0.1,0.3-0.1,0.6,0,0.7c-0.2,0.1-0.3,0.2-0.3,0.2s0.1,0.1,0.3,0c0,0.2,0.2,0.4,0.5,0.6
        c0.3,0.2,0.5,0.3,0.7,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.3,0.1,0.3s0.1-0.1,0.1-0.3c0.2,0.1,0.4-0.1,0.7-0.3
        c0.1-0.1,0.5-0.4,0.5-0.6c0.1,0,0.4,0,0.4,0S30.9,43.1,30.7,43.1z M28.4,42.5c0.1-0.2,0.2-0.4,0.3-0.4c0.1,0,0.2,0.1,0.3,0.3
        c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c-0.1,0-0.3,0-0.3,0C28.5,43,28.4,43,28.4,43
        C28.3,42.9,28.3,42.7,28.4,42.5z M29.2,43.4c0.1,0.3,0.1,0.3,0,0.4c-0.1,0.1-0.2,0-0.4-0.2c-0.2-0.2-0.3-0.3-0.3-0.4
        c0-0.1,0.1-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1C29.3,43.2,29.2,43.3,29.2,43.4z
         M29.4,42.5c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.3-0.3-0.2-0.4c0-0.1,0.2-0.1,0.5-0.1c0.3,0,0.4,0,0.5,0.1
        c0,0.1,0,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.1,0.3C29.5,42.5,29.4,42.5,29.4,42.5z M29.9,43.6c-0.2,0.1-0.4,0.2-0.4,0.2
        c-0.1-0.1-0.1-0.1,0-0.4c0-0.1,0-0.2-0.1-0.3c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0.1,0.2,0.2,0.2
        c0.3,0,0.4,0.1,0.4,0.2C30.3,43.3,30.1,43.5,29.9,43.6z M30.3,43c-0.1,0.1-0.2,0-0.4-0.1c-0.1,0-0.2,0-0.2,0c0,0,0,0,0-0.1
        c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.2,0.2-0.3,0.3-0.3c0.1,0,0.2,0.2,0.3,0.4
        C30.4,42.7,30.4,42.9,30.3,43z"
        />
        <path
          d="M41.1,43.1c0.1-0.1,0.1-0.4,0-0.7c-0.1-0.3-0.2-0.5-0.4-0.6c0.1-0.1,0.1-0.4,0.1-0.4s-0.2,0-0.3,0.2
        c-0.1-0.1-0.3-0.2-0.7-0.2c-0.3,0-0.6,0-0.7,0.2c-0.1-0.1-0.3-0.2-0.3-0.2s0,0.2,0.1,0.4c-0.2,0.1-0.3,0.3-0.4,0.6
        c-0.1,0.3-0.1,0.5,0,0.7c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.3,0.2s0.2,0.1,0.4,0c-0.1,0.2,0.3,0.5,0.5,0.6c0.2,0.2,0.5,0.3,0.7,0.3
        c0,0.2,0.1,0.3,0.1,0.3s0.1-0.2,0.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.1,0.4,0,0.7-0.3c0.2-0.2,0.4-0.4,0.5-0.6c0.2,0,0.3,0,0.3,0
        S41.3,43.1,41.1,43.1z M38.8,42.5c0.1-0.2,0.2-0.4,0.3-0.4c0.1,0,0.2,0.1,0.3,0.3c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0
        c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c-0.1,0-0.2,0-0.2,0C39,43,38.8,43,38.8,43C38.7,42.9,38.7,42.7,38.8,42.5z M39.7,43.4
        c0.1,0.3,0,0.3,0,0.4c-0.1,0.1-0.2,0-0.4-0.2c-0.2-0.1-0.3-0.3-0.3-0.4c0-0.1,0.1-0.1,0.4-0.2c0.1,0,0.2-0.1,0.2-0.2
        c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1C39.7,43.2,39.6,43.3,39.7,43.4z M39.6,42.6c0-0.1,0-0.2-0.1-0.3
        c-0.2-0.2-0.2-0.3-0.2-0.4c0-0.1,0.2-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1c0,0.1,0,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.1,0.3
        c0,0-0.1,0-0.2,0C39.7,42.5,39.6,42.5,39.6,42.6z M40.3,43.6c-0.2,0.2-0.4,0.2-0.4,0.2c-0.1-0.1-0.1-0.1,0-0.4c0-0.1,0-0.2-0.1-0.3
        c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0,0.3,0.1,0.4,0.2C40.7,43.3,40.5,43.4,40.3,43.6z M40.7,43
        c-0.1,0.1-0.1,0-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c0.1,0,0.2-0.1,0.2-0.2c0.1-0.2,0.2-0.3,0.3-0.3
        c0.1,0,0.2,0.2,0.3,0.4C40.8,42.7,40.8,42.9,40.7,43z"
        />
        <g>
          <g>
            <path
              d="M25,40.9c-0.2,0.1-0.2,0.3-0.1,0.5c0.1,0.2,0.2,0.3,0.4,0.2c0.2-0.1,0.2-0.3,0.1-0.5C25.4,40.9,25.2,40.8,25,40.9z
             M41.5,40.2c0.2,0.1,0.4,0,0.5-0.1c0.1-0.2,0.1-0.3-0.1-0.5c-0.2-0.1-0.3,0-0.4,0.1C41.3,39.9,41.3,40.1,41.5,40.2z M31.5,38.1
            c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.4C31.8,38.2,31.7,38,31.5,38.1z M43,40.6
            c0.1-0.1,0-0.2-0.1-0.2l-0.1-0.1l-0.2,0.3l0.1,0C42.9,40.7,43,40.6,43,40.6z M39.3,38.4l-0.3,0.3l0.2,0.1L39.3,38.4z M40.6,39.1
            c0-0.1,0-0.1-0.1-0.2l-0.1,0l-0.1,0.2l0.1,0C40.5,39.2,40.6,39.2,40.6,39.1z M40.5,39.5c0-0.1,0-0.1-0.1-0.2l-0.1,0l-0.1,0.2
            l0.1,0.1C40.4,39.6,40.5,39.6,40.5,39.5z M53.7,24.1c-0.4,0.5-1,0.7-1.3,0.6c0.4-0.4,0.3-0.6,0.1-0.8c-0.6,1.1-2.5,0.1-2.7,1.7
            c-2.1,1.3-0.1,3.9,0.2,4.1c-0.4,0.1-1.2-0.2-1.2-0.9c-0.7,0.5-0.2,1.2,0,1.4c-0.1,0.3,0,1,1,1.2c-0.8,0.2-0.7,1.3,0,1.5
            c-0.3-0.5,0.2-0.9,0.6-0.7c0.5,0.3,0.5,1.1-0.2,1.3c-0.6,0.2-1.1-0.4-1.4-1c-0.3-0.8-0.3-1.7-1.4-2.8c-0.9-0.9-0.8-2.5-0.2-3.2
            c0.3,0.2,0.7,0.2,1.2,0.1c-0.6-0.4-0.8-1.1-0.6-1.8c0.1,0.1,0.3,0.2,0.6,0.2c-0.4-0.4,0.1-1.5-0.3-2.3c0.3,0,0.5,0,0.7-0.1
            c-0.3-0.1-0.7-0.4-0.7-0.4s0.5-0.2,0.4-0.5c-0.5,0-0.5-0.1-0.5-0.1s0.4-0.4,0.4-0.7c0-0.2-0.5-0.2-0.9-0.1c0.2-0.2,0.6-0.9,1-1.4
            c-0.3-0.2-0.4-0.2-0.6-0.3l-0.2,0.3l-0.4-0.2l0.2-0.4c-0.2-0.1-0.4-0.2-0.6-0.3l-0.1,0.4c0,0-0.3-0.1-0.6-0.2
            c0-0.1,0.1-0.4,0.1-0.4c-0.2,0-0.4-0.1-0.7-0.1l0,0.4c0,0-0.3,0-0.5,0c0,0,0-0.4,0-0.4c-0.2,0-0.4,0-0.7,0c0,0.3,0.1,1.8,0.1,1.8
            s-0.6-0.6-0.7-0.5c-0.3,0.3-0.1,0.8-0.1,0.8s-0.3,0.2-0.9-0.1c0,0.4,0.2,0.6,0.2,0.6s-0.7,0.3-0.8,0.9c0.2-0.1,0.6-0.1,0.7-0.1
            c-0.6,0.4-0.7,1.6-1.5,2.1c0.1,0.2,0.3,0.3,0.7,0.1c-0.3,0.3-0.4,1.2-0.4,1.2s0,0-0.3,0c-1-0.6-1.4-0.4-2.2-0.8
            c-0.3,0-0.6,0.1-0.9,0.2c-0.1,0-0.1,0-0.2,0c-0.1-0.3-0.3-1-0.9-1.1c-0.1-0.1-0.3-0.3-0.4-0.7c-0.1-0.3,0.3-1.2,0.5-2.1
            c0,0,0,0,0,0c0-0.2,0.1-0.3,0.1-0.5c0.5,0.1,1.8,0,2.1,1c0,0-0.6,0.5-1.4,0.1c-0.1,0.7,0.2,1,0.2,1s-0.2,0.6-1,1
            c1.2,0.3,1.6,0.1,1.6,0.1s0.4,0.6,1,0.6c-0.6-0.8-0.1-1.6-0.1-1.6v0c0.7-1,0.2-1.9-0.2-2.3c1.3-0.5,1.3-1.7,1.7-1.9
            c-0.6-0.2-0.9-0.1-0.9-0.1s-0.1-0.5-0.2-0.9c0.9,0.5,2,1.2,2.9,1.2c1.6,0,2.2-1.1,2.1-2.3c-0.2-1.1-1.3-2.3-0.5-3
            c-1-0.1-1.6,0.2-1.7,1c-0.7-0.1-1.5-0.5-1.6-0.7c-0.1-0.4,0.1-0.8,0.5-1c-0.4-0.1-0.9,0-1.3,0.5c-0.9,1.2,1.2,3,1.2,3s0,0,0,0
            c-0.7,0-1.5-1-2.1-1.2c-0.5-0.2-0.8,0.1-0.9,0.3c-0.2,0.7-0.4,1-0.8,1.9c-0.3,0.7,0.4,1.2,0.6,1.8c0.1,0.3,0.2,0.7-0.5,0.7
            c-0.2,0-0.7-0.1-1.1-0.3c-0.3-0.1-0.4-0.3-0.5-0.5c0.4-0.1,0.4-0.5,0.2-0.7c0.6-0.6,1-1.4,1-2.3c0-1.8-1.5-3.3-3.3-3.3
            c-1.8,0-3.3,1.5-3.3,3.3c0,0.9,0.4,1.7,0.9,2.3c-0.3,0.3,0,0.8,0.6,0.7c0,0,0,0.1-0.1,0.1c-0.5,0.3-1.5,0.6-1.9,0.6
            c-0.6,0-0.6-0.4-0.5-0.7c0.2-0.5,0.9-1.1,0.6-1.8c-0.4-0.8-0.6-1.1-0.8-1.9c0-0.2-0.3-0.4-0.9-0.3c-0.6,0.2-1.3,1.2-2.1,1.2
            c0,0,0,0,0,0s2.1-1.8,1.2-3c-0.3-0.5-0.9-0.6-1.3-0.5c0.4,0.2,0.6,0.6,0.5,1c0,0.2-0.9,0.5-1.6,0.7c-0.1-0.9-0.7-1.1-1.7-1
            c0.9,0.7-0.3,1.9-0.5,3c-0.2,1.2,0.5,2.3,2.1,2.3c1,0,2.1-0.7,2.9-1.2c-0.1,0.4-0.2,0.9-0.2,0.9s-0.4-0.1-0.9,0.1
            c0.3,0.2,0.3,1.4,1.7,1.9c-0.5,0.5-1,1.3-0.2,2.3v0c0,0,0.5,0.8-0.1,1.6c0.7,0,1-0.6,1-0.6s0.5,0.2,1.6-0.1c-0.8-0.4-1-1-1-1
            s0.3-0.3,0.2-1c-0.8,0.4-1.4-0.1-1.4-0.1c0.3-1,1.6-0.9,2.1-1c0,0,0,0,0,0c-0.3,0.1-0.7,0.4-0.7,0.5c1.8,1.5,1.9,2.9,1.9,3
            c0,0-0.1,0.2-0.4,0.3c-0.1-0.1-0.3-0.1-0.4,0.3c0,0.1,0,0.2-0.1,0.4c-0.4,0-0.8,0-1.2,0.1l-0.2-0.1c0,0-0.2-0.2-0.5-0.2
            c-0.6,0.2-1.4,0-2.4,0.4c-0.5-0.5-1-0.7-1.1-0.8c0.2-0.3-0.2-0.4-0.3-0.3c-0.1-0.1-0.4-0.2-0.4-0.3c0.1-0.1-0.1-0.4-0.5-0.2
            c-0.6-0.4-0.7-0.7-0.7-0.7s0.9-0.7,1.2,0.2c0-0.5,0.4-0.5,0.4-0.7c0-0.1,0-0.1-0.1-0.1c-0.1,0,0-0.1,0-0.3c-0.1,0-0.2,0-0.3,0.1
            c-0.3,0.4-0.5,0.2-0.4,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.2-0.3,0.2c0.3-0.5,0.7-0.6,1-0.6c0.3,0,0.7,0.2,1.2,0.1
            c0.4-0.1,0.8-0.6,0.6-0.9c-0.1,0.4-0.8,0.7-1.3,0.5c-0.5-0.1-1,0-1.3,0.3c-0.4,0.4-0.5,0.5-0.7,0.5c-0.3,0-0.1-0.7,0.7-1
            c0.2,0,0.2,0.2,0.2,0.2s0.2,0,0.3-0.1c0.1-0.2,0.3-0.3,0.4-0.4c0.3-0.1,0.3,0.3,0.3,0.3s0.1,0,0.4-0.1c0.1-0.4,0.5-0.7,0.8-0.5
            c0-0.5-0.5-0.8-0.6-0.8c-0.6,0-0.6-0.4-0.8-0.3c-0.1,0-0.2,0.2-0.1,0.3c-0.2,0.2-0.7,0.1-1.2,0.2c0.2-0.2-0.1-0.3-0.5-0.2
            c-0.2,0.1-0.5,0.1-0.8,0.2c-0.2-0.4-0.6-1.3-1.1-1.8c-0.7-0.8-1-0.8-1.5-1c-0.3-0.2-0.8-0.4-1-0.7c-0.2-0.2-0.1,0.1-0.1,0.1
            c0.2,0.8,1.2,1.3,1.6,1.5c-0.3,0-0.3,0-0.6,0c-0.6-0.4-1.2-0.3-1.9-1.1c-0.2-0.2-0.4-0.5-0.4-0.1c0,0.3,0.2,0.9,1.1,1.4
            c-0.1,0.1-0.4,0.1-0.8,0.1c-0.4,0,0,0.2,0,0.2c0.5,0.3,1.7-0.1,2.1,0.3c0,0-0.5,0.2-1.3,0.2c-0.7-0.1-0.3,0.2-0.1,0.3
            c0.5,0.2,1.7,0,2.2,0.4c-0.1,0.1-0.5,0.3-1.3,0c-0.5-0.1,0.1,0.5,0.8,0.5c0.3,0,0.7-0.2,1,0c-0.4,0.1-0.7,0.5-1.1,0.5
            c0.1,0.2,0.5,0.3,0.9,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.7-0.1-1.5-0.1-1.9,0.7c-0.3,0.7-1.3,0.8-1.4,0.1c-0.5,1.3,0.9,1.7,2.1,1.3
            c-0.6,0.4-0.7,1.2,0.3,1.4c-0.3-0.8,0.5-0.9,1.1-0.7C21.1,24,21,25.3,22,25.7c-0.1-0.3,0.1-0.9,0.7-0.9c0.1,0.1,0.1,0.2,0.1,0.2
            c-0.2,0.5,0.3,0.3,0.3,0.3s0.2,0.3,0.4,0.5c-0.3,0.4,0,0.4,0.2,0.4c-0.1,0.7,0.1,1.3,0,1.8c-0.1,1.1-0.7,1.6-1.2,2.4
            c-0.4,0.8-0.5,1.3-0.8,1.7c-0.4,0.4-1.2,0.9-2.5,0.5c-0.5-0.1-0.9-0.9-0.5-1.4c0.4-0.6,1.5,0,0.7,0.6c1-0.1,0.8-1.4-0.1-1.5
            c1.2,0.1,2-0.5,1.9-1.2c0.3-0.4,0.1-1-0.5-1.3c0.2,1.1-1.1,1.2-1.2,0.9c1.1-1.5,0.8-2.8,0.6-3.3c-0.6-1.2-2-2-3.9-1
            c0.2,0,0.4,0,0.5,0.1c-1,0.6-0.6,1.9-0.3,2.5c0.1,0.2,0.3,1-0.2,1.3c-0.4,0.2-1.1-0.2-0.6-1.3c-1.4,1.4,0.1,2.7,1.1,2.1
            c0.1,0.3,0,0.5-0.1,0.8c1.8-1,1.3-2.4,1-2.9c1.2,0.7,2-0.3,1.3-1c0,0.3-0.2,0.6-0.6,0.6c-0.6-0.1-0.6-0.8-0.2-1.1
            c0.5-0.4,1.1,0,1.3,0.3c0.3,0.5,0.3,1.4-0.4,2.4c-0.5,0.6-2.5,3-0.6,4.8c-2.1,0.6-1.1,2.5-0.2,2.7c-0.1-0.1-0.5-0.9,0.1-1.2
            c0,0.6,0.7,1.1,1.5,1.1c-1.3-2,1.4-1.7,2.5-3c0,1,0.7,1.3,0.5,1.6c-0.3,0.7-1.2,0.7-1.5,0.6c0.1,0.6,0.7,0.5,0.7,0.5
            s-0.2,0.2-0.6,0.2c0.1,0.3,0.2,1.1,0.1,1.9c0,0.1-0.3,0.6-0.6,0.8c0.2,0.2,0.4,0.2,0.6,0.1c-0.2,0.2-0.2,0.5-0.1,0.7
            c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0.1,0.2,0.3,0.2,0.3s-0.2,0.6,0.6,0.8c0.4,0.1,0.8,0.1,0.8,0.1s-0.1-0.7-0.3-1
            c0.1-0.5-1.2-1.7-0.3-3c0.4-0.5,1.3-0.6,1.9-1.5c0.1-0.1,0.4-1,0.3-1.5c1.7,0,1.8,1.7,0.6,2.2c0.1,0.1,0.3,0.1,0.6,0
            c-0.2,0.3-0.4,0.4-0.5,0.5c1.2,0,1.8,0.3,2.1,0.5c0.2,0.1,0.2,0.3,0.1,0.7c0.2,0,0.4-0.2,0.4-0.2s0,0.3,0.3,0.3
            c0-0.1,0.1-0.2,0.2-0.2c0.1,0.2,0.1,0.2,0.2,0.3c0.6,0.1,1.3,0,1.3,0s-0.1-0.5-0.8-0.8c-0.3-0.7-1.8-1-2-1.2
            c-0.1-0.1,0-0.3,0-0.4c0.2-0.5,0.7-1.2,0.3-2c-0.1-0.3,0.1-1.4-1.2-1.5c-0.6,0-1.3,0.2-1.3,0.2s0.8-0.7,1.2-1.1
            c0.4-0.4,1.1-1.3,1.1-1.3s0.6,0.4,0.9,0.6c0,0.1,0.1,0.2,0.1,0.3c0,0.3,0,0.6,0,0.9c0,0.1-0.1,0.1-0.1,0.2
            c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.1,0.1,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.5,0.2,1.1,0.2,1.1s0.2-0.2,0.4-0.4
            c0,0.1,0,0.1,0,0.1c0,1.4,1,2.9,2.2,4c0.3,0,0.6,0,0.9,0.1c-1.3-1.2-2.5-2.8-2.5-4.2c0-0.5,0-1.1,0-1.8c1.4,1.5,4.7,5.6,5.9,7.4
            c-0.3,0.3-0.6,0.5-0.8,0.6c-0.3-0.2-0.8-0.6-1.3-1c0,0,0,0,0,0c-0.1-0.2-0.3-0.4-0.6-0.5c-3.5-1.7-8.5,3.8-10.4,3.4
            c-0.3-0.2-0.4-0.7-0.4-1c0-0.5-0.9-0.8-2.7,0.1c0,0,1,1.3,1.2,2.7l0,0.2c0,0,0.7-0.4,1.5-0.6c0-0.4-0.3-1.1-0.2-1.1
            c0.5,0.7,0.8,1.9,0.9,2.1c0.1,0.3,0.4,0.4,1.4,0.2c1.6-0.3,5-3.3,7.3-3.6c0.6-0.1,1.2,0,1.3,0.5c-0.3-0.3-0.9-0.3-1.2-0.3
            c-0.2,0.2-0.4,0.4-0.3,0.7c0,0,0,0,0,0c0.2,0.6,0.6,1.5,0.6,1.8c0,0.7,1.8,0.9,2.9,0.9c1.1,0,2.9-0.3,2.9-0.9
            c0-0.3,0.3-1.3,0.6-1.8h0l0,0c0.1-0.3-0.1-0.6-0.3-0.7c-0.3-0.1-0.9,0-1.2,0.3c0.2-0.5,0.7-0.6,1.3-0.5c2.2,0.3,5.7,3.3,7.3,3.6
            c1.1,0.2,1.4,0.1,1.4-0.2c0.1-0.3,0.4-1.4,0.9-2.1c0,0-0.2,0.7-0.2,1.1c0.8,0.2,1.5,0.6,1.5,0.6l0-0.2c0.2-1.4,1.2-2.7,1.2-2.7
            c-1.8-0.8-2.7-0.5-2.7-0.1c0,0.3,0,0.8-0.4,1c-1.9,0.4-6.9-5.1-10.4-3.4c-0.2,0.1-0.4,0.3-0.6,0.5c0,0,0,0,0,0c0,0,0,0,0,0
            c0,0,0,0,0,0c0-0.2,0.1-0.5,0.5-0.7c-1.1-1.6-3.7-4.8-5.1-6.4c0.6,0,1.2,0,1.7,0c1.4,1.6,3.4,4.1,4.5,5.7
            c-0.1,0.1-0.3,0.2-0.4,0.4c0.3-0.1,0.7-0.1,0.9-0.1c1.2-1.2,2.2-2.7,2.2-4.1c0-0.4,0-0.8,0-1.2c0.1,0,0.1-0.1,0.2-0.1
            c0.1,0,0.1,0,0.1,0l0,0c0,0,0.2,0.1,0.4,0c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0.3-0.3,0.3-0.6,0.4-0.8
            c0.1-0.2,0.8-0.5,1.4-0.7c0.4,0.6,1.8,1.2,2.2,1.5c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0h0c-0.8-0.1-1.3-0.1-1.7,0h0
            c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.3-0.7,0.9-0.9,1.6c-0.1,0.6,0.6,1.3,0.9,1.8c0.3,0.5,0.2,0.9-0.1,1.2c-0.8,0.6-1.5,0.6-1.5,0.6
            s-0.3,0-0.5,0c-0.1-0.1-0.3-0.1-0.3-0.1c-0.5,0-0.6,0.5-0.5,0.7c-0.1,0-0.2,0.2-0.2,0.4c0.2-0.2,0.3-0.2,0.3-0.2s0,0.1,0.2,0.2
            c-0.1,0.1-0.1,0.3-0.1,0.4c0.1-0.2,0.2-0.2,0.3-0.2c0.1,0.1,0.1,0.1,0.3,0.1c0,0.1,0,0.2,0,0.4c0.1-0.1,0.1-0.3,0.3-0.3
            c0.1,0,0.5,0.2,1,0.1c0.8-0.8,1.4-0.8,1.4-0.8s0.6,0.1,1.2,0c-0.4-0.1-0.5-0.6-0.5-0.6s0.5,0.1,0.8,0c-1.4-1.4-1.2-3,0.8-3.3
            c-0.2,0.8-0.1,1.9,0.3,2.3c0.3,0.3,1,1.3,1,1.7c0,0.2,0,0.5-0.2,0.7c0,0-0.4,0.4-0.9,0.5c-0.8-0.6-1.3,0.3-1.1,0.5
            c0,0.1-0.2,0.1-0.2,0.4c0.2-0.2,0.4-0.2,0.4-0.2l0.2,0.1c0,0-0.1,0.1,0,0.5c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0.1,0.3,0.1,0.4,0.1
            c0,0.1,0,0.3,0.1,0.5c0.1-0.2,0.2-0.4,0.2-0.4s0.3,0.3,0.8,0.1c0.5-0.6,0.9-1.1,1.2-1.4c0.3,0,0.4,0.2,1.2-0.1
            c-0.6-0.4-0.7-0.6-0.7-0.6s0.4,0.3,0.8,0c-0.5-0.3-0.6-0.5-0.8-0.7c-0.3-0.3-0.6-0.5-0.6-1.3c0-0.7,0.5-1.7,0.5-2.2
            c0.3,1,2.4,1.1,2,2.3c0.4,0,0.9-0.9,0.3-1.6c0.5-0.2,2-1.7-0.2-4.2c-0.8-1-2-3.1-0.3-3.7c0.7-0.2,1.8,0.1,1.5,1.3
            c1,0,1.3-1.3,0.5-1.7C53.2,25.8,53.8,25,53.7,24.1z M42.4,15.9c0,0-2.3-1.6-1.7-2.8c0.4,1.1,1.8,1.2,2.6,1.5
            c-0.1-0.4,0-1.3,0.8-1.3c-0.4,0.2,0,1,0.2,1.6c0.1,0.3,0.8,1.6,0.2,2.4c0.1-0.5,0-1.3-1.5-2.1c1.6,1.1,1.6,2.4,0.5,2.6
            c0.2-0.3,0.1-0.8-0.2-1c-0.3-0.2-1.4,0-1.5,0.5C41.6,16.2,42.4,15.9,42.4,15.9z M39.4,23.3c-0.1-0.1-0.3-0.2-0.5-0.5
            c-0.1,0.1-0.6,0.3-0.9,0.2c0.6-0.4,0.6-0.9,0.6-0.9s-0.3-0.3-0.3-0.7c0.5,0.2,1.2-0.2,1.2-0.2C39.7,21.9,39.1,22.7,39.4,23.3z
             M38.7,17.9c-0.6-0.6-0.6-1.2,0-1.4c0.2-0.1,0.5,0,0.9,0.2c0.4,0.7,0.4,1.6,0.4,1.6s0.3-0.2,0.6-0.2c-0.2,0.6-0.7,1-1.2,1.3
            c0.3-0.7,0.4-1.8-0.7-2.6c0.7,0.8,0.7,1.6,0.5,2.2C39.3,18.7,39.2,18.4,38.7,17.9z M27.3,17.2c-0.1-0.5-1.2-0.7-1.5-0.5
            c-0.3,0.2-0.4,0.7-0.2,1c-1.1-0.2-1-1.5,0.5-2.6c-1.5,0.8-1.6,1.6-1.5,2.1c-0.6-0.8,0.1-2.1,0.2-2.4c0.2-0.5,0.5-1.4,0.2-1.6
            c0.8,0,0.9,0.9,0.8,1.3c0.8-0.3,2.2-0.4,2.6-1.5c0.6,1.2-1.7,2.8-1.7,2.8S27.5,16.2,27.3,17.2z M30.8,21.5c0,0.4-0.3,0.7-0.3,0.7
            s0,0.5,0.6,0.9c-0.3,0-0.8-0.1-0.9-0.2c-0.2,0.3-0.3,0.4-0.5,0.5c0.3-0.6-0.4-1.4-0.1-2C29.6,21.3,30.3,21.8,30.8,21.5z M29.8,19
            c-0.2-0.5-0.2-1.3,0.5-2.2c-1,0.8-0.9,2-0.7,2.6c-0.5-0.3-1-0.7-1.2-1.3c0.3,0,0.6,0.2,0.6,0.2s0-0.9,0.4-1.6
            c0.4-0.2,0.7-0.3,0.9-0.2c0.6,0.2,0.6,0.8,0,1.4C29.9,18.4,29.8,18.7,29.8,19z M21.3,21.5c-0.6-0.1-1.1,0.3-1.5,0.8
            c0.2-0.7,1-1.1,1.5-1.1C21.9,21.3,21.7,21.5,21.3,21.5z M16,28.9c0.3-0.1,1.3-0.7,0.9-2C17.8,28.5,16.3,29,16,28.9z M19.6,25.2
            c-0.7-0.6-2.1-0.4-2.5,0.4c0-0.3,0.5-1.1,1.5-1c-0.1-0.1-0.3-0.2-0.6-0.2C18.4,24.2,19.3,24.5,19.6,25.2
            C19.6,25.2,19.6,25.3,19.6,25.2z M19.6,29.6c0.1,0,0.8,0.1,1.3-0.4C20.5,29.9,19.8,29.8,19.6,29.6C19.4,29.7,19.5,29.6,19.6,29.6
            z M20,33.3c-0.6,0.3-1.1,0.4-1.3,0.8c0-0.1,0-0.4,0.2-0.6c-0.5-0.1-0.9,0.3-1,0.4c0-1.1,1.7-0.7,2.3-0.9
            C20.4,33,20.3,33.2,20,33.3z M22.6,38.4c0.1,0.3,0.1,0.3,0.1,0.3s-0.3,0.1-0.5,0c-0.1-0.2-0.2-0.7-0.1-1.2c0-0.1,0.1-0.1,0.2,0
            C22.4,38,22.5,38.2,22.6,38.4z M28.3,35.3c0.4,0.1,0.7,0.3,1,0.5c0.1,0.1-0.2,0.3-0.3,0.2c-0.3-0.2-0.4-0.4-0.8-0.6
            C28,35.3,28.1,35.3,28.3,35.3z M26.2,31.2c0.6-0.1,1.3,0.1,1.4,1.2c0.1,0.4-0.4,1.3-0.4,0.9c0-0.3,0-0.6-0.2-0.7
            c0.2-0.6-0.2-1.1-0.9-1.2C26,31.4,26,31.2,26.2,31.2z M24.3,42.3c-0.4,0.1-0.6,0-0.7-0.3c-0.1-0.3,0-0.6,0.4-0.7
            c0.1-0.1,0.2-0.1,0.3-0.1l0,0.2c-0.1,0-0.1,0-0.3,0c-0.2,0.1-0.3,0.2-0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.2c0.1,0,0.2-0.1,0.2-0.1
            l0.1,0.2C24.5,42.2,24.4,42.2,24.3,42.3z M25.5,41.7c-0.3,0.2-0.6,0.1-0.8-0.2c-0.2-0.3-0.1-0.6,0.3-0.8c0.3-0.2,0.6-0.1,0.8,0.2
            C25.9,41.2,25.8,41.5,25.5,41.7z M26.9,40.8l-0.7-0.4l0.1,0.1l0.3,0.5l-0.2,0.1l-0.5-0.9l0.3-0.1l0.7,0.4l-0.1-0.1l-0.3-0.5
            l0.2-0.1l0.5,0.9L26.9,40.8z M28,40.2c-0.3,0.2-0.6,0.1-0.8-0.2c-0.2-0.3-0.1-0.6,0.2-0.8c0.1-0.1,0.2-0.1,0.3-0.1l0,0.2
            c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.2,0.3-0.1,0.4c0.1,0.2,0.3,0.2,0.5,0.1c0.1-0.1,0.1-0.1,0.2-0.1l0.1,0.1
            C28.2,40.1,28.1,40.1,28,40.2z M28.7,39.8l-0.5-1l0.2-0.1l0.5,1L28.7,39.8z M29.3,39.5l-0.4-1l0.2-0.1l0.3,0.8l0.4-0.2l0.1,0.2
            L29.3,39.5z M30.4,39.1l-0.3-1l0.2-0.1l0.3,1L30.4,39.1z M31.5,38.9c-0.4,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.6,0.5-0.6
            c0.4,0,0.6,0.2,0.6,0.5C32.1,38.7,31.9,38.9,31.5,38.9z M44,40.7l0.6,0.3l-0.1,0.2L44.1,41L44,41.2l0.4,0.2l-0.1,0.2l-0.4-0.2
            l-0.1,0.2l0.4,0.2L44.1,42l-0.6-0.4L44,40.7z M43.1,40.2c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l0,0.2
            c0,0.1,0.1,0.2,0.1,0.2L43,41.4c-0.2-0.1-0.2-0.3-0.2-0.4l0-0.2c0,0,0,0,0,0l-0.1,0L42.4,41l-0.2-0.1l0.5-0.9L43.1,40.2z
             M41.1,39.6c0.2-0.3,0.5-0.4,0.8-0.2c0.3,0.2,0.4,0.5,0.2,0.8c-0.2,0.3-0.4,0.4-0.8,0.2C41,40.2,41,39.9,41.1,39.6z M40.3,38.6
            l0.3,0.2c0.2,0.1,0.3,0.3,0.2,0.4c0,0.1-0.1,0.1-0.2,0.1l0,0c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.2,0.2-0.5,0.1l-0.4-0.2
            L40.3,38.6z M39.2,38.2l0.3,0.1l0,1.1l-0.2-0.1l0-0.3l-0.3-0.1l-0.2,0.3l-0.2-0.1L39.2,38.2z M37.6,37.8l0.3,0l-0.1,0.9l0.5,0.1
            l0,0.2l-0.7-0.1L37.6,37.8z M34.3,42l0,0.2l-0.4,0l0,0.2l0.5,0l0,0.2l-0.7,0l0.1-1.1l0.7,0l0,0.2l-0.4,0l0,0.2L34.3,42z
             M35.3,42.6l-0.3,0l0-0.9l-0.3,0l0-0.2l0.9,0l0,0.2l-0.3,0L35.3,42.6z M36.2,39.9c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.3
            c0.3,0,0.8-0.1,1-0.3c0,0.4-1.1,0.5-1.1,0.5c-0.5,0.1-1.2,0.1-1.8,0.1c-0.6,0-1.3,0-1.8-0.1c0,0-1.2-0.1-1.1-0.5
            c0.2,0.2,0.7,0.3,1,0.3c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.3,0-0.4c0-0.4,0.1-0.8,0.4-1.2c0.7,0.5,1.2,0.9,1.3,0.9
            c0.1-0.1,0.7-0.4,1.3-0.9C36.1,39.2,36.2,39.5,36.2,39.9z M41.4,36.8c0,0-0.3-0.1-0.5,0.2c-0.2,0-0.2-0.3,0-0.4
            c-0.1,0-0.3,0-0.4,0.1c-0.2-0.1-0.1-0.3,0-0.4c-0.1,0-0.3,0-0.4,0.1c-0.3,0,0.2-0.6,0.6-0.2c0.1,0,0.5,0.1,0.4,0.3
            C41.5,36.6,41.4,36.8,41.4,36.8z M41.6,36.5c-0.2,0-0.3-0.1-0.1-0.2c0.1,0,0.4-0.1,0.7-0.3c0.3-0.2,0.6-0.4,0.6-0.3
            C42.6,36,41.8,36.5,41.6,36.5z M42.3,32.3c0.1-0.2,0.1-0.9,0.8-1.1c0.7-0.2,1.3-0.1,1.3-0.1C44.1,31.5,42.9,31.3,42.3,32.3z
             M47.5,22.8c0.6,0.7,0.4,1.4,0.2,1.5c-0.1,0-0.1-0.2-0.1-0.2c-0.2,0.1-0.5,0.4-0.8,0.9C46.5,24,47.5,23.8,47.5,22.8z M47.2,22.2
            c0,0.2-0.4,1-0.8,1.2c0.2-0.3,0.2-0.5,0.3-0.7c0.2-0.2,0.3-0.3,0.4-0.5c0,0-0.3,0.2-0.5,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
            c0.3,0.2,0.6-0.2,0.8-0.2C47.2,21.8,47.2,22.1,47.2,22.2z M45.6,23.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.3,0.1-0.3
            s0.4-0.1,0.4-0.2c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.7-0.4,0.6c0,0.1-0.2,0.3-0.2,0.3S45.7,23.3,45.6,23.3z M45.2,23.8
            c0.1,0,0.1,0,0.2,0.1c0.1,0.5-0.4,0.8-0.7,0.9c-0.8,0.2-0.7-0.3-0.4-0.6c0.2-0.2,0.4-0.4,0.5-0.7c0,0,0.1,0.1,0.2,0.2
            c0,0.2-0.1,0.5-0.5,0.9C45.1,24.4,45.2,24,45.2,23.8z M46.6,20.9c-0.2,0.2-0.2,0.3-0.2,0.4c0.6-0.4,0.7,0.2,0.7,0.3
            c-0.4-0.2-0.6,0-0.8-0.1C46,21.3,46.3,21,46.6,20.9z M47.2,19.5c0,0.1-0.1,0.2-0.1,0.3c-0.4-0.1-0.9-0.3-1.4-0.3
            c0.6,0.2,1,0.3,1.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.3S46,20,44.9,19.9c0-0.2,0-0.5-0.1-0.9C45.4,19,46.6,19.3,47.2,19.5z M42.8,21.3
            c0.3-0.4,0.7-0.4,0.7-0.4s-0.3-0.1-0.3-0.3c0.2,0.1,0.4,0,0.6,0c0.9-0.4,1.1,0.1,1.3,0.3c0-0.1,0-0.2,0-0.4
            c0.2,0.2,0.3,0.5,0.2,0.5c0,0.2-0.2,0.2-0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0h0c-0.3,0-0.3-0.3-0.6-0.2c0.1,0.6,0.6,0.7,0.7,0.4
            c0.3-0.3,0.8,0,0.8,0.1c0,0.4-0.1,0.7-0.1,0.7s-0.2-0.1-0.4,0c-0.2-0.2-0.3-0.2-0.4-0.2c-0.2,0.4,0.2,0.6,0.2,0.6
            s-0.1,0.3-0.1,0.3c-0.1,0.1-0.2,0-0.3,0c-0.2,0.1-0.3,0.3-0.3,0.3s0-0.2,0-0.3c-0.1,0-0.2-0.1-0.3-0.3c0-0.2,0.2-0.7,0.2-0.7
            s-0.3-0.3-0.3-0.5c0,0.3,0.1,0.5,0.1,0.5s-0.2,0.3-0.2,0.6c-0.2-0.4-0.3-0.8-0.3-1.1c-0.2,0.3-0.4,0.7-0.2,1.8
            c-0.8-0.8-0.7-1.6-0.2-2C43.3,21.3,43.1,21.2,42.8,21.3z M40.2,25.1c0.3,0.1,0.8,0.2,1,0.4c0.2,0.2,0,0.6,0,0.6s-0.6-0.4-1-0.6
            V25.1z M40.2,26.7c0.2,0.2,0.5,0.4,0.8,0.8c0.2,0,0.8-0.5,0.9-0.1c-0.7,0.5-1.1,0.9-1.7,1.2c0,0,0,0-0.1,0.1V26.7z M37.4,23.9
            c0.1,0.1,0.2,0.3,0.3,0.5c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.2-0.6-0.7-0.6C37,23.7,37.2,23.7,37.4,23.9z M31.8,15.4
            c0-1.5,1.3-2.8,2.8-2.8s2.8,1.2,2.8,2.8c0,0.9-0.5,1.8-1.2,2.3c-0.2-0.1-0.5-0.1-0.7,0c-0.1,0-0.5-0.1-0.8,0
            c-0.3-0.1-0.6-0.1-0.9,0c-0.3-0.1-0.6-0.1-0.8,0C32.2,17.2,31.8,16.3,31.8,15.4z M35.4,17.9c0.7-0.2,0.9,0.3,1,0.4
            C35.7,18.5,35.6,18,35.4,17.9z M36.4,20c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
            C36.3,19.8,36.4,19.9,36.4,20z M35,18.3c-0.9,0.2-0.9-0.3-1.1-0.4C34.8,17.6,34.8,18.2,35,18.3z M32.5,18.1
            c0-0.2,0.2-0.4,0.5-0.3c0.2,0.1,0.4,0.4,0.5,0.4C33,18.4,32.6,18.4,32.5,18.1z M32.1,20.1c0.2-0.6,0.9-1.3,1.2-1.5
            c0.2-0.1,0.6-0.2,0.5,0c-0.4,0.5-0.7,1.2-0.8,1.6C32.1,20.4,32,20.3,32.1,20.1z M32.6,24.8c0.1,0.2,0.6,0.6,1.8-0.1
            c-1.1,0.2-1.5,0-1.5-0.2c0.2-0.2,0.4-0.4,0.4-0.4c0.1-1.4-0.8-2.7-1.6-3.4c0,0,0-0.1,0-0.1c1,0.1,2.6-0.2,3.3-0.3
            c0.4-0.1,0.2-0.8,0.1-1c0.1,0,0.3,0,0.5,0c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.2,0-0.2,0c0,0,0.2,0.7-0.3,0.9
            c-0.6,0.2-1.3,0.3-2.1,0.3c0.3,0.3,0.8,0.5,1.4,0.5c0,0.8,0.2,2.3,0.3,2.5c-0.4,0.2-0.8,0.3-1.5,0.4c0.5,0.3,1.1,0.1,1.6-0.2
            c0.2,0.1,0.5,0.1,0.7,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.6,0.5-1.3,1-2,1.1c-0.4,0.1-1,0-1.2-0.5C32.4,24.6,32.6,24.6,32.6,24.8z
             M29,24.7L29,24.7L28.9,25c-0.3,0.1-0.3,0.2-0.5,0.2c-0.2-0.2-0.2-0.4-0.2-0.4C28.4,24.7,28.5,24.7,29,24.7z M28.5,26
            c0.1,0,0.3-0.1,0.3-0.2c0,0.7,0,1.3,0,1.9c-0.2-0.4-0.4-0.7-0.4-0.7S28.8,26.8,28.5,26z M26.2,23.6c-0.4,0.3-1.1,0.7-1.8,1.2
            c0.1-0.1,0.3-0.4,0.6-0.6c-0.3,0.1-0.4,0.2-0.7,0.4c0.2-0.2,0.6-0.6,0.6-0.6s-0.3,0.1-0.8,0.4c1-0.8,1.5-1.1,1.7-1.2
            C26,23.4,26.1,23.5,26.2,23.6z M24.3,22.5c0.2,0.3,0.5,0.5,0.7,0.6c-0.2,0.1-0.5,0.3-0.5,0.3S24.2,23.3,24,23
            c-0.5-0.1-1-0.4-1.2-1C23.2,22.6,24,22.8,24.3,22.5z M24.3,19.8c-0.1,0.4-0.3,0.4-0.9,0.4C24,20,24,19.6,24.3,19.8z M23.9,20.7
            c0.1-0.1,0.2,0,0.2,0c-0.3,0.3-0.3,0.7-0.2,1c0.1,0.2,0,0.3-0.2,0.2c-0.6-0.3-0.8-0.9-0.4-1.4c0,0.2,0,0.5,0.2,0.8
            C23.4,21.2,23.7,20.8,23.9,20.7z M23.2,19.5L23,19.6c0,0-0.5-1.2-1.1-2C22.4,17.8,23.2,19.5,23.2,19.5z M22.1,18.4
            c0,0.1-0.2,0.1-0.2,0.1c-0.1-0.1-0.3-0.3-0.3-0.3C21.8,18.2,22,18.2,22.1,18.4z M20.1,17.4c1.1,0.5,2.1,1.7,2.6,2.2
            c-0.1,0-0.3,0-0.4,0C21.9,19,21,18.1,20.1,17.4z M22.1,21C22.1,20.9,22.1,20.9,22.1,21c-0.2,1.4,0.8,2.7,1.3,3.3
            c-0.2,0.1-0.3,0.3-0.3,0.3S21.5,22.6,22.1,21z M23.2,24.7c1.2-1,1.9-1.4,2.3-1.6c0.2-0.1,0.4,0,0.2,0.1
            C25.2,23.4,24.2,24,23.2,24.7C23.2,24.9,23.1,24.8,23.2,24.7z M23.6,25.5c0,0-0.2-0.1-0.4-0.4c0.3-0.1,0.6,0.2,0.6,0.2L23.6,25.5
            z M23.7,25.6c0.8-0.8,2.4-1.8,2.6-1.9c0.3-0.2,0.3-0.1,0.2,0c-0.1,0-0.2,0.1-0.3,0.2c-0.6,0.4-1.4,0.8-2.6,1.9
            C23.6,25.8,23.6,25.7,23.7,25.6z M24.9,31.4c-0.1,0.6,0,2-0.7,2.4c0.3-0.7-0.2-1.6,0-2.2c-0.2,0-0.3,0.1-0.4,0.2
            c-0.3-1.3,1.4-1.2,2.6-2.7C26,30.4,25,30.6,24.9,31.4z M26.4,28c-0.7-0.2-1.1-0.5-1.6-1.5c1,1.2,1.9,1.3,1.9,1.3s0.1,0.4,1,0.8
            C27,28.4,26.6,28.4,26.4,28z M28.6,32.7c0-0.1-0.1-0.5-0.1-0.5s0.4-0.4,0.5-0.3C29.1,32.1,28.9,32.5,28.6,32.7z M29.3,29.5
            c-0.1-0.3,0-0.4-0.3-0.8c-0.3-0.3-1.2-1.4-1.5-1.6c0-0.1,0.1-0.4,0.3-0.6c-0.4,0-0.6,0.3-0.6,0.3s-0.6-1.6-1.4-1.6
            c-0.2,0.2-0.1,0.3-0.3,0.6c-0.3-0.3-0.5-0.6-0.5-0.8c0.2-0.1,0.8-0.6,1.2-0.8c0.4,0.1,1,0.5,1.1,0.8c0.8,0.2,1.1,1.5,0.5,1.5
            c0.8,0.8,1.3,1.9,1.7,2.3c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.6,0.1,0.7C29.6,29.9,29.4,29.9,29.3,29.5z M30.3,29.1
            c0.1,0,1.1,0.5,1.5,0.7c0.1,0.3,0.3,0.4,0.4,0.7c-1,0-1.9,0-2.7,0.1c0-0.1,0.1-0.2,0.1-0.2c0.2-0.2,0.2-0.6,0.2-0.6s0-0.9-0.2-1
            c-0.1,0-0.1-0.1-0.2-0.2c0-1,0-2.2,0-3.5c0.9-0.1,1.8-0.2,2.6-0.2c0.1,0.4,0.5,0.8,1.4,0.8c0.6,0,1.3-0.4,1.8-0.8
            c0.9,0,2.7,0.1,4.4,0.3c0,1.5,0,2.9,0,4c0,0,0,0,0,0c-0.3,0-0.7,0.4-0.4,0.8c-0.3,0.1-0.4,0.4-0.4,0.6c-0.5,0-1,0-1.5-0.1
            c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1c0-0.1,0.1-0.2,0.2-0.4c-0.3,0-1.1,0-1.5,0c-0.2-0.4-0.4-1.2-0.2-1.6
            c0.1,0,0.2-0.1,0-0.1c-0.9,0.1-1.6,0.2-1.7,0.2c-0.1,0-0.3,0.1-0.1,0.1c-0.2,0.2-0.3,0.7-0.1,1.3c0.2-0.2,1.1-0.3,1.8,0.1
            c0,0,0,0,0,0c0,0-0.2,0.2-0.3,0.2c-1.4,0-2.8,0-3.6-0.3C31.7,29.6,30.7,29.1,30.3,29.1C30.3,29,30.2,29.1,30.3,29.1z M34.8,30.5
            c-0.3,0-0.5,0-0.7,0v0c-0.4,0-1,0-1.5,0c0.2-0.2,0.4-0.2,0.9-0.2c0.3,0.2,1,0.1,1.4,0c0.3,0.1,1.4,0,1.8,0.3
            C35.9,30.6,35.3,30.6,34.8,30.5L34.8,30.5L34.8,30.5z M38.4,35.5c-0.9-1.3-2.3-3.1-3.6-4.6c0.5,0,1.1,0,1.7,0
            c0.6,0.8,1.8,2.2,2.6,3.4C39,34.7,38.7,35.1,38.4,35.5z M39.6,32.5c-0.4-0.5-0.7-0.9-1.2-1.5c0.2,0,0.4,0,0.6,0
            c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0-0.1,0-0.1,0.1-0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0V32.5z M40.3,30.9
            c-0.1,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0.1-0.1,0c-0.1-0.1-0.2-0.4,0.2-0.6C40.4,30.5,40.5,30.7,40.3,30.9z M41.7,28.7
            c-0.1,0.1,0,0.3,0.7,0.1c0,0,0,0,0,0c-0.6,0.3-1.1,0.4-1.3,0.7c0,0.1-0.6,0.7-0.9,0.8c-0.4,0.1-0.5,0.4-0.5,0.5
            c0,0.1-0.1,0.1-0.2,0c-0.2-0.2,0-0.5,0.2-0.7c-0.1,0-0.4,0.1-0.5,0.3c0,0.1-0.1,0-0.2,0c-0.1-0.2,0-0.4,0.2-0.5
            c0.1,0,0.4-0.2,0.4-0.4c-0.2,0.1-0.3,0.2-0.4,0.2c0-0.1-0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.3-0.1,0.4-0.4,0.6-0.6
            c0.7-0.4,1.2-0.9,1.9-1.3c-0.1-0.3-0.3-0.8-0.3-1.3c-0.3,0.1-0.4,0.2-0.5,0.1c0.4-0.4,0.1-2,1-2.4c-0.4,0.6-0.1,1.3-0.3,1.9
            c0.1-0.1,0.2-0.5,0.8-0.7c-0.7,1-0.2,1.7-0.1,1.9c0.8-1.1,1.7-1.5,2.9-1.6c-1.5,0.3-2.4,1.5-2.6,2C42.6,27.9,42.1,28.4,41.7,28.7
            z M43.1,29.2c0.4-0.2,0.8-0.3,1.1-0.4c0.4-0.5,0.9-0.8,1.6-0.8c-0.1,0.3-0.7,0.5-0.7,0.5s0.5,0.2,0.8,0.2
            c-0.4,0.4-1.3,0.1-1.3,0.1s0.2,0.3,0,0.6C44.3,29,43.1,29.2,43.1,29.2z M46.1,33.6c0,0.1-0.1,0.1-0.1,0c-0.1-1,0.4-2.2-0.1-2.6
            c-0.4-0.4-1.2-0.4-1.7-1.2c0.9,0.5,2.3,0.1,2.5,0.6c-0.3,0-0.5,0.1-0.6,0.3c0.5,0,0.7,0.5,0.7,0.9C46.8,31.9,46.3,32.7,46.1,33.6
            z M46,39c-0.2,0-0.2-0.3,0-0.4c-0.1,0-0.2-0.1-0.5,0.1c-0.1,0,0-0.3,0.1-0.3c-0.1,0-0.3,0-0.4,0.1c-0.3-0.1,0.2-0.8,0.6-0.2
            c0.1,0,0.5,0.1,0.5,0.3c0.3,0,0.4,0.3,0.4,0.3S46.1,38.8,46,39z M47.2,37.9c-0.1,0.3-0.3,0.4-0.4,0.5c-0.3,0.2-0.5-0.2-0.4-0.2
            c0.2-0.1,0.5-0.3,0.7-0.6C47.2,37.5,47.4,37.6,47.2,37.9z M50.8,31.1C50.7,31,50.6,31,50.3,31c0.6,0.2,0.8,0.7,0.6,1.2
            c-0.4-0.7-1.2-0.7-1.4-0.2c-0.1-0.2,0.3-0.8,0.9-0.5c-0.3-0.7-1-0.3-1.4-1.1c0.2,0.2,0.5,0.1,0.7,0.2c0-0.1-0.9-0.2-0.9-1
            C49.4,30.4,50.6,30.3,50.8,31.1z M50.1,25.5c0-0.8,0.9-0.6,1.5-0.9C51.3,25.4,50.7,25,50.1,25.5z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <path d="M60,14.1h2.3L66.7,27l4.9-12.8h2.2v13.8h-1.6V18l0.1-2.1l-4.6,12h-2.2l-4.1-12l0.1,2.2v9.8H60V14.1z" />
        </g>
        <g>
          <path d="M80.8,14.1h2.1l4.8,13.8h-1.7l-1.8-5.1H79l-2,5.1h-1.6L80.8,14.1z M83.9,21.5l-2.1-6.2l-2.3,6.2H83.9z" />
        </g>
        <g>
          <path d="M89.6,14.1h2.2l6.8,12.4l-0.1-1.7V14.1h1.6v13.8h-2.4l-6.7-12.2l0.1,1.4v10.8h-1.5V14.1z" />
        </g>
        <g>
          <path
            d="M102.6,21.1c0-4.3,2.6-7.2,7.1-7.2c2.3,0,3.6,0.8,4,1.1l-0.5,1.3c-0.5-0.3-1.8-1-3.5-1c-2.9,0-5.4,1.6-5.4,5.6
          c0,4.1,2.7,5.9,5.8,5.9c1.3,0,2.5-0.4,3.1-0.8l0.5,1.2c-0.4,0.3-1.7,1-4,1C105.2,28.2,102.6,25.2,102.6,21.1z"
          />
        </g>
        <g>
          <path d="M115.7,14.2h1.6v6.2h7.4v-6.2h1.6v13.8h-1.6v-6.3h-7.4v6.3h-1.6V14.2z" />
        </g>
        <g>
          <path d="M129.1,14.2h7.5v1.3h-5.9v4.9h5.5v1.3h-5.5v4.9h6.4v1.3h-7.9V14.2z" />
        </g>
        <g>
          <path
            d="M139.1,27.1l0.6-1.3c0.7,0.4,1.8,1,3.1,1c1.5,0,3.3-0.5,3.3-2.3c0-3.5-6.6-2.7-6.6-7.2c0-2.2,1.8-3.3,4-3.3
          c1.5,0,2.7,0.5,3.3,1l-0.5,1.2c-0.6-0.4-1.6-0.8-2.8-0.8c-1.5,0-2.5,0.6-2.5,2c0,3.1,6.7,2.7,6.7,7.1c0,2.6-2.1,3.8-4.8,3.8
          C140.9,28.2,139.8,27.5,139.1,27.1z"
          />
        </g>
        <g>
          <path d="M153.6,15.5H149v-1.4h10.7v1.4h-4.5v12.4h-1.6V15.5z" />
        </g>
        <g>
          <path d="M161.8,14.1h7.7v1.3h-6.1v4.9h5.7v1.3h-5.7v4.9h6.6v1.3h-8.2V14.1z" />
        </g>
        <g>
          <path
            d="M179.3,26l-2.5-3.7c-0.3,0.1-0.9,0.1-1.3,0.1h-1.8v5.5h-1.6V14.2h4.4c2.6,0,4.4,1.2,4.4,4c0,1.6-0.7,3.1-2.5,3.8l2.1,3.1
          c0.9,1.3,1.9,1.7,2.6,1.9l-0.3,1.3C182,28.1,180.4,27.7,179.3,26z M175.6,21.1c2.1,0,3.6-0.6,3.6-2.8c0-2.1-1.1-2.8-3.1-2.8h-2.4
          v5.7H175.6z"
          />
        </g>
        <g>
          <path
            d="M58.6,38c0-3.1,1.7-5,4.8-5c1.5,0,2.4,0.5,2.7,0.7l-0.4,1.1c-0.5-0.3-1.2-0.6-2.3-0.6c-1.9,0-3.4,1-3.4,3.6
          c0,2.6,1.6,3.8,3.7,3.8c0.9,0,1.7-0.3,2.2-0.5l0.3,1.1c-0.5,0.3-1.3,0.6-2.7,0.6C60.3,42.8,58.6,40.9,58.6,38z"
          />
        </g>
        <g>
          <path d="M67.7,33.2h1.4v9.5h-1.4V33.2z" />
        </g>
        <g>
          <path d="M73.6,34.4h-3.1v-1.2h7.5v1.2H75v8.3h-1.4V34.4z" />
        </g>
        <g>
          <path d="M81.9,39.3l-3.1-6h1.6l2.3,4.8l2.4-4.8h1.5l-3.3,6v3.5h-1.4V39.3z" />
        </g>
        <g>
          <path
            d="M90.2,38c0-3.1,1.7-5,4.9-5c1.5,0,2.4,0.5,2.8,0.7l-0.4,1.1c-0.5-0.3-1.2-0.6-2.3-0.6c-1.9,0-3.4,1-3.4,3.6
          c0,2.6,1.6,3.8,3.7,3.8c0.9,0,1.7-0.3,2.2-0.5l0.3,1.1c-0.5,0.3-1.3,0.6-2.7,0.6C92,42.8,90.2,40.9,90.2,38z"
          />
        </g>
        <g>
          <path
            d="M109.6,39.2v-6h1.4v5.9c0,1.9,1.1,2.5,2.4,2.5c1.4,0,2.5-0.7,2.5-2.8v-5.6h1.4v5.7c0,3.1-2.1,4-3.8,4
          C111.6,42.8,109.6,42.1,109.6,39.2z"
          />
        </g>
        <g>
          <path
            d="M98.7,37.9c0-3,1.9-4.9,4.9-4.9c3.1,0,4.6,2.1,4.6,4.9c0,3-1.8,4.9-4.8,4.9C100.3,42.9,98.7,40.9,98.7,37.9z M106.7,37.9
          c0-2.1-1.1-3.7-3.3-3.7c-2.2,0-3.3,1.6-3.3,3.7c0,2,1,3.8,3.2,3.8C105.7,41.7,106.7,40.1,106.7,37.9z"
          />
        </g>
        <g>
          <path d="M119.4,33.2h1.8l4.4,8l-0.1-1.2v-6.8h1.4v9.5h-2l-4.3-7.9l0.1,1.1v6.8h-1.4V33.2z" />
        </g>
        <g>
          <path
            d="M128.7,38c0-3.1,1.7-5,4.8-5c1.5,0,2.4,0.5,2.7,0.7l-0.4,1.1c-0.5-0.3-1.2-0.6-2.3-0.6c-1.9,0-3.4,1-3.4,3.6
          c0,2.6,1.6,3.8,3.7,3.8c0.9,0,1.7-0.3,2.2-0.5l0.3,1.1c-0.5,0.3-1.3,0.6-2.7,0.6C130.5,42.9,128.7,40.9,128.7,38z"
          />
        </g>
        <g>
          <path d="M138.1,33.2h1.4v9.5h-1.4V33.2z" />
        </g>
        <g>
          <path d="M142,33.2h1.4v8.3h4v1.2H142V33.2z" />
        </g>
      </g>
    </svg>
  );
});

export default MCC_LOGO;
