import Modal from '@material-ui/core/Modal';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { UsersContext } from '../../contexts/Users';
import ClassroomCard from './ClassroomCard';
import CreateClassroomForm from './CreateClassroomForm';
import * as CSS from './elements/Classrooms';

const Classrooms = observer(() => {
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);

  const store = useLocalStore(() => ({
    searchTerm: '',
    isModalOpen: false,
    setModal: (open) => {
      store.isModalOpen = open;
    },
  }));

  const handleSearchChange = (e) => {
    store.searchTerm = e.target.value;
  };

  return (
    <CSS.StaffContainer data-testid="Classrooms">
      <CSS.StaffRow>
        <CSS.Inline>
          <CSS.StaffHeader>Classrooms</CSS.StaffHeader>
          <CSS.AlignButton>
            <CSS.CreateButton onClick={() => store.setModal(true)}>
              Create <CSS.PlusIcon />
            </CSS.CreateButton>
          </CSS.AlignButton>
        </CSS.Inline>
        <CSS.SearchField
          onChange={handleSearchChange}
          data-testid="Classrooms-search"
          placeholder="Search Classrooms"
        ></CSS.SearchField>
      </CSS.StaffRow>
      <CSS.StaffRow>
        <CSS.StaffHeaderGroup>
          <CSS.StaffHeaderCount>
            {
              chatsStore.getActiveGroupChats({
                searchTerm: store.searchTerm,
                selectedCategoryId: usersStore.getLoggedInUser()
                  .selectedCategory,
              }).length
            }
          </CSS.StaffHeaderCount>
          <CSS.StaffSubHeader>Active</CSS.StaffSubHeader>
        </CSS.StaffHeaderGroup>
      </CSS.StaffRow>
      <CSS.CardList>
        {chatsStore
          .getActiveGroupChats({
            searchTerm: store.searchTerm,
            selectedCategoryId: usersStore.getLoggedInUser().selectedCategory,
          })
          .map((groupChat) => (
            <ClassroomCard key={groupChat.id} groupChatId={groupChat.id} />
          ))}
      </CSS.CardList>
      <CSS.StaffHeaderGroup>
        <CSS.StaffHeaderCount>
          {
            chatsStore.getInactiveGroupChats({
              searchTerm: store.searchTerm,
              selectedCategoryId: usersStore.getLoggedInUser().selectedCategory,
            }).length
          }
        </CSS.StaffHeaderCount>
        <CSS.StaffSubHeader>Inactive</CSS.StaffSubHeader>
      </CSS.StaffHeaderGroup>
      <CSS.CardList>
        {chatsStore
          .getInactiveGroupChats({
            searchTerm: store.searchTerm,
            selectedCategoryId: usersStore.getLoggedInUser().selectedCategory,
          })
          .map((groupChat) => (
            <ClassroomCard key={groupChat.id} groupChatId={groupChat.id} />
          ))}
      </CSS.CardList>
      <Modal
        data-testid="create-classroom-modal"
        open={store.isModalOpen}
        onClose={() => store.setModal(false)}
        aria-labelledby="create-classroom-modal"
        aria-describedby="create-classroom-modal"
      >
        <CreateClassroomForm closeModal={() => store.setModal(false)} />
      </Modal>
    </CSS.StaffContainer>
  );
});

export default Classrooms;
