import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { LearningAssessmentsContext } from '../../contexts/LearningAssessments';
import { UsersContext } from '../../contexts/Users';
import * as CSS from './elements/AddAssessment';

const AddAssessment = observer(({ studentId, onClose = () => {} }) => {
  const usersStore = useContext(UsersContext);
  const learningAssessmentsStore = useContext(LearningAssessmentsContext);

  const handleNewAssessment = async ({ assessment }, { resetForm }) => {
    resetForm();
    await student.setLearningAssessment(
      assessment.toJSON(),
      usersStore.getLoggedInUser().selectedCategory
    );
    onClose();
  };
  const student = usersStore.getUserById(studentId);

  const publishedLearningAssessments = learningAssessmentsStore.getAvailablePublishedAssessments(
    student?.private,
    usersStore.getLoggedInUser().selectedCategory
  );

  return (
    <CSS.AddAssessmentContainer>
      <CSS.InfoText>Add a new Learning Plan for this learner</CSS.InfoText>
      <CSS.InfoText>
        n.b. This will archive any existing Learning Plans
      </CSS.InfoText>
      <CSS.CloseIconButton
        type="button"
        onClick={onClose}
        data-testid="Close add assessment"
      >
        <CSS.XIcon />
      </CSS.CloseIconButton>
      <Formik
        key="no-assessment"
        enableReinitialize
        initialValues={{
          assessment: '',
        }}
        onSubmit={handleNewAssessment}
      >
        {({ dirty }) => (
          <Observer>
            {() => (
              <CSS.AssessmentForm>
                <CSS.SelectContainer>
                  <Field
                    select
                    name="assessment"
                    id="assessment"
                    label="Choose Learning Plan"
                    component={CSS.StaffTextFieldInput}
                  >
                    {publishedLearningAssessments.length > 0 ? (
                      publishedLearningAssessments.map((assessment) => {
                        return (
                          <CSS.StaffMenuItem
                            key={assessment.id}
                            value={assessment}
                          >
                            {assessment.title}
                          </CSS.StaffMenuItem>
                        );
                      })
                    ) : (
                      <CSS.StaffMenuItem key="placeholder" value="">
                        No published learning plans available
                      </CSS.StaffMenuItem>
                    )}
                  </Field>
                  <ErrorMessage name="role" component="p" />
                </CSS.SelectContainer>
                <CSS.SaveChangesButton disabled={!dirty} type="submit">
                  Add
                </CSS.SaveChangesButton>
              </CSS.AssessmentForm>
            )}
          </Observer>
        )}
      </Formik>
    </CSS.AddAssessmentContainer>
  );
});

export default AddAssessment;
