import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import * as api from '../../api.js';
import { CategoriesContext } from '../../contexts/Categories.js';
import { UsersContext } from '../../contexts/Users.js';
import * as CSS from './elements/InviteUser';

const createUserSchema = yup.object().shape({
  firstName: yup.string().required('Please enter their first name'),
  surname: yup.string().required('Please enter their surname'),
  phoneNumber: yup.string().required('Please their phone number'),
  pin: yup
    .string()
    .min(6, 'PINs must have a minimum length of 6 characters')
    .required('Please enter their PIN'),
  confirmPin: yup
    .string()
    .min(6, 'PINs must have a minimum length of 6 characters')
    .oneOf([yup.ref('pin'), null], 'PINs must match')
    .required('Please confirm their PIN'),
  role: yup.string().required('Please choose a role'),
});

const InviteUser = React.forwardRef(({ closeModal }, ref) => {
  const usersStore = useContext(UsersContext);
  const categoriesStore = useContext(CategoriesContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const isAdmin = loggedInUser.role === 'admin';
  const selectedCategory = categoriesStore.getCategoryById(
    loggedInUser.selectedCategory
  );

  const handleSubmit = async (
    { serverError, confirmPin, ...userDetails },
    actions
  ) => {
    await api
      .signUpUser({ ...userDetails, categoryId: loggedInUser.selectedCategory })
      .then(() => {
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          return actions.setFieldError('serverError', err.response.data.msg);
        } else {
          return actions.setFieldError(
            'serverError',
            'Something went wrong, please try again'
          );
        }
      });
  };
  return useObserver(() => (
    <Formik
      initialValues={{
        firstName: '',
        surname: '',
        phoneNumber: '',
        pin: '',
        confirmPin: '',
        role: isAdmin ? '' : 'student',
        serverError: null,
      }}
      validationSchema={createUserSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="InviteUser-form">
              <CSS.StaffFormHeaderRow>
                <CSS.StaffFormHeader>
                  Invite {isAdmin ? 'User' : 'Student'} to{' '}
                  {selectedCategory.categoryName}
                </CSS.StaffFormHeader>
                <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                  <CSS.StaffCloseIcon />
                </CSS.StaffFormCloseButton>
              </CSS.StaffFormHeaderRow>
              <CSS.FieldGroup>
                <Field
                  type="text"
                  name="firstName"
                  id="firstName"
                  label="First Name"
                  component={CSS.StaffTextFieldInput}
                />
                <ErrorMessage
                  name="firstName"
                  component="p"
                  data-testid="firstName-error"
                />
              </CSS.FieldGroup>
              <CSS.FieldGroup>
                <Field
                  type="text"
                  name="surname"
                  id="surname"
                  label="Surname"
                  component={CSS.StaffTextFieldInput}
                />
                <ErrorMessage
                  name="surname"
                  component="p"
                  data-testid="surname-error"
                />
              </CSS.FieldGroup>
              <CSS.FieldGroup>
                <Field
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  label="Phone Number"
                  component={CSS.StaffTextFieldInput}
                  inputMode="numeric"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="p"
                  data-testid="phoneNumber-error"
                />
              </CSS.FieldGroup>

              <CSS.FieldGroup>
                <Field
                  type="password"
                  name="pin"
                  id="pin"
                  label="PIN"
                  component={CSS.StaffTextFieldInput}
                />
                <ErrorMessage
                  name="pin"
                  component="p"
                  data-testid="pin-error"
                />
              </CSS.FieldGroup>

              <CSS.FieldGroup>
                <Field
                  type="password"
                  name="confirmPin"
                  id="confirmPin"
                  label="Confirm PIN"
                  component={CSS.StaffTextFieldInput}
                />
                <ErrorMessage
                  name="confirmPin"
                  component="p"
                  data-testid="confirmPin-error"
                />
              </CSS.FieldGroup>

              {isAdmin && (
                <CSS.FieldGroup>
                  <Field
                    select
                    name="role"
                    id="role"
                    label="User Role"
                    component={CSS.StaffTextFieldInput}
                  >
                    {['Student', 'Teacher', 'Admin'].map((role) => {
                      return (
                        <CSS.StaffMenuItem
                          key={role}
                          value={role.toLowerCase()}
                        >
                          {role}
                        </CSS.StaffMenuItem>
                      );
                    })}
                  </Field>
                  <ErrorMessage name="role" component="p" />
                  <ErrorMessage name="serverError" component="p" />
                </CSS.FieldGroup>
              )}

              <CSS.StaffFooterRow>
                <CSS.SubmitChangesButton
                  data-testid="EditUserPersonalInfoForm-submit"
                  type="submit"
                  disabled={!dirty && !isSubmitting}
                >
                  Invite
                </CSS.SubmitChangesButton>
              </CSS.StaffFooterRow>
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  ));
});

export default InviteUser;
