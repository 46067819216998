import styled from 'styled-components/macro';
import * as StaffCommon from '../../common/elements/Staff';
import * as Topics from './Topics';

export const StaffRowFill = styled(StaffCommon.StaffRow)`
  flex-grow: 1;
`;

export const ModuleList = styled(Topics.CardList)`
  width: 100%;

  @media only screen and (min-width: 700px) {
    width: auto;
  }
`;

export const Module = styled.div`
  width: 100%;
`;

export const ProgressContainer = styled.div`
  width: 20em;
  padding: 1em 0 0.5em 0;
`;

export * from '../../common/elements/Notifications';
export * from './Topics';
