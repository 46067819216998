import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const ENV = process.env.NODE_ENV;

let testApp;
let storageMock;

if (ENV === 'test') {
  const firebaseTesting = require('@firebase/testing');
  testApp = firebaseTesting.initializeAdminApp({
    projectId: 'adult-education-service',
  });
  const ref = jest.fn(() => ({ child }));
  const put = jest.fn(async () => {});
  const child = jest.fn(() => ({ getDownloadURL, put }));
  const getDownloadURL = jest.fn(async () => 'www.example.com/image.jpg');
  storageMock = {
    ref,
  };
} else {
  const firebaseConfig = require('./firebaseConfig.json');
  firebase.initializeApp(firebaseConfig);
}

export const db = ENV === 'test' ? testApp.firestore() : firebase.firestore();

export const storage = ENV === 'test' ? storageMock : firebase.storage();

export default ENV === 'test' ? testApp : firebase;
