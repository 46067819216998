import { useLocalStore } from 'mobx-react-lite';
import { useEffect } from 'react';

const useLoadingTimer = () => {
  const store = useLocalStore(() => ({
    isLoadingTimeout: true,
    timerId: null,
  }));

  useEffect(() => {
    if (store.isLoadingTimeout) {
      const timerId = setTimeout(() => {
        store.isLoadingTimeout = false;
      }, 1500);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [store.isLoadingTimeout]);

  return store.isLoadingTimeout;
};

export default useLoadingTimer;
