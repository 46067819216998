import firebase from 'firebase/app';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import { useWindowSize } from '../../hooks/useWindowSize.js';
import StarSVG from '../../svgs/StarSVG.js';
import UploadPendingBiggerSVG from '../../svgs/UploadPendingBiggerSVG.js';
import Error from '../common/Error.js';
import EvidenceList from '../common/EvidenceList.js';
import MessageForm from '../messaging/MessageForm';
import * as CSS from './elements/PersonalTarget';

const PersonalTarget = observer(({ targetId }) => {
  const usersStore = useContext(UsersContext);
  const windowStore = useWindowSize();
  const loggedInUser = usersStore.getLoggedInUser();
  const numberOfColumns = windowStore.getColumns();
  const target = loggedInUser.private.personalTargets.get(targetId);

  const unreadCount = loggedInUser.private.getUnreadCommentsByItemId(
    targetId,
    'personalTargets'
  );

  useEffect(() => {
    if (target) {
      db.collection('usersPrivate')
        .doc(loggedInUser.id)
        .update({
          [`personalTargets.${targetId}.lastChecked.${loggedInUser.id}`]: firebase.firestore.Timestamp.fromDate(
            new Date()
          ),
        });
    }
  }, [loggedInUser.id, target, targetId, unreadCount]);

  if (!target) return <Error msg="Target not found" />;

  const evidence = loggedInUser.private.getEvidenceByTargetId(
    target.targetId,
    numberOfColumns
  );

  return (
    <div data-testid="PersonalTarget">
      <CSS.StudentHeaderRow>
        <CSS.CenterLink to="/targets">
          <StarSVG />
          <CSS.TargetHeader>My Targets</CSS.TargetHeader>
        </CSS.CenterLink>
      </CSS.StudentHeaderRow>
      <CSS.EvidenceContainer>
        <CSS.TargetLi as="p" complete={evidence.length > 0}>
          {target.targetText}
        </CSS.TargetLi>
        {evidence.length === 0 ? (
          <CSS.UploadEvidence data-testid="Task-upload">
            <UploadPendingBiggerSVG />
            <CSS.UploadText>Add evidence</CSS.UploadText>
          </CSS.UploadEvidence>
        ) : (
          <EvidenceList
            evidence={evidence}
            resource="target"
            studentId={loggedInUser.id}
            targetId={target.targetId}
          />
        )}
        <MessageForm targetId={targetId} />
      </CSS.EvidenceContainer>
    </div>
  );
});

export default PersonalTarget;
