import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as firebase from 'firebase/app';
import { FieldArray, Formik } from 'formik';
import { Observer, useLocalStore, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import AddStudentCard from './AddStudentCard.js';
import * as CSS from './elements/CreateClassroomForm';

const EditStudentsForm = React.forwardRef(
  ({ closeModal, studentsInChat, usersInChat, chatId }, ref) => {
    const usersStore = useContext(UsersContext);
    const store = useLocalStore(() => ({
      errorMsg: '',
    }));

    const handleSubmit = async ({ users, usersToRemove }) => {
      const chatSnap = await db.collection('groupChats').doc(chatId).get();

      const userUpdates = users.map((user) => {
        return db
          .collection('users')
          .doc(user.id)
          .update({
            [`groupChats.${chatId}`]: chatSnap.ref,
          });
      });

      const userRemovals = usersToRemove.map((usersToRemove) => {
        return db
          .collection('users')
          .doc(usersToRemove.id)
          .set(
            {
              groupChats: {
                [chatId]: firebase.firestore.FieldValue.delete(),
              },
            },
            { merge: true }
          );
      });
      await Promise.all([...userUpdates, ...userRemovals]);
      closeModal();
    };

    return useObserver(() => (
      <Formik
        initialValues={{ users: usersInChat, usersToRemove: [] }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, setFieldError, errors }) => (
          <Observer>
            {() => (
              <CSS.Form data-testid="EditStudentsForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>Users</CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <FieldArray name="users">
                  {({ remove }) => {
                    const addedStudents = values.users.filter(
                      (user) => user.role === 'student'
                    );
                    const addedTeachers = values.users.filter(
                      (user) => user.role === 'teacher'
                    );

                    return (
                      <Observer>
                        {() => (
                          <>
                            <CSS.StaffAutocomplete
                              onChange={(event, user, reason) => {
                                if (reason === 'select-option') {
                                  const alreadySelected = values.users.find(
                                    ({ id }) => id === user.id
                                  );
                                  if (!alreadySelected) {
                                    setFieldValue('users', [
                                      ...values.users,
                                      user,
                                    ]);
                                  }
                                  const updatedUsersToRemove = values.usersToRemove.filter(
                                    ({ id }) => user.id !== id
                                  );
                                  setFieldValue(
                                    'usersToRemove',
                                    updatedUsersToRemove
                                  );
                                }
                                store.errorMsg = '';
                              }}
                              options={usersStore.getUsersSortedByRoleAndDisplayName()}
                              filterOptions={createFilterOptions({
                                limit: 20,
                              })}
                              getOptionLabel={(user) => user.displayName}
                              groupBy={(user) => user.role}
                              renderInput={(params) => (
                                <CSS.StaffAutocompleteInput
                                  {...params}
                                  label="Add Users"
                                  variant="outlined"
                                />
                              )}
                            />
                            <CSS.StudentList>
                              {!!addedStudents.length && (
                                <CSS.StaffSubHeader as="li">
                                  Students
                                </CSS.StaffSubHeader>
                              )}
                              {addedStudents.map((user) => (
                                <AddStudentCard
                                  key={user.id}
                                  student={user}
                                  removeStudent={() => {
                                    setFieldValue('usersToRemove', [
                                      ...values.usersToRemove,
                                      user,
                                    ]);
                                    const userIndexToRemove = values.users.findIndex(
                                      ({ id }) => id === user.id
                                    );
                                    remove(userIndexToRemove);
                                  }}
                                />
                              ))}

                              {!!addedTeachers.length && (
                                <CSS.StaffSubHeader as="li">
                                  Teachers
                                </CSS.StaffSubHeader>
                              )}
                              {addedTeachers.map((user) => (
                                <AddStudentCard
                                  key={user.id}
                                  student={user}
                                  removeStudent={() => {
                                    if (addedTeachers.length > 1) {
                                      setFieldValue('usersToRemove', [
                                        ...values.usersToRemove,
                                        user,
                                      ]);
                                      const userIndexToRemove = values.users.findIndex(
                                        ({ id }) => id === user.id
                                      );
                                      remove(userIndexToRemove);
                                    } else {
                                      store.errorMsg =
                                        'Chats must contain at least one teacher';
                                    }
                                  }}
                                />
                              ))}
                              {store.errorMsg && (
                                <CSS.StudentErrorMsg>
                                  {store.errorMsg}
                                </CSS.StudentErrorMsg>
                              )}
                            </CSS.StudentList>
                          </>
                        )}
                      </Observer>
                    );
                  }}
                </FieldArray>

                <CSS.StaffFooterRow>
                  <CSS.StaffNavButton type="submit">Finish</CSS.StaffNavButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default EditStudentsForm;
