import { FieldArray, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { TopicsContext } from '../../contexts/Topics.js';
import { db } from '../../firebase';
import * as CSS from './elements/SelectModuleForm.js';

const addModuleSchema = yup.object().shape({
  modules: yup
    .array()
    .of(yup.object())
    .required('Please select at least one module to add'),
});

const SelectModuleForm = React.forwardRef(
  ({ closeModal, groupChatId, chatToEdit }, ref) => {
    const topicsStore = useContext(TopicsContext);

    const handleSubmit = async ({ modules }) => {
      closeModal();
      const topicIndexes = {};
      const modulesToAdd = modules.reduce((acc, module, i) => {
        if (!topicIndexes.hasOwnProperty(module.topicId)) {
          const newTopicMaxIndex = chatToEdit.getMaxModuleIndex(module.topicId);
          topicIndexes[module.topicId] = newTopicMaxIndex;
        }
        acc[module.id] = {
          module: db
            .collection('topics')
            .doc(module.topicId)
            .collection('modules')
            .doc(module.id),
          topic: db.collection('topics').doc(module.topicId),
          index: ++topicIndexes[module.topicId],
        };
        return acc;
      }, {});

      await db.collection('groupChats').doc(groupChatId).set(
        {
          modules: modulesToAdd,
        },
        { merge: true }
      );
    };

    return useObserver(() => (
      <Formik
        initialValues={{
          modules: [],
        }}
        validationSchema={addModuleSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Observer>
            {() => (
              <CSS.Form data-testid="SelectModuleForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>
                    Select module(s) to add
                  </CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <FieldArray name="modules">
                  {({ form }) => (
                    <CSS.StaffAutocomplete
                      multiple
                      onChange={(event, modules, reason) => {
                        form.setFieldValue('modules', modules);
                      }}
                      options={topicsStore.getAvailableChatModules(
                        chatToEdit.modules
                      )}
                      groupBy={(option) => option.topicName}
                      getOptionLabel={(option) =>
                        `${option.moduleName} - ${option.topicName}`
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <CSS.StaffAutocompleteInput
                          {...params}
                          label="Type a module name"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </FieldArray>
                <CSS.StaffFooterRow>
                  <CSS.StaffNavButton type="submit">
                    Add module(s)
                  </CSS.StaffNavButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default SelectModuleForm;
