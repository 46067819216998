import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs().format();

export const formatDate = (createdAt) => {
  const dayjsDate = dayjs(createdAt);
  const messageWasToday = dayjsDate.isToday();
  const dayjsRelativeDay = dayjs().to(createdAt);
  const dayjsTimeToday = dayjsDate.format('HH:mma');
  return messageWasToday ? dayjsTimeToday : dayjsRelativeDay;
};
