import styled from 'styled-components/macro';

export const TaskInstructions = styled.p`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: ${({ theme, isEvidenced }) =>
    isEvidenced ? theme.headerColour : theme.textDark};
  background: ${({ theme, isEvidenced }) =>
    isEvidenced ? theme.completedHighlight : theme.staffBackground};
  border-radius: 12px;
  padding: 1em;
  margin: 1em;
  box-sizing: border-box;
  width: 100%;
  white-space: pre-line;
`;

export * from '../../common/elements/Students';
