import React, { createContext } from 'react';
import ListenersModel from '../models/Listeners.js';

const ListenersStore = ListenersModel.create({});

export const ListenersContext = createContext(ListenersStore);

export const ListenersProvider = ({ children }) => {
  return (
    <ListenersContext.Provider value={ListenersStore}>
      {children}
    </ListenersContext.Provider>
  );
};
