import React from 'react';
import { useObserver } from 'mobx-react-lite';
import * as CSS from './elements/SayHello.js';

const SayHello = React.forwardRef((props, ref) => {
  return useObserver(() => (
    <CSS.SayHelloMsg ref={ref}>Say "hello"!</CSS.SayHelloMsg>
  ));
});

export default SayHello;
