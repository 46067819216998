export const formatInternalLink = (to, origin = null) => {
  // pass origin to generate full href for internal link
  try {
    const url = origin ? new URL(to, origin) : new URL(to);
    if (url.hostname === window.location.hostname) {
      return origin ? url.href : url.pathname;
    }
    return null;
  } catch {
    return null;
  }
};
