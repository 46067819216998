import { Link } from '@reach/router';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { PrivacyPoliciesContext } from '../../contexts/PrivacyPolicies';
import * as CSS from './elements/PrivacyPolicies.js';
import PolicyCard from './PolicyCard.js';

const PrivacyPolicies = observer(() => {
  const privacyPoliciesStore = useContext(PrivacyPoliciesContext);
  const privacyPolicies = privacyPoliciesStore.getPolicies();
  if (privacyPolicies.length === 0) return <p>No policies found</p>;

  const activePolicies = privacyPolicies.filter((policy) => policy.active);
  const activePolicy = activePolicies[0];
  // const inactivePolicies = privacyPolicies.filter((policy) => !policy.active);

  return (
    <div data-testid="PrivacyPolicies">
      <CSS.StaffRow>
        <CSS.PolicyLinkText>
          You can view the current policy, as seen by users here:{' '}
          <Link to="/privacy">Privacy Policy</Link>
        </CSS.PolicyLinkText>
      </CSS.StaffRow>
      <CSS.StaffRow>
        <CSS.StaffSubHeader>Active</CSS.StaffSubHeader>
      </CSS.StaffRow>
      <CSS.AssessmentList>
        <PolicyCard key={activePolicy.id} policyId={activePolicy.id} />
      </CSS.AssessmentList>
      {/* <CSS.StaffRow>
        <CSS.StaffSubHeader>Inactive</CSS.StaffSubHeader>
      </CSS.StaffRow>
      <CSS.AssessmentList>
        {inactivePolicies.map((policy) => {
          return <PolicyCard key={policy.id} policyId={activePolicy.id} />;
        })}
      </CSS.AssessmentList> */}
    </div>
  );
});

export default PrivacyPolicies;
