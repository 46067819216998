import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components/macro';
import * as common from '../../common/elements/Form';
import * as StaffCommon from '../../common/elements/Staff';

export const Form = styled(FormikForm)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 15px;
  ${common.ModalScreenWidthMQs}
`;

export const SubmitChangesButton = styled(StaffCommon.StaffNavButton)`
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.buttonDisabled};
      &:hover {
        cursor: default;
      }
    `}
`;

export const TargetFieldGroup = styled(common.FieldGroup)`
  width: 90%;
`;

export const TargetGroup = styled.div`
  display: grid;
  grid-template-columns: 95% 5%;
  grid-template-areas: 'target-text delete';
`;

export const DeleteButton = styled.button`
  grid-area: delete;
  padding: 0;
  color: ${({ theme }) => theme.textDark};
  border: none;
  background: none;
  justify-self: center;
  &:hover {
    cursor: pointer;
  }
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
