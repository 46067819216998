import elementResizeEvent, { unbind } from 'element-resize-event';
import { observer } from 'mobx-react-lite';
import React, { useLayoutEffect, useRef } from 'react';
import * as CSS from './elements/Whiteboard.js';

const Whiteboard = observer(
  ({ whiteboardStore, chat: { chatName, whiteboard } }) => {
    const whiteboardRef = useRef(null);

    useLayoutEffect(() => {
      if (whiteboardRef.current) {
        const whiteboardElement = whiteboardRef.current;
        whiteboardStore.height = whiteboardElement.clientHeight;
        elementResizeEvent(whiteboardElement, function () {
          whiteboardStore.height = whiteboardElement.clientHeight;
        });
        const onScreenWhiteboard = whiteboardRef.current.querySelector(
          '.resize-sensor'
        );
        if (onScreenWhiteboard) onScreenWhiteboard.ariaLabel = 'whiteboard';
        return () => unbind(whiteboardElement);
      }
    }, [whiteboardStore.height]);

    return (
      <CSS.Whiteboard ref={whiteboardRef}>
        <CSS.ClassroomTitle>{chatName}</CSS.ClassroomTitle>
        <CSS.WhiteboardContent>{whiteboard}</CSS.WhiteboardContent>
      </CSS.Whiteboard>
    );
  }
);

export default Whiteboard;
