import React, { createContext } from 'react';
import PrivacyPoliciesModel from '../models/PrivacyPolicies.js';

const PrivacyPoliciesStore = PrivacyPoliciesModel.create({
  PrivacyPolicies: {},
});

export const PrivacyPoliciesContext = createContext(PrivacyPoliciesStore);

export const PrivacyPoliciesProvider = ({ children }) => {
  return (
    <PrivacyPoliciesContext.Provider value={PrivacyPoliciesStore}>
      {children}
    </PrivacyPoliciesContext.Provider>
  );
};
