import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats.js';
import useLoadingTimer from '../../hooks/useLoadingTimer.js';
import Error from '../common/Error';
import Loading from '../common/Loading';
import Chat from './Chat';
import * as CSS from './elements/Classroom.js';
import Whiteboard from './Whiteboard';

const Classroom = observer(({ id }) => {
  const whiteboardStore = useLocalStore(() => ({
    height: 0,
  }));

  const isLoadingTimer = useLoadingTimer();

  const chatsStore = useContext(ChatsContext);
  const thisChat = chatsStore.groupChats.find((chat) => chat.id === id);

  if (!thisChat && isLoadingTimer) return <Loading />;
  if (!thisChat) return <Error msg="Chat not found" />;

  return (
    <CSS.ClassroomContainer data-testid="Classroom">
      <Whiteboard whiteboardStore={whiteboardStore} chat={thisChat} />
      <Chat
        chatId={id}
        whiteboardStore={whiteboardStore}
        chatCollection="groupChats"
      />
    </CSS.ClassroomContainer>
  );
});

export default Classroom;
