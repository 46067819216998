import styled from 'styled-components/macro';
import { Inline, StaffCard, StaffSubHeader } from '../../common/elements/Staff';

export const CategoryCard = styled(StaffCard)`
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CategoryTitle = styled(StaffSubHeader)`
  padding: 0;
  font-size: 2.4rem;
  width: 22rem;
`;

export const InlineJustified = styled(Inline)`
  justify-content: space-between;
  @media only screen and (min-width: 700px) {
    align-items: baseline;
  }
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
