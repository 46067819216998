import styled, { css } from 'styled-components/macro';
import { StudentHeader } from '../../common/elements/Students.js';

export const TargetHeader = styled(StudentHeader)`
  margin: 0.83em 0 0.55em 0.5em;
`;

export const TargetLi = styled.li`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: ${({ theme, complete }) =>
    complete
      ? theme.isTargetDark
        ? theme.textLight
        : theme.headerColour
      : theme.textDark};
  background: ${({ theme, complete }) =>
    complete ? theme.targetHighlight : theme.staffBackground};
  border-radius: 12px;
  padding: 1em;
  width: 100%;
  margin: 1em 0;
  white-space: pre-line;
  box-sizing: border-box;

  ${({ isTop }) =>
    isTop &&
    css`
      margin-top: 0;
    `}

  &:hover {
    cursor: pointer;
  }

  ${({ notClickable }) =>
    notClickable &&
    css`
      &:hover {
        cursor: default;
      }
    `}

  ${({ hasNotifications }) =>
    hasNotifications &&
    css`
      margin: 0;
    `}
`;

export const TargetText = styled.p`
  margin: 0;
  padding-right: 0.6em;
`;

export * from '../../common/elements/Notifications';
export * from '../../common/elements/Students';
