import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/Theme';

const UploadPendingSVG = observer(({ taskId }) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      data-testid={`${taskId}-pending`}
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="26.8443"
        cy="27.3843"
        r="26.8443"
        fill={`url(#pending-gradient-${taskId})`}
      />
      <path
        d="M27.3275 11.2047C27.061 10.9383 26.629 10.9383 26.3625 11.2047L13.3956 24.1717C11.9983 25.5689 11.9983 27.8343 13.3956 29.2315C14.7928 30.6288 17.0582 30.6288 18.4554 29.2315L23.432 24.255V40.3518C23.432 42.2363 24.9598 43.7641 26.8443 43.7641C28.7289 43.7641 30.2567 42.2363 30.2567 40.3518V24.2549L35.2332 29.2315C36.6305 30.6287 38.8959 30.6287 40.2931 29.2315C41.6903 27.8342 41.6903 25.5688 40.2931 24.1716L27.3275 11.2047Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={`pending-gradient-${taskId}`}
          x1="43.3431"
          y1="49.2781"
          x2="9.99963"
          y2="-2.43255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.pendingDark} stopOpacity="0.47" />
          <stop offset="1" stopColor={theme.pendingFill} stopOpacity="0.51" />
        </linearGradient>
      </defs>
    </svg>
  );
});

export default UploadPendingSVG;
