import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/Theme';

const UploadSuccessSVG = observer(({ taskId }) => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      data-testid={`${taskId}-success`}
      width="53"
      height="57"
      viewBox="0 0 53 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="26.5"
        cy="27.2334"
        rx="26.5"
        ry="27"
        fill="url(#success-gradient)"
      />
      <g clipPath={`url(#clip0-${taskId})`}>
        <path
          d="M26.9865 10.4408C26.7181 10.1643 26.2829 10.1643 26.0144 10.4408L12.9521 23.8989C11.5446 25.3491 11.5446 27.7003 12.9521 29.1504C14.3596 30.6006 16.6417 30.6006 18.0492 29.1504L23.0623 23.9854V40.6919C23.0623 42.6478 24.6013 44.2335 26.4998 44.2335C28.3982 44.2335 29.9372 42.6478 29.9372 40.6919V23.9853L34.9503 29.1504C36.3578 30.6005 38.6399 30.6005 40.0474 29.1504C41.4549 27.7002 41.4549 25.349 40.0474 23.8989L26.9865 10.4408Z"
          fill="white"
        />
      </g>
      <circle
        cx="43.7065"
        cy="46.9203"
        r="9.18988"
        fill={theme.completedHighlight}
      />
      <path
        d="M43.1886 51.3828C42.8329 51.3828 42.4897 51.2467 42.2304 51.0011L38.5807 47.5462C38.0216 47.017 37.9974 46.1347 38.5266 45.5756C39.0558 45.0166 39.938 44.9923 40.4972 45.5216L43.1083 47.9933L47.3258 43.3019C47.8404 42.7293 48.7218 42.6826 49.2943 43.1971C49.8667 43.7117 49.9136 44.5931 49.3989 45.1656L44.2252 50.9208C43.9739 51.2002 43.6204 51.3665 43.2448 51.3816C43.226 51.3824 43.2072 51.3828 43.1886 51.3828Z"
        fill={theme.completedDark}
      />
      <defs>
        <linearGradient
          id="success-gradient"
          x1="42.7872"
          y1="49.2542"
          x2="8.99557"
          y2="-2.18104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.buttonPrimary} />
          <stop
            offset="1"
            stopColor={theme.lessonHighlight}
            stopOpacity="0.51"
          />
        </linearGradient>
        <clipPath id={`clip0-${taskId}`}>
          <rect
            width="33"
            height="34"
            fill="white"
            transform="translate(10 10.2334)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default UploadSuccessSVG;
