import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { Observer, useLocalStore, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users.js';
import { db } from '../../firebase';
import AddStudentCard from './AddStudentCard.js';
import * as CSS from './elements/CreateClassroomForm.js';

const createClassroomSchema = yup.object().shape({
  classroomName: yup.string().required('Please enter a name'),
  topicId: yup.string().required('Please choose a topic'),
  moduleId: yup.string().required('Please choose a module'),
  teacherId: yup.string().required('Please choose a teacher'),
  students: yup.array().of(yup.object()),
});

const CreateClassroomForm = React.forwardRef(({ closeModal }, ref) => {
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);

  const pageStore = useLocalStore(() => ({
    page: 1,
    changePage: async (direction, validateForm, setTouched) => {
      const potentialErrors = await validateForm();
      if (Object.keys(potentialErrors).length === 0) {
        const newPage = pageStore.page + direction;
        if (newPage > 0 && newPage < 3) pageStore.page = newPage;
      } else {
        setTouched(potentialErrors);
      }
    },
  }));

  const handleSubmit = async (values) => {
    const newChat = {
      chatName: values.classroomName,
      currentModule: values.moduleId,
      currentTopic: values.topicId,
      teacher: values.teacherId,
      active: true,
      modules: {
        [values.moduleId]: {
          active: true,
          index: 0,
          module: db
            .collection('topics')
            .doc(values.topicId)
            .collection('modules')
            .doc(values.moduleId),
          topic: db.collection('topics').doc(values.topicId),
        },
      },
      category: usersStore.getLoggedInUser().selectedCategory,
    };

    const chatRef = await db.collection('groupChats').add(newChat);
    const userPromises = values.students.map((student) =>
      db
        .collection('users')
        .doc(student.id)
        .update({ [`groupChats.${chatRef.id}`]: chatRef })
    );
    userPromises.push(
      db
        .collection('users')
        .doc(values.teacherId)
        .update({ [`groupChats.${chatRef.id}`]: chatRef })
    );
    if (values.teacherId !== usersStore.loggedInUserId) {
      userPromises.push(
        db
          .collection('users')
          .doc(usersStore.loggedInUserId)
          .update({ [`groupChats.${chatRef.id}`]: chatRef })
      );
    }
    await Promise.all(userPromises);
    closeModal();
  };

  return useObserver(() => (
    <Formik
      initialValues={{
        classroomName: '',
        topicId: '',
        moduleId: '',
        teacherId: '',
        students: [],
      }}
      validationSchema={createClassroomSchema}
      onSubmit={handleSubmit}
    >
      {({ values, validateForm, setTouched }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="CreateClassroomForm">
              {pageStore.page === 1 && (
                <>
                  <CSS.StaffFormHeaderRow>
                    <CSS.StaffFormHeader>
                      Create a Classroom
                    </CSS.StaffFormHeader>
                    <CSS.StaffFormCloseButton
                      onClick={closeModal}
                      type="button"
                    >
                      <CSS.StaffCloseIcon />
                    </CSS.StaffFormCloseButton>
                  </CSS.StaffFormHeaderRow>
                  <CSS.FieldGroup>
                    <Field
                      type="text"
                      name="classroomName"
                      id="classroomName"
                      label="Choose a name"
                      component={CSS.StaffTextFieldInput}
                    />
                    <ErrorMessage name="classroomName" component="p" />
                  </CSS.FieldGroup>
                  <CSS.FieldGroup>
                    <Field
                      select
                      name="topicId"
                      id="topicId"
                      label="Choose a topic"
                      component={CSS.StaffTextFieldInput}
                    >
                      {topicsStore.topics.map((topic) => {
                        return (
                          <CSS.StaffMenuItem key={topic.id} value={topic.id}>
                            {topic.topicName}
                          </CSS.StaffMenuItem>
                        );
                      })}
                    </Field>
                    <ErrorMessage name="topicId" component="p" />
                  </CSS.FieldGroup>
                  <CSS.FieldGroup>
                    <Field
                      select
                      name="moduleId"
                      id="moduleId"
                      label="Choose a module"
                      component={CSS.StaffTextFieldInput}
                    >
                      {values.topicId ? (
                        topicsStore.topics
                          .find((topic) => topic.id === values.topicId)
                          .modules.map((module, index) => {
                            return (
                              <CSS.StaffMenuItem key={index} value={module.id}>
                                {module.moduleName}
                              </CSS.StaffMenuItem>
                            );
                          })
                      ) : (
                        <CSS.StaffMenuItem value="">
                          Please choose a topic first
                        </CSS.StaffMenuItem>
                      )}
                    </Field>
                    <ErrorMessage name="moduleName" component="p" />
                  </CSS.FieldGroup>
                  <CSS.FieldGroup>
                    <Field
                      select
                      name="teacherId"
                      id="teacherId"
                      label="Assign a teacher"
                      component={CSS.StaffTextFieldInput}
                    >
                      {usersStore.getUsersByRole('teacher').map((teacher) => {
                        return (
                          <CSS.StaffMenuItem
                            key={teacher.id}
                            value={teacher.id}
                          >
                            {teacher.displayName}
                          </CSS.StaffMenuItem>
                        );
                      })}
                    </Field>
                    <ErrorMessage name="teacherId" component="p" />
                  </CSS.FieldGroup>
                  <CSS.StaffFooterRow>
                    <CSS.StaffNavButton
                      onClick={() =>
                        pageStore.changePage(1, validateForm, setTouched)
                      }
                      type="button"
                    >
                      next
                    </CSS.StaffNavButton>
                  </CSS.StaffFooterRow>
                </>
              )}
              {pageStore.page === 2 && (
                <>
                  <CSS.StaffFormHeaderRow>
                    <CSS.StaffFormHeader>Add Students</CSS.StaffFormHeader>
                    <CSS.StaffFormCloseButton
                      onClick={closeModal}
                      type="button"
                    >
                      <CSS.StaffCloseIcon />
                    </CSS.StaffFormCloseButton>
                  </CSS.StaffFormHeaderRow>
                  <FieldArray name="students">
                    {({ push, remove }) => (
                      <>
                        <CSS.StaffAutocomplete
                          onChange={(event, student, reason) => {
                            if (reason === 'select-option') {
                              const alreadySelected = values.students.find(
                                ({ id }) => id === student.id
                              );
                              if (!alreadySelected) {
                                push(student);
                              }
                            }
                          }}
                          options={usersStore.getUsersByRole('student')}
                          getOptionLabel={(student) => student.displayName}
                          renderInput={(params) => (
                            <CSS.StaffAutocompleteInput
                              {...params}
                              label="Add Students"
                              variant="outlined"
                            />
                          )}
                        />
                        <CSS.StudentList>
                          {values.students.map((student, index) => (
                            <AddStudentCard
                              key={student.id}
                              student={student}
                              removeStudent={() => remove(index)}
                            />
                          ))}
                        </CSS.StudentList>
                      </>
                    )}
                  </FieldArray>
                  <CSS.StaffFooterRow>
                    <CSS.StaffNavButton
                      onClick={() =>
                        pageStore.changePage(-1, validateForm, setTouched)
                      }
                      type="button"
                    >
                      back
                    </CSS.StaffNavButton>
                    <CSS.StaffNavButton>Finish</CSS.StaffNavButton>
                  </CSS.StaffFooterRow>
                </>
              )}
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  ));
});

export default CreateClassroomForm;
