import React from 'react';
import * as CSS from './elements/AddStudentCard';

const AddStudentCard = ({ student, removeStudent }) => {
  return (
    <CSS.ListCard
      data-testid="AddStudentCard"
      isStaff={['teacher', 'admin'].includes(student.role)}
    >
      <CSS.Avatar src={student.imageURL} />
      <CSS.StudentName>{student.displayName}</CSS.StudentName>
      <CSS.DeleteButton
        data-testid="AddStudentCard-delete"
        onClick={removeStudent}
        type="button"
      >
        <CSS.Bin />
      </CSS.DeleteButton>
    </CSS.ListCard>
  );
};

export default AddStudentCard;
