import { Popover } from '@material-ui/core';
import { Link } from '@reach/router';
import { ErrorMessage, Field, Formik } from 'formik';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import * as yup from 'yup';
import * as api from '../../api.js';
import { UsersContext } from '../../contexts/Users.js';
import { db } from '../../firebase/index.js';
import { ReactComponent as Padlock } from '../../svgs/padlock.svg';
import Loading from '../common/Loading.js';
import * as CSS from './elements/CreateUserForm';

const resetSchema = yup.object().shape({
  phoneNumber: yup.string().required('Please enter your phone number'),
  password: yup
    .string()
    .required('Please choose a new password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Minimum length 8 characters, including one uppercase, one lowercase, one number and one special case character (@ $ ! % * # ? &)'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your new password'),
});

const ResetPassword = observer(({ resetCode, resetRequired }) => {
  const usersStore = useContext(UsersContext);
  const infoRef = useRef(null);

  const store = useLocalStore(() => ({
    hasReset: false,
    isInfoOpen: false,
    setInfoOpen: (open) => {
      store.isInfoOpen = open;
    },
    token: null,
  }));
  const handleSubmit = async (
    { phoneNumber, password },
    { setFieldError, resetForm }
  ) => {
    if (resetRequired) {
      const userPrivateDoc = await db
        .collection('usersPrivate')
        .doc(usersStore.loggedInUserId)
        .get();
      if (userPrivateDoc.data().resetRequired) {
        resetCode = userPrivateDoc.data().resetCode;
      }
    }

    return api
      .resetPin({ phoneNumber, pin: password, resetCode })
      .then(() => {
        resetForm();
        store.hasReset = true;
        if (store.token) {
          localStorage.setItem('token', store.token);
        }
      })
      .catch((err) => {
        setFieldError('serverError', err.response?.data.msg);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      store.token = token;
      localStorage.removeItem('token');
    }
  }, [store]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        phoneNumber: resetRequired
          ? usersStore.getLoggedInUser()?.private?.phoneNumber
          : '',
        password: '',
        confirmNewPassword: '',
        serverError: null,
      }}
      validationSchema={resetSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Observer>
          {() => (
            <CSS.ResetForm data-testid="ResetPassword">
              <Padlock />
              {!resetRequired && (
                <CSS.BackLink to="/" aria-label="back">
                  <CSS.BigArrowBackIcon />
                </CSS.BackLink>
              )}
              {store.hasReset ? (
                <CSS.Success>
                  <CSS.FormHeader>Password Reset OK</CSS.FormHeader>
                  <Link to="/">
                    <CSS.SignUpButton>Login</CSS.SignUpButton>
                  </Link>
                </CSS.Success>
              ) : (
                <>
                  <CSS.FieldGroup>
                    <CSS.FormHeader>Choose a new Password</CSS.FormHeader>
                    <CSS.AlignButton ref={infoRef} hoverable center>
                      <CSS.InfoIcon onClick={() => store.setInfoOpen(true)} />
                    </CSS.AlignButton>
                    <Field
                      type="text"
                      placeholder="07123456789"
                      name="phoneNumber"
                      autoComplete="tel"
                      label="Phone Number"
                      component={CSS.StaffTextFieldInput}
                      id="phoneNumber"
                    />
                    <ErrorMessage name="phoneNumber" component="p" />
                  </CSS.FieldGroup>

                  <Popover
                    open={store.isInfoOpen}
                    anchorEl={infoRef.current}
                    onClose={() => store.setInfoOpen(false)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <CSS.InfoContainer>
                      <CSS.InfoHeading>Password Requirements</CSS.InfoHeading>
                      <CSS.InfoText>Minimum length 8 characters</CSS.InfoText>
                      <CSS.InfoText>
                        Must include at least one uppercase and lowercase
                        character
                      </CSS.InfoText>
                      <CSS.InfoText>
                        Must include at least one of the following special case
                        characters: @ $ ! % * # ? &
                      </CSS.InfoText>
                    </CSS.InfoContainer>
                  </Popover>
                  <CSS.FieldGroup>
                    <Field
                      type="password"
                      name="password"
                      autoComplete="new-password"
                      label="Password"
                      component={CSS.StaffTextFieldInput}
                      id="password"
                    />

                    <ErrorMessage name="password" component="p" />
                  </CSS.FieldGroup>
                  <CSS.FieldGroup>
                    <Field
                      type="password"
                      name="confirmNewPassword"
                      autoComplete="new-password"
                      label="Confirm Password"
                      component={CSS.StaffTextFieldInput}
                      id="confirmNewPassword"
                    />
                    <ErrorMessage name="confirmNewPassword" component="p" />
                  </CSS.FieldGroup>
                  <ErrorMessage name="serverError" component="p" />
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <CSS.SignUpButton
                      data-testid="RequestReset-submit"
                      type="submit"
                    >
                      OK
                    </CSS.SignUpButton>
                  )}
                </>
              )}
            </CSS.ResetForm>
          )}
        </Observer>
      )}
    </Formik>
  );
});

export default ResetPassword;
