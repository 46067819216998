import { navigate } from '@reach/router';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { observer, Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { LearningAssessmentsContext } from '../../contexts/LearningAssessments';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { db } from '../../firebase';
import Error from '../common/Error';
import * as CSS from './elements/LearningAssessment';

const questionsSchema = yup.object().shape({
  title: yup.string().required('Please add a title'),
  questions: yup.array().of(
    yup.object().shape({
      text: yup
        .string()
        .matches(/[^\s]+/, 'Please fill out this question')
        .required('Please fill out this question'),
    })
  ),
});

const LearningAssessment = observer(({ id, questionSet }) => {
  const validQuestionSets = {
    start: 'initialQuestions',
    end: 'finalQuestions',
  };
  const learningAssessmentsStore = useContext(LearningAssessmentsContext);
  const saveChangesStore = useContext(SaveChangesContext);

  const assessment = learningAssessmentsStore.getById(id);
  const validQuestionSet = validQuestionSets[questionSet];
  if (!assessment || !validQuestionSet)
    return <Error msg="Learning Plan not found" />;

  const questions = assessment
    .getOrderedQuestions(validQuestionSet)
    .map((question) => question.toJSON());

  const handleSubmit = async ({ questions, title }) => {
    if (!assessment.published) {
      const questionsMap = questions.reduce((questionsMap, question, i) => {
        questionsMap[question.id] = { ...question, index: i };
        return questionsMap;
      }, {});

      await db
        .collection('learningAssessments')
        .doc(id)
        .update({ title, [`${validQuestionSet}`]: questionsMap });
    }
  };

  const handlePublish = () => {
    saveChangesStore.setText({
      heading: 'Publish',
      message:
        'Are you sure you wish to publish this learning plan? You will not be able to edit it afterwards.',
      leavingText: 'Yes',
      remainingText: 'No',
    });
    saveChangesStore.open(async () => {
      await db
        .collection('learningAssessments')
        .doc(id)
        .update({ published: true });
    });
  };

  const addQuestion = (push, values) => {
    const newIndex = values.questions.length;
    push({
      id: uuidv4(),
      text: '',
      index: newIndex,
      teacherOnly: false,
    });
  };

  const deleteQuestion = (remove, indexToRemove) => {
    remove(indexToRemove);
  };

  const deleteAssessment = async () => {
    saveChangesStore.setText({
      heading: `Delete ${assessment.title}`,
      message: `Are you sure that you want to delete ${assessment.title}? \nThis cannot be undone.`,
      leavingText: 'Yes',
      remainingText: 'No',
    });
    saveChangesStore.open(async () => {
      navigate('/staff/admin/learningPlans');
      await db.collection('learningAssessments').doc(id).delete();
    });
  };

  return (
    <CSS.AssessmentContainer data-testid="LearningAssessment">
      <Formik
        enableReinitialize
        initialValues={{
          questions,
          title: assessment.title,
        }}
        validationSchema={questionsSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty }) => {
          saveChangesStore.setEdited(dirty);
          return (
            <Observer>
              {() => (
                <Form data-testid="LearningAssessment-form">
                  <CSS.StaffRow>
                    <CSS.TitleGroup>
                      <Field
                        name="title"
                        data-testid="LearningAssessment-title"
                        component={CSS.AssessmentTitle}
                        disabled={assessment.published}
                      ></Field>
                      <ErrorMessage name={`title`} component={CSS.TitleError} />
                    </CSS.TitleGroup>
                    <CSS.DeleteAssessmentButton
                      onClick={deleteAssessment}
                      type="button"
                    >
                      Delete
                    </CSS.DeleteAssessmentButton>
                  </CSS.StaffRow>
                  <CSS.AssessmentNavRow>
                    <nav>
                      <CSS.StaffFilterLink
                        isfirst
                        to={`/staff/admin/learningPlans/${id}/start`}
                      >
                        Start
                      </CSS.StaffFilterLink>
                      <CSS.StaffFilterLink
                        to={`/staff/admin/learningPlans/${id}/end`}
                      >
                        End
                      </CSS.StaffFilterLink>
                      <CSS.PublishButton
                        data-testid="LearningAssessment-publish"
                        type="button"
                        disabled={assessment.published}
                        onClick={handlePublish}
                      >
                        {assessment.published ? 'Published' : 'Publish'}
                      </CSS.PublishButton>
                    </nav>
                    {!assessment.published && (
                      <CSS.SaveChangesButton type="submit" disabled={!dirty}>
                        {dirty ? 'Save' : 'Saved'}
                      </CSS.SaveChangesButton>
                    )}
                  </CSS.AssessmentNavRow>
                  <FieldArray name="questions">
                    {({ push, remove }) => (
                      <Observer>
                        {() => (
                          <>
                            <CSS.QuestionList>
                              <CSS.QuestionLi>
                                <CSS.InfoText>
                                  Questions marked as teacher only cannot be
                                  answered by students themselves. A teacher
                                  must fill them out for them.
                                </CSS.InfoText>
                              </CSS.QuestionLi>
                              {values.questions.map((question, i) => (
                                <CSS.QuestionLi key={question.id}>
                                  <CSS.QuestionBullet>
                                    {i + 1}
                                  </CSS.QuestionBullet>
                                  <Field
                                    data-testid="LearningAssessment-question"
                                    component={CSS.TextArea}
                                    name={`questions.${i}.text`}
                                    rowsMin={3}
                                    disabled={assessment.published}
                                  />
                                  <ErrorMessage
                                    name={`questions.${i}.text`}
                                    component={CSS.ErrorMessage}
                                  />
                                  <Field
                                    data-testid="LearningAssessment-teacherOnly"
                                    name={`questions.${i}.teacherOnly`}
                                    component={CSS.Check}
                                    disabled={assessment.published}
                                  />
                                  {!assessment.published && (
                                    <CSS.DeleteIconButton
                                      type="button"
                                      aria-label="delete question"
                                      onClick={() => deleteQuestion(remove, i)}
                                    >
                                      <CSS.Bin style={{ fontSize: '3rem' }} />
                                    </CSS.DeleteIconButton>
                                  )}
                                </CSS.QuestionLi>
                              ))}
                            </CSS.QuestionList>
                            {!assessment.published && (
                              <CSS.Center>
                                <CSS.AddQuestionButton
                                  type="button"
                                  aria-label="add question"
                                  onClick={() => addQuestion(push, values)}
                                >
                                  Add new question{' '}
                                  <CSS.PlusIcon
                                    style={{
                                      fontSize: '2rem',
                                      paddingLeft: '0.3em',
                                      verticalAlign: 'sub',
                                    }}
                                  />
                                </CSS.AddQuestionButton>
                              </CSS.Center>
                            )}
                          </>
                        )}
                      </Observer>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Observer>
          );
        }}
      </Formik>
    </CSS.AssessmentContainer>
  );
});

export default LearningAssessment;
