import { types } from 'mobx-state-tree';

const Category = types
  .model('Category', {
    id: types.maybeNull(types.string),
    categoryName: types.string,
  })
  .actions((self) => ({
    updateInfo(updatedCategory) {
      self.id = updatedCategory.id || null;
      self.categoryName = updatedCategory.categoryName;
    },
  }));

export default Category;
