import { Form as FormikForm } from 'formik';
import styled from 'styled-components/macro';
import * as common from '../../common/elements/Form';

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${common.ScreenWidthMQs}
`;

export const LoginButton = styled(common.Button)`
  margin-top: 1.5em;
  padding-right: 1.3em;
  background: ${({ theme }) => theme.classroomHighlight};
`;

export const StudentLoginButton = styled(common.Button)`
  margin-top: 1.5em;
`;

export const LoadingContainer = styled.div`
  margin-top: 2em;
`;

export const LoginInput = styled(common.FieldInput)`
  font-size: 1.8rem;
  padding: 0.3em 0;
`;

export const LogoImg = styled.img`
  height: 12em;
  margin-top: 4em;
`;

export const IsStaffButton = styled(common.ResetButton)`
  @media only screen and (min-width: 700px) {
    margin-top: 2.5em;
  }
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
