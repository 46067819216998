import { navigate } from '@reach/router';
import { types } from 'mobx-state-tree';

const SaveChanges = types
  .model('SaveChanges', {
    isOpen: types.optional(types.boolean, false),
    isEdited: types.optional(types.boolean, false),
    heading: types.optional(types.string, 'You have unsaved changes'),
    message: types.optional(
      types.string,
      'Are you sure you wish to discard these changes?'
    ),
    leavingText: types.optional(types.string, 'Discard'),
    remainingText: types.optional(types.string, 'Cancel'),
  })
  .volatile(() => ({
    callback: types.function,
  }))
  .actions((self) => ({
    open(callback) {
      self.isOpen = true;
      if (callback) self.callback = callback;
    },
    close(isLeaving) {
      self.isOpen = false;
      if (isLeaving && self.callback) {
        self.isEdited = false;
        self.callback();
        self.callback = undefined;
      }
      self.resetText();
    },
    setEdited(edited) {
      self.isEdited = edited;
    },
    setText({ heading, message, leavingText, remainingText }) {
      if (heading) self.heading = heading;
      if (message) self.message = message;
      if (leavingText) self.leavingText = leavingText;
      if (remainingText) self.remainingText = remainingText;
    },
    resetText() {
      self.heading = 'You have unsaved changes';
      self.message = 'Are you sure you wish to discard these changes?';
      self.leavingText = 'Discard';
      self.remainingText = 'Cancel';
    },
    handleNavigate(e, isMobile, chatsStore) {
      e.preventDefault();
      if (isMobile) chatsStore.setIsSelectedChatVisible(false);
      const { href } = e.target;
      if (self.isEdited && href !== window.location.href) {
        self.open(() => {
          navigate(href);
        });
      } else navigate(href);
    },
  }));

export default SaveChanges;
