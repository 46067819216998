import { observer } from 'mobx-react-lite';
import React from 'react';

const MAE_LOGO = observer(() => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 393 218"
      style={{ width: '30%', marginRight: '1em', height: '4em' }}
    >
      <g>
        <path
          className="st0"
          d="M23.12,193.94c0,0.39,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h30.39c0.86,0,1.43-0.08,1.73-0.26
        c0.3-0.17,0.45-0.45,0.45-0.83v-8.72c0-0.86-0.09-1.43-0.26-1.73c-0.17-0.3-0.45-0.45-0.83-0.45h-4.3l-1.79,6.16H36.96v-11.8H51.9
        l1.54-4.94c0-0.38-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26h-14.3v-11.35h11.41l1.79,6.15h4.3
        c0.38,0,0.66-0.15,0.83-0.45c0.17-0.3,0.26-0.88,0.26-1.73v-8.72c0-0.39-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26H25.3
        c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.41,4.74h4.23v29.17h-4.23L23.12,193.94L23.12,193.94z M87.62,195.03
        h7.31c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42H92.3v-39.63c0-0.39-0.15-0.66-0.45-0.83
        c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.78v10.9
        c-0.9-0.43-1.92-0.78-3.08-1.06c-1.15-0.28-2.44-0.42-3.85-0.42c-2.78,0-5.09,0.46-6.93,1.38c-1.84,0.92-3.3,2.14-4.39,3.65
        c-1.09,1.52-1.86,3.23-2.31,5.13c-0.45,1.9-0.67,3.84-0.67,5.8c0,2.27,0.22,4.37,0.67,6.32c0.45,1.95,1.22,3.62,2.31,5.03
        c1.09,1.41,2.57,2.52,4.42,3.33c1.86,0.81,4.18,1.22,6.96,1.22c2.05,0,3.82-0.35,5.29-1.06c1.48-0.71,2.7-1.42,3.69-2.15
        L87.62,195.03L87.62,195.03z M84.41,187.6c-0.04,0.04-0.18,0.18-0.42,0.42c-0.24,0.23-0.58,0.49-1.03,0.77
        c-0.45,0.28-1.03,0.52-1.73,0.74c-0.71,0.21-1.55,0.32-2.53,0.32c-1.54,0-2.81-0.3-3.81-0.9c-1-0.6-1.78-1.38-2.34-2.34
        c-0.56-0.96-0.94-2.04-1.15-3.24c-0.21-1.2-0.32-2.41-0.32-3.65c0-1.15,0.11-2.33,0.32-3.53c0.21-1.2,0.6-2.3,1.15-3.3
        c0.56-1,1.34-1.82,2.34-2.44c1-0.62,2.28-0.93,3.81-0.93c0.94,0,1.75,0.11,2.44,0.32c0.68,0.21,1.25,0.45,1.7,0.71
        c0.45,0.26,0.8,0.5,1.06,0.74c0.26,0.23,0.43,0.37,0.51,0.42V187.6L84.41,187.6z M103.39,179.64c0,2.57,0.14,4.83,0.42,6.8
        c0.28,1.97,0.85,3.63,1.73,5c0.88,1.37,2.14,2.4,3.78,3.11c1.64,0.7,3.86,1.06,6.64,1.06c1.92,0,3.74-0.33,5.45-0.99
        c1.71-0.66,3.16-1.33,4.36-2.02l1.03,2.44h7.31c0.86,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46v-24.11
        c0-0.38-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66c-0.85,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83
        l1.35,4.42h3.78v17.7c-0.56,0.38-1.36,0.82-2.4,1.31c-1.05,0.49-2.26,0.74-3.62,0.74c-1.45,0-2.6-0.26-3.43-0.77
        c-0.83-0.51-1.46-1.15-1.89-1.92c-0.43-0.77-0.7-1.62-0.8-2.57c-0.11-0.94-0.16-1.86-0.16-2.76v-16.16c0-0.38-0.15-0.66-0.45-0.83
        c-0.3-0.17-0.88-0.26-1.73-0.26h-8.34c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.46V179.64
        L103.39,179.64z M165.46,185.42c-0.09-0.3-0.28-0.45-0.58-0.45c-0.21,0-0.39,0.07-0.51,0.22c-0.13,0.15-0.28,0.31-0.45,0.48
        c-0.56,0.56-1.11,1.1-1.67,1.64c-0.56,0.53-1.18,1-1.86,1.41c-0.68,0.41-1.44,0.73-2.28,0.96c-0.83,0.24-1.79,0.35-2.85,0.35
        c-1.71,0-3.08-0.38-4.1-1.15c-1.03-0.77-1.81-1.7-2.34-2.79c-0.53-1.09-0.88-2.22-1.03-3.4c-0.15-1.18-0.22-2.17-0.22-2.98
        c0-0.9,0.06-1.96,0.19-3.17c0.13-1.22,0.46-2.38,0.99-3.49c0.53-1.11,1.34-2.05,2.4-2.82c1.07-0.77,2.52-1.15,4.36-1.15
        c0.73,0,1.38,0.12,1.95,0.35c0.58,0.24,1.17,0.52,1.76,0.87l1.73,5.77h4.1c0.38,0,0.66-0.15,0.83-0.45
        c0.17-0.3,0.26-0.88,0.26-1.73v-6.67c-1.5-1.32-3.22-2.28-5.16-2.85c-1.95-0.58-3.99-0.87-6.12-0.87c-2.18,0-4.2,0.36-6.06,1.09
        c-1.86,0.73-3.45,1.79-4.78,3.17c-1.33,1.39-2.36,3.09-3.11,5.1c-0.75,2.01-1.12,4.3-1.12,6.86c0,5.13,1.29,9.12,3.88,11.96
        c2.59,2.84,6.44,4.26,11.57,4.26c1.5,0,2.82-0.13,3.98-0.39c1.15-0.26,2.2-0.6,3.14-1.03c0.94-0.43,1.77-0.94,2.5-1.54
        c0.73-0.6,1.39-1.24,1.99-1.92L165.46,185.42L165.46,185.42z M192.52,195.03h7.37c0.85,0,1.43-0.08,1.73-0.26
        c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46V175.8c0-1.75-0.2-3.39-0.61-4.91c-0.41-1.52-1.09-2.82-2.05-3.91
        c-0.96-1.09-2.23-1.94-3.81-2.57c-1.58-0.62-3.55-0.93-5.9-0.93c-1.92,0-3.94,0.27-6.06,0.8c-2.12,0.54-4.2,1.42-6.25,2.66
        l1.48,5.51c0.04,0.26,0.21,0.39,0.51,0.39c0.17,0,0.38-0.07,0.64-0.19c1.8-0.98,3.42-1.77,4.87-2.37c1.45-0.6,2.93-0.9,4.42-0.9
        c1.07,0,1.93,0.19,2.6,0.58c0.66,0.39,1.19,0.9,1.57,1.54c0.38,0.64,0.64,1.39,0.77,2.24c0.13,0.86,0.19,1.75,0.19,2.69v0.39h-6.73
        c-2.05,0-3.84,0.25-5.35,0.74c-1.52,0.49-2.77,1.15-3.75,1.99c-0.98,0.83-1.71,1.79-2.18,2.88c-0.47,1.09-0.7,2.26-0.7,3.49
        c0,6.46,3.76,9.68,11.29,9.68c1.97,0,3.74-0.32,5.32-0.96c1.58-0.64,2.91-1.3,3.98-1.99L192.52,195.03L192.52,195.03z
         M189.63,188.24c-0.73,0.39-1.71,0.8-2.95,1.25c-1.24,0.45-2.57,0.67-3.97,0.67c-3.12,0-4.68-1.37-4.68-4.1
        c0-0.39,0.06-0.82,0.19-1.31c0.13-0.49,0.42-0.96,0.87-1.41c0.45-0.45,1.09-0.82,1.92-1.12c0.83-0.3,1.93-0.45,3.3-0.45h5.32
        V188.24L189.63,188.24z M208.29,184.58c0,2.39,0.28,4.32,0.83,5.77c0.55,1.45,1.3,2.57,2.24,3.33c0.94,0.77,2.02,1.28,3.24,1.54
        c1.22,0.26,2.49,0.38,3.82,0.38c1.28,0,2.78-0.17,4.49-0.51c1.71-0.34,3.25-0.98,4.62-1.92l-1.41-5.64
        c-0.09-0.3-0.28-0.45-0.58-0.45c-0.09,0-0.28,0.12-0.58,0.35c-0.3,0.23-0.7,0.5-1.19,0.8c-0.49,0.3-1.05,0.57-1.67,0.8
        c-0.62,0.23-1.27,0.35-1.96,0.35c-0.86,0-1.54-0.13-2.05-0.38c-0.51-0.26-0.92-0.6-1.22-1.03c-0.3-0.43-0.49-0.93-0.58-1.51
        c-0.09-0.58-0.13-1.21-0.13-1.89v-14.75h9.23c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-10.07v-8.46
        h-5.71c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83v7.37h-2.76c-0.86,0-1.43,0.09-1.73,0.26
        c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.59V184.58L208.29,184.58z M229.96,193.94c0,0.39,0.15,0.66,0.45,0.83
        c0.3,0.17,0.88,0.26,1.73,0.26h13.47c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46v-24.11
        c0-0.38-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83
        l1.35,4.42h3.78v19.68h-3.78L229.96,193.94L229.96,193.94z M234.74,158.39c0.83,0.92,2.06,1.38,3.69,1.38
        c1.62,0,2.85-0.46,3.69-1.38c0.83-0.92,1.25-2.06,1.25-3.43c0-1.37-0.42-2.51-1.25-3.43c-0.83-0.92-2.06-1.38-3.69-1.38
        c-1.63,0-2.85,0.46-3.69,1.38c-0.83,0.92-1.25,2.06-1.25,3.43C233.49,156.33,233.9,157.47,234.74,158.39L234.74,158.39z
         M279.33,191.67c2.82-2.84,4.23-6.83,4.23-11.96c0-5.13-1.41-9.12-4.23-11.96c-2.82-2.84-6.77-4.26-11.86-4.26
        c-5.09,0-9.05,1.42-11.89,4.26c-2.84,2.84-4.26,6.83-4.26,11.96c0,5.13,1.42,9.12,4.26,11.96c2.84,2.84,6.81,4.26,11.89,4.26
        C272.56,195.93,276.51,194.51,279.33,191.67L279.33,191.67z M263.11,189.3c-1.07-0.79-1.88-1.75-2.44-2.89
        c-0.56-1.13-0.91-2.31-1.06-3.53c-0.15-1.22-0.22-2.28-0.22-3.17c0-0.9,0.07-1.96,0.22-3.17c0.15-1.22,0.5-2.39,1.06-3.53
        c0.55-1.13,1.37-2.09,2.44-2.89c1.07-0.79,2.52-1.19,4.36-1.19c1.84,0,3.29,0.4,4.36,1.19c1.07,0.79,1.87,1.75,2.41,2.89
        c0.53,1.13,0.87,2.31,1.03,3.53c0.15,1.22,0.22,2.28,0.22,3.17c0,0.9-0.08,1.96-0.22,3.17c-0.15,1.22-0.49,2.4-1.03,3.53
        c-0.54,1.13-1.34,2.09-2.41,2.89c-1.07,0.79-2.52,1.19-4.36,1.19C265.63,190.48,264.18,190.09,263.11,189.3L263.11,189.3z
         M288.05,193.94c0,0.39,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h13.79c0.85,0,1.43-0.08,1.73-0.26
        c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.78v-17.7c0.55-0.39,1.35-0.82,2.37-1.32c1.03-0.49,2.24-0.74,3.66-0.74
        c1.45,0,2.6,0.26,3.43,0.77c0.83,0.51,1.46,1.16,1.89,1.96c0.43,0.79,0.7,1.66,0.8,2.6c0.11,0.94,0.16,1.86,0.16,2.76v16.09
        c0,0.39,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h8.34c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83
        l-1.35-4.42h-3.46v-9.81c0-2.56-0.14-4.83-0.42-6.8c-0.28-1.97-0.85-3.63-1.73-5c-0.88-1.37-2.15-2.4-3.82-3.11
        c-1.67-0.71-3.87-1.06-6.6-1.06c-1.92,0-3.75,0.33-5.48,0.99c-1.73,0.66-3.17,1.34-4.33,2.02l-1.03-2.44h-7.63
        c-0.85,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.78v19.68h-3.78L288.05,193.94L288.05,193.94z"
        />
        <path
          className="st0"
          d="M21,124.04c0,0.38,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h13.98c0.85,0,1.43-0.08,1.73-0.26
        c0.3-0.17,0.45-0.45,0.45-0.83l-1.41-4.75H33.7l2.05-6.35h15.71l1.99,6.35h-4.23l-1.41,4.75c0,0.38,0.15,0.66,0.45,0.83
        c0.3,0.17,0.88,0.26,1.73,0.26h14.49c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.41-4.75h-3.59L51.58,88.01
        c-0.34-1.07-0.98-1.96-1.92-2.66c-0.94-0.71-2.2-1.06-3.78-1.06H31.26c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83
        l1.41,4.74h5.39L26,119.3h-3.59L21,124.04L21,124.04z M37.54,107.43l5.58-16.99h0.58c0.21,0,0.42,0.06,0.61,0.19
        c0.19,0.13,0.37,0.43,0.55,0.9l4.87,15.9H37.54L37.54,107.43z M93.26,125.13h7.31c0.85,0,1.43-0.08,1.73-0.26
        c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46V79.99c0-0.39-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66
        c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.78v10.9c-0.9-0.43-1.92-0.78-3.08-1.06
        c-1.15-0.28-2.44-0.42-3.85-0.42c-2.78,0-5.09,0.46-6.93,1.38c-1.84,0.92-3.3,2.14-4.39,3.66c-1.09,1.52-1.86,3.23-2.31,5.13
        c-0.45,1.9-0.67,3.84-0.67,5.8c0,2.27,0.22,4.37,0.67,6.32c0.45,1.95,1.22,3.62,2.31,5.03c1.09,1.41,2.57,2.52,4.42,3.33
        c1.86,0.81,4.18,1.22,6.96,1.22c2.05,0,3.82-0.35,5.29-1.06c1.48-0.7,2.7-1.42,3.69-2.15L93.26,125.13L93.26,125.13z M90.06,117.69
        c-0.04,0.04-0.18,0.18-0.42,0.42c-0.24,0.24-0.58,0.49-1.03,0.77c-0.45,0.28-1.03,0.52-1.73,0.74c-0.71,0.21-1.55,0.32-2.53,0.32
        c-1.54,0-2.81-0.3-3.82-0.9c-1-0.6-1.78-1.38-2.34-2.34c-0.56-0.96-0.94-2.04-1.15-3.24c-0.21-1.2-0.32-2.41-0.32-3.66
        c0-1.15,0.11-2.33,0.32-3.53c0.21-1.2,0.6-2.3,1.15-3.3c0.56-1,1.34-1.82,2.34-2.44c1-0.62,2.28-0.93,3.82-0.93
        c0.94,0,1.75,0.11,2.44,0.32c0.68,0.21,1.25,0.45,1.7,0.7c0.45,0.26,0.8,0.5,1.06,0.74c0.26,0.23,0.43,0.37,0.51,0.42V117.69
        L90.06,117.69z M109.03,109.74c0,2.57,0.14,4.83,0.42,6.8c0.28,1.97,0.85,3.63,1.73,5c0.88,1.37,2.14,2.41,3.78,3.11
        c1.65,0.71,3.86,1.06,6.64,1.06c1.92,0,3.74-0.33,5.45-0.99c1.71-0.66,3.16-1.33,4.36-2.02l1.03,2.44h7.31
        c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46V95.51c0-0.39-0.15-0.66-0.45-0.83
        c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.78v17.7
        c-0.56,0.39-1.36,0.82-2.4,1.31c-1.05,0.49-2.26,0.74-3.62,0.74c-1.45,0-2.6-0.26-3.43-0.77c-0.83-0.51-1.46-1.15-1.89-1.92
        c-0.43-0.77-0.69-1.62-0.8-2.57c-0.11-0.94-0.16-1.86-0.16-2.76V95.51c0-0.39-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26
        h-8.33c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.46V109.74L109.03,109.74z M144.17,124.04
        c0,0.38,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h13.47c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83
        l-1.35-4.42h-3.46V79.99c0-0.39-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66c-0.86,0-1.43,0.09-1.73,0.26
        c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.78v35.2h-3.78L144.17,124.04L144.17,124.04z M168.54,114.68c0,2.4,0.28,4.32,0.83,5.77
        c0.56,1.45,1.3,2.57,2.24,3.33c0.94,0.77,2.02,1.28,3.24,1.54c1.22,0.26,2.49,0.39,3.82,0.39c1.28,0,2.78-0.17,4.49-0.51
        c1.71-0.34,3.25-0.98,4.62-1.92l-1.41-5.64c-0.09-0.3-0.28-0.45-0.58-0.45c-0.09,0-0.28,0.12-0.58,0.35
        c-0.3,0.24-0.69,0.5-1.19,0.8c-0.49,0.3-1.05,0.57-1.67,0.8c-0.62,0.24-1.27,0.35-1.96,0.35c-0.85,0-1.54-0.13-2.05-0.38
        c-0.51-0.26-0.92-0.6-1.22-1.03c-0.3-0.43-0.49-0.93-0.58-1.51c-0.09-0.58-0.13-1.21-0.13-1.89V99.93h9.23
        c0.86,0,1.43-0.09,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-10.07v-8.46h-5.71c-0.86,0-1.43,0.09-1.73,0.26
        c-0.3,0.17-0.45,0.45-0.45,0.83v7.37h-2.76c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.59V114.68
        L168.54,114.68z"
        />
        <path
          className="st0"
          d="M23.12,54.14c0,0.38,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h14.55c0.85,0,1.43-0.08,1.73-0.26
        c0.3-0.17,0.45-0.45,0.45-0.83l-1.41-4.74h-4.23V20.8h0.26c0.26,0,0.44,0.07,0.54,0.22c0.11,0.15,0.2,0.33,0.29,0.54l10.07,24.69
        c0.13,0.34,0.29,0.61,0.48,0.8c0.19,0.19,0.57,0.29,1.12,0.29h3.53c0.51,0,0.88-0.1,1.09-0.29c0.21-0.19,0.38-0.46,0.51-0.8
        l9.68-24.43c0.08-0.21,0.18-0.39,0.29-0.55c0.11-0.15,0.29-0.22,0.54-0.22h0.26v28.34h-4.23l-1.41,4.74c0,0.38,0.15,0.66,0.45,0.83
        c0.3,0.17,0.88,0.26,1.73,0.26h15.13c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.41-4.74h-4.23V20.22h4.23
        l1.41-4.74c0-0.38-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26H63.45c-1.24,0-2.22,0.15-2.95,0.45
        c-0.73,0.3-1.32,0.98-1.8,2.05l-7.63,20.13h-0.26l-7.57-20.13c-0.43-1.11-1.02-1.8-1.76-2.08c-0.75-0.28-1.74-0.42-2.98-0.42H25.3
        c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.41,4.74h4.23v29.17h-4.23L23.12,54.14L23.12,54.14z M105.19,55.23
        h7.37c0.86,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46V35.99c0-1.75-0.2-3.39-0.61-4.9
        c-0.41-1.52-1.09-2.82-2.05-3.91c-0.96-1.09-2.23-1.94-3.81-2.56c-1.58-0.62-3.55-0.93-5.9-0.93c-1.92,0-3.94,0.27-6.06,0.8
        c-2.12,0.54-4.2,1.42-6.25,2.66l1.47,5.52c0.04,0.26,0.21,0.38,0.51,0.38c0.17,0,0.39-0.06,0.64-0.19
        c1.79-0.98,3.42-1.77,4.87-2.37c1.45-0.6,2.93-0.9,4.42-0.9c1.07,0,1.93,0.19,2.6,0.58c0.66,0.39,1.19,0.9,1.57,1.54
        c0.39,0.64,0.64,1.39,0.77,2.24c0.13,0.86,0.19,1.75,0.19,2.69v0.39h-6.73c-2.05,0-3.84,0.25-5.35,0.74
        c-1.52,0.49-2.77,1.15-3.75,1.99c-0.98,0.83-1.71,1.79-2.18,2.89c-0.47,1.09-0.71,2.25-0.71,3.49c0,6.45,3.76,9.68,11.29,9.68
        c1.97,0,3.74-0.32,5.32-0.96c1.58-0.64,2.91-1.3,3.98-1.99L105.19,55.23L105.19,55.23z M102.3,48.43c-0.73,0.39-1.71,0.8-2.95,1.25
        s-2.56,0.67-3.98,0.67c-3.12,0-4.68-1.37-4.68-4.1c0-0.39,0.06-0.82,0.19-1.32c0.13-0.49,0.42-0.96,0.87-1.41
        c0.45-0.45,1.09-0.82,1.92-1.12c0.83-0.3,1.93-0.45,3.3-0.45h5.32V48.43L102.3,48.43z M117.31,54.14c0,0.38,0.15,0.66,0.45,0.83
        c0.3,0.17,0.88,0.26,1.73,0.26h13.79c0.86,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.78v-17.7
        c0.55-0.38,1.35-0.82,2.37-1.31c1.03-0.49,2.24-0.74,3.65-0.74c1.45,0,2.6,0.26,3.43,0.77c0.83,0.51,1.46,1.17,1.89,1.96
        c0.43,0.79,0.69,1.66,0.8,2.6c0.11,0.94,0.16,1.86,0.16,2.76v16.09c0,0.38,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h8.34
        c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46V39.9c0-2.57-0.14-4.83-0.42-6.8
        c-0.28-1.96-0.86-3.63-1.73-5c-0.88-1.37-2.15-2.4-3.82-3.11c-1.67-0.7-3.87-1.06-6.6-1.06c-1.92,0-3.75,0.33-5.48,0.99
        c-1.73,0.66-3.17,1.34-4.33,2.02l-1.03-2.44h-7.63c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.43h3.78
        v19.68h-3.78L117.31,54.14L117.31,54.14z M184.18,45.61c-0.09-0.3-0.28-0.45-0.58-0.45c-0.22,0-0.39,0.08-0.51,0.22
        c-0.13,0.15-0.28,0.31-0.45,0.48c-0.56,0.56-1.11,1.1-1.67,1.64c-0.56,0.54-1.18,1.01-1.86,1.41c-0.68,0.41-1.44,0.73-2.28,0.96
        c-0.83,0.23-1.78,0.35-2.85,0.35c-1.71,0-3.08-0.38-4.1-1.15c-1.03-0.77-1.81-1.7-2.34-2.79c-0.54-1.09-0.88-2.22-1.03-3.4
        c-0.15-1.18-0.22-2.17-0.22-2.98c0-0.9,0.06-1.96,0.19-3.17c0.13-1.22,0.46-2.38,0.99-3.49c0.53-1.11,1.33-2.05,2.4-2.82
        c1.07-0.77,2.52-1.15,4.36-1.15c0.73,0,1.38,0.12,1.96,0.35c0.58,0.24,1.16,0.52,1.76,0.87l1.73,5.77h4.1
        c0.38,0,0.66-0.15,0.83-0.45c0.17-0.3,0.26-0.88,0.26-1.73V27.4c-1.5-1.32-3.22-2.28-5.16-2.85c-1.95-0.58-3.99-0.87-6.12-0.87
        c-2.18,0-4.2,0.36-6.06,1.09c-1.86,0.73-3.45,1.79-4.78,3.17c-1.33,1.39-2.36,3.09-3.11,5.1c-0.75,2.01-1.12,4.3-1.12,6.86
        c0,5.13,1.29,9.12,3.88,11.96c2.59,2.84,6.44,4.26,11.57,4.26c1.49,0,2.82-0.13,3.97-0.38c1.15-0.26,2.2-0.6,3.14-1.03
        c0.94-0.43,1.77-0.94,2.5-1.54c0.73-0.6,1.39-1.24,1.99-1.92L184.18,45.61L184.18,45.61z M188.67,54.14c0,0.38,0.15,0.66,0.45,0.83
        c0.3,0.17,0.88,0.26,1.73,0.26h13.79c0.86,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.78v-17.7
        c0.51-0.38,1.28-0.82,2.31-1.31c1.03-0.49,2.26-0.74,3.72-0.74c1.45,0,2.6,0.26,3.43,0.77c0.83,0.51,1.46,1.17,1.89,1.96
        c0.43,0.79,0.69,1.66,0.8,2.6c0.11,0.94,0.16,1.86,0.16,2.76v16.09c0,0.38,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h8.34
        c0.86,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-3.46V39.9c0-2.57-0.14-4.83-0.42-6.8
        c-0.28-1.96-0.85-3.63-1.73-5c-0.88-1.37-2.15-2.4-3.82-3.11c-1.67-0.7-3.87-1.06-6.6-1.06c-1.37,0-2.7,0.19-4.01,0.58
        c-1.3,0.39-2.51,0.83-3.62,1.35V10.09c0-0.39-0.15-0.66-0.45-0.83c-0.3-0.17-0.88-0.26-1.73-0.26h-8.66
        c-0.85,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.42h3.78v35.2h-3.78L188.67,54.14L188.67,54.14z M256.25,46.12
        c-0.09-0.3-0.28-0.45-0.58-0.45c-0.21,0-0.38,0.08-0.51,0.22c-0.13,0.15-0.28,0.31-0.45,0.48c-1.11,1.07-2.45,1.98-4.01,2.73
        c-1.56,0.75-3.3,1.12-5.23,1.12c-1.54,0-2.81-0.3-3.82-0.9c-1-0.6-1.79-1.34-2.37-2.21c-0.58-0.88-0.99-1.83-1.25-2.85
        c-0.26-1.03-0.41-1.99-0.45-2.88h17.89c0.85,0,1.46-0.05,1.83-0.16c0.36-0.11,0.7-0.42,0.99-0.93v-2.12c0-1.67-0.17-3.37-0.51-5.1
        c-0.34-1.73-0.99-3.29-1.95-4.68c-0.96-1.39-2.28-2.52-3.94-3.4c-1.67-0.88-3.85-1.31-6.54-1.31c-5.09,0-8.93,1.42-11.54,4.26
        c-2.61,2.84-3.91,6.83-3.91,11.96c0,5.13,1.33,9.12,3.98,11.96c2.65,2.84,6.52,4.26,11.6,4.26c2.86,0,5.28-0.49,7.25-1.47
        c1.96-0.98,3.57-2.16,4.81-3.53L256.25,46.12L256.25,46.12z M237.72,36.57c0.13-0.9,0.34-1.82,0.64-2.76
        c0.3-0.94,0.74-1.8,1.31-2.57c0.58-0.77,1.32-1.4,2.24-1.89c0.92-0.49,2.06-0.74,3.43-0.74c1.32,0,2.36,0.35,3.11,1.06
        c0.75,0.71,1.31,1.5,1.7,2.37c0.39,0.88,0.62,1.72,0.71,2.53c0.08,0.81,0.13,1.33,0.13,1.54v0.45H237.72L237.72,36.57z
         M263.75,52.09c1.75,1.58,3.85,2.65,6.28,3.21c2.44,0.55,4.87,0.83,7.31,0.83c1.8,0,3.46-0.19,5-0.58
        c1.54-0.38,2.88-0.96,4.04-1.73c1.15-0.77,2.05-1.74,2.69-2.92c0.64-1.18,0.96-2.55,0.96-4.14c0-1.5-0.28-2.77-0.83-3.82
        c-0.56-1.05-1.32-1.95-2.31-2.69c-0.98-0.75-2.17-1.39-3.56-1.92c-1.39-0.53-2.94-1.04-4.65-1.51c-1.03-0.3-2-0.58-2.92-0.83
        c-0.92-0.26-1.71-0.54-2.37-0.87c-0.66-0.32-1.2-0.71-1.6-1.19c-0.41-0.47-0.61-1.07-0.61-1.79c0-1.07,0.5-1.93,1.51-2.6
        c1-0.66,2.3-0.99,3.88-0.99c1.28,0,2.38,0.18,3.3,0.54c0.92,0.36,1.63,0.78,2.15,1.25l1.28,4.1h4.17c0.39,0,0.66-0.15,0.83-0.45
        c0.17-0.3,0.26-0.88,0.26-1.73v-4.62c-1.71-1.54-3.67-2.57-5.87-3.11c-2.2-0.53-4.37-0.8-6.51-0.8c-1.37,0-2.79,0.13-4.26,0.39
        c-1.47,0.26-2.81,0.74-4.01,1.44c-1.2,0.71-2.18,1.68-2.95,2.92c-0.77,1.24-1.15,2.86-1.15,4.87c0,2.22,0.71,4.07,2.15,5.55
        c1.43,1.48,4.01,2.73,7.73,3.75c2.78,0.77,4.88,1.49,6.32,2.15c1.43,0.66,2.15,1.64,2.15,2.92c0,0.64-0.16,1.2-0.48,1.67
        c-0.32,0.47-0.76,0.84-1.31,1.12c-0.56,0.28-1.18,0.48-1.86,0.61c-0.69,0.13-1.41,0.19-2.18,0.19c-1.28,0-2.5-0.19-3.65-0.58
        c-1.15-0.38-2.03-0.85-2.63-1.41l-1.22-4.17h-3.98c-0.39,0-0.66,0.15-0.83,0.45c-0.17,0.3-0.26,0.88-0.26,1.73V52.09L263.75,52.09z
         M297.86,44.78c0,2.4,0.28,4.32,0.83,5.77c0.55,1.45,1.3,2.57,2.24,3.33c0.94,0.77,2.02,1.28,3.24,1.54
        c1.22,0.26,2.49,0.39,3.82,0.39c1.28,0,2.78-0.17,4.49-0.51c1.71-0.34,3.25-0.98,4.62-1.92l-1.41-5.64
        c-0.09-0.3-0.28-0.45-0.58-0.45c-0.09,0-0.28,0.12-0.58,0.35c-0.3,0.24-0.69,0.5-1.19,0.8c-0.49,0.3-1.05,0.57-1.67,0.8
        c-0.62,0.24-1.27,0.35-1.96,0.35c-0.86,0-1.54-0.13-2.05-0.39c-0.51-0.26-0.92-0.6-1.22-1.02c-0.3-0.43-0.49-0.93-0.58-1.51
        c-0.09-0.58-0.13-1.21-0.13-1.89V30.03h9.23c0.85,0,1.43-0.09,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-10.07v-8.46
        h-5.71c-0.86,0-1.43,0.09-1.73,0.26c-0.3,0.17-0.45,0.45-0.45,0.83v7.37h-2.76c-0.86,0-1.43,0.09-1.73,0.26
        c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.43h3.59V44.78L297.86,44.78z M346.53,46.12c-0.09-0.3-0.28-0.45-0.58-0.45
        c-0.21,0-0.38,0.08-0.51,0.22c-0.13,0.15-0.28,0.31-0.45,0.48c-1.11,1.07-2.45,1.98-4.01,2.73c-1.56,0.75-3.3,1.12-5.23,1.12
        c-1.54,0-2.81-0.3-3.82-0.9c-1.01-0.6-1.8-1.34-2.37-2.21c-0.58-0.88-0.99-1.83-1.25-2.85c-0.26-1.03-0.41-1.99-0.45-2.88h17.89
        c0.85,0,1.46-0.05,1.83-0.16c0.36-0.11,0.69-0.42,0.99-0.93v-2.12c0-1.67-0.17-3.37-0.51-5.1c-0.34-1.73-0.99-3.29-1.96-4.68
        c-0.96-1.39-2.28-2.52-3.94-3.4c-1.67-0.88-3.85-1.31-6.54-1.31c-5.09,0-8.93,1.42-11.54,4.26c-2.61,2.84-3.91,6.83-3.91,11.96
        c0,5.13,1.32,9.12,3.98,11.96c2.65,2.84,6.52,4.26,11.61,4.26c2.86,0,5.28-0.49,7.24-1.47c1.97-0.98,3.57-2.16,4.81-3.53
        L346.53,46.12L346.53,46.12z M328,36.57c0.13-0.9,0.34-1.82,0.64-2.76c0.3-0.94,0.74-1.8,1.31-2.57c0.58-0.77,1.32-1.4,2.24-1.89
        c0.92-0.49,2.06-0.74,3.43-0.74c1.32,0,2.36,0.35,3.11,1.06c0.75,0.71,1.32,1.5,1.7,2.37c0.38,0.88,0.62,1.72,0.71,2.53
        c0.08,0.81,0.13,1.33,0.13,1.54v0.45H328L328,36.57z M352.94,54.14c0,0.38,0.15,0.66,0.45,0.83c0.3,0.17,0.88,0.26,1.73,0.26h14.43
        c0.85,0,1.43-0.08,1.73-0.26c0.3-0.17,0.45-0.45,0.45-0.83l-1.35-4.42h-4.42V32.34c0.68-0.6,1.5-1.07,2.44-1.41
        c0.94-0.34,1.9-0.51,2.89-0.51c1.07,0,2,0.15,2.79,0.45c0.79,0.3,1.55,0.45,2.28,0.45c0.47,0,0.7-0.23,0.7-0.7
        c-0.04-0.17-0.11-0.47-0.19-0.9c-0.09-0.43-0.25-1.07-0.48-1.92c-0.23-0.85-0.54-2.07-0.93-3.65c-0.73-0.13-1.43-0.19-2.12-0.19
        c-1.8,0-3.53,0.34-5.19,1.03c-1.67,0.68-3.06,1.5-4.17,2.44l-1.22-2.89h-7.63c-0.86,0-1.43,0.09-1.73,0.26
        c-0.3,0.17-0.45,0.45-0.45,0.83l1.35,4.43h3.78v19.68h-3.78L352.94,54.14L352.94,54.14z"
        />
      </g>
    </svg>
  );
});

export default MAE_LOGO;
