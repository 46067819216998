import { useLocalStore, useObserver } from 'mobx-react-lite';
import React from 'react';
import CreateEditModuleForm from './CreateEditModuleForm';
import ImportModuleForm from './ImportModuleForm';

const SetModuleForm = React.forwardRef((props, ref) => {
  const store = useLocalStore(() => ({
    isImporting: false,
    setImporting(isImporting) {
      store.isImporting = isImporting;
    },
  }));

  return useObserver(() =>
    store.isImporting ? (
      <ImportModuleForm
        {...props}
        ref={ref}
        isImporting={store.isImporting}
        setImporting={store.setImporting}
      />
    ) : (
      <CreateEditModuleForm
        {...props}
        ref={ref}
        isImporting={store.isImporting}
        setImporting={store.setImporting}
      />
    )
  );
});

export default SetModuleForm;
