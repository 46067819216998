import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { Form as FormikForm } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { DeleteAssessmentButton } from '../../common/elements/Assessments';
import { CreateButton } from '../../common/elements/Form';
import { StaffHeader } from '../../common/elements/Staff';
import * as ModuleCard from './ModuleCard';

export const ModuleInfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
`;

export const ModuleInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.6em;
`;

export const ModuleHeader = styled(StaffHeader)`
  line-height: 100%;
  font-size: ${({ characterLength }) => {
    if (characterLength < 50) return '2.6rem';
    if (characterLength < 100) return '2rem';
    else return '1.6rem';
  }};
`;

export const ModuleImage = styled(ModuleCard.ModuleImage)`
  margin-right: 1em;
`;

export const AlignModuleButtons = styled.div`
  display: flex;
`;

export const ModuleCreatedBy = styled.p`
  margin: 0.4em 0 0 0;
  font-size: 1.2rem;
  font-weight: 650;
  color: ${({ theme }) => theme.textDark};
`;

export const EditModuleButton = styled(CreateButton)`
  align-self: center;
  margin: 0 0.2em;
  &:hover {
    cursor: pointer;
  }
`;

export const ModuleNav = styled.ol`
  list-style: none;
  padding-left: 0;
  align-self: center;
`;

export const ModuleListItem = styled.li`
  display: inline;
`;

export const AddFirstItemButton = styled(EditModuleButton)`
  justify-self: center;
  padding: 0.4em 1.8em 0.4em 1.8em;
  font-size: 2rem;
`;

export const StaffModuleForm = styled(FormikForm)`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.textDark};
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 2.3rem;
`;

const StyledTextInput = styled.input`
  border: none;
  font-family: inherit;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 0.5em;
  color: ${({ theme }) => theme.textDark};
  background: ${({ theme }) => theme.appBackground};
  &:disabled {
    background: ${({ theme }) => theme.appBackground};
  }
`;

export const TextInput = ({ field, form, ...props }) => (
  <StyledTextInput {...field} {...props} />
);

const StyledTextArea = styled.textarea`
  border: none;
  font-family: inherit;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 0.5em;
  height: 10em;
  color: ${({ theme }) => theme.textDark};
  background: ${({ theme }) => theme.appBackground};
  &:disabled {
    background: ${({ theme }) => theme.appBackground};
  }
`;

export const TextArea = ({ field, form, ...props }) => {
  return <StyledTextArea {...field} {...props} />;
};

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 5px;
  padding: 1em;
  height: 100%;
`;

export const EmbedPreview = styled.iframe`
  flex-grow: 0.95;
  width: 95%;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  padding-bottom: 1px;
`;

export const CopyLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textDark};
  &:hover {
    cursor: pointer;
  }
`;

export const LinkIcon = () => <LinkRoundedIcon style={{ fontSize: '3rem' }} />;

export const LinkSaveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeleteItemButton = styled(DeleteAssessmentButton)`
  margin-right: 1em;
`;

export * from '../../auth/elements/StaffAuth';
export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
