import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Link } from '@reach/router';
import { Form as FormikForm } from 'formik';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ThemeContext } from '../../../contexts/Theme';
import * as common from '../../common/elements/Form';

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${common.ScreenWidthMQs}
`;

export const SignUpButton = styled(common.Button)`
  margin-top: 1.5em;
  background: ${({ theme }) => theme.classroomHighlight};
`;

export const NextButton = styled(common.Button)`
  margin-top: 1.5em;
  align-items: center;
  padding-right: 1.3em;
  background: ${({ theme }) => theme.classroomHighlight};
`;

export const BackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1.4em;
  margin-left: 1.4em;
  padding: 1em;
  border: none;
  background: none;
`;

export const BigArrowBackIcon = () => {
  const theme = useContext(ThemeContext);
  return (
    <ArrowBackRoundedIcon
      style={{ fontSize: '4rem', color: theme.headerColour }}
    />
  );
};

export const LoadingContainer = styled.div`
  margin-top: 2em;
`;

export const CreateUserInput = styled(common.FieldInput)`
  font-size: 1.8rem;
  padding: 0.3em 0;
`;

export const BackLink = styled(Link)`
  position: absolute;
  top: 2em;
  left: 2em;
`;

export const ResetForm = styled(Form)`
  height: 100vh;
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
