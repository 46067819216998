import React, { useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import styled, { css } from 'styled-components/macro';
import imagePlaceholder from '../../../svgs/image-placeholder.svg';
import { TeacherNavLink } from '../../common/elements/Nav';
import ProfileImage from '../../common/ProfileImage';

export const MessageItem = styled.li`
  display: grid;
  grid-template-rows: 1.5em 2fr 1.5em min-content;
  grid-template-columns: 3.2em auto auto;
  grid-template-areas:
    '. created-at .'
    '. message-content message-content'
    'author-image message-content message-content'
    'author-image author author';
  padding: 0.4em;
  align-self: flex-start;
  ${({ authorIsStaff, isOwn }) =>
    authorIsStaff &&
    !isOwn &&
    css`
      ${MessageContent} {
        border: 2px solid ${({ theme }) => theme.classroomHighlight};
      }
      ${MessageCreatedByImage} {
        border: 2px solid ${({ theme }) => theme.classroomHighlight};
      }
    `}

  ${({ isOwn, isMessageDeletable }) =>
    !isOwn &&
    isMessageDeletable &&
    css`
      grid-template-areas:
        '. created-at .'
        'delete-message message-content message-content'
        'author-image message-content message-content'
        'author-image author author';
      ${DeleteMessageButton} {
        text-align: right;
      }
    `}

    ${({ isOwn, isEvidencable }) =>
      !isOwn &&
      isEvidencable &&
      css`
        grid-grid-template-columns: 3.2em auto auto 4em;
        grid-template-areas:
          '. created-at . .'
          'delete-message message-content message-content evidence'
          'author-image message-content message-content evidence'
          'author-image author author author';
      `}

  ${({ isOwn }) =>
    isOwn &&
    css`
      grid-template-columns: 4em auto auto min-content;
      grid-template-areas:
        '. author author .'
        'evidence message-content message-content delete-message'
        'evidence message-content message-content delete-message'
        '. . created-at .';
      align-self: flex-end;
      ${MessageContent} {
        border-radius: 1em 1em 0.3em 1em;
        justify-self: end;
        color: ${({ theme }) => theme.textLight};
        background: ${({ theme }) => theme.userMessage};
      }
      ${MessageCreatedBy} {
        justify-self: end;
        margin-right: 0.5em;
      }
      ${MessageCreatedAt} {
        justify-self: end;
      }
      ${DeleteButton} {
        text-align: right;
        display: inline-block;
      }
    `}

    ${({ isPreview }) =>
      isPreview &&
      css`
        grid-template-columns: 5em 1fr;
        grid-template-areas:
          '. created-at'
          'delete-file message-content'
          '. message-content'
          '. author';
      `}

    ${({ isEvidencePreview }) =>
      isEvidencePreview &&
      css`
        grid-template-columns: 5em 1fr;
        grid-template-areas:
          'created-at created-at'
          'message-content message-content'
          'message-content message-content'
          '. author';
      `}

      ${({ isOwn, isEvidencePreview }) =>
        !isOwn &&
        isEvidencePreview &&
        css`
          grid-grid-template-columns: auto auto;
          grid-template-areas:
            'created-at created-at '
            'message-content message-content'
            'message-content message-content'
            'author author';
        `}

    ${({ isAudio, isOwn }) =>
      isAudio &&
      (isOwn
        ? css`
            ${MessageAudio} {
              border-radius: 2.8em 2.8em 0.3em 2.8em;
            }
          `
        : css`
            ${MessageAudio} {
              border-radius: 2.8em 2.8em 2.8em 0.3em;
            }
          `)}

    ${({ isAudio, isOwn, hasText }) =>
      isAudio &&
      hasText &&
      (isOwn
        ? css`
            ${MessageContent} {
              border-radius: 2.5em 2.5em 0.3em 2.5em;
            }
          `
        : css`
            ${MessageContent} {
              border-radius: 2.5em 2.5em 2.5em 0.3em;
            }
          `)}

    ${({ isAudio, isOwn, hasText }) =>
      isAudio &&
      !hasText &&
      (isOwn
        ? css`
            ${MessageContent} {
              border-radius: 3em 3em 0.3em 3em;
            }
          `
        : css`
            ${MessageContent} {
              border-radius: 3em 3em 3em 0.3em;
            }
          `)}
`;

export const MessageContent = styled.div`
  grid-area: message-content;
  margin: 0;
  background: ${({ theme }) => theme.incomingMessage};
  border-radius: 1em 1em 1em 0.3em;
  justify-self: start;
  align-self: end;
  line-height: 0;
  padding: 0.2em;
  display: flex;
  flex-direction: column;
`;

export const MessageText = styled.p`
  margin: 0;
  padding: 0.2em 0.75em 0 0.75em;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.7em;
  word-break: break-word;
  white-space: pre-line;

  ${({ fileType }) =>
    fileType &&
    (fileType === 'audio'
      ? css`
          padding: 0 1.5em;
        `
      : css`
          padding: 0 0.8em;
        `)}
`;

const MsgImg = styled.img`
  border-radius: 0.8em;
  max-height: 21em;
  max-width: 100%;
  object-fit: contain;
`;

export const MessageImage = (props) => {
  const img = useRef(null);
  return (
    <MsgImg
      {...props}
      ref={img}
      onError={() => {
        if (img.current) {
          img.current.src = imagePlaceholder;
        }
      }}
    />
  );
};

export const MessageVideo = styled.video`
  max-height: 21em;
  max-width: 100%;
  border-radius: 0.8em;
`;

export const MessageAudio = styled(AudioPlayer)`
  min-width: 21em;
  padding: 0.3em 0.5em;
`;

export const MessageCreatedBy = styled.p`
  color: ${({ theme }) => theme.subHeaderColour};
  grid-area: author;
  margin: 0;
  align-self: end;
  padding: 0.2em 0;
  font-weight: 600;
  font-size: 1.3rem;
`;

export const MessageCreatedAt = styled.p`
  color: ${({ theme }) => theme.subHeaderColour};
  grid-area: created-at;
  margin: 0.3em 0 0 0;
`;

export const MessageCreatedByImage = styled(ProfileImage)`
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  grid-area: author-image;
  align-self: center;
  justify-self: center;
  object-fit: fill;
`;

export const DeleteButton = styled.button`
  grid-area: delete-file;
  background: none;
  border: none;
  text-align: left;
  height: min-content;
`;

export const DeleteMessageButton = styled.button`
  grid-area: delete-message;
  background: none;
  border: none;
  text-align: left;
  height: min-content;
  color: ${({ theme }) => theme.textDark};
  padding: 0;
  align-self: center;
  justify-self: center;
  line-height: 0.5rem;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.subHeaderColour};
  }
`;

export const InvisibleExternalLink = styled.button`
  background: none;
  border: none;
  color: inherit;
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.6rem;
  &:hover {
    font-style: italic;
  }
`;

export const InvisibleInternalLink = styled(TeacherNavLink)`
  color: inherit;
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.6rem;
  &:hover {
    font-style: italic;
  }
`;

export * from '../../common/elements/Students.js';
