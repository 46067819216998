import * as firebase from 'firebase/app';
import { destroy, getParentOfType, types } from 'mobx-state-tree';
import { db } from '../firebase';
import { assessmentToJSDates } from '../utils/db';
import LearningAssessment from './LearningAssessment';
import Message from './Message';
import User from './User';
import UserPreferences from './UserPreferences';

const UserPrivate = types
  .model('UserPrivate', {
    learningResources: types.optional(
      types.map(
        types.model('User-LearningResource', {
          status: types.maybeNull(types.string),
          resourceId: types.string,
        })
      ),
      {}
    ),
    tasks: types.optional(
      types.map(
        types.model('User-Task', {
          taskId: types.string,
          evidence: types.optional(types.array(Message), []),
          lastChecked: types.optional(types.map(types.Date), {}),
        })
      ),
      {}
    ),
    personalTargets: types.optional(
      types.map(
        types.model('User-PersonalTargets', {
          targetId: types.string,
          targetText: types.string,
          index: types.number,
          evidence: types.optional(types.array(Message), []),
          category: types.string,
          lastChecked: types.optional(types.map(types.Date), {}),
          isNew: types.optional(types.boolean, false),
        })
      ),
      {}
    ),
    phoneNumber: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    surname: types.maybeNull(types.string),
    learningAssessment: types.maybeNull(LearningAssessment),
    learningAssessments: types.optional(types.map(LearningAssessment), {}),
    learningAssessmentArchive: types.optional(
      types.map(LearningAssessment),
      {}
    ),
    resetRequired: types.optional(types.boolean, false),
    accountLocked: types.optional(types.boolean, false),
    preferences: types.optional(UserPreferences, {}),
  })
  .actions((self) => ({
    removeUserPrivate() {
      destroy(self);
    },
    getInfo() {
      const user = self.getParentUser();
      return db
        .collection('usersPrivate')
        .doc(user.id)
        .get()
        .then((doc) => {
          self.setInfo(doc.data());
        });
    },
    setInfo(userData) {
      if (!userData) return;
      const {
        firstName,
        surname,
        phoneNumber,
        learningResources,
        tasks,
        personalTargets,
        resetRequired,
        accountLocked,
      } = userData;
      self.phoneNumber = phoneNumber;
      self.firstName = firstName;
      self.surname = surname;
      self.resetRequired = resetRequired;
      self.accountLocked = accountLocked;
      self.learningResources.forEach((resource, id) => {
        if (!learningResources.hasOwnProperty(id)) {
          self.learningResources.delete(id);
        }
      });
      for (let id in learningResources) {
        const { status, resource } = learningResources[id];
        self.learningResources.set(id, { status, resourceId: resource.id });
      }
      self.tasks.forEach((task, id) => {
        if (!tasks.hasOwnProperty(id)) {
          self.tasks.delete(id);
        }
      });

      // tasks
      for (let id in tasks) {
        self.tasks.set(id, { taskId: id });
        const { evidence, lastChecked } = tasks[id];
        for (let evidenceIdKey in evidence) {
          const {
            createdAt,
            evidenceId,
            comment,
            ...restOfEvidence
          } = evidence[evidenceIdKey];
          const formattedComment = comment
            ? {
                text: comment.text,
                createdAt: comment.createdAt.toDate(),
                createdBy: comment.createdBy.id,
                file: comment.file,
                fileType: comment.fileType,
                filePath: comment.filePath,
              }
            : null;
          const newEvidence = Message.create({
            ...restOfEvidence,
            id: evidenceId,
            createdAt: createdAt.toDate(),
            comment: formattedComment,
          });
          self.tasks.get(id).evidence.push(newEvidence);
        }
        self.tasks
          .get(id)
          .lastChecked.forEach((existingLastChecked, userId) => {
            if (!lastChecked || (lastChecked && !lastChecked[userId]))
              self.tasks.get(id).lastChecked.delete(userId);
          });
        for (let userId in lastChecked) {
          self.tasks
            .get(id)
            .lastChecked.set(userId, lastChecked[userId].toDate());
        }
      }

      // personal targets
      [...self.personalTargets.keys()].forEach((id) => {
        if (personalTargets && !personalTargets[id]) {
          self.personalTargets.delete(id);
        }
      });
      for (let id in personalTargets) {
        const { evidence, lastChecked, ...restOfTarget } = personalTargets[id];
        self.personalTargets.set(id, restOfTarget);

        const existingEvidence = self.personalTargets.get(id).evidence;
        [...existingEvidence.keys()].forEach((id) => {
          if (evidence && !evidence[id]) {
            existingEvidence.delete(id);
          }
        });

        for (let evidenceIdKey in evidence) {
          const {
            createdAt,
            evidenceId,
            comment,
            ...restOfEvidence
          } = evidence[evidenceIdKey];
          const formattedComment = comment
            ? {
                text: comment.text,
                createdAt: comment.createdAt.toDate(),
                createdBy: comment.createdBy?.id,
                file: comment.file,
                fileType: comment.fileType,
                filePath: comment.filePath,
              }
            : null;
          const newEvidence = Message.create({
            ...restOfEvidence,
            id: evidenceId,
            createdAt: createdAt.toDate(),
            comment: formattedComment,
          });

          self.personalTargets.get(id).evidence.push(newEvidence);
        }
        self.personalTargets.get(id).evidence.replace(
          self.personalTargets
            .get(id)
            .evidence.slice()
            .sort((a, b) => a.index - b.index)
        );

        self.personalTargets
          .get(id)
          .lastChecked.forEach((existingLastChecked, userId) => {
            if (!lastChecked || (lastChecked && !lastChecked[userId]))
              self.personalTargets.get(id).lastChecked.delete(userId);
          });
        for (let userId in lastChecked) {
          self.personalTargets
            .get(id)
            .lastChecked.set(userId, lastChecked[userId].toDate());
        }
      }

      // learningAssessment
      if (userData.learningAssessment) {
        if (!self.learningAssessment) {
          const formattedAssessment = assessmentToJSDates(
            userData.learningAssessment
          );
          const newAssessment = LearningAssessment.create(formattedAssessment);

          self.learningAssessment = newAssessment;
        } else {
          self.learningAssessment.updateInfo(userData.learningAssessment);
        }
      } else {
        self.learningAssessment = null;
      }
      // learningAssessments
      self.learningAssessments.forEach((assessment, categoryId) => {
        if (!userData.learningAssessments?.hasOwnProperty(categoryId)) {
          self.learningAssessments.delete(categoryId);
        }
      });
      for (let categoryId in userData.learningAssessments) {
        const formattedAssessment = assessmentToJSDates(
          userData.learningAssessments[categoryId]
        );
        try {
          self.learningAssessments.set(categoryId, formattedAssessment);
        } catch (err) {
          // console.log(err);
        }
      }
      // archive
      self.learningAssessmentArchive.forEach((assessment, id) => {
        if (!userData.learningAssessmentArchive?.hasOwnProperty(id)) {
          self.learningAssessmentArchive.delete(id);
        }
      });
      for (let id in userData.learningAssessmentArchive) {
        const formattedAssessment = assessmentToJSDates(
          userData.learningAssessmentArchive[id]
        );
        self.learningAssessmentArchive.set(id, formattedAssessment);
      }
      // preferences
      if (userData.preferences) {
        if (!self.preferences) {
          self.preferences = UserPreferences.create(userData.preferences);
        } else {
          self.preferences.updateInfo(userData.preferences);
        }
      }
    },
    listenToUpdates() {
      const user = self.getParentUser();
      return db
        .collection('usersPrivate')
        .doc(user.id)
        .onSnapshot(self.handleSnapshot);
    },
    handleSnapshot(userSnap) {
      self.setInfo(userSnap.data());
    },
    setResourceStatus(resource, status) {
      const user = self.getParentUser();
      const userResource = self.learningResources.get(resource.id);

      if (userResource) {
        db.collection('usersPrivate')
          .doc(user.id)
          .set(
            {
              learningResources: { [resource.id]: { status } },
            },
            { merge: true }
          );
      } else {
        const topicId = resource.getParentTopic().id;
        const moduleId = resource.getParentModule().id;
        const resourceRef = db
          .collection('topics')
          .doc(topicId)
          .collection('modules')
          .doc(moduleId)
          .collection('learningResources')
          .doc(resource.id);

        db.collection('usersPrivate')
          .doc(user.id)
          .set(
            {
              learningResources: {
                [resource.id]: { status, resource: resourceRef },
              },
            },
            { merge: true }
          );
      }
    },
    deleteEvidenceByTaskIDAndEvidenceID(taskId, evidenceId) {
      const userId = self.getParentUser().id;
      return db
        .collection('usersPrivate')
        .doc(userId)
        .set(
          {
            tasks: {
              [`${taskId}.evidence.${evidenceId}`]: firebase.firestore.FieldValue.delete(),
            },
          },
          { merge: true }
        );
    },
    addMessageEvidenceToTarget(targetId, { id, ...message }) {
      const evidenceId = id;
      const index = self.getMaxEvidenceIndexByTargetId(targetId) + 1;
      const newEvidence = { ...message, evidenceId, index };
      if (newEvidence?.comment?.createdBy) {
        newEvidence.comment = {
          ...newEvidence.comment,
          createdBy: db.collection('users').doc(newEvidence.comment.createdBy),
        };
      }
      const user = self.getParentUser();
      return db
        .collection('usersPrivate')
        .doc(user.id)
        .update({
          [`personalTargets.${targetId}.evidence.${evidenceId}`]: newEvidence,
        });
    },
    addTaskEvidence(taskId, newEvidence, topicsStore, moduleId) {
      const index = self.getMaxEvidenceIndexByTaskId(taskId) + 1;
      const parentUserId = self.getParentUser().id;
      const parentModule = topicsStore.getModuleById(moduleId);
      const parentTopic = parentModule.getParentTopic();

      return db
        .collection('usersPrivate')
        .doc(parentUserId)
        .update({
          [`tasks.${taskId}.evidence.${newEvidence.evidenceId}`]: {
            ...newEvidence,
            index,
          },
          [`tasks.${taskId}.task`]: db
            .collection('topics')
            .doc(parentTopic.id)
            .collection('modules')
            .doc(parentModule.id)
            .collection('tasks')
            .doc(taskId),
        });
    },
  }))
  .views((self) => ({
    getParentUser() {
      return getParentOfType(self, User);
    },
    isResourceComplete(id) {
      const resource = self.learningResources.get(id);
      return resource && resource.status === 'completed';
    },
    getModuleItemsCompletionPercent(items, collection) {
      const completedCount = [...items].reduce((count, { id }) => {
        if (collection === 'learningResources') {
          return self.isResourceComplete(id) ? count + 1 : count;
        } else if (collection === 'tasks') {
          return self.isTaskEvidenced(id) ? count + 1 : count;
        }
        return count;
      }, 0);
      const completionPercent = Math.round(
        (completedCount / items.length) * 100
      );
      return completionPercent || 0;
    },
    getModuleAverageCompletionPercent(learningResources, tasks) {
      const completedResourceCount = [...learningResources].reduce(
        (count, { id }) => {
          return self.isResourceComplete(id) ? count + 1 : count;
        },
        0
      );
      const completedTaskCount = [...tasks].reduce((count, { id }) => {
        return self.isTaskEvidenced(id) ? count + 1 : count;
      }, 0);

      const totalCompletedItems = completedResourceCount + completedTaskCount;
      const totalItems = learningResources.length + tasks.length;
      const completionPercent = Math.round(
        (totalCompletedItems / totalItems) * 100
      );
      return completionPercent || 0;
    },
    isTaskEvidenced(id) {
      const task = self.tasks.get(id);
      return task?.evidence?.length ? true : false;
    },
    getEvidenceByTaskId(id, cols = 1) {
      const evidence = self.tasks.get(id)?.evidence || [];
      const evidenceCols = [];
      let currentCol = 0;
      [...evidence]
        .sort((a, b) => a.index - b.index)
        .forEach((singleEvidence) => {
          if (evidenceCols[currentCol]) {
            evidenceCols[currentCol].push(singleEvidence);
          } else {
            evidenceCols[currentCol] = [singleEvidence];
          }
          if (currentCol < cols - 1) {
            currentCol++;
          } else {
            currentCol = 0;
          }
        });
      return evidenceCols;
    },
    getEvidenceById(evidenceId, taskId, targetId) {
      const task = self.tasks.get(taskId);
      const target = self.personalTargets.get(targetId);
      if (task) {
        return task?.evidence?.find((evidence) => evidence.id === evidenceId);
      }
      if (target) {
        return target?.evidence?.find((evidence) => evidence.id === evidenceId);
      }
    },
    hasMessageBeenEvidenced(messageId, { targetsOnly = false } = {}) {
      const personalTargets = [...self.personalTargets.values()];
      for (let i = 0; i < personalTargets.length; i++) {
        const foundEvidence = personalTargets[i].evidence.find(
          (item) => item.id === messageId
        );
        if (foundEvidence) return true;
      }
      if (targetsOnly) return false;

      const tasks = [...self.tasks.values()];
      for (let i = 0; i < tasks.length; i++) {
        const foundEvidence = tasks[i].evidence.find(
          (item) => item.id === messageId
        );
        if (foundEvidence) return true;
      }
      return false;
    },
    getEvidenceByTargetId(targetId, cols = 1) {
      const evidence = self.personalTargets.get(targetId)?.evidence || [];
      const evidenceCols = [];
      let currentCol = 0;
      [...evidence].forEach((singleEvidence) => {
        if (evidenceCols[currentCol]) {
          evidenceCols[currentCol].push(singleEvidence);
        } else {
          evidenceCols[currentCol] = [singleEvidence];
        }
        if (currentCol < cols - 1) {
          currentCol++;
        } else {
          currentCol = 0;
        }
      });
      return evidenceCols;
    },
    getMaxEvidenceIndexByTaskId(id) {
      const isEvidenced = self.isTaskEvidenced(id);
      return isEvidenced
        ? Math.max(...self.tasks.get(id).evidence.map(({ index }) => index))
        : -1;
    },
    getMaxEvidenceIndexByTargetId(id) {
      const target = self.personalTargets.get(id);
      const isEvidenced = target.evidence.length > 0;
      return isEvidenced
        ? Math.max(...target.evidence.map(({ index }) => index))
        : -1;
    },
    getMaxPersonalTargetIndex(categoryId) {
      const targetIndexes = [...self.personalTargets.values()]
        .filter(({ category }) => category === categoryId)
        .map((target) => target.index);
      return Math.max(-1, ...targetIndexes);
    },
    getOrderedPersonalTargets(categoryId) {
      return [...self.personalTargets.values()]
        .filter(({ category }) => category === categoryId)
        .sort((a, b) => a.index - b.index);
    },
    getOtherPersonalTargets(categoryId) {
      return [...self.personalTargets.values()]
        .filter(({ category }) => category !== categoryId)
        .reduce((acc, { lastChecked, ...target }) => {
          acc[target.targetId] = target;
          return acc;
        }, {});
    },
    getAssessmentById(id, selectedCategoryId) {
      const currentAssessment = self.learningAssessments.get(
        selectedCategoryId
      );
      if (currentAssessment?.id === id) return currentAssessment;
      return self.learningAssessmentArchive.get(id);
    },
    getActiveAssessmentByCategory(selectedCategoryId) {
      return self.learningAssessments.get(selectedCategoryId);
    },
    getArchivedAssessments(selectedCategoryId) {
      return [...self.learningAssessmentArchive.values()]
        .filter((assessment) => assessment.category === selectedCategoryId)
        .sort((a, b) => a.title.localeCompare(b.title));
    },
    getAssessmentActive(id, selectedCategoryId) {
      return self.learningAssessments.get(selectedCategoryId)?.id === id;
    },
    getUncommentedEvidenceCountByItemId(id, collection) {
      const item = self[collection].get(id);
      if (!item) return 0;
      return item.evidence.reduce((total, evidence) => {
        return total + (evidence.comment ? 0 : 1);
      }, 0);
    },
    getTotalUncommentedItemEvidence(collection, selectedCategory, topicsStore) {
      let total = 0;
      self[collection].forEach((item) => {
        if (
          collection === 'personalTargets' &&
          item.category === selectedCategory
        ) {
          total += self.getUncommentedEvidenceCountByItemId(
            item.targetId,
            collection
          );
        } else {
          topicsStore.topics.forEach((topic) => {
            if (topic.category === selectedCategory) {
              topic.modules.forEach((module) => {
                const task = module.tasks.find(({ id }) => id === item.taskId);
                if (task) {
                  total += self.getUncommentedEvidenceCountByItemId(
                    item.taskId,
                    collection
                  );
                }
              });
            }
          });
        }
      });
      return total;
    },
    getTotalProgressNotifications(selectedCategory, topicsStore) {
      const totalUncommentedTargetEvidence = self.getTotalUncommentedItemEvidence(
        'personalTargets',
        selectedCategory,
        topicsStore
      );
      const totalUncommentedTaskEvidence = self.getTotalUncommentedItemEvidence(
        'tasks',
        selectedCategory,
        topicsStore
      );

      return totalUncommentedTargetEvidence + totalUncommentedTaskEvidence;
    },
    getTotalAssessmentNotifications(loggedInUser) {
      const currentAssessment = self.getActiveAssessmentByCategory(
        loggedInUser.selectedCategory
      );

      const total = currentAssessment?.getTotalUnchecked(loggedInUser.id);
      return total || 0;
    },
    getTotalStaffNotifications(loggedInUser, topicsStore) {
      const progressNotifications = self.getTotalProgressNotifications(
        loggedInUser.selectedCategory,
        topicsStore
      );
      const assessmentNotifications = self.getTotalAssessmentNotifications(
        loggedInUser
      );
      return progressNotifications + assessmentNotifications;
    },
    getUnreadCommentsByItemId(itemId, collection) {
      const item = self[collection].get(itemId);
      if (!item) return 0;
      return item.evidence.reduce((total, { comment }) => {
        const lastRead = item.lastChecked?.get(self.getParentUser().id);
        const notRead = comment?.createdAt > lastRead;
        if (comment && (notRead || !lastRead)) total++;
        return total;
      }, 0);
    },
    getTotalUnreadTargetComments(selectedCategory) {
      let total = 0;
      self.personalTargets.forEach((target) => {
        if (target.category === selectedCategory) {
          total += self.getUnreadCommentsByItemId(
            target.targetId,
            'personalTargets'
          );
        }
      });
      return total;
    },
    getNewTargets() {
      return [...self.personalTargets.values()].filter((target) => {
        return target.isNew;
      });
    },
    getNewTargetCount(selectedCategory) {
      return [...self.personalTargets.values()].reduce((total, target) => {
        if (target.category === selectedCategory && target.isNew) {
          total++;
        }
        return total;
      }, 0);
    },
  }));

export default UserPrivate;
