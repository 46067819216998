import { types } from 'mobx-state-tree';

const Alert = types
  .model('Alert', {
    isOpen: types.optional(types.boolean, false),
    text: types.maybeNull(types.string),
    heading: types.optional(types.string, 'Warning'),
    isWarning: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    open() {
      self.isOpen = true;
    },
    close() {
      self.isOpen = false;
      self.clearText();
    },
    setText({ heading, text, isWarning }) {
      if (heading) self.heading = heading;
      if (text) self.text = text;
      if (isWarning) self.isWarning = isWarning;
    },
    clearText() {
      self.heading = 'Warning';
      self.text = null;
      self.isWarning = false;
    },
  }));

export default Alert;
