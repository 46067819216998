import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AlertContext } from '../../contexts/Alert';
import { CategoriesContext } from '../../contexts/Categories';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/CategoryCard';

const CategoryCard = observer(({ categoryId, setCategoryModal }) => {
  const categoriesStore = useContext(CategoriesContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const alertStore = useContext(AlertContext);
  const usersStore = useContext(UsersContext);
  const category = categoriesStore.getCategoryById(categoryId);

  const deleteCategory = async (categoryIdToDelete) => {
    const usersSnap = await usersStore.fetchUsersByCategory(categoryIdToDelete);
    if (usersSnap.empty) {
      saveChangesStore.setText({
        heading: 'Delete Category',
        message: 'This will permanently delete the category, are you sure?',
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(async () => {
        await db.collection('categories').doc(categoryIdToDelete).delete();
      });
    } else {
      alertStore.setText({
        heading: 'Delete Category',
        text:
          'This category cannot be deleted as it has active users. If you wish to delete it please remove all users first.',
        isWarning: true,
      });
      alertStore.open();
    }
  };
  return (
    <CSS.CategoryCard data-testid="CategoryCard">
      <CSS.InlineJustified>
        <CSS.CategoryTitle>{category.categoryName}</CSS.CategoryTitle>
        <CSS.InvisibleButton
          data-testid={`edit-category-${categoryId}`}
          onClick={() => setCategoryModal(true, categoryId)}
          aria-label="Edit Category"
        >
          <CSS.EditIcon></CSS.EditIcon>
        </CSS.InvisibleButton>
      </CSS.InlineJustified>
      <CSS.InlineJustified>
        <div></div>
        <CSS.InvisibleButton
          data-testid={`delete-category-${categoryId}`}
          onClick={() => deleteCategory(categoryId)}
          aria-label="Delete Category"
        >
          <CSS.Bin></CSS.Bin>
        </CSS.InvisibleButton>
      </CSS.InlineJustified>
    </CSS.CategoryCard>
  );
});

export default CategoryCard;
