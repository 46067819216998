import { db } from '../firebase';

export const updateGroupChats = ({
  chatsStore,
  listenersStore,
  usersStore,
}) => ({ path, op, value }) => {
  const matchRes = path.match(
    /\/users\/(?<userId>\w*)\/groupChats\/(?<chatId>\w*)/
  );
  if (matchRes) {
    const { userId, chatId } = matchRes.groups;
    const groupChat = chatsStore.groupChats.find((chat) => chat.id === chatId);
    const shouldListenToPersonalGroupChats =
      usersStore.getLoggedInUser().role !== 'admin';

    if (op === 'add') {
      if (groupChat) groupChat.storeParticipant(userId);
      else if (
        userId === usersStore.loggedInUserId &&
        shouldListenToPersonalGroupChats
      ) {
        const chatUnSubs = chatsStore.listenToUsersChats([value], 'groupChats');
        listenersStore.addUnSubs(chatUnSubs);
      }
    } else if (op === 'remove') {
      if (groupChat) groupChat.removeParticipant(userId);
      if (
        userId === usersStore.loggedInUserId &&
        shouldListenToPersonalGroupChats &&
        groupChat
      ) {
        groupChat.unsubscribeFromAll();
        chatsStore.removeChat(groupChat, 'groupChats');
      }
    }
  }
};

export const updateIndividualChats = ({
  chatsStore,
  usersStore,
  listenersStore,
}) => ({ path, op, value }) => {
  const matchRes = path.match(/\/users\/(?<userId>\w*)\/individualChats/);

  if (matchRes) {
    if (op === 'add') {
      const { userId } = matchRes.groups;
      const individualChat = chatsStore.individualChats.find(
        (chat) => chat.id === value
      );
      if (individualChat) {
        if (userId === usersStore.loggedInUserId) {
          individualChat.storeLoggedInUser(userId);
        } else {
          individualChat.storeParticipant(userId);
        }
      } else if (userId === usersStore.loggedInUserId) {
        db.collection('users')
          .doc(userId)
          .get()
          .then(() => {
            const unSubs = chatsStore.addIndividualChatAndListen(value);
            listenersStore.addUnSubs(unSubs);

            const individualChat = chatsStore.individualChats.find(
              (chat) => chat.id === value
            );
            individualChat.storeLoggedInUser(userId);

            const otherParticipants = usersStore.getParticipantsByIndividualChatId(
              individualChat.id
            );
            otherParticipants.forEach((participant) => {
              individualChat.storeParticipant(participant.id);
            });
          });
      }
    }
  }
};

export const addParticipantsToNewGroupChat = ({ usersStore, chatsStore }) => ({
  path,
  op,
  value,
}) => {
  const groupChatMatch = path.match(/^\/groupChats\/\d*$/);

  if (groupChatMatch) {
    if (op === 'add') {
      usersStore.users.forEach((user) => {
        if (user.groupChats.has(value.id)) {
          chatsStore.groupChats
            .find(({ id }) => id === value.id)
            .storeParticipant(user.id);
        }
      });
    }
  }
};
