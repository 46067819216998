import { observer } from 'mobx-react-lite';
import React from 'react';
import * as CSS from './elements/AssessmentCard.js';

const AssessmentCard = observer(
  ({ assessment, studentId, archived, hasNotifications, as }) => {
    const initialQuestionCount = assessment.getQuestionCount(
      'initialQuestions'
    );
    const finalQuestionCount = assessment.getQuestionCount('finalQuestions');

    const linkTo = studentId
      ? `/staff/users/${studentId}/learningPlans/${assessment.id}/start`
      : `/staff/admin/learningPlans/${assessment.id}/start`;

    return (
      <CSS.StaffCard hasNotifications={hasNotifications} as={as}>
        <CSS.AssessmentLink
          to={linkTo}
          data-testid={`AssessmentCard-${assessment.id}${
            archived ? '-archived' : ''
          }`}
        >
          <CSS.AssessmentTitle>{assessment.title}</CSS.AssessmentTitle>
          <CSS.AssessmentInfo>
            {initialQuestionCount} Start Question
            {initialQuestionCount === 1 ? '' : 's'}
          </CSS.AssessmentInfo>
          <CSS.AssessmentInfo>
            {finalQuestionCount} End Question
            {finalQuestionCount === 1 ? '' : 's'}
          </CSS.AssessmentInfo>
        </CSS.AssessmentLink>
      </CSS.StaffCard>
    );
  }
);

export default AssessmentCard;
