import firebase from 'firebase/app';
import uniqueBy from 'lodash.uniqby';
import { types } from 'mobx-state-tree';
import { db, storage } from '../firebase';
import DefaultProfileImage from '../svgs/default-profile-image.svg';
import { assessmentToFirebaseDates } from '../utils/db';
import UserPrivate from './UserPrivate';

const User = types
  .model('User', {
    id: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    hasProfileImage: types.union(
      types.string,
      types.optional(types.boolean, false)
    ),
    imageURL: types.maybeNull(types.string),
    groupChats: types.optional(types.map(types.string), {}),
    individualChats: types.optional(types.map(types.string), {}),
    private: types.maybeNull(UserPrivate),
    categories: types.optional(types.map(types.string), {}),
    selectedCategory: types.maybeNull(types.string),
  })
  .volatile(() => ({
    listeners: [],
  }))
  .actions((self) => ({
    login: (uid) => {
      self.id = uid;
    },
    logout: () => {
      self.id = null;
      self.displayName = null;
      self.role = null;
      self.hasProfileImage = false;
      self.imageURL = null;
      self.groupChats = {};
      self.individualChats = {};
      self.private = null;
      self.categories = {};
      self.selectedCategory = null;
    },
    setUserInfo: (user) => {
      if (!user) return;
      self.displayName = user.displayName;
      self.role = user.role;
      self.hasProfileImage = user.hasProfileImage;
      self.selectedCategory = user.selectedCategory;

      self.groupChats = {};
      for (let id in user.groupChats) {
        self.groupChats.set(id, id);
      }
      self.individualChats = {};
      for (let id in user.individualChats) {
        self.individualChats.set(id, id);
      }
      self.categories = {};
      for (let id in user.categories) {
        self.categories.set(id, id);
      }
      if (self.hasProfileImage) {
        return storage
          .ref()
          .child(`/users/${self.id}/profileImages/profile-image`)
          .getDownloadURL()
          .then((imageURL) => self.setHasProfileImage(true, imageURL))
          .catch((err) => self.setHasProfileImage(false, DefaultProfileImage));
      } else {
        self.imageURL = DefaultProfileImage;
      }
    },
    updateUserInfo: (updatedUser) => {
      for (let field in updatedUser) {
        if (self[field] !== updatedUser[field]) {
          if (field === 'individualChats') {
            self.individualChats.forEach((value, id) => {
              if (!updatedUser.individualChats.hasOwnProperty(id)) {
                self.individualChats.delete(id);
              }
            });
            for (let id in updatedUser.individualChats) {
              if (!self.individualChats.has(id)) {
                self.individualChats.set(id, id);
              }
            }
          } else if (field === 'groupChats') {
            self.groupChats.forEach((value, id) => {
              if (!updatedUser.groupChats.hasOwnProperty(id)) {
                self.groupChats.delete(id);
              }
            });
            for (let id in updatedUser.groupChats) {
              if (!self.groupChats.has(id)) {
                self.groupChats.set(id, id);
              }
            }
          } else if (field === 'hasProfileImage') {
            if (updatedUser.hasProfileImage !== self.hasProfileImage) {
              storage
                .ref()
                .child(`/users/${self.id}/profileImages/profile-image`)
                .getDownloadURL()
                .then((imageURL) =>
                  self.setHasProfileImage(updatedUser.hasProfileImage, imageURL)
                )
                .catch((err) =>
                  self.setHasProfileImage(false, DefaultProfileImage)
                );
            }
          } else if (field === 'categories') {
            self.categories.forEach((value, id) => {
              if (!updatedUser.categories.hasOwnProperty(id)) {
                self.categories.delete(id);
              }
            });
            for (let id in updatedUser.categories) {
              if (!self.categories.has(id)) {
                self.categories.set(id, id);
              }
            }
          } else {
            self[field] = updatedUser[field];
          }
        }
      }
    },
    setHasProfileImage: (hasProfileImage, imageURL) => {
      self.hasProfileImage = hasProfileImage;
      self.imageURL = imageURL;
    },
    listenToPrivate() {
      const userPrivate = UserPrivate.create();
      self.private = userPrivate;
      const privateUnSub = userPrivate.listenToUpdates();
      self.addUnSub(privateUnSub);
      return privateUnSub;
    },
    getPrivate() {
      const userPrivate = UserPrivate.create();
      self.private = userPrivate;
      userPrivate.getInfo();
    },
    addPrivate(userPrivate) {
      self.private = userPrivate;
    },
    setLearningAssessment(newAssessment, selectedCategoryId) {
      const update = {
        [`learningAssessments.${selectedCategoryId}`]: newAssessment,
      };
      const currentActiveAssessment = self.private.learningAssessments.get(
        selectedCategoryId
      );
      if (currentActiveAssessment) {
        update[
          `learningAssessmentArchive.${currentActiveAssessment.id}`
        ] = assessmentToFirebaseDates(currentActiveAssessment.toJSON());
      }
      return db.collection('usersPrivate').doc(self.id).update(update);
    },
    archiveCurrentLearningAssessment(selectedCategoryId) {
      const update = {
        [`learningAssessments.${selectedCategoryId}`]: firebase.firestore.FieldValue.delete(),
      };
      const currentActiveAssessment = self.private.learningAssessments.get(
        selectedCategoryId
      );
      if (currentActiveAssessment) {
        const updatedArchivedAssessment = assessmentToFirebaseDates(
          currentActiveAssessment.toJSON()
        );
        update[
          `learningAssessmentArchive.${currentActiveAssessment.id}`
        ] = updatedArchivedAssessment;
      }
      return db.collection('usersPrivate').doc(self.id).update(update);
    },
    activateLearningAssessment(newAssessment, selectedCategoryId) {
      const update = {
        [`learningAssessments.${selectedCategoryId}`]: assessmentToFirebaseDates(
          newAssessment
        ),
      };
      const currentActiveAssessment = self.private.learningAssessments.get(
        selectedCategoryId
      );
      if (currentActiveAssessment) {
        const updatedArchivedAssessment = assessmentToFirebaseDates(
          currentActiveAssessment.toJSON()
        );
        update[
          `learningAssessmentArchive.${currentActiveAssessment.id}`
        ] = updatedArchivedAssessment;
      }
      if (self.private.learningAssessmentArchive.has(newAssessment.id)) {
        update[
          `learningAssessmentArchive.${newAssessment.id}`
        ] = firebase.firestore.FieldValue.delete();
      }
      return db.collection('usersPrivate').doc(self.id).update(update);
    },
    deleteAssessmentById(id, selectedCategoryId) {
      const currentActiveAssessment = self.private.learningAssessments.get(
        selectedCategoryId
      );
      if (currentActiveAssessment?.id === id) {
        return db
          .collection('usersPrivate')
          .doc(self.id)
          .update({
            [`learningAssessments.${selectedCategoryId}`]: firebase.firestore.FieldValue.delete(),
          });
      }
      if (self.private.learningAssessmentArchive.has(id)) {
        return db
          .collection('usersPrivate')
          .doc(self.id)
          .update({
            [`learningAssessmentArchive.${id}`]: firebase.firestore.FieldValue.delete(),
          });
      }
    },
    addUnSub(unsubscribe) {
      self.listeners.push(unsubscribe);
    },
    unsubscribeFromAll() {
      self.listeners.forEach((unsubscribe) => {
        if (typeof unsubscribe === 'function') unsubscribe();
      });
    },
  }))
  .views((self) => ({
    getUsersGroupChats(chatsStore, selectedCategory) {
      const usersGroupChats = [];
      self.groupChats.forEach((value, chatId) => {
        const chat = chatsStore.getChatById(chatId, 'groupChats');
        const categoryMatches = chat?.category === selectedCategory;
        if (chat?.active && categoryMatches) usersGroupChats.push(chat);
      });
      return usersGroupChats;
    },
    getIndividualChatByParticipantId(participant, chatsStore, categoryId) {
      const individualChats = [...self.individualChats.keys()];
      for (let i = 0; i < individualChats.length; i++) {
        const chat = chatsStore.getChatById(
          individualChats[i],
          'individualChats'
        );
        if (
          chat?.participants.includes(participant.id) &&
          categoryId === chat.category
        )
          return chat.id;
      }
    },
    getUniqueActiveModules(usersGroupChats, topicsStore, current) {
      const usersModules = usersGroupChats.reduce((modules, groupChat) => {
        const sortedModules = [...groupChat.modules.values()].sort((a, b) => {
          const aTopic = groupChat.topics.get(a.topic);
          const bTopic = groupChat.topics.get(b.topic);
          if (aTopic?.index !== bTopic?.index) {
            return aTopic?.index - bTopic?.index;
          } else {
            return a?.index - b?.index;
          }
        });
        sortedModules.forEach((chatModule) => {
          const module = topicsStore.getModuleById(chatModule.module);
          if (
            module &&
            chatModule.active &&
            (current !== undefined ? chatModule.current === current : true)
          ) {
            modules.push(module);
          }
        });
        return modules;
      }, []);

      const uniqueModules = uniqueBy(usersModules, 'id');
      return uniqueModules;
    },
    getCategoriesString(categoriesStore) {
      return [...self.categories].reduce((acc, [catId], i, arr) => {
        const prefix = i === 0 ? `` : i === arr.length - 1 ? ` and ` : `, `;
        acc += `${prefix}${
          categoriesStore.categories.find(({ id }) => catId === id)
            ?.categoryName
        }`;
        return acc;
      }, '');
    },
  }));

export default User;
