import { types } from 'mobx-state-tree';
import { db } from '../firebase';
import { assessmentToJSDates } from '../utils/db';
import LearningAssessment from './LearningAssessment';

const LearningAssessments = types
  .model('LearningAssessments', {
    learningAssessments: types.map(LearningAssessment),
  })
  .actions((self) => ({
    listenToUpdates() {
      db.collection('learningAssessments').onSnapshot(self.handleSnapshot);
    },
    handleSnapshot(querySnapshot) {
      querySnapshot.docChanges().forEach(({ doc, type }) => {
        if (type === 'added') {
          const data = assessmentToJSDates(doc.data());
          const newLearningAssessment = LearningAssessment.create({
            id: doc.id,
            ...data,
          });
          self.learningAssessments.set(doc.id, newLearningAssessment);
        } else if (type === 'modified') {
          const existingLearningAssessment = self.learningAssessments.get(
            doc.id
          );
          existingLearningAssessment.updateInfo({
            id: doc.id,
            ...doc.data(),
          });
        } else if (type === 'removed') {
          self.learningAssessments.delete(doc.id);
        }
      });
    },
  }))
  .views((self) => ({
    getAssessments(categoryId) {
      return [...self.learningAssessments.values()]
        .filter(({ category }) => category === categoryId)
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    getPublishedAssessments() {
      return [...self.learningAssessments.values()]
        .filter(({ published }) => published)
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    getById(id) {
      return self.learningAssessments.get(id);
    },
    getAvailablePublishedAssessments(userPrivate, selectedCategoryId) {
      return [...self.learningAssessments.values()]
        .filter((assessment) => {
          const isActive =
            userPrivate?.learningAssessments.get(selectedCategoryId)?.id ===
            assessment.id;
          const isArchived = userPrivate?.learningAssessmentArchive.has(
            assessment.id
          );
          const categoryMatches = assessment.category === selectedCategoryId;
          return (
            assessment.published && categoryMatches && !(isActive || isArchived)
          );
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1));
    },
  }));

export default LearningAssessments;
