import { observer, useLocalStore } from 'mobx-react-lite';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect, useState } from 'react';
import { CategoriesContext } from '../../contexts/Categories';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import UsersModel from '../../models/Users';
import UserCard from './UserCard';
import * as CSS from './elements/Users';

const CrossCategorySearch = observer(() => {
  const globalUsersStore = useContext(UsersContext);
  const categories = useContext(CategoriesContext);
  const [usersStore] = useState(() => UsersModel.create({ users: {} }));

  const loggedInUser = globalUsersStore.getLoggedInUser();
  const store = useLocalStore(() => ({
    usersSearchValue: '',
    inputSearchValue: '',
    isLoading: true,
    unlockedStudentsLimit: 10,
    lockedStudentsLimit: 10,
    unlockedTeachersLimit: 10,
    lockedTeachersLimit: 10,
    unlockedAdminsLimit: 10,
    lockedAdminsLimit: 10,
    searchBy: 'name',
  }));

  useEffect(() => {
    const savedSearch = localStorage.getItem('CrossCategorySearch-searchValue');
    const savedUsersState = localStorage.getItem('CrossCategorySearch-users');

    if (savedSearch && savedUsersState) {
      store.usersSearchValue = savedSearch;
      store.inputSearchValue = savedSearch;
      applySnapshot(usersStore, JSON.parse(savedUsersState));
      store.isLoading = false;
    }
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    store.inputSearchValue = value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    store.isLoading = true;
    store.usersSearchValue = store.inputSearchValue;

    usersStore.logout();
    await usersStore.getAllUsers(store.inputSearchValue, store.searchBy);

    localStorage.setItem(
      'CrossCategorySearch-searchValue',
      store.usersSearchValue
    );
    localStorage.setItem(
      'CrossCategorySearch-users',
      JSON.stringify(getSnapshot(usersStore))
    );
    store.isLoading = false;
  };

  const changeCategoryOnNavigate = async (userCategories) => {
    const currentCategory = loggedInUser.selectedCategory;
    const loggedInUserHasCategory = userCategories.find(
      (category) => category === currentCategory
    );
    if (!loggedInUserHasCategory && userCategories.length > 0) {
      let categoriesUpdate = {
        [userCategories[0]]: db.collection('categories').doc(userCategories[0]),
      };
      Object.keys(loggedInUser.categories.toJSON()).forEach((id) => {
        categoriesUpdate[id] = db.collection('categories').doc(id);
      });

      await db.collection('users').doc(loggedInUser.id).update({
        selectedCategory: userCategories[0],
        categories: categoriesUpdate,
      });
    }
  };

  const students = usersStore.getUsersByPartialNameAndRole('', 'student');
  const teachers = usersStore.getUsersByPartialNameAndRole('', 'teacher');
  const admins = usersStore.getUsersByPartialNameAndRole('', 'admin');
  const unlockedStudents = students.filter(
    (student) => !student.private?.accountLocked
  );
  const lockedStudents = students.filter(
    (student) => student.private?.accountLocked
  );

  const unlockedTeachers = teachers.filter(
    (teacher) => !teacher.private?.accountLocked
  );
  const lockedTeachers = teachers.filter(
    (teacher) => teacher.private?.accountLocked
  );

  const unlockedAdmins = admins.filter(
    (admin) => !admin.private?.accountLocked
  );
  const lockedAdmins = admins.filter((admin) => admin.private?.accountLocked);

  return (
    <CSS.StaffContainer data-testid="CrossCategorySearch">
      <CSS.StaffRow>
        <CSS.StaffHeader>Users Across Categories</CSS.StaffHeader>
      </CSS.StaffRow>
      <CSS.StaffRow>
        <CSS.Controls>
          <form onSubmit={handleSubmit}>
            <CSS.SearchStudentsField
              type="text"
              placeholder="Search by..."
              onChange={handleSearchChange}
              value={store.inputSearchValue}
            />

            <CSS.StaffSelect
              select
              id="Choose a topic"
              label="Search by"
              onChange={(e) => (store.searchBy = e.target.value)}
              value={store.searchBy}
              style={{ marginLeft: '1.8em' }}
            >
              <CSS.StaffMenuItem value="name">Name</CSS.StaffMenuItem>
              <CSS.StaffMenuItem value="phoneNumber">
                Phone Number
              </CSS.StaffMenuItem>
            </CSS.StaffSelect>

            <CSS.CreateButton
              style={{
                fontSize: '1.8rem',
                padding: '0.5em 1.8em 0.5em 1.8em',
                marginLeft: '1em',
              }}
              type="submit"
            >
              Search
            </CSS.CreateButton>
          </form>
        </CSS.Controls>
      </CSS.StaffRow>
      {store.isLoading &&
      store.usersSearchValue === '' ? null : store.isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <CSS.StaffRow>
            <CSS.StaffHeaderGroup>
              <CSS.StaffHeaderCount>
                {unlockedStudents.length}
              </CSS.StaffHeaderCount>
              <CSS.StaffSubHeader>
                Student{unlockedStudents.length === 1 ? '' : 's'}
              </CSS.StaffSubHeader>
            </CSS.StaffHeaderGroup>
          </CSS.StaffRow>
          <CSS.CardList>
            {unlockedStudents
              .slice(0, store.unlockedStudentsLimit)
              .map((student) => {
                const categoryNames = [...student.categories.values()].map(
                  (id) => categories.getCategoryById(id)?.categoryName
                );
                return (
                  <UserCard
                    key={student.id}
                    showCategories={true}
                    categoryNames={categoryNames}
                    onClick={() => {
                      changeCategoryOnNavigate([
                        ...student.categories.values(),
                      ]);
                    }}
                    {...student}
                  />
                );
              })}
          </CSS.CardList>
          {unlockedStudents.length > store.unlockedStudentsLimit ? (
            <CSS.Button onClick={() => (store.unlockedStudentsLimit += 10)}>
              Show more
            </CSS.Button>
          ) : null}

          {loggedInUser.role === 'admin' && (
            <>
              <CSS.StaffRow>
                <CSS.StaffHeaderGroup>
                  <CSS.StaffHeaderCount>
                    {unlockedTeachers.length}
                  </CSS.StaffHeaderCount>
                  <CSS.StaffSubHeader>
                    Teacher{unlockedTeachers.length === 1 ? '' : 's'}
                  </CSS.StaffSubHeader>
                </CSS.StaffHeaderGroup>
              </CSS.StaffRow>
              <CSS.CardList>
                {unlockedTeachers.map((teacher) => {
                  const categoryNames = [...teacher.categories.values()]
                    .slice(0, store.unlockedTeachersLimit)
                    .map((id) => categories.getCategoryById(id)?.categoryName);
                  return (
                    <UserCard
                      key={teacher.id}
                      showCategories={true}
                      categoryNames={categoryNames}
                      onClick={() => {
                        changeCategoryOnNavigate([
                          ...teacher.categories.values(),
                        ]);
                      }}
                      {...teacher}
                    />
                  );
                })}
              </CSS.CardList>
              {unlockedTeachers.length > store.unlockedTeachersLimit ? (
                <CSS.Button onClick={() => (store.unlockedTeachersLimit += 10)}>
                  Show more
                </CSS.Button>
              ) : null}
              <CSS.StaffRow>
                <CSS.StaffHeaderGroup>
                  <CSS.StaffHeaderCount>
                    {unlockedAdmins.length}
                  </CSS.StaffHeaderCount>
                  <CSS.StaffSubHeader>
                    Admin{unlockedAdmins.length === 1 ? '' : 's'}
                  </CSS.StaffSubHeader>
                </CSS.StaffHeaderGroup>
              </CSS.StaffRow>
              <CSS.CardList>
                {unlockedAdmins.map((admin) => {
                  const categoryNames = [...admin.categories.values()]
                    .slice(0, store.unlockedAdminsLimit)
                    .map((id) => categories.getCategoryById(id)?.categoryName);
                  return (
                    <UserCard
                      key={admin.id}
                      showCategories={true}
                      categoryNames={categoryNames}
                      onClick={() => {
                        changeCategoryOnNavigate([
                          ...admin.categories.values(),
                        ]);
                      }}
                      {...admin}
                    />
                  );
                })}
              </CSS.CardList>
              {unlockedAdmins.length > store.unlockedAdminsLimit ? (
                <CSS.Button onClick={() => (store.unlockedAdminsLimit += 10)}>
                  Show more
                </CSS.Button>
              ) : null}
            </>
          )}
          <br></br>
          <CSS.StaffHeader>Locked Accounts</CSS.StaffHeader>
          <CSS.StaffRow>
            <CSS.StaffHeaderGroup>
              <CSS.StaffHeaderCount data-testid="Users-unlocked-student-count">
                {lockedStudents.length}
              </CSS.StaffHeaderCount>
              <CSS.StaffSubHeader>
                Student{lockedStudents.length === 1 ? '' : 's'}
              </CSS.StaffSubHeader>
            </CSS.StaffHeaderGroup>
          </CSS.StaffRow>
          <CSS.CardList>
            {lockedStudents.map((student) => {
              const categoryNames = [...student.categories.values()]
                .slice(0, store.unlockedStudentsLimit)
                .map((id) => categories.getCategoryById(id)?.categoryName);
              return (
                <UserCard
                  key={student.id}
                  showCategories={true}
                  categoryNames={categoryNames}
                  onClick={() => {
                    changeCategoryOnNavigate([...student.categories.values()]);
                  }}
                  {...student}
                />
              );
            })}
          </CSS.CardList>
          {lockedStudents.length > store.lockedStudentsLimit ? (
            <CSS.Button onClick={() => (store.lockedStudentsLimit += 10)}>
              Show more
            </CSS.Button>
          ) : null}
          {loggedInUser.role === 'admin' && (
            <>
              <CSS.StaffRow>
                <CSS.StaffHeaderGroup>
                  <CSS.StaffHeaderCount>
                    {lockedTeachers.length}
                  </CSS.StaffHeaderCount>
                  <CSS.StaffSubHeader>
                    Teacher{lockedTeachers.length === 1 ? '' : 's'}
                  </CSS.StaffSubHeader>
                </CSS.StaffHeaderGroup>
              </CSS.StaffRow>
              <CSS.CardList>
                {lockedTeachers.map((teacher) => {
                  const categoryNames = [...teacher.categories.values()]
                    .slice(0, store.unlockedTeachersLimit)
                    .map((id) => categories.getCategoryById(id)?.categoryName);
                  return (
                    <UserCard
                      key={teacher.id}
                      showCategories={true}
                      categoryNames={categoryNames}
                      onClick={() => {
                        changeCategoryOnNavigate([
                          ...teacher.categories.values(),
                        ]);
                      }}
                      {...teacher}
                    />
                  );
                })}
              </CSS.CardList>
              {lockedTeachers.length > store.lockedTeachersLimit ? (
                <CSS.Button onClick={() => (store.lockedTeachersLimit += 10)}>
                  Show more
                </CSS.Button>
              ) : null}
              <CSS.StaffRow>
                <CSS.StaffHeaderGroup>
                  <CSS.StaffHeaderCount>
                    {lockedAdmins.length}
                  </CSS.StaffHeaderCount>
                  <CSS.StaffSubHeader>
                    Admin{lockedAdmins.length === 1 ? '' : 's'}
                  </CSS.StaffSubHeader>
                </CSS.StaffHeaderGroup>
              </CSS.StaffRow>
              <CSS.CardList>
                {lockedAdmins.map((admin) => {
                  const categoryNames = [...admin.categories.values()]
                    .slice(0, store.unlockedAdminsLimit)
                    .map((id) => categories.getCategoryById(id)?.categoryName);
                  return (
                    <UserCard
                      key={admin.id}
                      showCategories={true}
                      categoryNames={categoryNames}
                      onClick={() => {
                        changeCategoryOnNavigate([
                          ...admin.categories.values(),
                        ]);
                      }}
                      {...admin}
                    />
                  );
                })}
              </CSS.CardList>
              {lockedAdmins.length > store.lockedAdminsLimit ? (
                <CSS.Button onClick={() => (store.lockedAdminsLimit += 10)}>
                  Show more
                </CSS.Button>
              ) : null}
            </>
          )}
        </div>
      )}
    </CSS.StaffContainer>
  );
});

export default CrossCategorySearch;
