import escapeRegExp from 'lodash.escaperegexp';
import intersection from 'lodash.intersection';
import { types } from 'mobx-state-tree';
import { db } from '../firebase/index.js';
import { addFieldsToReport } from '../utils/reports.js';
import Chat from './Chat.js';

const Chats = types
  .model('Chats', {
    groupChats: types.array(Chat),
    individualChats: types.array(Chat),
    selectedChatId: types.maybeNull(types.string),
    isSelectedChatVisible: types.optional(types.boolean, false),
    fullPageChatId: types.maybeNull(types.string),
  })
  .volatile(() => ({
    listeners: [],
    groupChatListeners: [],
  }))
  .actions((self) => {
    return {
      addIndividualChatAndListen(id) {
        const newChat = Chat.create({ id });
        const unSub = newChat.listenToUpdates('individualChats');
        self['individualChats'].push(newChat);
        return unSub;
      },
      listenToAllGroupChats(selectedCategoryId) {
        if (selectedCategoryId) {
          return db
            .collection('groupChats')
            .where('category', '==', selectedCategoryId)
            .onSnapshot(self.handleGroupChatSnap);
        } else {
          return db
            .collection('groupChats')
            .onSnapshot(self.handleGroupChatSnap);
        }
      },
      listenToUsersChats(usersChatIds, collection) {
        const chatUnSubs = [];
        usersChatIds.forEach((id) => {
          const newChat = Chat.create({ id });
          const chatAndMessagesUnSubs = newChat.listenToUpdates(collection);
          self[collection].push(newChat);
          chatUnSubs.push(...chatAndMessagesUnSubs);
        });
        return chatUnSubs;
      },
      handleGroupChatSnap(querySnapshot) {
        querySnapshot.docChanges().forEach(({ doc, type }) => {
          // const chatData = doc.data();
          if (type === 'added') {
            const newChat = Chat.create({ id: doc.id });
            const chatSubs = newChat.listenToUpdates('groupChats');
            self.groupChats.push(newChat);
            self.addUnSubs(chatSubs);
            self.groupChatListeners.push(...chatSubs);
          }
          // if (type === 'modified') {
          // const chatToUpdate = self.groupChats.find(({ id }) => id === doc.id);
          // chatToUpdate.setChatInfo(chatData)
          // }
          // if (type === 'removed') {
          //   chatToUpdate.setChatInfo(chatData);
          //   self.removeChat(chatToUpdate, 'groupChats');
          // }
        });
      },
      setFullPageChatId(chatId) {
        self.fullPageChatId = chatId;
      },
      setSelectedChatId(chatId) {
        self.selectedChatId = chatId;
      },
      setIsSelectedChatVisible(isVisible) {
        self.isSelectedChatVisible = isVisible;
      },
      toggleChatVisibility() {
        self.isSelectedChatVisible = !self.isSelectedChatVisible;
      },
      clearSelectedChat() {
        self.selectedChatId = null;
        self.isSelectedChatVisible = false;
      },
      createNewIndividualChat(teacher, users) {
        const newChatName = users.reduce((acc, user) => {
          return `${acc} & ${user.displayName}`;
        }, teacher.displayName);
        return db
          .collection('individualChats')
          .add({ chatName: newChatName, category: teacher.selectedCategory })
          .then((newChatRef) => {
            const ownChatInsertion = db
              .collection('users')
              .doc(teacher.id)
              .update({ [`individualChats.${newChatRef.id}`]: newChatRef });
            return Promise.all([newChatRef, ownChatInsertion]);
          })
          .then(([newChatRef]) => {
            const participantsPromises = users.map((participant) => {
              return db
                .collection('users')
                .doc(participant.id)
                .update({ [`individualChats.${newChatRef.id}`]: newChatRef });
            });
            return Promise.all([newChatRef, participantsPromises]);
          });
      },
      removeChat(chatToRemove, collection) {
        self[collection].remove(chatToRemove);
      },
      addUnSubs(unsubscribes) {
        unsubscribes.forEach((unsubscribe) => {
          self.listeners.push(unsubscribe);
        });
      },
      unsubscribeFromAll() {
        self.listeners.forEach((unsubscribe) => {
          if (typeof unsubscribe === 'function') unsubscribe();
        });
      },
      logout() {
        self.groupChats = [];
        self.individualChats = [];
        self.selectedChatId = '';
      },
      clearGroupChats() {
        self.groupChats = [];
        self.groupChatListeners.forEach((unsubscribe) => {
          if (typeof unsubscribe === 'function') unsubscribe();
        });
      },
    };
  })
  .views((self) => {
    return {
      getChatById(chatId, collection) {
        return self[collection].find(({ id }) => chatId === id);
      },
      getChatByParticipants(userIds, collection) {
        return self[collection].find(({ participants }) => {
          const matchedParticipants = intersection(participants, userIds);
          return (
            matchedParticipants.length === userIds.length &&
            participants.length === userIds.length
          );
        });
      },
      getActiveGroupChats({ searchTerm = '', selectedCategoryId } = {}) {
        return self.groupChats.filter(({ active, chatName, category }) => {
          const literalSearchTerm = new RegExp(escapeRegExp(searchTerm), 'i');
          const nameMatches = literalSearchTerm.test(chatName);
          const categoryMatches = category === selectedCategoryId;
          return active && nameMatches && categoryMatches;
        });
      },
      getInactiveGroupChats({ searchTerm = '', selectedCategoryId } = {}) {
        return self.groupChats.filter(({ active, chatName, category }) => {
          const literalSearchTerm = new RegExp(escapeRegExp(searchTerm), 'i');
          const nameMatches = literalSearchTerm.test(chatName);
          const categoryMatches = category === selectedCategoryId;
          return !active && nameMatches && categoryMatches;
        });
      },
      getIndividualChatsByNewestMessages(categoryId) {
        return [...self.individualChats]
          .filter(({ category }) => category === categoryId)
          .sort((a, b) => {
            const aCreatedAt = a.messages[a.messages.length - 1]?.createdAt;
            const bCreatedAt = b.messages[b.messages.length - 1]?.createdAt;
            if (!aCreatedAt && bCreatedAt) return 1;
            if (!bCreatedAt && aCreatedAt) return -1;
            if (!bCreatedAt && !aCreatedAt) return 0;
            return bCreatedAt - aCreatedAt;
          });
      },
      getSelectedChat(test) {
        const selectedGroupChat = self.groupChats.find(
          (groupChat) => groupChat.id === self.selectedChatId
        );
        const selectedIndividualChat = self.individualChats.find(
          (individualChat) => individualChat.id === self.selectedChatId
        );

        if (selectedGroupChat) return [selectedGroupChat, 'groupChats'];
        if (selectedIndividualChat)
          return [selectedIndividualChat, 'individualChats'];
        return [];
      },
      getSelectedChatId() {
        return self.selectedChatId;
      },
      getJSONReport(collection, usersStore, topicsStore, selectedCategoryId) {
        const report = {
          fields: [
            'id',
            'chatName',
            'active',
            'currentModule',
            'whiteboard',
            'participants',
          ],
          data: [],
        };
        self[collection]
          .filter((chat) => chat.category === selectedCategoryId)
          .forEach(
            ({
              id,
              chatName,
              active,
              currentModule,
              whiteboard,
              participants,
              modules,
              messages,
            }) => {
              const chatData = [
                id,
                chatName,
                active,
                currentModule,
                whiteboard,
              ];
              const participantNames = participants.map(
                (id) => usersStore.getUserById(id)?.displayName
              );
              chatData.push(participantNames);

              const moduleNames = [...modules.values()].map(
                ({ module, index }) => {
                  return {
                    id: module,
                    index,
                    moduleName: topicsStore.getModuleById(module)?.moduleName,
                  };
                }
              );
              moduleNames.forEach((module, i) => {
                addFieldsToReport({
                  report,
                  objName: 'module',
                  reportedFields: ['id', 'moduleName'],
                  obj: module,
                  objIndex: module.index,
                  data: chatData,
                });
              });

              report.data.push(chatData);
            }
          );
        return report;
      },
      getMessagesJSONReport(collection, usersStore, selectedCategoryId) {
        const report = {
          fields: [
            'id',
            'text',
            'createdAt',
            'createdBy',
            'chatName',
            'chatId',
            'file',
            'fileType',
          ],
          data: [],
        };
        self[collection]
          .filter((chat) => chat.category === selectedCategoryId)
          .forEach(({ id: chatId, chatName, messages }) => {
            messages.forEach(
              ({ id, text, file, fileType, createdAt, createdBy }, i) => {
                const messageData = [
                  id,
                  text,
                  createdAt.toString(),
                  usersStore.getUserById(createdBy)?.displayName,
                  chatName,
                  chatId,
                  file,
                  fileType,
                ];
                report.data.push(messageData);
              }
            );
          });
        return report;
      },
      generateJSONReportFromDocs(chatSnap, nestedMessages, usersStore) {
        const report = {
          fields: [
            'id',
            'text',
            'createdAt',
            'createdBy',
            'chatName',
            'chatId',
            'file',
            'fileType',
          ],
          data: [],
        };
        chatSnap.docs.forEach((doc, i) => {
          const messages = nestedMessages[i].docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          messages.forEach(
            ({ id, text, file, fileType, createdAt, createdBy }, i) => {
              const messageData = [
                id,
                text,
                new Date(createdAt.seconds * 1000).toString(),
                usersStore.getUserById(createdBy.id)?.displayName,
                doc.data().chatName,
                doc.id,
                file,
                fileType,
              ];
              report.data.push(messageData);
            }
          );
        });
        return report;
      },
      getMessageFilePathsInUse() {
        const filePathsInUse = new Map();
        self.groupChats.forEach((chat) => {
          chat.messages.forEach((message) => {
            if (message.filePath) {
              filePathsInUse.set(message.filePath, true);
            }
          });
        });
        self.individualChats.forEach((chat) => {
          chat.messages.forEach((message) => {
            if (message.filePath) {
              filePathsInUse.set(message.filePath, true);
            }
          });
        });
        return filePathsInUse;
      },
      getChatsWithModule(moduleId) {
        return self.groupChats.filter(({ modules }) => modules.has(moduleId));
      },
      getChatsUnreadCount(loggedInUserId, selectedCategory, collection) {
        let total = 0;
        const chats = self[collection];
        chats.forEach((chat) => {
          const isStaffSelectedChat =
            chat.id === self.selectedChatId && self.isSelectedChatVisible;
          const isFullPageChat = chat.id === self.fullPageChatId;
          if (
            !(isStaffSelectedChat || isFullPageChat) &&
            chat.category === selectedCategory
          ) {
            total += chat.getUnreadCount(loggedInUserId);
          }
        });
        return total;
      },
    };
  });

export default Chats;
