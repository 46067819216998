import { ErrorMessage, Field, Formik } from 'formik';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
import React from 'react';
import * as yup from 'yup';
import * as api from '../../api';
import { ReactComponent as Telephone } from '../../svgs/telephone.svg';
import Loading from '../common/Loading';
import * as CSS from './elements/CreateUserForm';

const resetSchema = yup.object().shape({
  phoneNumber: yup.string().required('Please enter your phone number'),
});

const RequestReset = observer(() => {
  const store = useLocalStore(() => ({
    hasReset: false,
  }));
  const handleSubmit = ({ phoneNumber }, { setFieldError, resetForm }) => {
    return api
      .requestPinReset(phoneNumber)
      .then(() => {
        resetForm();
        store.hasReset = true;
      })
      .catch((err) => {
        setFieldError('phoneNumber', err.response?.data.msg);
      });
  };

  return (
    <Formik
      initialValues={{
        phoneNumber: '',
      }}
      validationSchema={resetSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Observer>
          {() => (
            <CSS.ResetForm data-testid="RequestReset" autoComplete="on">
              <Telephone />
              <CSS.BackLink to="/" aria-label="back">
                <CSS.BigArrowBackIcon />
              </CSS.BackLink>
              {store.hasReset ? (
                <CSS.FormHeader>Check your messages</CSS.FormHeader>
              ) : (
                <>
                  <CSS.FieldGroup>
                    <CSS.FormHeader>Reset your PIN</CSS.FormHeader>
                    <Field
                      label="Phone Number"
                      placeholder="07123456789"
                      type="text"
                      name="phoneNumber"
                      autoComplete="tel"
                      id="phoneNumber"
                      component={CSS.StaffTextFieldInput}
                      inputMode="numeric"
                    />
                    <ErrorMessage name="phoneNumber" component="p" />
                  </CSS.FieldGroup>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <CSS.SignUpButton
                      data-testid="RequestReset-submit"
                      type="submit"
                    >
                      Reset
                    </CSS.SignUpButton>
                  )}
                </>
              )}
            </CSS.ResetForm>
          )}
        </Observer>
      )}
    </Formik>
  );
});

export default RequestReset;
