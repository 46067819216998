import styled from 'styled-components/macro';
import * as common from '../../common/elements/Form';
import * as StaffCommon from '../../common/elements/Staff';

export const Warning = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  background: ${({ theme }) => theme.appBackground};
  border-radius: 15px;
  ${common.ModalScreenWidthMQs}
`;

export const SaveChangesFooterRow = styled(StaffCommon.StaffFooterRow)`
  padding: 0;
  margin: 1em;
  font-size: 1.8rem;
  border: none;
`;

export const SaveChangesMessage = styled.p`
  white-space: pre-line;
  text-align: center;
  margin: 1em;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.textDark};
`;

export const DiscardChangesButton = styled(common.Button)`
  background: ${({ theme }) => theme.buttonCreate};
`;

export const GoBackButton = styled(common.Button)`
  background: ${({ theme }) => theme.buttonWarningGradient};
`;

export const AlertButton = styled(common.Button)`
  background: ${({ theme, isWarning }) =>
    isWarning ? theme.buttonWarningGradient : theme.buttonCreate};
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
