import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import React from 'react';
import styled, { css } from 'styled-components/macro';

export const StaffWhiteboard = styled.section`
  grid-area: staffWhiteboard;
  background: #ffffff;
  box-shadow: 0em 0.4rem 0.4rem rgba(169, 169, 169, 0.25);
  border-radius: 0 0 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  padding: 1em;
  position: relative;
  ${({ isIndividual }) =>
    isIndividual &&
    css`
      align-items: center;
    `}
`;

export const ClassroomTitle = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.headerColour};
`;

export const ClassroomSubHeading = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.subHeaderColour};
  font-size: 1.1rem;
`;

export const WhiteboardContentForm = styled.form`
  min-height: 5em;
  margin: 0;
  margin-top: 0.5em;
  font-weight: 800;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.headerColour};
  border-left: 3px solid ${({ theme }) => theme.classroomHighlight};
  padding-left: 0.5em;
`;

export const Inline = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const WhiteboardInput = styled.textarea`
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  display: block;
  resize: none;
  font-family: inherit;
  font-size: 1.6rem;
  &:disabled {
    color: ${({ theme }) => theme.headerColour};
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const EditIcon = (props) => {
  return <EditOutlinedIcon {...props} style={{ fontSize: '1.6rem' }} />;
};
export const SaveIcon = (props) => {
  return <SaveAltRoundedIcon {...props} style={{ fontSize: '1.6rem' }} />;
};
export const ClearIcon = (props) => {
  return <ClearRoundedIcon {...props} style={{ fontSize: '1.6rem' }} />;
};

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding-top: 1em;
  cursor: pointer;
`;

export * from '../../common/elements/Form';
export * from '../../common/elements/Staff';
export * from './UserCard';
