import { ErrorMessage, FieldArray, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { CategoriesContext } from '../../contexts/Categories';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/SetModuleForm';

const validationSchema = yup.object().shape({
  categories: yup.array().of(yup.object()),
});

const ShareModuleForm = React.forwardRef(
  ({ closeModal, topicId, moduleId }, ref) => {
    const usersStore = useContext(UsersContext);
    const categoriesStore = useContext(CategoriesContext);
    const topicsStore = useContext(TopicsContext);

    const loggedInUser = usersStore.getLoggedInUser();
    const module = topicsStore.getModuleById(moduleId);

    const categoryOptions = categoriesStore
      .getUsersCategories(loggedInUser.categories)
      .filter(({ id }) => id !== loggedInUser.selectedCategory);

    const initialValues = {
      categories: categoriesStore.getUsersCategories(module.categories),
    };

    const handleSubmit = async ({ categories }) => {
      const updatedCategories = categories.reduce((acc, cat) => {
        acc[cat.id] = db.collection('categories').doc(cat.id);
        return acc;
      }, {});
      closeModal();
      await db
        .collection('topics')
        .doc(topicId)
        .collection('modules')
        .doc(moduleId)
        .update({
          categories: updatedCategories,
        });
    };

    return useObserver(() => (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, dirty, setFieldValue, setTouched }) => (
          <Observer>
            {() => (
              <CSS.Form data-testid="ShareModuleForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>Share Module</CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <CSS.FieldGroup>
                  <FieldArray name="categories">
                    {({ form }) => (
                      <Observer>
                        {() => (
                          <CSS.StaffAutocomplete
                            multiple
                            fullWidth
                            disableClearable
                            value={values.categories}
                            onChange={(event, categories, reason) => {
                              form.setFieldValue('categories', categories);
                            }}
                            options={categoryOptions}
                            getOptionLabel={({ categoryName }) => categoryName}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <CSS.StaffAutocompleteInput
                                {...params}
                                label="Choose categories to share with"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </Observer>
                    )}
                  </FieldArray>
                  <ErrorMessage name="categories" component="p" />
                </CSS.FieldGroup>
                <CSS.StaffFooterRow>
                  <CSS.StaffNavButton type="submit" disabled={!dirty}>
                    Save
                  </CSS.StaffNavButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default ShareModuleForm;
