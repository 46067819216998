import React, { createContext } from 'react';
import UsersModel from '../models/Users.js';

const UsersStore = UsersModel.create({ users: {} });

export const UsersContext = createContext(UsersStore);

export const UsersProvider = ({ children }) => {
  return (
    <UsersContext.Provider value={UsersStore}>{children}</UsersContext.Provider>
  );
};
