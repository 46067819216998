import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React from 'react';
import styled from 'styled-components/macro';
import { CreateButton } from '../../common/elements/Form';

export const InboxHeader = styled.header`
  padding: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const InboxTitle = styled.h1`
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 0;
  color: ${({ theme }) => theme.headerColour};
`;

export const PreviewsList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const CreateMessageButton = styled(CreateButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  padding-top: 0.4rem;
`;

export const CreateMessagePlusIcon = (props) => (
  <AddCircleOutlineIcon
    style={{
      fontSize: '1.6rem',
      paddingLeft: '0.3em',
      paddingBottom: '0.1em',
      verticalAlign: 'sub',
    }}
    {...props}
  />
);

export const InboxPlaceholder = styled.p`
  color: ${({ theme }) => theme.textDark};
  border: 1px solid ${({ theme }) => theme.textDark};
  border-radius: 12px;
  width: 75%;
  margin: auto;
  padding: 1.5em;
  font-size: 1.6rem;
  font-weight: 600;
  box-shadow: 0px 4px 4px rgba(169, 169, 169, 0.25);
`;
