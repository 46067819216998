import { navigate } from '@reach/router';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import useLoadingTimer from '../../hooks/useLoadingTimer.js';
import Error from '../common/Error';
import Loading from '../common/Loading';
import Chat from './Chat';
import DirectMessageBoard from './DirectMessageBoard';
import * as CSS from './elements/Classroom.js';

const DirectMessage = observer(({ chatId }) => {
  const whiteboardStore = useLocalStore(() => ({
    height: 0,
  }));

  const isLoadingTimer = useLoadingTimer();
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);
  const thisChat = chatsStore.individualChats.find(
    (chat) => chat.id === chatId
  );

  useEffect(() => {
    chatsStore.setFullPageChatId(chatId);
    return () => {
      chatsStore.setFullPageChatId(null);
    };
  }, [chatId, chatsStore]);

  if (!thisChat && isLoadingTimer) return <Loading />;
  if (!thisChat) return <Error msg="Chat not found" />;

  if (
    thisChat.category !== null &&
    usersStore.getLoggedInUser().selectedCategory !== thisChat.category
  ) {
    navigate('/inbox');
  }

  return (
    <CSS.ClassroomContainer>
      <DirectMessageBoard
        whiteboardStore={whiteboardStore}
        chatId={chatId}
        chatCollection="individualChats"
      />
      <Chat
        chatCollection="individualChats"
        chatId={chatId}
        whiteboardStore={whiteboardStore}
      />
    </CSS.ClassroomContainer>
  );
});

export default DirectMessage;
