import { Modal } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import * as CSS from './elements/SaveChangesModal.js';

const SaveChangesModal = observer((props) => {
  const saveChangesStore = useContext(SaveChangesContext);

  return (
    <Modal
      open={saveChangesStore.isOpen}
      onClose={() => saveChangesStore.close(false)}
      aria-labelledby="save-changes-modal"
      aria-describedby="save-changes-modal"
    >
      <CSS.Warning data-testid="SaveChanges">
        <CSS.StaffFormHeaderRow>
          <CSS.StaffFormHeader>{saveChangesStore.heading}</CSS.StaffFormHeader>
          <CSS.StaffFormCloseButton
            onClick={() => saveChangesStore.close(false)}
            type="button"
          >
            <CSS.StaffCloseIcon />
          </CSS.StaffFormCloseButton>
        </CSS.StaffFormHeaderRow>
        <CSS.SaveChangesMessage>
          {saveChangesStore.message}
        </CSS.SaveChangesMessage>
        <CSS.SaveChangesFooterRow>
          <CSS.DiscardChangesButton
            type="button"
            onClick={() => saveChangesStore.close(true)}
          >
            {saveChangesStore.leavingText}
          </CSS.DiscardChangesButton>
          <CSS.GoBackButton
            type="button"
            onClick={() => saveChangesStore.close(false)}
            autoFocus
          >
            {saveChangesStore.remainingText}
          </CSS.GoBackButton>
        </CSS.SaveChangesFooterRow>
      </CSS.Warning>
    </Modal>
  );
});

export default SaveChangesModal;
