import firebase from 'firebase/app';
import { Field, FieldArray, Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/StudentAssessment';

const StudentAssessment = observer(({ questionSet }) => {
  const validQuestionSets = {
    start: 'initialQuestions',
    end: 'finalQuestions',
  };
  const validQuestionPrefixes = {
    start: 'initial',
    end: 'final',
  };
  const validQuestionSet = validQuestionSets[questionSet];
  const validQuestionPrefix = validQuestionPrefixes[questionSet];

  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();

  const assessment = loggedInUser.private.getActiveAssessmentByCategory(
    loggedInUser.selectedCategory
  );

  useEffect(() => {
    if (assessment && validQuestionSet) {
      db.collection('usersPrivate')
        .doc(usersStore.loggedInUserId)
        .update({
          [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionPrefix}LastChecked.${usersStore.loggedInUserId}`]: firebase.firestore.Timestamp.fromDate(
            new Date()
          ),
        });
    }
  }, [
    assessment,
    loggedInUser.selectedCategory,
    usersStore.loggedInUserId,
    validQuestionPrefix,
    validQuestionSet,
  ]);

  if (!assessment || !validQuestionSet)
    return (
      <div data-testid="StudentAssessment">
        <CSS.CardWithImage isAssessment>
          <CSS.InfoHeader data-testid="StudentAssessment-none">
            No learning plan yet
          </CSS.InfoHeader>
          <CSS.NoAssessmentIcon />
        </CSS.CardWithImage>
      </div>
    );

  const questions = assessment
    .getOrderedQuestions(validQuestionSet)
    .map((question) => question.toJSON());

  const startUncheckedCount = assessment.getUncheckedCount(
    'initial',
    usersStore.loggedInUserId
  );
  const endUncheckedCount = assessment.getUncheckedCount(
    'final',
    usersStore.loggedInUserId
  );

  const handleSubmit = async ({ questions }) => {
    const questionsMap = questions.reduce((questionsMap, question, i) => {
      questionsMap[question.id] = { ...question, index: i };
      return questionsMap;
    }, {});

    await db
      .collection('usersPrivate')
      .doc(usersStore.loggedInUserId)
      .update({
        [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionSet}`]: questionsMap,
        [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionPrefix}LastModified`]: firebase.firestore.Timestamp.fromDate(
          new Date()
        ),
        [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionPrefix}LastChecked.${usersStore.loggedInUserId}`]: firebase.firestore.Timestamp.fromDate(
          new Date()
        ),
      });
  };

  return (
    <div data-testid="StudentAssessment">
      <Formik
        enableReinitialize
        initialValues={{
          questions,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, dirty }) => {
          return (
            <Observer>
              {() => (
                <CSS.AssessmentForm data-testid="StudentAssessment-form">
                  <CSS.AssessmentHeader>My Learning Plan</CSS.AssessmentHeader>
                  <nav>
                    <CSS.UnreadCount
                      badgeContent={startUncheckedCount}
                      data-testid={`unread-count-studentStart`}
                    >
                      <CSS.AssessmentLink to={`/learningPlan/start`}>
                        Start
                      </CSS.AssessmentLink>
                    </CSS.UnreadCount>
                    <CSS.UnreadCount
                      badgeContent={endUncheckedCount}
                      data-testid={`unread-count-studentEnd`}
                    >
                      <CSS.AssessmentLink to={`/learningPlan/end`}>
                        End
                      </CSS.AssessmentLink>
                    </CSS.UnreadCount>
                  </nav>
                  <FieldArray name="questions">
                    {() => (
                      <Observer>
                        {() => (
                          <>
                            <CSS.AnswerList>
                              {values.questions.map((question, i) => (
                                <CSS.AnswerLi
                                  key={question.id}
                                  isComplete={!!question.answer}
                                >
                                  <CSS.StudentQuestion
                                    htmlFor={`questions.${i}.answer`}
                                    isComplete={!!question.answer}
                                  >
                                    {i + 1}. {question.text}
                                    {question.teacherOnly && (
                                      <CSS.LockIcon
                                        style={{ fontSize: '1em' }}
                                      />
                                    )}
                                  </CSS.StudentQuestion>
                                  <Field
                                    data-testid="StudentAssessment-answer"
                                    component={CSS.AssessmentTextArea}
                                    name={`questions.${i}.answer`}
                                    id={`questions.${i}.answer`}
                                    rowsMin={3}
                                    style={{ gridArea: 'answer' }}
                                    disabled={question.teacherOnly}
                                  />
                                </CSS.AnswerLi>
                              ))}
                            </CSS.AnswerList>
                          </>
                        )}
                      </Observer>
                    )}
                  </FieldArray>
                  <CSS.SaveAssessmentButton type="submit" disabled={!dirty}>
                    {dirty ? 'Save' : 'Saved'}
                  </CSS.SaveAssessmentButton>
                </CSS.AssessmentForm>
              )}
            </Observer>
          );
        }}
      </Formik>
    </div>
  );
});

export default StudentAssessment;
