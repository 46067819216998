import styled, { css } from 'styled-components/macro';
import { SaveChangesButton } from '../../common/elements/Form';
import { ActiveButton, StaffSubHeader } from '../../common/elements/Staff';

export const AssessmentTitle = styled(StaffSubHeader)`
  padding: 0;
  margin: 1em 0;
`;

export const QuestionText = styled.p`
  grid-area: question;
  font-size: 1.6rem;
  margin: auto 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AssessmentTag = styled(ActiveButton).attrs({ as: 'p' })`
  margin-left: 1em;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.lessonHighlight};
    `}
`;

export const ArchiveButton = styled(SaveChangesButton)`
  margin-left: 2em;
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Form';
export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
