import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const DragAndDropProvider = ({ children }) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
};

export const ItemTypes = {
  CLASSROOM_MODULE: 'CLASSROOM_MODULE',
};
