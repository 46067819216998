import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import styled from 'styled-components/macro';
import * as StaffCommon from '../../common/elements/Staff';

export const ClassroomCard = styled(StaffCommon.StaffCard).attrs({ as: 'div' })`
  display: grid;
  margin: 0;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'cardHeader activeStatus activeStatus'
    'teacher teacher .'
    'topic topic delete'
    'module module delete';
`;

export const ClassroomCardLi = styled.li`
  margin: 1em 1em 2em 1em;
`;

export const ClassroomHeader = styled.h3`
  grid-area: cardHeader;
  font-weight: bold;
  font-size: 2.8rem;
  color: ${({ theme }) => theme.staffCardHeaderColour};
  margin: 0;
`;

const CardP = styled.p`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

export const Teacher = styled(CardP)`
  grid-area: teacher;
`;

export const Topic = styled(CardP)`
  grid-area: topic;
`;

export const Module = styled(CardP)`
  grid-area: module;
`;

export const Delete = styled.button`
  grid-area: delete;
  margin: 0;
  background: none;
  border: none;
  justify-self: end;
  align-self: end;
  display: flex;
  color: ${({ theme }) => theme.textDark};
`;

export const ActiveClassroom = styled(StaffCommon.ActiveButton)`
  grid-area: activeStatus;
  align-self: start;
  justify-self: end;
  padding-left: 0.6em;
`;

export const MagnifyingGlass = () => (
  <SearchIcon
    style={{
      fontSize: '2rem',
    }}
  />
);

export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
