import { Modal } from '@material-ui/core';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CategoriesContext } from '../../contexts/Categories';
import { ChatsContext } from '../../contexts/Chats';
import { ListenersContext } from '../../contexts/Listeners';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { db, storage } from '../../firebase';
import StarSVG from '../../svgs/StarSVG.js';
import SetCategoryModal from '../common/SetCategoryModal';
import * as CSS from './elements/EditUserProfile';

const EditUserProfile = observer(() => {
  const categoriesStore = useContext(CategoriesContext);

  const store = useLocalStore(() => ({
    error: null,
    setError: (error) => {
      store.error = error;
    },
    isCategoryModalOpen: false,
    setCategoryModal: (open, id) => {
      store.isCategoryModalOpen = open;
      if (id) store.selectedCategoryId = id;
      if (!open) store.selectedCategoryId = null;
    },
    selectedCategoryId: null,
  }));
  const listenersStore = useContext(ListenersContext);
  const topicsStore = useContext(TopicsContext);
  const chatsStore = useContext(ChatsContext);
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();

  const selectedCategory = categoriesStore.getCategoryById(
    loggedInUser.selectedCategory
  );

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file.size > 200 * 1024 * 1024) {
      return store.setError('Max file size: 200mb');
    }
    store.setError(null);

    const storageRef = storage.ref();

    const fileRef = storageRef.child(
      `/users/${loggedInUser.id}/profileImages/profile-image`
    );
    await fileRef.put(file);

    const hasProfileImageId = uuidv4();

    await db
      .collection('users')
      .doc(loggedInUser.id)
      .update({ hasProfileImage: hasProfileImageId });

    const imageURL = await fileRef.getDownloadURL();

    loggedInUser.setHasProfileImage(hasProfileImageId, imageURL);
  };

  const handleLogout = () => {
    loggedInUser.logout();
    localStorage.removeItem('token');

    listenersStore.unsubscribeFromAll();
    topicsStore.unsubscribeFromAll();
    chatsStore.unsubscribeFromAll();

    topicsStore.logout();
    chatsStore.logout();
    usersStore.logout();
    window.location.reload(true);
  };

  const toggleAutoOpenClassroomChat = async () => {
    // stops chat still being open when leaving profile page as it isn't
    // a staff route and otherwise would still be open when leaving
    // a student route - UX reasons
    if (loggedInUser.private.preferences?.autoOpenClassroomChat === true) {
      chatsStore.setSelectedChatId(null);
      chatsStore.setIsSelectedChatVisible(false);
    }
    await db.collection('usersPrivate').doc(loggedInUser.id).update({
      'preferences.autoOpenClassroomChat': !loggedInUser.private.preferences
        ?.autoOpenClassroomChat,
    });
  };

  return (
    <CSS.EditUserProfile>
      <CSS.ProfileImage src={loggedInUser.imageURL} alt="User profile" />
      {store.error && <CSS.FileError>{store.error}</CSS.FileError>}
      <CSS.DisplayName>{loggedInUser.displayName}</CSS.DisplayName>

      <CSS.UploadButton htmlFor="EditUserProfile-upload-file" tabIndex="0">
        Change My Picture
      </CSS.UploadButton>
      <CSS.ImageInput
        id="EditUserProfile-upload-file"
        type="file"
        accept="image/*"
        data-testid="EditUserProfile-change-pic"
        onChange={handleImageChange}
      />
      <CSS.LogoutButton onClick={handleLogout}>Logout</CSS.LogoutButton>
      {['teacher', 'admin'].includes(loggedInUser.role) &&
        loggedInUser.private.preferences !== null && (
          <CSS.PreferencesSection>
            <CSS.FormSubHeader>Preferences</CSS.FormSubHeader>
            <CSS.Label>Classroom chat open by default:</CSS.Label>
            <CSS.LockSwitch
              checked={loggedInUser.private.preferences?.autoOpenClassroomChat}
              onChange={toggleAutoOpenClassroomChat}
              inputProps={{ 'aria-label': 'Toggle preference' }}
            />
          </CSS.PreferencesSection>
        )}
      <CSS.ProfileCardList>
        {loggedInUser.categories.size > 1 && (
          <CSS.CategoryButtonContainer as="li">
            <CSS.InvisibleButton
              onClick={() => store.setCategoryModal(true)}
              data-testid={`Nav-selectedCategoryId-${selectedCategory?.id}`}
            >
              <CSS.CardWithImage isCategory as="div">
                <CSS.SpaceBetweenLink to="/profile">
                  <CSS.ClassroomHeader>
                    {selectedCategory?.categoryName}
                  </CSS.ClassroomHeader>
                  <CSS.CategoryArrow></CSS.CategoryArrow>
                </CSS.SpaceBetweenLink>
              </CSS.CardWithImage>
            </CSS.InvisibleButton>
          </CSS.CategoryButtonContainer>
        )}
        <CSS.CardWithImage isTarget>
          <CSS.SpaceBetweenLink to="/targets">
            <CSS.ClassroomHeader>My Targets</CSS.ClassroomHeader>
            <StarSVG size="5em" />
          </CSS.SpaceBetweenLink>
        </CSS.CardWithImage>
        <CSS.CardWithImage isAssessment>
          <CSS.SpaceBetweenLink to="/learningPlan/start">
            <CSS.ClassroomHeader>My Learning Plan</CSS.ClassroomHeader>
            <CSS.AssessmentIcon />
          </CSS.SpaceBetweenLink>
        </CSS.CardWithImage>
      </CSS.ProfileCardList>
      <Modal
        open={store.isCategoryModalOpen}
        onClose={() => store.setCategoryModal(false)}
      >
        <SetCategoryModal closeModal={() => store.setCategoryModal(false)} />
      </Modal>
    </CSS.EditUserProfile>
  );
});

export default EditUserProfile;
