import { Link } from '@reach/router';
import styled, { css } from 'styled-components/macro';
import RoundProfileImage from '../../common/ProfileImage';

export const MessagePreview = styled.li`
  list-style: none;
  border: 1px solid ${({ theme }) => theme.textDark};
  border-radius: 12px;
  margin: 1.5em;
  box-shadow: 0px 4px 4px rgba(169, 169, 169, 0.25);
  ${({ isStaff }) =>
    isStaff &&
    css`
      border: 1px solid ${({ theme }) => theme.classroomHighlight};
      ${ParticipantProfileImage} {
        border: 3px solid ${({ theme }) => theme.classroomHighlight};
        border-radius: 50%;
      }
    `}
`;
export const MessagePreviewLink = styled(Link)`
  display: grid;
  grid-template-columns: 7em 1fr 3em;
  grid-template-rows: 1.5em 2em 3.5em;
  grid-template-areas:
    'image created-at created-at'
    'image participant .'
    'image text .';
  text-decoration: none;
  width: 100%;
`;

export const ParticipantProfileImage = styled(RoundProfileImage)`
  grid-area: image;
  align-self: center;
  justify-self: center;
  height: 4em;
  width: 4em;
`;

export const MessagePreviewCreatedAt = styled.p`
  grid-area: created-at;
  align-self: start;
  justify-self: end;
  font-size: 1rem;
  color: ${({ theme }) => theme.textDark};
  margin: 0.5em 1em 0 0;
`;

export const ParticipantDisplayName = styled.p`
  grid-area: participant;
  align-self: end;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.headerColour};
  margin: 0;
  padding-right: 1.5em;
`;

export const TextPreview = styled.p`
  grid-area: text;
  align-self: start;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
  padding-right: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export * from '../../common/elements/Notifications';
