import Slide from '@material-ui/core/Slide';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import StarSVG from '../../svgs/StarSVG.js';
import * as CSS from './elements/EvidenceTarget';
import Message from './Message';

const EvidenceTarget = observer(
  ({ isOpen, message, openEvidence, isStaff, evidenceResource }) => {
    const usersStore = useContext(UsersContext);
    const chatsStore = useContext(ChatsContext);
    const topicsStore = useContext(TopicsContext);
    const evidenceAuthor = usersStore.getUserById(message.createdBy);
    const isOwn = evidenceAuthor === usersStore.getLoggedInUser();

    const store = useLocalStore(() => ({
      isShowing: true,
      toggleIsShowing() {
        store.isShowing = !store.isShowing;
      },
      selectedModule: '',
      handleChange(e) {
        store.selectedModule = e.target.value;
      },
    }));

    const usersGroupChats = evidenceAuthor.getUsersGroupChats(
      chatsStore,
      usersStore.getLoggedInUser().selectedCategory
    );

    const uniqueModules = evidenceAuthor.getUniqueActiveModules(
      usersGroupChats,
      topicsStore
    );

    const addTargetEvidence = async (target) => {
      await evidenceAuthor.private.addMessageEvidenceToTarget(
        target.targetId,
        message
      );
      openEvidence(false);
    };

    const addTaskEvidence = async (task) => {
      const { id, ...restOfMessage } = message;
      const newEvidence = { ...restOfMessage, evidenceId: message.id };
      await evidenceAuthor.private.addTaskEvidence(
        task.id,
        newEvidence,
        topicsStore,
        store.selectedModule.id
      );
      openEvidence(false);
    };

    return (
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <CSS.EvidenceTargetContainer
          data-testid="EvidenceTarget"
          isStaff={isStaff}
        >
          <CSS.ChatBackground isShowing={store.isShowing}>
            <CSS.ShowButton onClick={() => store.toggleIsShowing()}>
              {store.isShowing ? <CSS.HideIcon /> : <CSS.ShowIcon />}
            </CSS.ShowButton>
            {isOpen && (
              <Slide
                direction="down"
                in={store.isShowing}
                mountOnEnter
                unmountOnExit
              >
                {message && (
                  <Message message={message} isOwn={isOwn} isEvidencePreview />
                )}
              </Slide>
            )}
            <CSS.CloseButton
              data-testid="close-button"
              onClick={() => openEvidence(false)}
            >
              <CSS.XIcon style={{ fontSize: '3.5rem' }} />
            </CSS.CloseButton>
          </CSS.ChatBackground>
          <CSS.ScrollSection>
            <CSS.TargetEvidenceHeaderRow>
              <StarSVG />
              <CSS.TargetHeader>
                Select a target
                {isOwn ? '' : ` for ${evidenceAuthor.displayName}`}
              </CSS.TargetHeader>
            </CSS.TargetEvidenceHeaderRow>
            <CSS.StudentItemList>
              {evidenceAuthor.private
                .getOrderedPersonalTargets(
                  usersStore.getLoggedInUser().selectedCategory
                )
                .map((target, i) => (
                  <CSS.TargetLi
                    key={target.targetId}
                    onClick={() => addTargetEvidence(target)}
                    complete={target.evidence.length > 0}
                    isTop={i === 0}
                  >
                    {target.targetText}
                  </CSS.TargetLi>
                ))}
            </CSS.StudentItemList>
            {evidenceResource !== 'task' && (
              <>
                <CSS.SelectContainer>
                  <CSS.StaffTextFieldInput
                    select
                    label="Choose a module"
                    id="EvidenceTarget-module"
                    onChange={store.handleChange}
                    value={store.selectedModule}
                    style={{ width: '100%' }}
                  >
                    {uniqueModules.map((module) => {
                      return (
                        <CSS.StaffMenuItem key={module.id} value={module}>
                          {module.moduleName}
                        </CSS.StaffMenuItem>
                      );
                    })}
                  </CSS.StaffTextFieldInput>
                </CSS.SelectContainer>
                <CSS.StudentItemList>
                  {store.selectedModule &&
                    store.selectedModule
                      .getOrderedItems('tasks')
                      .map((task) => {
                        return (
                          <CSS.TaskInstructions
                            key={task.id}
                            onClick={() => addTaskEvidence(task)}
                            complete={evidenceAuthor.private.isTaskEvidenced(
                              task.id
                            )}
                          >
                            {task.instructions}
                          </CSS.TaskInstructions>
                        );
                      })}
                </CSS.StudentItemList>
              </>
            )}
          </CSS.ScrollSection>
        </CSS.EvidenceTargetContainer>
      </Slide>
    );
  }
);

export default EvidenceTarget;
