import { ErrorMessage, Field, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/EditUserPersonalInfoForm';

const validationSchema = yup.object().shape({
  targetText: yup.string().required('Please enter a target'),
});

const AddStudentPersonalTargetForm = React.forwardRef(
  ({ closeModal, studentId }, ref) => {
    const usersStore = useContext(UsersContext);
    const student = usersStore.getUserById(studentId);

    const handleSubmit = async ({ targetText }) => {
      const targetId = uuidv4();
      const index =
        student.private.getMaxPersonalTargetIndex(
          usersStore.getLoggedInUser().selectedCategory
        ) + 1;
      await db
        .collection('usersPrivate')
        .doc(student.id)
        .update({
          [`personalTargets.${targetId}`]: {
            targetId: targetId,
            targetText,
            index,
            evidence: {},
            category: usersStore.getLoggedInUser().selectedCategory,
            isNew: true,
          },
        });
      closeModal();
    };

    return useObserver(() => (
      <Formik
        enableReinitialize
        initialValues={{ targetText: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Observer>
            {() => (
              <CSS.Form data-testid="AddStudentPersonalTargetForm">
                <CSS.StaffFormHeaderRow>
                  <CSS.StaffFormHeader>
                    Add New Student Personal Target
                  </CSS.StaffFormHeader>
                  <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                    <CSS.StaffCloseIcon />
                  </CSS.StaffFormCloseButton>
                </CSS.StaffFormHeaderRow>
                <CSS.FieldGroup>
                  <Field
                    type="text"
                    name="targetText"
                    id="targetText"
                    label="New Target"
                    component={CSS.StaffTextFieldInput}
                  />
                  <ErrorMessage
                    name="targetText"
                    component="p"
                    data-testid="targetText-error"
                  />
                </CSS.FieldGroup>
                <CSS.StaffFooterRow>
                  <CSS.SubmitChangesButton
                    data-testid="AddStudentPersonalTargetForm-submit"
                    type="submit"
                  >
                    add target
                  </CSS.SubmitChangesButton>
                </CSS.StaffFooterRow>
              </CSS.Form>
            )}
          </Observer>
        )}
      </Formik>
    ));
  }
);

export default AddStudentPersonalTargetForm;
