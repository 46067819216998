import { types } from 'mobx-state-tree';

const PrivacyPolicy = types
  .model('PrivacyPolicy', {
    id: types.maybeNull(types.string),
    policy: types.string,
    createdAt: types.Date,
    active: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    updateInfo(updatedPrivacyPolicy) {
      self.policy = updatedPrivacyPolicy.policy;
      self.createdAt = updatedPrivacyPolicy.createdAt;
    },
  }));

export default PrivacyPolicy;
