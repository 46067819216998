import React, { createContext } from 'react';
import AlertModel from '../models/Alert.js';

const AlertStore = AlertModel.create({
  isOpen: false,
});

export const AlertContext = createContext(AlertStore);

export const AlertProvider = ({ children }) => {
  return (
    <AlertContext.Provider value={AlertStore}>{children}</AlertContext.Provider>
  );
};
