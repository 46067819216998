import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as Logo } from '../../../svgs/talk-english-logo.svg';
import { Button } from '../../common/elements/Form';

export const AuthContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  min-height: calc(100vh - max(8.5vh, 67px) - 3em);
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

export const ToggleSignUpInButton = styled(Button)`
  margin-top: 1em;
  background: ${({ theme }) => theme.lessonHighlight};
  color: ${({ theme }) => theme.textLight};
`;

export const TalkEnglishLogo = styled(Logo)`
  min-height: 3em;
`;

export const PNGLogo = styled.img`
  height: 5em;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Fullscreen = styled.main`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${fadeIn} 1s linear;
`;

export * from '../../common/elements/Form';
