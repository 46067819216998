import { navigate } from '@reach/router';
import firebase from 'firebase/app';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { Observer, observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import Error from '../common/Error';
import * as CSS from './elements/StaffStudentAssessment.js';

const StaffStudentAssessment = observer(({ id, studentId, questionSet }) => {
  const usersStore = useContext(UsersContext);
  const saveChangesStore = useContext(SaveChangesContext);
  const student = usersStore.getUserById(studentId);
  const loggedInUser = usersStore.getLoggedInUser();
  const validQuestionSets = {
    start: 'initialQuestions',
    end: 'finalQuestions',
  };
  const validQuestionPrefixes = {
    start: 'initial',
    end: 'final',
  };
  const validQuestionSet = validQuestionSets[questionSet];
  const validQuestionPrefix = validQuestionPrefixes[questionSet];

  const currentAssessment = student?.private.getAssessmentById(
    id,
    loggedInUser.selectedCategory
  );
  const isAssessmentActive = student?.private.getAssessmentActive(
    id,
    loggedInUser.selectedCategory
  );

  useEffect(() => {
    if (isAssessmentActive && validQuestionSet) {
      db.collection('usersPrivate')
        .doc(studentId)
        .update({
          [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionPrefix}LastChecked.${usersStore.loggedInUserId}`]: firebase.firestore.Timestamp.fromDate(
            new Date()
          ),
        });
    }
  }, [
    isAssessmentActive,
    loggedInUser.selectedCategory,
    studentId,
    usersStore.loggedInUserId,
    validQuestionPrefix,
    validQuestionSet,
  ]);

  if (!currentAssessment) {
    return <Error msg={'Learning Plan not found'} />;
  }

  if (!validQuestionSet) return <Error msg="Learning Plan not found" />;

  const startUncheckedCount = currentAssessment.getUncheckedCount(
    'initial',
    usersStore.loggedInUserId
  );
  const endUncheckedCount = currentAssessment.getUncheckedCount(
    'final',
    usersStore.loggedInUserId
  );

  const questions =
    currentAssessment
      .getOrderedQuestions(validQuestionSet)
      ?.map((question) => ({
        ...question.toJSON(),
        answer: question.answer || '',
      })) || [];

  const handleNewAnswers = async ({ questions }) => {
    const questionsMap = questions.reduce((questionsMap, question, i) => {
      questionsMap[question.id] = { ...question, index: i };
      return questionsMap;
    }, {});
    await db
      .collection('usersPrivate')
      .doc(studentId)
      .update({
        [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionSet}`]: questionsMap,
        [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionPrefix}LastModified`]: firebase.firestore.Timestamp.fromDate(
          new Date()
        ),
        [`learningAssessments.${loggedInUser.selectedCategory}.${validQuestionPrefix}LastChecked.${usersStore.loggedInUserId}`]: firebase.firestore.Timestamp.fromDate(
          new Date()
        ),
      });
  };

  const toggleArchive = async () => {
    if (
      student.private.getAssessmentActive(
        id,
        usersStore.getLoggedInUser().selectedCategory
      )
    ) {
      saveChangesStore.setText({
        heading: 'Archive Learning Plan',
        message:
          'Are you sure you wish to archive this Learning Plan? It will not be useable by the student whilst archived.',
        leavingText: 'Yes',
        remainingText: 'Cancel',
      });
      saveChangesStore.open(() => {
        student.archiveCurrentLearningAssessment(
          usersStore.getLoggedInUser().selectedCategory
        );
      });
    } else {
      saveChangesStore.setText({
        heading: 'Activate Learning Plan',
        message:
          'Are you sure you wish to activate this Learning Plan? It will become useable by the student and any existing Learning Plan will be archived.',
        leavingText: 'Yes',
        remainingText: 'Cancel',
      });
      saveChangesStore.open(() => {
        student.activateLearningAssessment(
          currentAssessment.toJSON(),
          usersStore.getLoggedInUser().selectedCategory
        );
      });
    }
  };

  const deleteAssessment = async () => {
    saveChangesStore.setText({
      heading: 'Delete Learning Plan',
      message:
        'Are you sure you wish to permanently delete this Learning Plan? This will delete any answers, archiving is recommended if you are not currently using this Learning Plan.',
      leavingText: 'Delete',
      remainingText: 'Cancel',
    });
    saveChangesStore.open(async () => {
      await student.deleteAssessmentById(
        currentAssessment.id,
        usersStore.getLoggedInUser().selectedCategory
      );
      navigate(`/staff/users/${student.id}/learningPlans`);
    });
  };

  return (
    <div data-testid="StaffStudentAssessment">
      <section>
        <CSS.StaffRow>
          <CSS.TitleContainer>
            <CSS.AssessmentTitle>{currentAssessment.title}</CSS.AssessmentTitle>
            <CSS.AssessmentTag
              active={student.private.getAssessmentActive(
                id,
                usersStore.getLoggedInUser().selectedCategory
              )}
            >
              {student.private.getAssessmentActive(
                id,
                usersStore.getLoggedInUser().selectedCategory
              )
                ? 'Active'
                : 'Archived'}
              <CSS.ActiveIcon />
            </CSS.AssessmentTag>
          </CSS.TitleContainer>
          <CSS.DeleteAssessmentButton onClick={deleteAssessment} type="button">
            Delete
          </CSS.DeleteAssessmentButton>
        </CSS.StaffRow>

        <Formik
          key="existing-assessment"
          enableReinitialize
          initialValues={{
            questions,
          }}
          onSubmit={handleNewAnswers}
        >
          {({ values, dirty }) => {
            return (
              <Observer>
                {() => {
                  saveChangesStore.setEdited(dirty);
                  return (
                    <Form data-testid="StaffStudentAssessment-form">
                      <div>
                        <CSS.AssessmentNavRow>
                          <nav>
                            <CSS.UnreadCount
                              badgeContent={startUncheckedCount}
                              data-testid="start-unchecked"
                            >
                              <CSS.StaffFilterLink
                                to={`/staff/users/${studentId}/learningPlans/${id}/start`}
                                isfirst
                              >
                                Start
                              </CSS.StaffFilterLink>
                            </CSS.UnreadCount>
                            <CSS.UnreadCount
                              badgeContent={endUncheckedCount}
                              data-testid="end-unchecked"
                            >
                              <CSS.StaffFilterLink
                                to={`/staff/users/${studentId}/learningPlans/${id}/end`}
                              >
                                End
                              </CSS.StaffFilterLink>
                            </CSS.UnreadCount>
                            <CSS.ArchiveButton
                              type="button"
                              onClick={toggleArchive}
                            >
                              {student.private.getAssessmentActive(
                                id,
                                usersStore.getLoggedInUser().selectedCategory
                              )
                                ? 'Archive'
                                : 'Activate'}
                            </CSS.ArchiveButton>
                          </nav>
                          <CSS.SaveChangesButton
                            type="submit"
                            disabled={!dirty}
                          >
                            {dirty ? 'Save' : 'Saved'}
                          </CSS.SaveChangesButton>
                        </CSS.AssessmentNavRow>
                      </div>
                      <FieldArray name="questions">
                        {({ push, remove }) => (
                          <Observer>
                            {() => (
                              <CSS.QuestionList>
                                {values.questions.map((question, i) => (
                                  <CSS.AnswerLi key={question.id}>
                                    <CSS.QuestionBullet>
                                      {i + 1}
                                    </CSS.QuestionBullet>
                                    <CSS.QuestionText>
                                      {question.text}
                                    </CSS.QuestionText>
                                    <Field
                                      data-testid="StaffStudentAssessment-answer"
                                      component={CSS.TextArea}
                                      style={{ gridArea: 'answer' }}
                                      name={`questions.${i}.answer`}
                                      rowsMin={3}
                                    />
                                    <ErrorMessage
                                      name={`questions.${i}.answer`}
                                      component={CSS.ErrorMessage}
                                    />
                                    <Field
                                      data-testid="StaffStudentAssessment-teacherOnly"
                                      name={`questions.${i}.teacherOnly`}
                                      component={CSS.Check}
                                    />
                                  </CSS.AnswerLi>
                                ))}
                              </CSS.QuestionList>
                            )}
                          </Observer>
                        )}
                      </FieldArray>
                    </Form>
                  );
                }}
              </Observer>
            );
          }}
        </Formik>
      </section>
    </div>
  );
});

export default StaffStudentAssessment;
