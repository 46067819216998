import AccordionSummary from '@material-ui/core/AccordionSummary';
import { observer, useLocalStore } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import { useWindowSize } from '../../hooks/useWindowSize.js';
import CheckSVGStaff from '../../svgs/CheckSVGStaff';
import CommentForm from '../common/CommentForm';
import Error from '../common/Error';
import EvidenceList from '../common/EvidenceList.js';
import MessageForm from '../messaging/MessageForm';
import * as CSS from './elements/StudentModuleTasks';

const StudentModuleTasks = observer(
  ({ studentId, moduleId, store: evidencingStore }) => {
    const usersStore = useContext(UsersContext);
    const topicsStore = useContext(TopicsContext);
    const windowStore = useWindowSize();

    const commentFormRef = useRef(null);

    const store = useLocalStore(() => ({
      evidenceIdToComment: null,
      taskIdBeingEvidenced: null,
      isAddingEvidence: false,
      taskIdToEvidence: null,
      selectEvidence(evidenceId, taskId, isAddingEvidence = false) {
        store.evidenceIdToComment = evidenceId;
        store.taskIdBeingEvidenced = taskId;
        store.isAddingEvidence = isAddingEvidence;
        store.taskIdToEvidence = null;
      },
      setTaskIdToEvidence(taskId) {
        store.taskIdToEvidence = taskId;
      },
    }));

    useEffect(() => {
      if (store.evidenceIdToComment && commentFormRef.current) {
        commentFormRef.current.scrollIntoView({
          block: 'end',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }, [store.evidenceIdToComment]);

    const student = usersStore.getUserById(studentId);
    const module = topicsStore.getModuleById(moduleId);
    if (!module) return <Error msg="Module not found" />;

    const tasks = module.getOrderedItems('tasks');

    const completionPercent = student.private.getModuleItemsCompletionPercent(
      tasks,
      'tasks'
    );

    const uncommentedCount = module.getUncommentedEvidenceCountByStudent(
      student.private
    );

    const numberOfColumns = windowStore.getColumns();

    return (
      <CSS.StaffContainerSpaceBetween data-testid="StudentModuleTasks">
        <CSS.StaffRow>
          <nav>
            <CSS.UnreadCount badgeContent={0}>
              <CSS.StaffFilterLink
                to={`/staff/users/${studentId}/progress/modules/${moduleId}/learning`}
                isfirst
              >
                Learning
              </CSS.StaffFilterLink>
            </CSS.UnreadCount>
            <CSS.UnreadCount
              badgeContent={uncommentedCount}
              data-testid={`uncommented-count-tasksLink`}
            >
              <CSS.StaffFilterLink
                to={`/staff/users/${studentId}/progress/modules/${moduleId}/tasks`}
              >
                Tasks
              </CSS.StaffFilterLink>
            </CSS.UnreadCount>
          </nav>
          <CSS.ProgressContainer>
            <CSS.ProgressBarWithLabel
              data-testid="StudentModuleTasks-ProgressBar"
              completionPercent={completionPercent}
            />
          </CSS.ProgressContainer>
        </CSS.StaffRow>
        <CSS.ItemListContainer>
          <CSS.ItemList>
            {tasks.length ? (
              tasks.map((task) => {
                const complete = student.private.isTaskEvidenced(task.id);
                const evidence = student.private.getEvidenceByTaskId(
                  task.id,
                  numberOfColumns
                );
                const uncommentedCount = student.private.getUncommentedEvidenceCountByItemId(
                  task.id,
                  'tasks'
                );
                return (
                  <CSS.ItemContainer key={task.id} isTop={task.index === 0}>
                    <CSS.FullWidthUnreadCount
                      badgeContent={uncommentedCount}
                      data-testid={`uncommented-count-${task.id}`}
                    >
                      <CSS.ItemAccordion>
                        <AccordionSummary>
                          <CSS.ItemSummary>
                            <CSS.Circle>
                              <CSS.ItemIndex
                                data-testid={`task-${task.index + 1}`}
                              >
                                {task.index + 1}
                              </CSS.ItemIndex>
                            </CSS.Circle>
                            <CSS.ModuleItemInstructions>
                              {task.instructions}
                            </CSS.ModuleItemInstructions>
                            <CSS.CheckSVGContainer>
                              <CheckSVGStaff complete={complete} />
                            </CSS.CheckSVGContainer>
                          </CSS.ItemSummary>
                        </AccordionSummary>
                        <CSS.ItemDetails>
                          <CSS.ListContainer>
                            <EvidenceList
                              evidence={evidence}
                              resource="task"
                              onEvidenceClick={(evidenceId) =>
                                store.selectEvidence(evidenceId, task.id)
                              }
                              taskId={task.id}
                              studentId={studentId}
                              isStaff
                              store={evidencingStore}
                            />
                            {store.taskIdBeingEvidenced === task.id ? (
                              <CommentForm
                                refProp={commentFormRef}
                                studentId={student.id}
                                taskId={store.taskIdBeingEvidenced}
                                evidenceId={store.evidenceIdToComment}
                                afterSubmit={() =>
                                  store.selectEvidence(null, null)
                                }
                              />
                            ) : store.taskIdToEvidence === task.id ? (
                              <>
                                <CSS.ModuleItemInstructions
                                  as="h3"
                                  style={{ margin: '1em 0' }}
                                >
                                  Adding Evidence for {student.displayName}
                                </CSS.ModuleItemInstructions>
                                <CSS.DeleteButton
                                  onClick={() =>
                                    store.setTaskIdToEvidence(null)
                                  }
                                >
                                  Cancel adding evidence
                                </CSS.DeleteButton>
                                <MessageForm
                                  isStaff={true}
                                  taskId={task.id}
                                  moduleId={moduleId}
                                  userToAddTo={student}
                                ></MessageForm>
                              </>
                            ) : (
                              <CSS.CreateButton
                                style={{ margin: '1em 0' }}
                                onClick={() =>
                                  store.setTaskIdToEvidence(task.id)
                                }
                              >
                                Add evidence
                              </CSS.CreateButton>
                            )}
                          </CSS.ListContainer>
                        </CSS.ItemDetails>
                      </CSS.ItemAccordion>
                    </CSS.FullWidthUnreadCount>
                  </CSS.ItemContainer>
                );
              })
            ) : (
              <CSS.ItemContainer isTop={true}>
                <CSS.ItemAccordion>
                  <CSS.ItemSummary>
                    <CSS.ModuleItemInstructions>
                      No tasks yet
                    </CSS.ModuleItemInstructions>
                  </CSS.ItemSummary>
                </CSS.ItemAccordion>
              </CSS.ItemContainer>
            )}
          </CSS.ItemList>
        </CSS.ItemListContainer>
      </CSS.StaffContainerSpaceBetween>
    );
  }
);

export default StudentModuleTasks;
