import firebase from 'firebase/app';
import cloneDeep from 'lodash.clonedeep';
import { db, storage } from '../firebase';

export async function deleteChatMessages(
  chatId,
  batchSize = 100,
  filePathsInUse
) {
  const collectionRef = db
    .collection('groupChats')
    .doc(chatId)
    .collection('messages');
  const query = collectionRef.orderBy('createdAt').limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });

  async function deleteQueryBatch(db, query, resolve) {
    const snapshot = await query.get();

    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }

    // Delete documents in a batch
    const batch = db.batch();
    const storageDeletes = [];

    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
      const filePath = doc.data().filePath;
      if (filePath) {
        if (!filePathsInUse.has(filePath)) {
          const storageRef = storage.ref();
          const fileRef = storageRef.child(filePath);
          storageDeletes.push(fileRef.delete());
        }
      }
    });
    await batch.commit();
    await Promise.all(storageDeletes);

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      deleteQueryBatch(db, query, resolve);
    });
  }
}

export const lastCheckedToFirestoreDates = (lastChecked) => {
  const dbUpdates = {};
  lastChecked.forEach((lastChecked, id) => {
    dbUpdates[id] = firebase.firestore.Timestamp.fromDate(lastChecked);
  });
  return dbUpdates;
};

export const lastCheckedToJSDates = (lastChecked) => {
  if (!lastChecked) return {};
  const lastCheckedJSDates = {};
  for (let userId in lastChecked) {
    lastCheckedJSDates[userId] = lastChecked[userId].toDate();
  }
  return lastCheckedJSDates;
};
export const lastCheckedJSToFirestoreDates = (lastChecked) => {
  if (!lastChecked) return {};

  const lastCheckedJSDates = {};
  for (let userId in lastChecked) {
    lastCheckedJSDates[userId] = firebase.firestore.Timestamp.fromDate(
      new Date(lastChecked[userId])
    );
  }
  return lastCheckedJSDates;
};

export const assessmentToJSDates = (assessment) => {
  const assessmentClone = cloneDeep(assessment);
  assessmentClone.initialLastModified = assessmentClone.initialLastModified?.toDate();
  assessmentClone.finalLastModified = assessmentClone.finalLastModified?.toDate();
  assessmentClone.initialLastChecked = lastCheckedToJSDates(
    assessmentClone.initialLastChecked
  );
  assessmentClone.finalLastChecked = lastCheckedToJSDates(
    assessmentClone.finalLastChecked
  );
  return assessmentClone;
};

export const assessmentToFirebaseDates = (assessment) => {
  const assessmentClone = cloneDeep(assessment);

  if (assessmentClone.initialLastModified) {
    assessmentClone.initialLastModified = firebase.firestore.Timestamp.fromDate(
      new Date(assessmentClone.initialLastModified)
    );
  }
  if (assessmentClone.finalLastModified) {
    assessmentClone.finalLastModified = firebase.firestore.Timestamp.fromDate(
      new Date(assessmentClone.finalLastModified)
    );
  }

  assessmentClone.initialLastChecked = lastCheckedJSToFirestoreDates(
    assessmentClone.initialLastChecked
  );
  assessmentClone.finalLastChecked = lastCheckedJSToFirestoreDates(
    assessmentClone.finalLastChecked
  );

  return assessmentClone;
};
