import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { TopicsContext } from '../../contexts/Topics';
import { UsersContext } from '../../contexts/Users';
import UploadPendingSVG from '../../svgs/UploadPendingSVG.js';
import UploadSuccessSVG from '../../svgs/UploadSuccessSVG.js';
import * as CSS from './elements/Module.js';

const Tasks = observer(({ moduleId, topicId }) => {
  const usersStore = useContext(UsersContext);
  const topicsStore = useContext(TopicsContext);
  const module = topicsStore.getModuleById(moduleId);
  const loggedInUser = usersStore.getLoggedInUser();
  const tasks = module.getOrderedItems('tasks');
  return (
    <CSS.StudentItemList>
      {tasks.length ? (
        tasks.map((task) => {
          const complete = loggedInUser.private.isTaskEvidenced(task.id);
          const unreadCommentCount = loggedInUser.private.getUnreadCommentsByItemId(
            task.id,
            'tasks'
          );
          return (
            <CSS.ItemLi key={task.id}>
              <CSS.SpaceBetweenLink
                to={`/topics/${topicId}/modules/${moduleId}/tasks/${task.id}`}
              >
                <CSS.StudentTaskNotification
                  badgeContent={unreadCommentCount}
                  data-testid={`unread-count-${task.id}`}
                >
                  <CSS.ItemInstructions complete={complete} hasNotifications>
                    {task.instructions}
                  </CSS.ItemInstructions>
                </CSS.StudentTaskNotification>
                {complete ? (
                  <UploadSuccessSVG taskId={task.id} />
                ) : (
                  <UploadPendingSVG taskId={task.id} />
                )}
              </CSS.SpaceBetweenLink>
            </CSS.ItemLi>
          );
        })
      ) : (
        <CSS.NoItemsLi>
          <CSS.ItemInstructions complete={true}>
            No tasks yet
          </CSS.ItemInstructions>
        </CSS.NoItemsLi>
      )}
    </CSS.StudentItemList>
  );
});

export default Tasks;
