import styled from 'styled-components/macro';
import { StaffSubHeader } from '../../common/elements/Staff';

export const AssessmentTitle = styled(StaffSubHeader)`
  padding: 0;
  margin: 1em 0;
  text-align: center;
`;

export const AssessmentList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export * from '../../common/elements/Assessments';
export * from '../../common/elements/Form';
export * from '../../common/elements/Notifications';
export * from '../../common/elements/Staff';
