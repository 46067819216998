import firebase from 'firebase/app';
import { ErrorMessage, Field, Formik } from 'formik';
import { observer, Observer, useLocalStore } from 'mobx-react';
import React, { useContext, useRef } from 'react';
import * as yup from 'yup';
import { AlertContext } from '../../contexts/Alert.js';
import { UsersContext } from '../../contexts/Users.js';
import { db, storage } from '../../firebase/index.js';
import Message from '../messaging/Message';
import * as CSS from './elements/CommentForm';
import Loading from './Loading.js';

const messageSchema = yup.object().shape({
  text: yup.string().required('Please enter a comment'),
});

const CommentForm = observer(
  ({
    studentId,
    taskId,
    targetId,
    evidenceId,
    afterSubmit = () => {},
    refProp,
  }) => {
    const usersStore = useContext(UsersContext);
    const alertStore = useContext(AlertContext);
    const student = usersStore.getUserById(studentId);
    const evidence = student.private.getEvidenceById(
      evidenceId,
      taskId,
      targetId
    );

    const audioStore = useLocalStore(() => ({
      recorder: null,
      isRecording: false,
      startRecording(setFieldValue) {
        audioStore.isRecording = true;
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          const recorder = new MediaRecorder(stream);
          recorder.addEventListener('dataavailable', (e) => {
            const blob = e.data;
            const audioFile = new File([blob], `audio-${Date.now()}`, {
              type: blob.type,
            });
            setFieldValue('file', audioFile);
            handleFilePreview(setFieldValue, audioFile);
          });
          recorder.start();
          audioStore.recorder = recorder;
        });
      },
      stopRecording() {
        audioStore.recorder.stop();
        audioStore.recorder.stream.getTracks().forEach((i) => i.stop());
        audioStore.isRecording = false;
      },
    }));

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isSafari =
      /Safari/i.test(navigator.userAgent) &&
      !/AlohaBrowser|Android|Brave|Chrome|CrIOS|diigobrowser|DuckDuckGo|EdgiOS|Firefox|FxiOS|KodeiOS|TansoDL/i.test(
        navigator.userAgent
      );

    const imageVideoRef = useRef(null);

    const handleImageVideoChange = (setFieldValue, file) => {
      setFieldValue('file', file);
    };

    const handleFilePreview = (setFieldValue, file) => {
      if (!file) return;
      let reader = new FileReader();
      const match = file.type.match(/^(?<fileType>\w+)\/.*/);
      const { fileType } = match?.groups;
      reader.onloadend = (e) => {
        setFieldValue('filePreview', reader.result);
        setFieldValue('fileType', fileType);
      };
      reader.readAsDataURL(file);
    };

    const initialValues = {
      text: evidence?.comment?.text || '',
      file: null,
      filePreview: evidence?.comment?.file || null,
      fileType: evidence?.comment?.fileType || '',
    };

    const handleSubmit = async ({ text, file, filePreview }, actions) => {
      const newComment = {
        text,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: db.collection('users').doc(usersStore.loggedInUserId),
      };

      if (file) {
        const match = file.type.match(/^(?<fileType>\w+)\/.*/);
        const { fileType } = match.groups;
        const storageRef = storage.ref();
        const filePath = `users/${usersStore.loggedInUserId}/${file.name}${
          fileType === 'audio' ? '.mp3' : '-' + Date.now()
        }`;
        const fileRef = storageRef.child(filePath);
        await fileRef.put(file);

        const fileURL = await fileRef.getDownloadURL();
        newComment.file = fileURL;
        newComment.filePath = filePath;
        newComment.fileType = fileType;
      }

      actions.setFieldValue('file', null);
      actions.setFieldValue('text', '');
      actions.resetForm();

      if (imageVideoRef.current) {
        imageVideoRef.current.value = '';
      }

      const taskOrTargetPath = taskId
        ? `tasks.${taskId}`
        : targetId
        ? `personalTargets.${targetId}`
        : null;

      if (!taskOrTargetPath) return;
      if (filePreview !== initialValues.filePreview) {
        const dbPromises = [
          db
            .collection('usersPrivate')
            .doc(studentId)
            .update({
              [`${taskOrTargetPath}.evidence.${evidence.id}.comment`]: newComment,
            }),
        ];
        if (evidence?.comment?.filePath) {
          const storageRef = storage.ref();
          const fileToRemoveRef = storageRef.child(evidence.comment.filePath);
          dbPromises.push(fileToRemoveRef.delete());
        }
        await Promise.all(dbPromises);
      } else {
        await db
          .collection('usersPrivate')
          .doc(studentId)
          .update({
            [`${taskOrTargetPath}.evidence.${evidence.id}.comment.text`]: newComment.text,
            [`${taskOrTargetPath}.evidence.${evidence.id}.comment.createdAt`]: newComment.createdAt,
            [`${taskOrTargetPath}.evidence.${evidence.id}.comment.createdBy`]: newComment.createdBy,
          });
      }

      afterSubmit();
    };

    return (
      <>
        <CSS.EvidencePreview>
          <CSS.EvidencePreviewHeadingContainer>
            <CSS.EvidencePreviewHeading>
              Submit a comment for the following piece of evidence
            </CSS.EvidencePreviewHeading>
          </CSS.EvidencePreviewHeadingContainer>
          <Message
            message={evidence}
            isPreview
            as="div"
            onDelete={afterSubmit}
          ></Message>
        </CSS.EvidencePreview>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={messageSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, dirty, values, setFieldValue }) => {
            const match = values.file?.type.match(/^(?<fileType>\w+)\/.*/);
            const fileType = match?.groups.fileType;
            return (
              <Observer>
                {() => (
                  <>
                    {values.filePreview && (
                      <CSS.CommentPreview>
                        <CSS.EvidencePreviewHeadingContainer>
                          <CSS.EvidencePreviewHeading>
                            Feedback file preview
                          </CSS.EvidencePreviewHeading>
                        </CSS.EvidencePreviewHeadingContainer>
                        <CSS.MessageContainer>
                          <Message
                            message={{
                              text: values.input,
                              createdBy: usersStore.loggedInUserId.id,
                              file: values.filePreview,
                              fileType: values.fileType,
                            }}
                            onDelete={() => {
                              setFieldValue('filePreview', null);
                              setFieldValue('file', null);
                              if (imageVideoRef.current) {
                                imageVideoRef.current.value = '';
                              }
                            }}
                            isPreview
                            as="div"
                          />
                        </CSS.MessageContainer>
                      </CSS.CommentPreview>
                    )}
                    <CSS.Form data-testid="CommentForm" ref={refProp}>
                      <CSS.CameraLabel
                        htmlFor="CommentForm-upload-file"
                        fileSelected={
                          (!!values.file && fileType === 'image') ||
                          fileType === 'video'
                        }
                      >
                        <CSS.CameraIcon />
                      </CSS.CameraLabel>
                      <CSS.HiddenImageInput
                        ref={imageVideoRef}
                        aria-label="upload-file"
                        id="CommentForm-upload-file"
                        name="upload-file"
                        type="file"
                        accept="image/*, video/*"
                        data-testid={
                          values.file
                            ? 'CommentForm-ImageVideoInput-selected'
                            : 'CommentForm-ImageVideoInput'
                        }
                        onChange={(e) => {
                          handleImageVideoChange(
                            setFieldValue,
                            e.target.files[0]
                          );
                          handleFilePreview(setFieldValue, e.target.files[0]);
                        }}
                      />
                      <Field
                        name="text"
                        id="text"
                        type="text"
                        aria-label="Comment"
                        component={CSS.MessageInput}
                        placeholder="Write something..."
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="text"
                        component={CSS.ValidationMessage}
                      />
                      {audioStore.isRecording ? (
                        <CSS.RecordButton
                          type="button"
                          data-testid="CommentForm-AudioInput-stop"
                          aria-label="Stop"
                          onClick={audioStore.stopRecording}
                          isRecording={audioStore.isRecording}
                        >
                          <CSS.StopIcon />
                        </CSS.RecordButton>
                      ) : (
                        <CSS.RecordButton
                          type="button"
                          data-testid="CommentForm-AudioInput-start"
                          aria-label="Record"
                          onClick={
                            isIOS && !isSafari
                              ? () => {
                                  alertStore.setText({
                                    heading: 'Warning',
                                    text:
                                      'Voice recording on iOS is only available in Safari',
                                    isWarning: true,
                                  });

                                  alertStore.open();
                                }
                              : () => audioStore.startRecording(setFieldValue)
                          }
                          fileSelected={!!values.file && fileType === 'audio'}
                        >
                          <CSS.MicIcon />
                        </CSS.RecordButton>
                      )}
                      <CSS.MessageSubmitButton
                        type="submit"
                        disabled={isSubmitting || !dirty}
                        aria-label="Comment submit"
                      >
                        {isSubmitting ? <Loading /> : <CSS.SendIcon />}
                      </CSS.MessageSubmitButton>
                    </CSS.Form>
                  </>
                )}
              </Observer>
            );
          }}
        </Formik>
      </>
    );
  }
);

export default CommentForm;
