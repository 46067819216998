import firebase from 'firebase/app';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { SaveChangesContext } from '../../contexts/SaveChanges';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from './elements/EditUserPersonalInfoForm';

const validationSchema = yup.object().shape({
  targets: yup.array().of(
    yup.object().shape({
      targetText: yup.string().required('Please enter a target'),
    })
  ),
});

const EditStudentPersonalTargetsForm = React.forwardRef(
  ({ closeModal, studentId }, ref) => {
    const usersStore = useContext(UsersContext);
    const saveChangesStore = useContext(SaveChangesContext);

    const student = usersStore.getUserById(studentId);
    const targets = student.private.getOrderedPersonalTargets(
      usersStore.getLoggedInUser().selectedCategory
    );

    const handleSubmit = async ({ targets }) => {
      const targetUpdates = targets.reduce((updates, target, newIndex) => {
        updates[`personalTargets.${target.targetId}.targetText`] =
          target.targetText;
        updates[`personalTargets.${target.targetId}.index`] = newIndex;
        return updates;
      }, {});

      const existingTargets = student.private.getOrderedPersonalTargets(
        usersStore.getLoggedInUser().selectedCategory
      );
      const targetDeletes = {};
      existingTargets.forEach((target) => {
        if (!targets.find(({ targetId }) => targetId === target.targetId)) {
          targetDeletes[
            `personalTargets.${target.targetId}`
          ] = firebase.firestore.FieldValue.delete();
        }
      });

      closeModal();
      await db
        .collection('usersPrivate')
        .doc(student.id)
        .update({ ...targetUpdates, ...targetDeletes });
    };

    return useObserver(() => (
      <Formik
        enableReinitialize
        initialValues={{
          targets,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty }) => (
          <CSS.Form data-testid="EditStudentPersonalTargetsForm">
            <CSS.StaffFormHeaderRow>
              <CSS.StaffFormHeader>
                Edit Student Personal Targets
              </CSS.StaffFormHeader>
              <CSS.StaffFormCloseButton
                data-testid="EditStudentPersonalTargetsForm-close"
                onClick={closeModal}
                type="button"
              >
                <CSS.StaffCloseIcon />
              </CSS.StaffFormCloseButton>
            </CSS.StaffFormHeaderRow>
            <FieldArray name="targets">
              {({ remove }) => (
                <>
                  {values.targets.map((target, i) => {
                    return (
                      <CSS.TargetFieldGroup key={target.targetId}>
                        <CSS.TargetGroup>
                          <Field
                            type="text"
                            name={`targets.${i}.targetText`}
                            id={`targets.${i}`}
                            label={`Target ${target.index + 1}`}
                            component={CSS.StaffTextFieldInput}
                          />
                          <CSS.DeleteButton
                            data-testid={`${target.targetId}-delete-button`}
                            type="button"
                            onClick={() => {
                              if (Object.keys(target.evidence).length > 0) {
                                saveChangesStore.setText({
                                  heading: 'Delete Personal Target',
                                  message:
                                    'Are you sure you wish to delete this personal target and all associated evidence?',
                                  leavingText: 'Yes',
                                  remainingText: 'No',
                                });
                                saveChangesStore.open(() => remove(i));
                              } else {
                                remove(i);
                              }
                            }}
                          >
                            <CSS.Bin />
                          </CSS.DeleteButton>
                        </CSS.TargetGroup>
                        <ErrorMessage
                          name={`targets.${i}.targetText`}
                          component="p"
                          data-testid={`${target.targetId}-error`}
                        />
                      </CSS.TargetFieldGroup>
                    );
                  })}
                </>
              )}
            </FieldArray>
            <CSS.StaffFooterRow>
              <CSS.SubmitChangesButton
                data-testid="EditStudentPersonalTargetsForm-submit"
                type="submit"
                disabled={!dirty}
              >
                Save changes
              </CSS.SubmitChangesButton>
            </CSS.StaffFooterRow>
          </CSS.Form>
        )}
      </Formik>
    ));
  }
);

export default EditStudentPersonalTargetsForm;
