import firebase from 'firebase/app';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import { ChatsContext } from '../../contexts/Chats';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import useLoadingTimer from '../../hooks/useLoadingTimer';
import Error from '../common/Error';
import Loading from '../common/Loading';
import EvidenceTarget from '../messaging/EvidenceTarget';
import Message from '../messaging/Message';
import MessageForm from '../messaging/MessageForm';
import SayHello from '../messaging/SayHello';
import * as CSS from './elements/StaffChat';

const StaffChat = observer(() => {
  const usersStore = useContext(UsersContext);
  const bottomMessageRef = useRef(null);

  const loadingTimer = useLoadingTimer();

  const store = useLocalStore(() => {
    return {
      evidenceOpen: false,
      messageToEvidence: null,
      scrollId: 1,
      atBottom: true,
      setAtBottom(atBottom) {
        store.atBottom = atBottom;
      },
      openEvidence(isOpen, message = null) {
        store.evidenceOpen = isOpen;
        store.messageToEvidence = message;
      },
    };
  });

  const chatsStore = useContext(ChatsContext);
  const [thisChat, selectedCollection] = chatsStore.getSelectedChat();

  const messagesLength = thisChat?.messages?.length;

  useEffect(() => {
    if (store.atBottom && bottomMessageRef.current) {
      bottomMessageRef.current.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: loadingTimer ? 'auto' : 'smooth',
      });
    }
  }, [store, messagesLength, store.scrollId, loadingTimer]);

  useEffect(() => {
    if (thisChat?.id) {
      db.collection(selectedCollection)
        .doc(thisChat.id)
        .update({
          [`lastChecked.${usersStore.loggedInUserId}`]: firebase.firestore.Timestamp.fromDate(
            new Date(Date.now())
          ),
        });
    }
  }, [selectedCollection, messagesLength, thisChat, usersStore.loggedInUserId]);

  if (!thisChat && loadingTimer) return <Loading />;
  if (!thisChat) return <Error msg="Chat not found" />;

  return (
    <CSS.ChatContainer>
      <CSS.ChatMessagesList data-testid="StaffChat-ChatMessagesList">
        {thisChat.messages.length ? (
          thisChat.messages.map((message, index) => (
            <Message
              key={message.id}
              message={message}
              onLoad={() => (store.scrollId = Math.random())}
              onEvidence={() => store.openEvidence(true, message)}
              ref={
                index === thisChat.messages.length - 1 ? bottomMessageRef : null
              }
              chatId={thisChat.id}
              chatCollection={selectedCollection}
            />
          ))
        ) : (
          <SayHello />
        )}
      </CSS.ChatMessagesList>
      <MessageForm
        chatId={thisChat.id}
        chatCollection={selectedCollection}
        isStaff
        renderLinkInput
      />
      {store.messageToEvidence && (
        <EvidenceTarget
          isOpen={store.evidenceOpen}
          message={store.messageToEvidence}
          openEvidence={store.openEvidence}
          isStaff
        />
      )}
    </CSS.ChatContainer>
  );
});

export default StaffChat;
