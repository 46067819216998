import { Link } from '@reach/router';
import styled from 'styled-components/macro';
import { RoundProfileImage } from '../../common/elements/ProfileImage';
import { StaffCard } from '../../common/elements/Staff';

export const UserCard = styled(StaffCard)`
  width: 24rem;
`;

export const UserLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-decoration: none;
  color: inherit;
`;

export const UserProfileImage = styled(RoundProfileImage)`
  height: 5em;
  width: 5em;
`;

export const UserDisplayName = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.staffCardHeaderColour};
  padding-left: 0.5em;
`;

export const UserCategory = styled.span`
  font-weight: 500;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.staffCardHeaderColour};
  padding-right: 7px;
`;

export const PWithSubscript = styled.div`
  padding-left: 7px;

  ${UserDisplayName} {
    margin: 0;
    padding-left: 0;
  }

  ${UserCard} {
    width: 24rem;
  }
`;
