import React, { useRef } from 'react';
import DefaultProfileImage from '../../svgs/default-profile-image.svg';
import * as CSS from './elements/ProfileImage.js';

const ProfileImage = ({ src, alt, ...props }) => {
  const img = useRef(null);
  const isChrome =
    /Chrome/.test(navigator?.userAgent || '') &&
    /Google Inc/.test(navigator?.vendor || '');
  return (
    <CSS.RoundProfileImage
      src={src || DefaultProfileImage}
      alt={alt || 'profile image'}
      isChrome={isChrome}
      ref={img}
      {...props}
      onError={() => {
        if (img.current) {
          img.current.src = DefaultProfileImage;
        }
      }}
    />
  );
};

export default ProfileImage;
