import { types } from 'mobx-state-tree';

const UserPreferences = types
  .model('UserPreferences', {
    autoOpenClassroomChat: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    updateInfo(updatedUserPreferences) {
      if (updatedUserPreferences.autoOpenClassroomChat !== undefined) {
        self.autoOpenClassroomChat =
          updatedUserPreferences.autoOpenClassroomChat;
      }
    },
  }));

export default UserPreferences;
