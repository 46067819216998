import firebase from 'firebase/app';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { ChatsContext } from '../../contexts/Chats.js';
import { SaveChangesContext } from '../../contexts/SaveChanges.js';
import { UsersContext } from '../../contexts/Users.js';
import { db, storage } from '../../firebase/index.js';
import NoStarSVG from '../../svgs/NoStarSVG.js';
import StarSVG from '../../svgs/StarSVG.js';
import { formatDate } from '../../utils/format-date.js';
import * as CSS from './elements/EvidenceList.js';

const EvidenceList = observer(
  ({
    taskId,
    targetId,
    studentId,
    evidence,
    resource,
    isStaff,
    onEvidenceClick = () => {},
    store,
  }) => {
    const usersStore = useContext(UsersContext);
    const chatsStore = useContext(ChatsContext);
    const saveChangesStore = useContext(SaveChangesContext);
    const student = usersStore.getUserById(studentId);

    const handleDeleteComment = (evidenceId) => {
      const singleEvidence = student.private.getEvidenceById(
        evidenceId,
        taskId,
        targetId
      );
      saveChangesStore.setText({
        heading: 'Delete Comment',
        message: 'Are you sure you wish to delete this comment?',
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(async () => {
        const taskOrTargetPath = taskId
          ? `tasks.${taskId}`
          : targetId
          ? `personalTargets.${targetId}`
          : null;

        if (!taskOrTargetPath) return;

        const deletionPromises = [
          db
            .collection('usersPrivate')
            .doc(studentId)
            .update({
              [`${taskOrTargetPath}.evidence.${evidenceId}.comment`]: null,
            }),
        ];

        if (singleEvidence.comment.file) {
          const storageRef = storage.ref();
          const fileRef = storageRef.child(singleEvidence.comment.filePath);
          deletionPromises.push(fileRef.delete());
        }

        await Promise.all(deletionPromises);
      });
    };

    const handleDeleteEvidence = (evidenceId) => {
      const student = usersStore.getUserById(studentId);
      const singleEvidence = student.private.getEvidenceById(
        evidenceId,
        taskId,
        targetId
      );
      saveChangesStore.setText({
        heading: 'Delete Evidence',
        message:
          'Are you sure you want to delete this evidence and linked comment?',
        leavingText: 'Yes',
        remainingText: 'No',
      });
      saveChangesStore.open(async () => {
        const taskOrTargetPath = taskId
          ? `tasks.${taskId}`
          : targetId
          ? `personalTargets.${targetId}`
          : null;

        if (!taskOrTargetPath) return;
        const deletionPromises = [
          db
            .collection('usersPrivate')
            .doc(studentId)
            .update({
              [`${taskOrTargetPath}.evidence.${evidenceId}`]: firebase.firestore.FieldValue.delete(),
            }),
        ];
        const filePathsInUse = chatsStore.getMessageFilePathsInUse();
        if (
          singleEvidence.filePath &&
          !filePathsInUse.has(singleEvidence.filePath)
        ) {
          const evidenceFileStorageRef = storage.ref();
          const evidenceFileRef = evidenceFileStorageRef.child(
            singleEvidence.filePath
          );
          deletionPromises.push(evidenceFileRef.delete());
        }
        if (singleEvidence.comment?.file) {
          const commentFileStorageRef = storage.ref();
          const commentFileRef = commentFileStorageRef.child(
            singleEvidence.comment.filePath
          );
          deletionPromises.push(commentFileRef.delete());
        }

        await Promise.all(deletionPromises);
      });
    };

    const onEvidence = (evidence) => {
      const evidenceWithStudentId = { ...evidence, createdBy: studentId };
      store.openEvidence(true, evidenceWithStudentId, resource);
    };

    return (
      <CSS.EvidenceList>
        {!evidence[0]?.length
          ? `Learner has not uploaded evidence for this ${resource} yet`
          : evidence.map((column, i) => (
              <CSS.Column key={i}>
                {column.map((evidence) => {
                  const {
                    text,
                    file,
                    fileType,
                    createdAt,
                    index,
                    comment,
                    id,
                  } = evidence;
                  const formattedDate = formatDate(createdAt);
                  const author = usersStore.getUserById(comment?.createdBy);
                  return (
                    <CSS.EvidenceCommentContainer key={`${id}${index}`}>
                      <CSS.EvidenceItem
                        isAudio={fileType === 'audio'}
                        isCommented={!!comment}
                      >
                        <CSS.SpaceBetweenFlex>
                          <CSS.EvidenceCreatedAt>
                            {formattedDate}
                          </CSS.EvidenceCreatedAt>
                          {(isStaff || !comment) && (
                            <CSS.DeleteCommentButton
                              data-testid={`EvidenceList-Delete-Evidence-Button-${id}`}
                              onClick={() => handleDeleteEvidence(id)}
                              aria-label="delete comment"
                            >
                              <CSS.XIcon />
                            </CSS.DeleteCommentButton>
                          )}
                        </CSS.SpaceBetweenFlex>
                        <CSS.Row>
                          <CSS.EvidenceContent resource={resource}>
                            {fileType === 'audio' && (
                              <CSS.EvidenceAudio
                                src={file}
                                data-testid={`${resource}-audio-source`}
                                type="audio/mp3"
                                showJumpControls={false}
                                layout="horizontal-reverse"
                                customProgressBarSection={[
                                  RHAP_UI.PROGRESS_BAR,
                                  RHAP_UI.CURRENT_LEFT_TIME,
                                ]}
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                              />
                            )}
                            {fileType === 'video' && (
                              <CSS.EvidenceVideo
                                controls
                                controlsList="nodownload"
                                disablePictureInPicture
                              >
                                <source
                                  src={file}
                                  data-testid={`${resource}-video-source`}
                                />
                              </CSS.EvidenceVideo>
                            )}
                            {fileType === 'image' && (
                              <CSS.EvidenceImage
                                src={file}
                                data-testid={`${resource}-image`}
                                alt={`${module.moduleName} ${resource} evidence`}
                              />
                            )}
                            {text && (
                              <CSS.EvidenceText>{text}</CSS.EvidenceText>
                            )}
                          </CSS.EvidenceContent>
                          {resource === 'task' && (
                            <CSS.EvidenceButton
                              aria-label="Evidence"
                              onClick={() => onEvidence(evidence)}
                              disabled={student.private.hasMessageBeenEvidenced(
                                id,
                                {
                                  targetsOnly: true,
                                }
                              )}
                            >
                              {student.private.hasMessageBeenEvidenced(id, {
                                targetsOnly: true,
                              }) ? (
                                <StarSVG size={'2em'} targetId={id} />
                              ) : (
                                <NoStarSVG size={'2em'} targetId={id} />
                              )}
                            </CSS.EvidenceButton>
                          )}
                        </CSS.Row>
                        {isStaff && !comment && (
                          <CSS.EditCommentButton
                            data-testid="EvidenceList-add-comment"
                            onClick={() => onEvidenceClick(id)}
                          >
                            <CSS.AddCommentIcon />
                          </CSS.EditCommentButton>
                        )}
                      </CSS.EvidenceItem>
                      {comment && (
                        <CSS.CommentItem data-testid="EvidenceList-comment">
                          <CSS.SpaceBetweenFlex>
                            <CSS.CommentDetails>
                              {formatDate(comment.createdAt)}
                            </CSS.CommentDetails>
                            {isStaff && (
                              <CSS.DeleteCommentButton
                                data-testid="EvidenceList-delete-comment"
                                onClick={() => handleDeleteComment(id)}
                                aria-label="delete comment"
                              >
                                <CSS.XIcon />
                              </CSS.DeleteCommentButton>
                            )}
                          </CSS.SpaceBetweenFlex>
                          <CSS.CommentContent withFile={!!comment.file}>
                            <CSS.CommentText>{comment.text}</CSS.CommentText>
                            {isStaff && (
                              <CSS.EditCommentButton
                                data-testid="EvidenceList-edit-comment"
                                onClick={() => onEvidenceClick(id)}
                              >
                                <CSS.EditCommentIcon />
                              </CSS.EditCommentButton>
                            )}
                            {comment.fileType === 'image' && (
                              <CSS.CommentImage
                                src={comment.file}
                                alt={`${comment.text} image`}
                              />
                            )}
                            {comment.fileType === 'video' && (
                              <CSS.CommentVideo
                                controls
                                controlsList="nodownload"
                                disablePictureInPicture
                              >
                                <source
                                  src={comment.file}
                                  data-testid="EvidenceList-comment-video-source"
                                />
                              </CSS.CommentVideo>
                            )}
                            {comment.fileType === 'audio' && (
                              <CSS.CommentAudio
                                src={comment.file}
                                data-testid="EvidenceList-comment--audio-source"
                                type="audio/mp3"
                                showJumpControls={false}
                                layout="horizontal-reverse"
                                customProgressBarSection={[
                                  RHAP_UI.PROGRESS_BAR,
                                  RHAP_UI.CURRENT_LEFT_TIME,
                                ]}
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                              />
                            )}
                          </CSS.CommentContent>
                          <CSS.CommentDetails>
                            {author?.displayName || 'Deleted User'}
                          </CSS.CommentDetails>
                        </CSS.CommentItem>
                      )}
                    </CSS.EvidenceCommentContainer>
                  );
                })}
              </CSS.Column>
            ))}
      </CSS.EvidenceList>
    );
  }
);

export default EvidenceList;
