import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { navigate } from '@reach/router';
import { FieldArray, Formik } from 'formik';
import { observer } from 'mobx-react';
import { Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { ChatsContext } from '../../contexts/Chats.js';
import { UsersContext } from '../../contexts/Users.js';
import * as CSS from './elements/NewMessageForm.js';

const newMessageSchema = yup.object().shape({
  users: yup.array().of(yup.object()).required(),
});

const NewMessageForm = React.forwardRef(({ closeModal }, ref) => {
  const usersStore = useContext(UsersContext);
  const loggedInUser = usersStore.getLoggedInUser();
  const chatsStore = useContext(ChatsContext);

  const handleSubmit = async ({ users }) => {
    const userIds = [...users, loggedInUser].map((user) => user.id);
    let individualChat = chatsStore.getChatByParticipants(
      userIds,
      'individualChats'
    );
    if (
      !individualChat ||
      individualChat.category !== loggedInUser.selectedCategory
    ) {
      const [newChatRef] = await chatsStore.createNewIndividualChat(
        loggedInUser,
        users
      );
      individualChat = newChatRef;
    }

    navigate(`/messages/${individualChat.id}`);
  };

  return (
    <Formik
      initialValues={{
        users: [],
      }}
      validationSchema={newMessageSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="NewMessageForm">
              <CSS.StaffFormHeaderRow>
                <CSS.StaffFormHeader>Add Users</CSS.StaffFormHeader>
                <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                  <CSS.StaffCloseIcon />
                </CSS.StaffFormCloseButton>
              </CSS.StaffFormHeaderRow>
              <FieldArray name="users">
                {({ form }) => (
                  <CSS.StaffAutocomplete
                    multiple
                    onChange={(event, users, reason) => {
                      form.setFieldValue('users', users);
                    }}
                    options={usersStore
                      .getUsersSortedByRoleAndDisplayName()
                      .filter(({ id }) => id !== loggedInUser.id)}
                    groupBy={(option) => option.role}
                    getOptionLabel={(user) => user.displayName}
                    filterOptions={createFilterOptions({
                      limit: 20,
                    })}
                    renderInput={(params) => (
                      <CSS.StaffAutocompleteInput
                        {...params}
                        label="Type user name"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </FieldArray>
              <CSS.StaffFooterRow>
                <CSS.StaffNavButton type="submit">
                  Start messaging
                </CSS.StaffNavButton>
              </CSS.StaffFooterRow>
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  );
});

export default observer(NewMessageForm);
